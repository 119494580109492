import React from 'react';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import moment from 'moment'
import { InputCtlViewMode } from '../../models/enums';
import checkImg from '../../images/Check-2-64.png';
import crossImg from '../../images/Cross-2-64.png';


import './DateSelectCtl.css';

interface DateSelectCtlProps {
    valueDate?: Date;
    valueStr?: string;
    mode?: InputCtlViewMode;
    style?: React.CSSProperties;
    isRequired?: boolean;
    onUpdate?: (e?: Date, setViewMode?: (newMode: InputCtlViewMode) => void) => void;
}


interface DateSelectCtlState {
    inputDateStr: string;
    inputDateStrValidDate: boolean;
    selectedDate?: Date;
    OrigSelectedDate?: Date;
    mode: InputCtlViewMode;
}

class DateSelectCtl extends React.Component<DateSelectCtlProps, DateSelectCtlState> {
    constructor(props: DateSelectCtlProps) {
        super(props);

        let str = '';
        let dv: Date | undefined = undefined;

        if (this.props.valueDate) {
            let tmp = moment(this.props.valueDate);
            if (tmp.isValid()) {
                str = tmp.format("DD-MMM-yyyy");
                dv = tmp.toDate();
            }
        } else if (this.props.valueStr) {
            let tmp = moment(this.props.valueStr, ["D-MMM-YYYY", "D-M-YYYY", "D-MMM-YY", "D-M-YY", "YYYY-MM-DD", "D/MMM/YYYY", "D/M/YYYY", "D/MMM/YY", "D/M/YY", "YYYY/MM/DD","DD-MMM-yyyy"], true);
            if (tmp.isValid()) {
                str = tmp.format("DD-MMM-yyyy");
                dv = tmp.toDate();
            }
        }


        this.state = {
            inputDateStr: str,
            inputDateStrValidDate: true,
            selectedDate: dv,
            OrigSelectedDate: dv,
            mode: this.props.mode ?? InputCtlViewMode.ReadOnly
        }

    }

    componentDidUpdate(prevProps: DateSelectCtlProps) {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    dateSelectChange = (e: DatePickerChangeEvent) => {
        let dateStr = '';
        let dateVal: Date | undefined;

        if (e.value) {
            let tmp = moment(e.value);
            if (tmp.isValid()) {
                dateVal = e.value;
                dateStr = tmp.format("DD-MMM-yyyy");
            } else {
                dateStr = '';
            }
        }
        this.setState({ inputDateStr: dateStr, inputDateStrValidDate: true, selectedDate: dateVal });
        if (this.props.mode === InputCtlViewMode.Select && this.props.onUpdate)
            this.props.onUpdate(dateVal);
    }

    dateInputChange = (e: InputChangeEvent) => {

        let dateStr = e.value;
        let dateVal: Date | undefined;
        let validDate: boolean = true;

        if (dateStr) {
            let tmp = moment(e.value, ["DD-MMM-YYYY","D-MMM-YYYY", "D-M-YYYY", "D-MMM-YY", "D-M-YY", "YYYY-MM-DD", "D/MMM/YYYY", "D/M/YYYY", "D/MMM/YY", "D/M/YY", "YYYY/MM/DD"], true);
            if (tmp.isValid()) {
                dateVal = tmp.toDate();
            } else {
                validDate = false;
            }

        }
        this.setState({ inputDateStr: dateStr, inputDateStrValidDate: validDate, selectedDate: dateVal });
        if (this.props.mode === InputCtlViewMode.Select && this.props.onUpdate)
            this.props.onUpdate(dateVal);
    }

    // dateClearClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    //     this.setState({ inputDateStr: '', inputDateStrValidDate: true, selectedDate: undefined });
    //     if (this.props.onUpdate)
    //         this.props.onUpdate(undefined);

    // }

    dateSelChangeToEdit = () => {
        this.setState({ mode: InputCtlViewMode.Edit, OrigSelectedDate: this.state.selectedDate });
    }

    dateSelCancelEdit = () => {
        if (this.state.OrigSelectedDate) {
            let tmp = moment(this.state.OrigSelectedDate);
            if (tmp.isValid()) {
                this.setState({ mode: InputCtlViewMode.View, inputDateStr: tmp.format("DD-MMM-yyyy"), inputDateStrValidDate: true, selectedDate: this.state.OrigSelectedDate });
            } else {
                this.setState({ mode: InputCtlViewMode.View, inputDateStr: '', inputDateStrValidDate: true, selectedDate: undefined });
            }
        } else {
            this.setState({ mode: InputCtlViewMode.View, inputDateStr: '', inputDateStrValidDate: true, selectedDate: undefined });
        }
    }

    dateSelSaveChange = () => {
        this.setState({ mode: InputCtlViewMode.Saving }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.selectedDate, this.setViewMode);
        });
    }

    setViewMode = (e: InputCtlViewMode) => {
        this.setState({ mode: e });
    }


    renderDatePicker = (ro: boolean) => {
        let cn = "DatePicker";
        if (!this.state.inputDateStrValidDate)
            cn += " InvalidDate"

        return (<div className={cn}>
            <DatePicker value={this.state.selectedDate} format="dd-MMM-yyyy" disabled={ro} onChange={this.dateSelectChange} />
        </div>
        );



        // return (<div><Input placeholder="-- select date --" value={this.state.inputDateStr} onChange={this.dateInputChange} />
        //     { (this.state.inputDateStr.length > 0) && <div className="clearBtn" onClick={this.dateClearClick}><span className="k-icon k-font-icon k-i-close" /></div>}
        // </div>);
    }

    render() {


        switch (this.state.mode) {
            case InputCtlViewMode.ReadOnly:
            default:
                return (<div className='DateSelectCtl readonly'>{this.state.inputDateStr}</div>)

            case InputCtlViewMode.Select:
                return (this.renderDatePicker(false));

            case InputCtlViewMode.View:
                return (<div className='DateSelectCtl view' onClick={() => this.dateSelChangeToEdit()}>{this.state.inputDateStr}</div>)

            case InputCtlViewMode.Edit:
                return (<div className='DateSelectCtl edit' >
                    {this.renderDatePicker(false)}
                    <img className='check' hidden={this.state.mode !== InputCtlViewMode.Edit} src={checkImg} alt="Save" title='Save' onClick={this.dateSelSaveChange} />
                    <img className='cross' hidden={this.state.mode !== InputCtlViewMode.Edit} src={crossImg} alt="Cancel" title='Cancel' onClick={this.dateSelCancelEdit} />
                </div>);

            case InputCtlViewMode.Saving:
                return (<div className='DateSelectCtl edit' >
                    {this.renderDatePicker(true)}
                    <div className='saving' hidden={this.state.mode !== InputCtlViewMode.Saving}><span className="k-icon k-font-icon k-i-loading" /></div>
                </div>);

        }
    }

}

export default DateSelectCtl;
