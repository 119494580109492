import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import TcbObjProperties from './DetailsControls/TcbObjProperties';
import TcbObjNotes from './DetailsControls/TcbObjNotes';
import TcbObjAttachments from './DetailsControls/TcbObjAttachments';
import TcbObjMilestones from './DetailsControls/TcbObjMilestones';
import TcbObjMembers from './DetailsControls/TcbObjMembers';
import TcbObjQuarantineItems from './DetailsControls/TcbObjQuarantineItems';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import { CustomWindow } from '../models/custom.window'
import { UserInfo, ItemDetailTabRow, TcbObjInfo } from '../models/models';
import { loadingDiv } from '../functions/componentFunctions';
import { addPageViewClass } from '../functions/generalFunctions'

import './ItemDetails.css';
import { FileDisplayModeEnum, PageViewTypeEnum, MembersPageTypeEnum } from '../models/enums';


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


type ItemDetailsProps = PropsFromRedux & {
    menuItemId: number;
    stateToLoad?: ItemDetailsState;
    tcbObj: TcbObjInfo;
    onSaveState?: (menuItemId: number, state: ItemDetailsState) => void;
    defaultTab?: string;
}

interface ItemDetailsState {
    searchInProg: boolean;
    selectedTab?: ItemDetailTabRow;
    tabItems: ItemDetailTabRow[];
    tabItemsFetched: boolean;
    currTabBody: JSX.Element | null;
    tabStripStyle: React.CSSProperties;
    bodyStyle: React.CSSProperties;
}

declare let window: CustomWindow;


class ItemDetails extends React.Component<ItemDetailsProps, ItemDetailsState> {
    constructor(props: ItemDetailsProps) {
        super(props);

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad }
        } else {

            this.state = {
                searchInProg: true,
                tabItems: [],
                tabItemsFetched: false,
                currTabBody: null,
                tabStripStyle: {},
                bodyStyle: {}
            }
        }

    }


    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

        if (!this.state.tabItemsFetched) {
            this.getDetailsTabs();
        }
    }

    componentDidUpdate(prevProps: ItemDetailsProps) {
        if (this.props.defaultTab && this.props.defaultTab !== prevProps.defaultTab) {
            let idt = this.state.tabItems.find(x => x.tabName === this.props.defaultTab);

            if (idt) {
                this.setState({ selectedTab: idt });
            }

        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            var bs: React.CSSProperties = { ...this.state.bodyStyle };
            bs.height = body.clientHeight - 115;

            //mobiles need width to prevent tabstrip push out page width            
            let ts = this.state.tabStripStyle;
            if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
                bs.width = body.clientWidth;
                ts.width = body.clientWidth;
            }
            this.setState({
                bodyStyle: bs,
                tabStripStyle: ts
            });
        }
    }

    getDetailsTabs = () => {
        this.setState({ tabItems: [], searchInProg: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjDetailTabs';

        fetch(url, { method: 'POST', body: JSON.stringify(this.props.tcbObj), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => {
                let gg = resp.json();
                return gg;
            })
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let ti: ItemDetailTabRow[] = res.results;
                        let st = ti.find(x => x);   //find first record, if exists

                        this.setState({ tabItems: ti, selectedTab: st, tabItemsFetched: true, searchInProg: false }
                            , () => { if (this.props.onSaveState) this.props.onSaveState(this.props.menuItemId, this.state) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg: false });
                        break;

                    default:
                        if (res.callStatusMessage)
                            throw (res.callStatusMessage);
                        else
                            throw (res.title)
                }
            })
            .catch(err => {
                alert(err);
                this.setState({ searchInProg: false });
            });

    }

    tabSelectChanged = (e: ItemDetailTabRow) => {
        this.setState({ selectedTab: e });
    }

    updateTabItemState = (e: ItemDetailTabRow | undefined, newState: any) => {

        if (e) {
            let tabitms = this.state.tabItems;
            let itm = tabitms.find(x => x.keyId === e.keyId);
            if (itm) {
                itm.tabState = newState;
            }
            this.setState({ tabItems: tabitms }, () => {
                if (this.props.onSaveState)
                    this.props.onSaveState(this.props.menuItemId, this.state);
            })
        }
    }






    renderSelectedTabItem = (e?: ItemDetailTabRow) => {

        let idt = e;


        if (this.props.defaultTab) {
            idt = this.state.tabItems.find(x => x.tabName === this.props.defaultTab);
        }


        if (!idt)
            return null;

        switch (idt.tabName) {
            case 'Details':
                return (<TcbObjProperties tcbObj={this.props.tcbObj} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />);

            case 'Notes':
                return (<TcbObjNotes tcbObj={this.props.tcbObj} canEdit={idt.canEdit} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />);

            case 'Milestones':
                return (<TcbObjMilestones tcbObj={this.props.tcbObj} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />);

            case 'Attachments':
                return (<TcbObjAttachments key={1} tcbObj={this.props.tcbObj} fileMode={FileDisplayModeEnum.Attachments} canEdit={idt.canEdit} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />)

            case 'Images':
                return (<TcbObjAttachments key={2} tcbObj={this.props.tcbObj} fileMode={FileDisplayModeEnum.Images} canEdit={idt.canEdit} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />)

            case 'Members':
                return (<TcbObjMembers key={1} pageType={MembersPageTypeEnum.Members} tcbObj={this.props.tcbObj} canEdit={idt.canEdit} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />);

            case 'MemberOf':
                return (<TcbObjMembers key={2} pageType={MembersPageTypeEnum.MembersOf} tcbObj={this.props.tcbObj} canEdit={idt.canEdit} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(idt, s) }} />);

            case 'Quarantine':
                return (<TcbObjQuarantineItems tcbObj={this.props.tcbObj} stateToLoad={idt.tabState} onSaveState={(s: any) => { this.updateTabItemState(e, s) }} />);

            default:
                return null;
        }
    }

    render() {
        if (this.state.searchInProg) {
            return loadingDiv();
        }

        if (this.state.tabItems.length === 0) {
            return (<div id="itemDetailsMainDiv">
                <div className='noDetailsError'>
                    Your account is not authorised to view any information for<br /><br />
                    <h3>{this.props.tcbObj.tcbObjDesc}</h3>
                </div>
            </div>);
        } else {

            return (
                <div id="itemDetailsMainDiv">
                    {!this.props.defaultTab &&
                        <div className={"itemDetailsHdrDiv" + addPageViewClass(this.props.pageInf)} style={this.state.tabStripStyle} >
                            {this.state.tabItems.map(itm => {
                                let cn = "itemDetailsTabStripItem" + addPageViewClass(this.props.pageInf);
                                if (itm.keyId === this.state.selectedTab?.keyId)
                                    cn += " selected";
                                return (<div key={itm.tabName} className={cn} onClick={() => this.tabSelectChanged(itm)}>{itm.tabDisplayName} </div>)
                            })}
                        </div>
                    }
                    <div id="itemDetailsBdyDiv" style={this.state.bodyStyle}>
                        {this.renderSelectedTabItem(this.state.selectedTab)}
                    </div>
                </div>
            );
        }
    }
}

export default connector(ItemDetails);
