import { UserInfo } from '../models/models';
import { UPDATE_USERINFO, CLEAR_USERINFO, UserInfoActionType} from './reduxActionTypes';


const istate:UserInfo = new UserInfo();

function userInfo(state:UserInfo=istate, action:UserInfoActionType) {
    switch (action.type) {
        case UPDATE_USERINFO:
            let userinfo = action.userInf;
            return userinfo;

        case CLEAR_USERINFO:
            return new UserInfo();

        default:
            return state;
    }
}

export default userInfo;