import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { Dialog } from '@progress/kendo-react-dialogs';
import { addPageViewClass } from '../../functions/generalFunctions'
import tcbLogoSml from '../../images/Tcb-50.gif';
import './ItemSearchDlg.css';
import Map from '../../functions/map'

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface MapDlgState {
	centerLoc: any;
}
type MapViewerDlgProps = PropsFromRedux & {
	onClose: () => void;
	location: string;
}

class MapDlg extends React.Component<MapViewerDlgProps, MapDlgState> {
	// constructor(props: MapViewerDlgProps) {
	// 	super(props);
	// }



	dlgActionCancel = () => {
		this.props.onClose();
	}


	renderTitle = () => {
		return (
			<table id="dlgTitleStd">
				<thead>
					<tr>
						<td className='left'><img style={{ height: "50px" }} src={tcbLogoSml} alt="TracBASE" /> </td>
						<th>Last Known Location</th>
						<td className='right'><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.dlgActionCancel()}></span></td>
					</tr>
				</thead>
			</table>)
	}



	render() {

		return (
			<Dialog className={"iteamSearchDlg" + addPageViewClass(this.props.pageInf)} title={this.renderTitle()} closeIcon={false}>
				<div style={{ height: "50vh", width: "50vw" }}>
						<Map  location= {this.props.location} />
				</div>
			</Dialog>
		)
	}
}

export default connector(MapDlg);