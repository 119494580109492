import { UserInfo, PageInfo } from '../models/models';

export const UPDATE_USERINFO = "UPDATE_USERINFO";
export const CLEAR_USERINFO = "CLEAR_USERINFO";
export const UPDATE_PAGEINFO = "UPDATE_PAGEINFO";

export type UserInfoActionType = {
    type: string,
    userInf?: UserInfo
}

export type PageInfoActionType = {
    type: typeof UPDATE_PAGEINFO,
    pageInf: PageInfo
}

