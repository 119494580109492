import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent, UploadOnProgressEvent, UploadOnStatusChangeEvent, UploadOnBeforeRemoveEvent, UploadFileRestrictions  } from '@progress/kendo-react-upload';
import {TcbFileUploadDefaultsResp} from '../../models/models';
import {FileDisplayModeEnum} from '../../models/enums';
import './FileUploadCtl.css';

const connector = connect(mapStateToProps, mapDispatchToProps );
type PropsFromRedux = ConnectedProps<typeof connector>;

type FileUploadCtlProps = PropsFromRedux & {
    mode: FileDisplayModeEnum;
    defaultFiles?: Array<UploadFileInfo>;

    onChange: (files: Array<UploadFileInfo>) => void;
}

type FileUploadCtlState = {
    files: Array<UploadFileInfo>,
    fileRestrict: UploadFileRestrictions,
    defaultsLoaded: boolean
}


//class FileUploadCtl extends React.Component<FileUploadCtlProps, FileUploadCtlState> {

function FileUploadCtl(props: FileUploadCtlProps){
    

    // constructor(props: FileUploadCtlProps) {
    //     super(props);


        let defFiles: Array<UploadFileInfo> = [];
        if (props.defaultFiles)
            defFiles = props.defaultFiles;


        let rs:UploadFileRestrictions = {};       

        const [state, setState] = useState<FileUploadCtlState>({
            files: defFiles,
            fileRestrict: rs,
            defaultsLoaded: false
        });

  

    // componentDidMount() {
    //     if (!this.state.defaultsLoaded) {
    //         this.fetchUploadDefaults();
    //     }
    // }

    useEffect(() => {
        if (!state.defaultsLoaded) {
            fetchUploadDefaults();
        }
    }, []);

    const fetchUploadDefaults = () => {

        let url: string = props.userInf.currProject.apiUrl + '/api/file/GetTcbFileUploadDefaults';

        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let defs:TcbFileUploadDefaultsResp = res.result;

                        let rs:UploadFileRestrictions = {};       
                        rs.maxFileSize = defs.maxUploadFileSize;
                        if (props.mode === FileDisplayModeEnum.Images) {
                            rs.allowedExtensions = defs.allowedImageTypes;
                        }
                        if (props.mode === FileDisplayModeEnum.Attachments) {
                            rs.allowedExtensions = defs.allowedAttachmentTypes;
                        }
                        if (props.mode === FileDisplayModeEnum.All) {
                            rs.allowedExtensions = defs.allowedAttachmentTypes.concat(defs.allowedImageTypes);
                        }

                        state.fileRestrict=rs;
                            state.defaultsLoaded= true;


                        setState({ ...state });
                        break;

                    case "Error":
                    default:
                        alert('Error loading Defaults - ' + res.callStatusMessage);
                }
            })
            .catch(err => {
                alert('Error - ' + err.toString());
            });

    }

    // HandleErrors(response: any) {
    //     if (!response.ok) {
    //         throw Error(response.statusText);
    //     }
    //     return response;
    // }

    const onAdd = (e: UploadOnAddEvent) => {
        console.log('onAdd: ', e.affectedFiles);

        state.files= e.newState;
        setState({ ...state });
    }

    const onBeforeRemove = (e:UploadOnBeforeRemoveEvent) => {
        //Assuming only one file removed at a time
        if (e.files.length >= 1) {
            e.additionalData = {'fileId': e.files[0].uid};
        }
    }

    const onRemove = (e: UploadOnRemoveEvent) => {
        console.log('onRemove: ', e.affectedFiles);

        state.files= e.newState;
        setState({ ...state });
    }

    const onProgress = (e: UploadOnProgressEvent) => {
        console.log('onProgress: ', e.affectedFiles);

        state.files= e.newState;
        setState({ ...state });
    }

    const onStatusChange = (e: UploadOnStatusChangeEvent) => {
        state.files= e.newState;
        setState({ ...state });
        // use guid returned from server to link it to TCB object
        if (e.response?.response) {
            const ufi: UploadFileInfo = {
                uid: e.response?.response,
                name: '',
                status: 4,
                progress: 100
            }
            props.onChange([ufi]);
        }
    }


//NOTE: Below block was changed:
//                    saveHeaders={{ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token }}
// responseType="text"
        if (state.defaultsLoaded)
            return (
                <Upload
                    batch={false}
                    multiple={false}
                    files={state.files}
                    restrictions={state.fileRestrict}
                    onAdd={onAdd}
                    onProgress={onProgress}
                    onBeforeRemove={onBeforeRemove}
                    onRemove={onRemove}
                    onStatusChange={onStatusChange}
                    withCredentials={false}
                    saveHeaders={{ 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + props.userInf.token }}
                    responseType="json"
                    saveUrl={props.userInf.currProject.apiUrl + '/api/file/SaveFileForm'}
                    removeMethod='POST'
                    removeHeaders={{ 'Authorization': 'Bearer ' + props.userInf.token }}
                    removeUrl={props.userInf.currProject.apiUrl + '/api/file/RemoveFile'}
                />
            );
        else
                return (<span className="fileUploadDefLoading k-icon k-font-icon k-i-loading" />)
    }


export default connector(FileUploadCtl);