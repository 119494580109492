import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../redux/reduxActions';
import { Upload, UploadFileStatus, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent, UploadOnProgressEvent, UploadOnStatusChangeEvent, UploadOnBeforeRemoveEvent } from '@progress/kendo-react-upload';

import './FileUploadCtl.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type FileUploadCtlProps = PropsFromRedux & {
    authToken: string;
    defaultFiles?: Array<UploadFileInfo>;
    onChange: (files: Array<UploadFileInfo>) => void;
}

interface FileUploadCtlState {
    files: Array<UploadFileInfo>,
}


class FileUploadCtl extends React.Component<FileUploadCtlProps, FileUploadCtlState> {
    constructor(props: FileUploadCtlProps) {
        super(props);


        let defFiles: Array<UploadFileInfo> = [];
        if (this.props.defaultFiles)
            defFiles = this.props.defaultFiles;

        this.state = {
            files: defFiles
        };

    };

    HandleErrors(response: any) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    onAdd = (e: UploadOnAddEvent) => {
        console.log('onAdd: ', e.affectedFiles);

        this.setState({
            files: e.newState
        });
    }

    onBeforeRemove = (e:UploadOnBeforeRemoveEvent) => {
        //Assuming only one file removed at a time
        if (e.files.length >= 1) {
            e.additionalData = {'fileId': e.files[0].uid};
        }
    }

    onRemove = (e: UploadOnRemoveEvent) => {
        console.log('onRemove: ', e.affectedFiles);

        this.setState({
            files: e.newState
        });

        if (e.affectedFiles.length > 0) {
            this.props.onChange(e.newState);
        }
    }

    onProgress = (e: UploadOnProgressEvent) => {
        console.log('onProgress: ', e.affectedFiles);

        this.setState({
            files: e.newState
        });
    }

    onStatusChange = (e: UploadOnStatusChangeEvent) => {
        console.log('onStatusChange: ', e.affectedFiles);

        //Assuming only one file is processed at a time (Batch=False)
        if (e.affectedFiles.length > 0) {

            let fl = e.affectedFiles[0];

            switch (fl.status) {
                case UploadFileStatus.Uploaded:
                    let newFile = e.newState.find(x => x.uid === fl.uid);
                    if (newFile) {
    
                        //Did we get a response "guid"?
                        if (!e.response?.response) {
                            newFile.status = UploadFileStatus.UploadFailed;
                        } else {
                            //Update the UID in relevant file with the response GUID (= FileBase GUID)
                            newFile.uid = e.response?.response;
                            this.props.onChange(e.newState);
                        }
                    }
                break;

            }

        }
        this.setState({
            files: e.newState
        });
    }


    render() {

        return (
            <Upload
                batch={false}
                multiple={true}
                files={this.state.files}
                onAdd={this.onAdd}
                onProgress={this.onProgress}
                onBeforeRemove={this.onBeforeRemove}
                onRemove={this.onRemove}
                onStatusChange={this.onStatusChange}
                withCredentials={false}
                saveHeaders={{ 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + this.props.authToken }}
                saveMethod='POST'
                responseType="text"
                saveUrl={this.props.userInf.currProject.apiUrl + '/api/file/SaveFileForm'}
                removeMethod='POST'
                removeHeaders={{ 'Authorization': 'Bearer ' + this.props.authToken }}
                removeUrl={this.props.userInf.currProject.apiUrl + '/api/file/RemoveFile'}
            />
        )
    }
}
export default connector(FileUploadCtl);