import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Splitter, SplitterPaneProps, SplitterOnChangeEvent, Menu, MenuSelectEvent } from '@progress/kendo-react-layout';
import { Stepper, StepperChangeEvent, StepProps, Step } from '@progress/kendo-react-layout';
import { Grid, GridColumn, GridNoRecords, GridSortChangeEvent, GridFilterChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
//import { GridRowDoubleClickEvent } from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import { filterBy, CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
//import { Button } from '@progress/kendo-react-buttons';

import SearchMain from '../SearchMain';
import { UserInfo, StepperItem, TcbObjInfo, MenuItemModel, PdaMemberAddHistory, MilestoneScanLog } from '../../models/models';
import { addPageViewClass } from '../../functions/generalFunctions'
import { CustomWindow } from '../../models/custom.window'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { PageViewTypeEnum, SearchModeEnum, AddMemberToTcbObjEnum, MemberSelectStatusEnum } from '../../models/enums';
import QtyDialog from '../Dialogs/QtyDialog';
import '@progress/kendo-theme-default/dist/all.css'
import './PdaAddMember.css';

import warningSound from "../../audio/warning.mp3";
import dingSound from "../../audio/ding.mp3";
import bulkSound from "../../audio/bulk.mp3";

const sleep = ms => new Promise(r => setTimeout(r,ms));

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

declare let window: CustomWindow;

const StepperItem_SelectMember = 0;
const StepperItem_AddMembers = 1;

type PdaAddMemberProps = PropsFromRedux & {
    menuItemId: number;
    filterPackListTypeId?: number;
    stateToLoad?: PdaAddMemberState;
    onSaveState?: (menuItemId: number, itemState: any) => void;
    onViewItem?: (tcbObj: TcbObjInfo) => void;
}


interface PdaAddMemberState {
    pageStyle: React.CSSProperties;
    panesListMain: Array<SplitterPaneProps>;
    stepperItems: Array<StepperItem>;
    stepperCurrentIndex: number;

    hideLazerScannerMsg: boolean;
    findMemberResultsControlState?: any;
    findChildResultsControlState?: any;
    searchSelectedObjs: TcbObjInfo[];

    parentMember?: TcbObjInfo;
    parentMemberSelectStatus: MemberSelectStatusEnum;
    parentSelectStatusMsg: string;

    childMember?: TcbObjInfo;    
    childMemberSelectStatus: MemberSelectStatusEnum;
    childSelectStatusMsg: string;
    childAddStatusMsg: string;

    addMemberHistoryList: Array<PdaMemberAddHistory>;
    addMemberHistorySortDescriptor: SortDescriptor[];
    addMemberHistoryFilterDescriptor: CompositeFilterDescriptor | undefined;

    qtyRequired: boolean;
    selectedTcbObj?: TcbObjInfo;
}

class PdaAddMember extends React.Component<PdaAddMemberProps, PdaAddMemberState> {
    constructor(props: PdaAddMemberProps) {
        super(props);

        //Make functions available to WebView
        window.pdaAddMbrRef = this;

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad }
        } else {

            let headerSize = (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) ? '52px' : '55px';

            let plMain: Array<SplitterPaneProps> = [
                { size: headerSize, resizable: false, collapsible: true },
                {},
                { size: '30%', min: '20px', resizable: true, collapsible: true, collapsed: true }
            ];

            this.state = {
                pageStyle: {},
                panesListMain: plMain,
                stepperItems: this.getBaseStepperItems(),
                stepperCurrentIndex: 0,
                hideLazerScannerMsg: (this.props.pageInf.deviceVendor !== "Zebra"),
                searchSelectedObjs: [],
                addMemberHistoryList: [],
                addMemberHistorySortDescriptor: [],
                addMemberHistoryFilterDescriptor: undefined,
                parentMemberSelectStatus: MemberSelectStatusEnum.NotSelected,
                parentSelectStatusMsg: '',
                childMemberSelectStatus: MemberSelectStatusEnum.NotSelected,
                childSelectStatusMsg: '',
                childAddStatusMsg: '',
                qtyRequired: false
            };
        }
    }


    componentDidUpdate(prevProps: PdaAddMemberProps) {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        if (this.props.onSaveState)
            this.props.onSaveState(this.props.menuItemId, this.state);
    }

    getBaseStepperItems = () => {
        let sl = new Array<StepperItem>();
        sl.push({ index: StepperItem_SelectMember, label: 'Select<br/>Parent', icon: 'k-i-select-box', disabled: false, optional: false, current: true, visible: 'true', isValid: true, required: true });
        sl.push({ index: StepperItem_AddMembers, label: 'Add<br/>Members', icon: 'k-i-search', disabled: true, optional: false, current: false, visible: 'true', isValid: true, required: true });

        // if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
        //     sl[StepperItem_SelectMember].label = 'Select';
        //     sl[StepperItem_AddMembers].label = 'Scan';
        // }
        return sl;
    }


    stepperChange = (e: StepperChangeEvent) => {

        let currIdx = e.value;
        let si = this.setCurrentStepperItem(e.value);
        this.setState({ stepperCurrentIndex: currIdx, stepperItems: si });
    }
    splitterChange = (e: SplitterOnChangeEvent) => {
        this.setState({
            panesListMain: e.newState,
        });
    }


    setCurrentStepperItem = (currIdx: number) => {

        let si = this.state.stepperItems;
        si.forEach((x) => {
            if (x.index === currIdx)
                x.current = true;
            else
                x.current = false
        });
        return si;
    }

    childSearchSelectedRowsChanged = (e: TcbObjInfo[]) => {
        if (e.length > 0)
        {
            this.selectChild(e[0] );
        }
    }

    parentSearchSelectedRowsChanged = (e: TcbObjInfo[]) => {
        if (e.length > 0)
        {
            this.selectParent(e[0]);
        }


    }

    selectParent = (e: TcbObjInfo) => {

        let warning= new Audio(warningSound);
        let ding = new Audio(dingSound);

        let url = this.props.userInf.currProject.apiUrl + '/api/details/VerifyTcbObjCanAddMember';
        let body = {
            tcbObjClass: e.tcbObjClass,
            tcbObjId: e.tcbObjId,
            filterPackListTypeId: this.props.filterPackListTypeId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    //Search API uses callStatus to indicate of barcode found
                    case "OK":
                        if (res.result.canAddMembers) {
                            ding.play();
                            let si = this.setCurrentStepperItem(StepperItem_AddMembers);
                            si[StepperItem_AddMembers].disabled = false;
                            this.setState({ stepperCurrentIndex: StepperItem_AddMembers, stepperItems: si, parentMember: e, findChildResultsControlState: undefined });                            
                        } else {
                            warning.play();
                            sleep(2000);
                            this.setState({ parentMemberSelectStatus: MemberSelectStatusEnum.Invalid, parentSelectStatusMsg: res.result.statusMsg });
                            alert(res.result.statusMsg);
                        }   
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        alert(res.callStatus);
                }
            })
            .catch(err => {
                alert(err.toString());
            });

    }

    selectChild = (e: TcbObjInfo) => {

        let warning= new Audio(warningSound);
        let ding = new Audio(dingSound);
        let bulk = new Audio(bulkSound);

        let parent = this.state.parentMember!;


        let url = this.props.userInf.currProject.apiUrl + '/api/details/VerifyTcbObjCanAddChild';
        let body = {
            parentTcbObjClass: parent.tcbObjClass,
            parentTcbObjId: parent.tcbObjId,
            childTcbObjClass: e.tcbObjClass,
            childTcbObjId: e.tcbObjId,
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    //Search API uses callStatus to indicate of barcode found
                    case "OK":
                        if (res.result.canAddMembers) {

                            if (e.tcbObjSundry) {
                                bulk.play();
                                this.setState({ qtyRequired: true, selectedTcbObj: e });
    
                            } else{
                                ding.play();
                                this.addMemberToParent(e);
                            }


                        } else {
                            warning.play();
                            sleep(2000);
                            this.setState({ childMemberSelectStatus: MemberSelectStatusEnum.Invalid, childSelectStatusMsg: res.result.statusMsg });
                            alert(res.result.statusMsg);
                        }   
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        alert(res.callStatusMessage);
                }
            })
            .catch(err => {
                alert(err.toString());
            });

    }


    reAddMemberToParent = (am: PdaMemberAddHistory) => {
        let al = this.state.addMemberHistoryList;

        am.addMemberStatus = AddMemberToTcbObjEnum.Adding;
        am.addMemberStatusMsg = "Add in Progress...";
        am.addMemberDate = moment();
        am.actionLog.push(new MilestoneScanLog("Add Started", "Add in Progress..."));

        let msg = "Member " + am.addObj.tcbObjDesc + " add started. View progress in history list";
        let pl = this.state.panesListMain;
        if (pl[2].collapsed) pl[2].collapsed = false;
        this.setState({ childMemberSelectStatus: MemberSelectStatusEnum.NotSelected, childSelectStatusMsg: '', addMemberHistoryList: al, childAddStatusMsg: msg, panesListMain: pl });                            


        let url = this.props.userInf.currProject.apiUrl + '/api/details/AddMemberToTcbObj';
        let body = {
            parentTcbObjClass: this.state.parentMember!.tcbObjClass,
            parentTcbObjId: this.state.parentMember!.tcbObjId,
            childTcbObjClass: am.addObj.tcbObjClass,
            childTcbObjId: am.addObj.tcbObjId,
            quantity:am.addObj.tcbObjQty
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    //Search API uses callStatus to indicate of barcode found
                    case "OK":
                        let ah = this.state.addMemberHistoryList;
                        if (res.result.addSuccessful) {
                            am.addMemberStatus = AddMemberToTcbObjEnum.AddSuccess;
                            am.addMemberStatusMsg = "Added";
                            am.actionLog.push(new MilestoneScanLog("AddSuccess", "Add Successful"));
                        } else {
                            am.addMemberStatus = AddMemberToTcbObjEnum.AddFail;
                            am.addMemberStatusMsg = res.result.statusMsg;
                            am.actionLog.push(new MilestoneScanLog("AddFail", am.addMemberStatusMsg));
                        }   
                        this.setState({ addMemberHistoryList: ah });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        alert(res.callStatusMessage);
                }
            })
            .catch(err => {
                alert(err.toString());
            });

    }
    addMemberToParent = (cld: TcbObjInfo) => {

        let al = this.state.addMemberHistoryList;

        let am = new PdaMemberAddHistory();
        am.parentObj = this.state.parentMember!;
        am.addObj = cld;
        am.addMemberStatus = AddMemberToTcbObjEnum.Adding;
        am.addMemberStatusMsg = "Add in Progress...";
        am.addMemberDate = moment();
        am.actionLog.push(new MilestoneScanLog("Add Started", "Add in Progress..."));
        al.reverse();
        al.push(am);
        al.reverse();
        

        let msg = "Member " + cld.tcbObjDesc + " add started. View progress in history list";
        let pl = this.state.panesListMain;
        if (pl[2].collapsed)
            pl[2].collapsed = false;
        this.setState({ childMemberSelectStatus: MemberSelectStatusEnum.NotSelected, childSelectStatusMsg: '', addMemberHistoryList: al, childAddStatusMsg: msg, panesListMain: pl });                            


        let url = this.props.userInf.currProject.apiUrl + '/api/details/AddMemberToTcbObj';
        let body = {
            parentTcbObjClass: this.state.parentMember!.tcbObjClass,
            parentTcbObjId: this.state.parentMember!.tcbObjId,
            childTcbObjClass: cld.tcbObjClass,
            childTcbObjId: cld.tcbObjId,
            quantity: cld.tcbObjQty? cld.tcbObjQty : 0
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    //Search API uses callStatus to indicate of barcode found
                    case "OK":
                        let ah = this.state.addMemberHistoryList;
                        if (res.result.addSuccessful) {
                            am.addMemberStatus = AddMemberToTcbObjEnum.AddSuccess;
                            am.addMemberStatusMsg = "Added";
                            am.actionLog.push(new MilestoneScanLog("AddSuccess", "Add Successful"));
                        } else {
                            am.addMemberStatus = AddMemberToTcbObjEnum.AddFail;
                            am.addMemberStatusMsg = res.result.statusMsg;
                            am.actionLog.push(new MilestoneScanLog("AddFail", am.addMemberStatusMsg));
                        }   
                        this.setState({ addMemberHistoryList: ah });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        alert(res.callStatusMessage);
                }
            })
            .catch(err => {
                alert(err.toString());
            });

    }

    removeMemberFromParent = (am: PdaMemberAddHistory) => {

        let al = this.state.addMemberHistoryList;

        am.addMemberStatus = AddMemberToTcbObjEnum.Removing;
        am.addMemberStatusMsg = "Remove in Progress...";
        am.addMemberDate = moment();
        am.actionLog.push(new MilestoneScanLog("Remove Started", "Remove in Progress..."));

        let msg = "Member " + am.addObj.tcbObjDesc + " remove started. View progress in history list";
        let pl = this.state.panesListMain;
        if (pl[2].collapsed)
            pl[2].collapsed = false;
        this.setState({ childMemberSelectStatus: MemberSelectStatusEnum.NotSelected, childSelectStatusMsg: '', addMemberHistoryList: al, childAddStatusMsg: msg, panesListMain: pl });                            


        let url = this.props.userInf.currProject.apiUrl + '/api/details/RemoveMemberFromTcbObj';
        let body = {
            parentTcbObjClass: this.state.parentMember!.tcbObjClass,
            parentTcbObjId: this.state.parentMember!.tcbObjId,
            childTcbObjClass: am.addObj.tcbObjClass,
            childTcbObjId: am.addObj.tcbObjId,
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    //Search API uses callStatus to indicate of barcode found
                    case "OK":
                        let ah = this.state.addMemberHistoryList;
                        if (res.result.removeSuccessful) {
                            am.addMemberStatus = AddMemberToTcbObjEnum.RemoveSuccess;
                            am.addMemberStatusMsg = "Removed";
                            am.actionLog.push(new MilestoneScanLog("RemoveSuccess", "Remove Successful"));
                        } else {
                            am.addMemberStatus = AddMemberToTcbObjEnum.RemoveFail;
                            am.addMemberStatusMsg = res.result.statusMsg;
                            am.actionLog.push(new MilestoneScanLog("RemoveFail", am.addMemberStatusMsg));
                        }   
                        this.setState({ addMemberHistoryList: ah });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        alert(res.callStatusMessage);
                }
            })
            .catch(err => {
                alert(err.toString());
            });

    }



    closeScannerMsg = () => {
        this.setState({ hideLazerScannerMsg: true });
    }
    closeSelectStatusMsg = () => {
        this.setState({ childMemberSelectStatus: MemberSelectStatusEnum.NotSelected, parentSelectStatusMsg: '' });
    }
    closeAddStatusMsg = () => {
        this.setState({ childMemberSelectStatus: MemberSelectStatusEnum.NotSelected, childAddStatusMsg: '' });
    }


        addMemberHistorySortChange = (e: GridSortChangeEvent) => {

        this.setState({
            addMemberHistorySortDescriptor: e.sort
        });

    }

    addMemberHistoryFilterChange = (event: GridFilterChangeEvent) => {

        this.setState({
            addMemberHistoryFilterDescriptor: event.filter,
        });
    }

    MemberAddHistoryContextMenuClick = (e: MenuSelectEvent, dataItem: PdaMemberAddHistory) => {
        //let shl = this.state.addMemberHistoryList;
        // let msh = shl.find(x => x.milestoneScanHistoryId === dataItem.milestoneScanHistoryId)!;

        switch (e.item['action']) {
            case "DeleteChild":
                this.removeMemberFromParent(dataItem);
                break;
            case "ReAddChild":
                this.reAddMemberToParent(dataItem);
                break;
            case "Resubmit":
                if (dataItem.addMemberStatus === AddMemberToTcbObjEnum.AddFail) {
                    this.reAddMemberToParent(dataItem);
                }
                if (dataItem.addMemberStatus === AddMemberToTcbObjEnum.RemoveFail) {
                    this.removeMemberFromParent(dataItem);
                }
                
                break;

            case "ScanLog":
                break;

            case "View Details":
                if (this.props.onViewItem)
                    this.props.onViewItem(dataItem.addObj);
                break;
            default:
        }
    }

    closeQtyDialog = (qty: number) => {
        if (this.state.selectedTcbObj) {

            let thisTcbObj = this.state.selectedTcbObj;

            if (qty && thisTcbObj) {
                thisTcbObj.tcbObjQty = qty;

                this.setState({ qtyRequired: false, selectedTcbObj: undefined })

                this.addMemberToParent(thisTcbObj);


            }

        }

    }


    //Render Functions

    renderHeader = () => {
        return (
            <div id="pamHeaderMain" className={addPageViewClass(this.props.pageInf)}>

                <Stepper className={"pamStepperMain" + addPageViewClass(this.props.pageInf)}
                    linear={false}
                    items={this.state.stepperItems.filter(x => x.visible === 'true')}
                    item={this.renderStepperItem}
                    value={this.state.stepperCurrentIndex}
                    onChange={this.stepperChange} />
                {/* <div id="mssHeaderMilestone">
                    {this.state.selectedMilestone?.milestoneDesc}
                </div> */}
            </div>
        )

    }

    renderStepperItem = (e: StepProps) => {

        let classes = '';
        let isMobile = (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile);

        if (e.current)
            classes += 'pamStepCurrent ';
        else
            classes += 'pamStepNotCurrent ';
        if (isMobile) {
            classes += 'mobile ';
        }

        if (e.disabled) {
            classes += 'pamStepDisabled ';
        } else {
            classes += 'pamStepEnabled ';
        }


        if ((e.index ?? 0) < this.state.stepperCurrentIndex) {
            classes += 'pamStepCompleted ';
        }

        return (<Step  {...e} >

            <div className={classes} dangerouslySetInnerHTML={{ __html: e.label ?? '' }}></div></Step>);
    }

    renderBody = () => {
        switch (this.state.stepperCurrentIndex) {
            case 0:
                return (this.renderMemberSelect());

            case 1:
                return (this.renderChildMembersSelect());

            default:
                return null;
        }
    }

    renderMemberSelectHeader = () => {
        let retVal: JSX.Element[] = [];
        // if (!this.state.hideLazerScannerMsg) {
        //     retVal.push(<tr><th>Scanner Enabled</th><td><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.closeScannerMsg()}/></td></tr>);
        //     retVal.push(<tr><td colSpan={2}>Scan the barcode of the PARENT item (member you want to add items to)</td></tr>);
        // }

        if (this.state.parentMemberSelectStatus === MemberSelectStatusEnum.Invalid) {
            retVal.push(<tr className='error'><th>Invalid Parent</th><td><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.closeSelectStatusMsg()}/></td></tr>);
            retVal.push(<tr className='error'><td colSpan={2}>{this.state.parentSelectStatusMsg}</td></tr>);
        }

        return retVal;
    }
    renderMemberSelect = () => {
        return (<div className="pamBodyMain">
                <table id="pamSearchTable" cellSpacing={0}>
                    <thead>
                        {this.renderMemberSelectHeader()}
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <SearchMain
                                key={1}
                                offsetHeight={90}
                                    menuItemId={this.props.menuItemId}
                                    stateToLoad={this.state.findMemberResultsControlState}
                                    mode={SearchModeEnum.SelectItem}
                                    onScanObjAction={this.selectParent}
                                    onSaveState={(id: number, state: any) => {
                                        this.setState({ findMemberResultsControlState: state })
                                    }}
                                    onSelectedChanged={this.parentSearchSelectedRowsChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>


        </div>
        );
    }

    renderChildMemberSelectHeader = () => {
        let retVal: JSX.Element[] = [];
        retVal.push(<tr className='parent'><th colSpan={2}>Adding to <span>{this.state.parentMember?.tcbObjDesc}</span></th></tr>);

        // if (!this.state.hideLazerScannerMsg) {
        //     retVal.push(<tr><th>Scanner Enabled</th><td><span className="k-icon k-font-icon k-i-close-outline"  onClick={() => this.closeScannerMsg()}/></td></tr>);
        //     retVal.push(<tr><td colSpan={2}>Scan the barcode of the item you want to {this.state.parentMember?.tcbObjDesc}</td></tr>);
        // }

        // if (this.state.childMemberSelectStatus === MemberSelectStatusEnum.Invalid) {
        //     retVal.push(<tr className='error'><th>Unable to Add</th><td><span className="k-icon k-font-icon k-i-close-outline"  onClick={() => this.closeSelectStatusMsg()}/></td></tr>);
        //     retVal.push(<tr className='error'><td colSpan={2}>{this.state.childSelectStatusMsg}</td></tr>);
        // }

        // if (this.state.childAddStatusMsg && this.state.childAddStatusMsg.length > 0) {
        //     retVal.push(<tr className='status'><th>Member queued for Add</th><td><span className="k-icon k-font-icon k-i-close-outline"  onClick={() => this.closeAddStatusMsg()}/></td></tr>);
        //     retVal.push(<tr className='status'><td colSpan={2}>{this.state.childAddStatusMsg}</td></tr>);
        // }

        return retVal;
    }
    renderChildMembersSelect = () => {
        return (<div className="pamBodyMain">
                <table id="pamSearchTable" cellSpacing={0}>
                    <thead>
                        {this.renderChildMemberSelectHeader()}
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <SearchMain
                                key={2}
                                offsetHeight={90}
                                    menuItemId={this.props.menuItemId}
                                    stateToLoad={this.state.findChildResultsControlState}
                                    mode={SearchModeEnum.SelectItem}
                                    onScanObjAction={this.selectChild}
                                    onSaveState={(id: number, state: any) => {
                                        this.setState({ findChildResultsControlState: state });
                                    }}
                                    onSelectedChanged={this.childSearchSelectedRowsChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>


        </div>
        );
    }

    renderGridHistory = () => {

        let filtList = this.state.addMemberHistoryList;
        if (this.state.addMemberHistoryFilterDescriptor)
            filtList = filterBy(this.state.addMemberHistoryList, this.state.addMemberHistoryFilterDescriptor);
        if (this.state.addMemberHistorySortDescriptor.length > 0)
            filtList = orderBy(filtList, this.state.addMemberHistorySortDescriptor);


        return (
            <div id="pamHistoryDiv">
                <div id="pamHistoryHdr">Add Member History</div>
                <div id="pamHistoryBody">
                    <Grid className="pamHistoryGrid"
                        data={filtList}
                        resizable
                        sortable
                        scrollable="scrollable"
                        sort={this.state.addMemberHistorySortDescriptor}
                        onSortChange={this.addMemberHistorySortChange}
                        filter={this.state.addMemberHistoryFilterDescriptor}
                        onFilterChange={this.addMemberHistoryFilterChange}
                        rowRender={this.addMemberHistoryRowRender}
                    >
                        <GridNoRecords>
                            No scans performed
                        </GridNoRecords>
                        <GridColumn field="menu" title=" " resizable={false} width='20px' cell={this.renderAddMemberHistoryContextMenuCell} />
                        <GridColumn field="parentObj.tcbObjDesc" title="Parent" resizable={true} />
                        <GridColumn field="addObj.tcbObjDesc" title="New Member" resizable={true} />
                        <GridColumn title="Status" field="addMemberStatusMsg" resizable={true} />

                    </Grid>
                </div>
            </div>
        );



    }

    addMemberHistoryRowRender = (row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => {
        let cn = "";
        let dataItem: PdaMemberAddHistory = rowProps.dataItem;
        switch (dataItem.addMemberStatus) {
            case AddMemberToTcbObjEnum.AddSuccess:
            case AddMemberToTcbObjEnum.RemoveSuccess:
                    cn = "good";
                break;
            case AddMemberToTcbObjEnum.AddFail:
            case AddMemberToTcbObjEnum.RemoveFail:
                    cn = "error";
                break;
            case AddMemberToTcbObjEnum.Adding:
            case AddMemberToTcbObjEnum.Removing:    
                cn = "inprogress";
                break;
            case AddMemberToTcbObjEnum.NotStarted:
                cn = "";
                break;
            default:
                cn = "inprogress";
        }

        if (cn !== "")
            return (<tr className={cn} {...rowProps}>{rowProps.children}</tr>);
        else
            return (<tr {...rowProps}>{rowProps.children}</tr>);
    }

    renderAddMemberHistoryContextMenuCell = (cellProps: any) => {
        let retCell: JSX.Element;
        let dataItem: PdaMemberAddHistory = cellProps.dataItem;

        let scanHistoryItemMenuItems: MenuItemModel[] = [];
        let dotMenu: MenuItemModel = { items: [], linkRender: () => { return <span id="contextMenuLinkSpan" className="k-icon k-font-icon k-i-more-vertical" /> } };
        scanHistoryItemMenuItems.push(dotMenu);
        switch (dataItem.addMemberStatus) {
            case AddMemberToTcbObjEnum.AddFail:
            case AddMemberToTcbObjEnum.RemoveFail:
                dotMenu.items.push({ text: 'Actions for : ' + dataItem.addObj.tcbObjDesc, action: 'HeaderSingle', cssClass: 'menuTitle', disabled: true, items: [] });
                dotMenu.items.push({ text: 'Scan Details', action: 'ScanLog', cssClass: 'menuItem', disabled: true, items: [] });
                // dotMenu.items.push({text: 'Delete', action: 'DeleteScan', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Re-Submit', action: 'Resubmit', cssClass: 'menuItem', disabled: false, items: [] });
                // dotMenu.items.push({text: 'Scan Log', action: 'ScanLog', cssClass: 'menuItem', disabled: false, items: [] });
                break;
            case AddMemberToTcbObjEnum.AddSuccess:
                dotMenu.items.push({ text: 'Actions for : ' + dataItem.addObj.tcbObjDesc, action: 'HeaderSingle', cssClass: 'menuTitle', disabled: true, items: [] });
                dotMenu.items.push({ text: 'New Member Details', action: 'ViewDetails', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'View Add Log', action: 'ScanLog', cssClass: 'menuItem', disabled: true, items: [] });
                dotMenu.items.push({ text: 'Remove From Parent', action: 'DeleteChild', cssClass: 'menuItem', disabled: false, items: [] });
                // dotMenu.items.push({text: 'Add Photo', action: 'AddPhoto', cssClass: 'menuItem', disabled: false, items: [] });
                // dotMenu.items.push({text: 'Scan Log', action: 'ScanLog', cssClass: 'menuItem', disabled: false, items: [] });
                break;
            case AddMemberToTcbObjEnum.RemoveSuccess:
                    dotMenu.items.push({ text: 'Actions for : ' + dataItem.addObj.tcbObjDesc, action: 'HeaderSingle', cssClass: 'menuTitle', disabled: true, items: [] });
                    dotMenu.items.push({ text: 'New Member Details', action: 'ViewDetails', cssClass: 'menuItem', disabled: false, items: [] });
                    dotMenu.items.push({ text: 'View Remove Log', action: 'ScanLog', cssClass: 'menuItem', disabled: true, items: [] });
                    dotMenu.items.push({ text: 'Re-Add to Parent', action: 'ReAddChild', cssClass: 'menuItem', disabled: false, items: [] });
                    // dotMenu.items.push({text: 'Add Photo', action: 'AddPhoto', cssClass: 'menuItem', disabled: false, items: [] });
                    // dotMenu.items.push({text: 'Scan Log', action: 'ScanLog', cssClass: 'menuItem', disabled: false, items: [] });
                    break;
                default:
                dotMenu.items.push({ text: 'In Progess...', cssClass: 'menuItem', disabled: true, items: [] });
        }

        retCell = <td style={{ paddingLeft: 0 }}>
            <Menu className="cellMenu" items={scanHistoryItemMenuItems}
                openOnClick={true} vertical={true} style={{ width: '10px' }}
                onSelect={(e) => this.MemberAddHistoryContextMenuClick(e, cellProps.dataItem)} />
        </td>
        return retCell;
    }

    renderQtyDialog = () => {
        
        let header = 'Add to ' + this.state.parentMember?.tcbObjDesc;
        
        if (this.state.qtyRequired === true) {
            //There's an scan that requires a quantity

            return <QtyDialog headerText = {header} onClose={this.closeQtyDialog}></QtyDialog>
        }
        //else
        return;
    }


    render() {

        return (
            <div id="pamDiv" style={this.state.pageStyle}>
                <Splitter className="pamSplitterDiv" panes={this.state.panesListMain} style={{ height: '100%' }}
                    orientation={'vertical'}
                    onChange={this.splitterChange} >
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderGridHistory()}
                </Splitter>
                {this.renderQtyDialog()}
            </div>
        );
    }
}

export default connector(PdaAddMember);
