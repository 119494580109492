import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import mmgLogo from '../images/MMG_Logo_64.png';
import { PageViewTypeEnum } from '../models/enums';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import packageJson from '../../package.json';


import './About.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

 type AboutProps = PropsFromRedux;

interface AboutState {
    fetchedAPIVersion: string;
}

class About extends React.Component<AboutProps, AboutState> {

    constructor(props: AboutProps) {
        super(props);

        this.state = {
            fetchedAPIVersion: "",
          };
    };


    componentDidMount() {
        if (this.state.fetchedAPIVersion ==="") {
            this.fetchAPIVersion();
        }
    }

    //todo: get API version here
    fetchAPIVersion = () =>
    {
        let url = this.props.userInf.currProject.apiUrl + '/api/GetVersionAuth';

        let retval = "";

        fetch(url, { method: 'GET',  headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token }) })
        .then(resp => resp.json())
        .then(res => 
            {
            if (res.callStatus !== "OK") 
            {
                this.setState({ fetchedAPIVersion: "TEST" });

            } else{
                alert('Error fetching version info - ' + res.callStatusMessage);
            }

            return retval;
        }
        )



        
    }

    renderDeviceType =() => {
        let devType ="Browser";
        if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile)
            devType = "Mobile";
        else if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet)
            devType = "Tablet";
        return devType;
    }

    render() {
        return (
            <div id="aboutOuterDiv">
                <div className="mainDiv" >
                    <div className="titleTxt">TracBASE</div>
                    <div className="titleSubTxt">Version {packageJson.version}</div>
                    <div className="titleSubTxt">Version {this.state.fetchedAPIVersion}</div>
                    <table className="aboutDetailsTbl">
                        <tbody>
                            <tr>
                                <th>User :</th>
                                <td>{this.props.userInf.displayName + ' (' + this.props.userInf.username + ')'}</td>
                            </tr>
                            <tr>
                                <th>Project :</th>
                                <td>{this.props.userInf.currProject.projectName}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr>
                                <th colSpan={2} style={{textAlign:"center"}}>Device</th>
                            </tr>
                            <tr>
                                <th>Type :</th>
                                <td>{this.renderDeviceType()}</td>
                            </tr>
                            <tr>
                                <th>Model :</th>
                                <td>{this.props.pageInf.deviceModel}</td>
                            </tr>
                            <tr>
                                <th>Vendor :</th>
                                <td>{this.props.pageInf.deviceVendor}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td><img src={mmgLogo} alt="MMG Logo" /></td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Materials Management Group</th>
                                            </tr>
                                            <tr>
                                                <td><a href="www.matmangroup.com.au">www.matmangroup.com.au</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>);
    }
}

export default connector(About);
