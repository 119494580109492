import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import Login from '../login/Login';
import SearchResults from './SearchResults';
import { UserInfo, SearchTypeSummaryResults, SearchResultsCriteria, TcbObjInfo } from '../models/models';
import { loadingDiv } from '../functions/componentFunctions';
import { addPageViewClass } from '../functions/generalFunctions'
import { CustomWindow } from '../models/custom.window'
import { PageViewTypeEnum, SearchModeEnum, TcbObjClassEnum } from '../models/enums';

import './SearchMain.css';

const searchTimerDelay = 2000;

declare function invokeEnableZebraScannerAction(callBackFunc: string, callBackId: string): void;
declare function invokeDisableZebraScannerAction(): void;
declare function invokeOpenCameraScannerAction(callBackFunc: string, callBackId: string): void;

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
declare let window: CustomWindow;

type SearchMainProps = PropsFromRedux & {
    menuItemId: number;
    stateToLoad?: SearchMainState;
    mode: SearchModeEnum;
    offsetHeight?: number;
    milestoneId?: number;
    clearSelection?: boolean;
    onOpenObjAction?: (e: TcbObjInfo) => void;
    onOpenObjsAction?: (e: TcbObjInfo[]) => void;
    onScanObjAction?: (e: TcbObjInfo) => void;
    onScanObjsAction?: (e: TcbObjInfo[]) => void;
    onSaveState?: (menuItemId: number, state: SearchMainState) => void;
    onSaveMenuItem?: (menuItemId: number, state: SearchMainState, menuCustomTxt: string) => void;
    onSelectedChanged?: (e: TcbObjInfo[]) => void;
}

enum SearchResultsViewEnum {
    Blank,
    InProgress,
    Summary,
    Results
}

interface SearchMainState {
    searchStr: string;
    searchResultsStatus: SearchResultsViewEnum;
    searchResultsStatusTxt: string;
    resultsSummaryData: Array<SearchTypeSummaryResults>;
    searchResultsCriteria: SearchResultsCriteria;
    searchResultsState?: any;
    selectedSearchResults: TcbObjInfo[];
    showCameraScan: boolean;
    searchPopupShow: boolean;
    clearSelection: boolean;
}

class SearchMain extends React.Component<SearchMainProps, SearchMainState> {
    constructor(props: SearchMainProps) {
        super(props);

        //Make functions available to WebView
        window.srchMainRef = this;

        // let gg = new AbortController()

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad }
        } else {

            let showCam = false;
            if (this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser) {
                showCam = true;
            }

            this.state = {
                searchStr: '',
                searchResultsStatus: SearchResultsViewEnum.Blank,
                searchResultsStatusTxt: '',
                resultsSummaryData: [],
                searchResultsCriteria: new SearchResultsCriteria(),
                selectedSearchResults: [],
                showCameraScan: showCam,
                searchPopupShow: false,
                clearSelection: this.props.clearSelection ? this.props.clearSelection : false
            };
        }


        this.timer = null;
    }

    private timer: NodeJS.Timeout | null;
    private searchPopupAnchor: any;


    componentDidUpdate(prevProps: SearchMainProps, prevState: SearchMainState) {
        if (prevState.searchStr !== this.state.searchStr &&
            this.state.searchStr.trim().length > 2) {
            this.startSearchTimer();
        }
    }

    componentDidMount() {
        if (typeof invokeEnableZebraScannerAction !== 'undefined') {


            invokeEnableZebraScannerAction('window.srchMainRef.processScan', "SearchMain" + this.props.menuItemId.toString());
            // else
            // invokeEnableZebraScannerAction('window.srchMainRef.processScan', '0');

        }
    }

    componentWillUnmount() {

        if (typeof invokeDisableZebraScannerAction !== 'undefined') {
            invokeDisableZebraScannerAction();
        }

    }

    saveState = (e: any) => {
        this.setState({ searchResultsState: e }, () => {
            if (this.props.onSaveState) {
                this.props.onSaveState(this.props.menuItemId, this.state);
            }
        });
    }



    searchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.timer)
            clearTimeout(this.timer);
        if (e.key === 'Enter') {
            this.runSummarySearch();
        }
    }

    startSearchTimer = () => {
        // Clears running timer and starts a new one each time the user types
        if (this.timer)
            clearTimeout(this.timer);

        this.timer = setTimeout(() => { if (this.state.searchStr.trim().length > 2) this.runSummarySearch(); }, searchTimerDelay);
    }

    searchCriteriaChange = (e: InputChangeEvent) => {
        this.setState({ searchStr: e.value, searchResultsStatus: SearchResultsViewEnum.Blank, searchResultsStatusTxt: '' });
        //reset
        if (this.props.onSelectedChanged) this.props.onSelectedChanged([])
    }

    searchBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.state.searchStr.trim().length !== 0)
            this.runSummarySearch();
    }

    cameraScanClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (process.env.REACT_APP_RunMode === "DEV") {
            this.processScan("SearchMain" + this.props.menuItemId.toString(), 'AS|579222|20231011102345|');
        } else {
            //this.processScan(this.props.menuItemId.toString(), 'PH21178478M161');
            if (typeof invokeOpenCameraScannerAction !== 'undefined') {
                invokeOpenCameraScannerAction('window.srchMainRef.processScan', "SearchMain" + this.props.menuItemId.toString());
            }
        }
    }

    runSummarySearch = () => {
        
        //See if we have a barcode scanned by keyboard wedge

        //this.processScan("SearchMain" + this.props.menuItemId.toString(), this.state.searchStr);
        
        
        
        this.setState({ searchResultsStatus: SearchResultsViewEnum.InProgress, searchResultsStatusTxt: '', resultsSummaryData: [], searchResultsCriteria: new SearchResultsCriteria() });

        let url = this.props.userInf.currProject.apiUrl + '/api/search/SearchTypeSummary';

        let body = {
            searchStr: this.state.searchStr,
            //packlistItemsOnly: true,
            milestoneId: this.props.milestoneId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        this.setState({
                            resultsSummaryData: res.results,
                            searchResultsStatus: SearchResultsViewEnum.Summary
                        }, () => { if (this.props.onSaveMenuItem) this.props.onSaveMenuItem(this.props.menuItemId, this.state, this.state.searchStr) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({
                            searchResultsStatus: SearchResultsViewEnum.Blank,
                            searchResultsStatusTxt: 'Unauthorised - Login Required'
                        });
                        break;

                    default:
                        this.setState({
                            searchResultsStatus: SearchResultsViewEnum.Blank,
                            searchResultsStatusTxt: 'Error Searching! - ' + res.callStatusMessage
                        });
                }
            })
            .catch(err => {
                this.setState({
                    searchResultsStatus: SearchResultsViewEnum.Blank,
                    searchResultsStatusTxt: 'Error Searching! - ' + err.toString()
                });
            });

    }

    showSearchResults = (e: SearchTypeSummaryResults) => {
        if (this.state.searchResultsCriteria && this.state.searchResultsCriteria.objCode === e.objCode && this.state.searchResultsCriteria.objTypeId === e.objTypeId) {
            this.setState({ searchResultsStatus: SearchResultsViewEnum.Results })
        } else {
            let srch: SearchResultsCriteria = { objCode: e.objCode, srchString: this.state.searchStr, objTypeId: e.objTypeId, objTypeDesc: e.objTypeDesc };
            this.setState({
                searchResultsCriteria: srch,
                searchResultsStatus: SearchResultsViewEnum.Results,
                searchResultsState: undefined
            })
        }

    }


    processScan = (callBackId: string, scannedCode: string) => {

        // Verify the callback is for this function
        if (callBackId !== "SearchMain" + this.props.menuItemId.toString())
            return;

        //reset
        if (this.props.onSelectedChanged) this.props.onSelectedChanged([])


        if (scannedCode.length === 0) {
            this.setState({
                searchResultsStatus: SearchResultsViewEnum.Blank,
                searchResultsStatusTxt: 'No scanned code received'
            });
            return;
        }

        this.setState({ searchResultsStatus: SearchResultsViewEnum.InProgress });

        let sdt = this.getTcbObjFromScanCode(scannedCode);

        if (!sdt.tcbObjClass || !sdt.tcbObjId) {
            //Search barcode table for match
            let url = this.props.userInf.currProject.apiUrl + '/api/search/SearchByBarcode';
            let body = {
                barcodeStr: scannedCode
            }

            fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                .then(resp => resp.json())
                .then(res => {

                    switch (res.callStatus) {
                        //Search API uses callStatus to indicate of barcode found
                        case "OK":

                            let srchItm: TcbObjInfo = res.result;

                            this.setState({ searchResultsStatus: SearchResultsViewEnum.Blank, searchResultsStatusTxt: 'Scanned ' + srchItm.tcbObjDesc });
                            if (this.props.mode === SearchModeEnum.ViewDetails) {
                                if (this.props.onOpenObjAction)
                                    this.props.onOpenObjAction(srchItm);
                            } else if (this.props.mode === SearchModeEnum.MilestoneScan) {
                                if (this.props.onScanObjAction)
                                    this.props.onScanObjAction(srchItm);
                            }
                            else if (this.props.mode === SearchModeEnum.AddItem) {
                                if (this.props.onScanObjAction) this.props.onScanObjAction(srchItm);
                            }


                            break;
                        case "UNAUTH":
                            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                            this.props.updateUserInfo(uInf);
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.Blank,
                                searchResultsStatusTxt: 'Unauthorised - Login Required'
                            });
                            break;

                        default:
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.Blank,
                                searchResultsStatusTxt: res.callStatusMessage
                            });
                    }
                })
                .catch(err => {

                    this.setState({
                        searchResultsStatus: SearchResultsViewEnum.Blank,
                        searchResultsStatusTxt: 'Error Searching! - ' + err.toString()
                    });
                });

        } else {

            //Verify object exists and/or user can iew
            this.setState({ searchResultsStatus: SearchResultsViewEnum.InProgress, searchResultsStatusTxt: '', resultsSummaryData: [] });

            let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjInfo';

            let body = {
                tcbObjClass: sdt.tcbObjClass,
                tcbObjId: sdt.tcbObjId
            }

            fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                .then(resp => resp.json())
                .then(res => {
                    switch (res.callStatus) {
                        case "OK":
                            let sdt: TcbObjInfo = res.result;

                            this.setState({ searchResultsStatus: SearchResultsViewEnum.Blank, searchResultsStatusTxt: 'Loading ' + sdt.tcbObjDesc });
                            switch (this.props.mode) {
                                case SearchModeEnum.AddItem:
                                case SearchModeEnum.MilestoneScan:
                                case SearchModeEnum.SelectItem:
                                    if (this.props.onScanObjAction) this.props.onScanObjAction(sdt);
                                    break;

                                case SearchModeEnum.ViewDetails:
                                    if (this.props.onOpenObjAction) this.props.onOpenObjAction(sdt);
                                    break;

                            }

                            break;
                        case "UNAUTH":
                            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                            this.props.updateUserInfo(uInf);
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.Blank,
                                searchResultsStatusTxt: 'Unauthorised - Login Required'
                            });
                            break;

                        default:
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.Blank,
                                searchResultsStatusTxt: res.callStatusMessage
                            });
                    }
                })
                .catch(err => {
                    this.setState({
                        searchResultsStatus: SearchResultsViewEnum.Blank,
                        searchResultsStatusTxt: 'Error Searching! - ' + err.toString()
                    });
                });

        }
    }

    getTcbObjFromScanCode = (scannedCode: string) => {
        let sdt = new TcbObjInfo();

        if (scannedCode.startsWith('AS|') || scannedCode.startsWith('IT|')) {
            sdt.tcbObjClass = TcbObjClassEnum.Item;
        }
        if (scannedCode.startsWith('PL|')) {
            sdt.tcbObjClass = TcbObjClassEnum.PackList;
        }

        let id = parseInt(scannedCode.substring(3, scannedCode.indexOf('|', 3)));
        if (!isNaN(id))
            sdt.tcbObjId = id;
        return sdt;
    }

    onSearchResultsBackSelected = () => {
        this.setState({ searchResultsStatus: SearchResultsViewEnum.Summary })
    }

    onSearchResultsSelectedChanged = (e: TcbObjInfo[]) => {
        this.setState({ selectedSearchResults: e });
        if (this.props.onSelectedChanged)
            this.props.onSelectedChanged(e);
    }

    renderSummaryDiv = (x: SearchTypeSummaryResults, i: number) => {
        if (x.canView) {
            return <div className={"searchSummaryItem" + addPageViewClass(this.props.pageInf)} key={i} onClick={() => this.showSearchResults(x)}  >{x.objTypeDesc + ' (' + x.objCount.toLocaleString() + ')'}</div>
        } else {
            return <div className={"searchSummaryItem readonly" + addPageViewClass(this.props.pageInf)} key={i} title="Not authorised to view" >{x.objTypeDesc + ' (' + x.objCount.toLocaleString() + ')'}</div>
        }
    }

    renderSearchResults = () => {
        switch (this.state.searchResultsStatus) {
            case SearchResultsViewEnum.InProgress:
                return (<div className="searchSummaryResults" >{loadingDiv()}</div>);
            case SearchResultsViewEnum.Summary:
                return (<div id="searchSummaryDiv">
                    {this.state.resultsSummaryData.map((x, i) => this.renderSummaryDiv(x, i))}
                </div>);
            case SearchResultsViewEnum.Results:
                return (<SearchResults searchCriteria={this.state.searchResultsCriteria}
                    mode={this.props.mode}
                    offsetHeight={this.props.offsetHeight ? this.props.offsetHeight : 30}
                    stateToLoad={this.state.searchResultsState}
                    singleSelect={this.props.mode === SearchModeEnum.AddItem}
                    clearSelection={this.props.clearSelection ? this.props.clearSelection : false}
                    onBackSelected={this.onSearchResultsBackSelected}
                    onSelectedChanged={this.onSearchResultsSelectedChanged}
                    onOpenObjAction={this.props.onOpenObjAction}
                    onScanObjAction={this.props.onScanObjAction}
                    onScanObjsAction={this.props.onScanObjsAction}
                    onSaveState={(e) => { this.saveState(e) }} />);
            case SearchResultsViewEnum.Blank:
            default:
                return (<div className="searchSummary"><p>{this.state.searchResultsStatusTxt}</p></div>);

        }
    }

    ggtest = () => {
        return (<span>test</span>);
    }

    showPopup = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({ searchPopupShow: !this.state.searchPopupShow });
    }

    render() {
        return (
            <div data-component="srchMainRef" id="searchMainDiv" className={addPageViewClass(this.props.pageInf)}>
                <div id="searchHdrDiv" className={addPageViewClass(this.props.pageInf)}>
                    <Input className={addPageViewClass(this.props.pageInf)} placeholder="Search by Name" value={this.state.searchStr} onChange={this.searchCriteriaChange} onKeyDown={this.searchKeyDown} />
                    <Button className={addPageViewClass(this.props.pageInf)} onClick={this.searchBtnClick} ><span className="k-icon k-font-icon k-i-search srchIconsBtn" /></Button>
                    {this.state.showCameraScan &&
                        <Button className={addPageViewClass(this.props.pageInf)} onClick={this.cameraScanClick} title="Scan barcode using Camera" ><span className="k-icon k-font-icon k-i-qr-code-outline srchIconsBtn" /></Button>
                    }
                </div>
                <div id="searchBdyDiv" >
                    {this.renderSearchResults()}
                    {!this.props.userInf.isAuthorised && <Login />}
                </div>
            </div>
        );
    }
}

export default connector(SearchMain);
