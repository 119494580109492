import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import './DashboardViewer.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type DashboardViewerProps = PropsFromRedux & {
    dashboardUrl: string;
}


interface DashboardViewerState {
    pageStyle: React.CSSProperties;
}

class DashboardViewer extends React.Component<DashboardViewerProps, DashboardViewerState> {
    constructor(props: DashboardViewerProps) {
        super(props);

        this.state = {
            pageStyle: {}
        }

    }

    
    componentDidMount() {
        // this.updateDimensions();
        // window.addEventListener("resize", this.updateDimensions);

    }


    componentWillUnmount() {
        // window.removeEventListener("resize", this.updateDimensions);

    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            var gs: React.CSSProperties = { ...this.state.pageStyle };
            gs.height = body.clientHeight - 80;
            this.setState({
                pageStyle: gs
            });
        }
    }


    render() {
            return (
                <div className='dashBoardDiv' style={this.state.pageStyle}>
                    <iframe className='dashBoardIframe' src={this.props.dashboardUrl} title='Dashboards' />
                </div>
            );
    }
}

export default connector(DashboardViewer);
