import { UserInfo, PageInfo, LanguageLookupItem, TcbFileItem, MilestoneScanHistory, MilestoneScanLog } from '../models/models';
import { PageViewTypeEnum, MilestoneScanStatusEnum, TcbFileLoadStatusEnum } from '../models/enums';

export const ll = (dict: Array<LanguageLookupItem>, txt: string) => {
  if (dict) {
    let itm = dict.find(x => x.text === txt);
    if (itm)
      return itm.translated;
  }
  return txt;
}

export function addPageViewClass(p: PageInfo) {
  switch (p.pageViewMode) {
    case PageViewTypeEnum.Browser:
      return (" browser");

    case PageViewTypeEnum.Mobile:
      return (" mobile");

    case PageViewTypeEnum.Tablet:
      return (" tablet");

    default:
      return ("");
  }
}

export function addDBEnvironment(u: UserInfo) {
  if (u.currProject.isUAT)
      return (" uat");
    
      return (" prod");


}

const Image_Extentsions = [".jpg", ".jpeg", ".gif", ".png", ".bmp", ".tif", ".tiff"]
export function isImage(ext: string) {
  return Image_Extentsions.some(x => x === ext.toLowerCase());
}



//const MILESTONE_IMAGE_DOCTYPE = "Milestone Photo";

export function fetchFullImageStr(u: UserInfo, i: TcbFileItem,
  onSuccess: (i: TcbFileItem) => void,
  onUnAuth: (u: UserInfo) => void,
  onError: (i: TcbFileItem) => void) {


  let url = u.currProject.apiUrl + '/api/file/GetTcbFileBase64';

  let body = {
    fileId: i.fileId
  }

  fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + u.token } })
    .then(resp => resp.json())
    .then(res => {

      switch (res.callStatus) {
        case "OK":
          i.fileDataStr = res.stringResponse;
          i.fileLoadStatus = TcbFileLoadStatusEnum.OK;
          onSuccess(i);
          break;
        case "UNAUTH":
          let uInf: UserInfo = { ...u, isAuthorised: false };
          onUnAuth(uInf);
          break;

        default:
          i.fileLoadStatus = TcbFileLoadStatusEnum.Error;
          onError(i);
      }
    })
    .catch(err => {
      i.fileLoadStatus = TcbFileLoadStatusEnum.Error;
      onError(i);
    });
}

export function uploadImage(u: UserInfo, defType: string, fileName: string, imgBase64: string,
  onSuccess: (e: TcbFileItem) => void,
  onUnAuth: (e: UserInfo) => void,
  onError: (e: string) => void) {


  let url = u.currProject.apiUrl + '/api/file/SaveFileBase64';

  let body = {
    fileName: fileName,
    documentDefaultType: defType,
    fileBase64: imgBase64
  }


  //   let url = u.currProject.apiUrl + '/api/details/UploadImage';

  // let body = {
  //   fileName: fileName,
  //   fileDesc: null,
  //   fileBase64: imgBase64,
  //   docTypeDesc: MILESTONE_IMAGE_DOCTYPE
  // }

  fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + u.token } })
    .then(resp => resp.json())
    .then(res => {

      switch (res.callStatus) {
        case "OK":
          //Result is a TcbFileItem, but didn't send back full image (no need)
          let tii: TcbFileItem = res.result;
          tii.fileDataStr = imgBase64;
          onSuccess(tii);
          break;
        case "UNAUTH":

          let uInf: UserInfo = { ...u, isAuthorised: false };
          onUnAuth(uInf);
          break;
        case "FAIL":
        default:
          onError("Upload Failed - " + res.callStatusMessage);
      }
    })
    .catch(err => {
      onError("Upload Failed - " + err.toString());
    });
}

export function linkFileToMilestoneScan(u: UserInfo, msi: MilestoneScanHistory, i: TcbFileItem, defType: string,
  onSuccess: (e: MilestoneScanHistory) => void,
  onUnAuth: (u: UserInfo, e: MilestoneScanHistory) => void,
  onError: (e: MilestoneScanHistory) => void) {

  let url = u.currProject.apiUrl + '/api/details/LinkFileToTcbObj';

  let body = {
    fileId: i.fileId,
    documentDefaultType: defType,
    tcbObjs: msi.tcbObjs
  }

  fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + u.token } })
    .then(resp => resp.json())
    .then(res => {

      let imgs: TcbFileItem[] = [...msi.scanImages];
      let siIndex = imgs.findIndex(x => x.fileName === i.fileName && x.fileLoadStatus === TcbFileLoadStatusEnum.InProgress);
      switch (res.callStatus) {
        case "OK":
          i.fileLoadStatus = TcbFileLoadStatusEnum.OK;
          if (siIndex === -1) {
            imgs.push(i);
          } else {
            imgs[siIndex] = i;
          }
          msi.scanImages = imgs;
          msi.scanLog.push(new MilestoneScanLog("Add Photo", "Photo " + i.fileName + " added"));
          onSuccess(msi);
          break;
        case "UNAUTH":
          i.fileLoadStatus = TcbFileLoadStatusEnum.Error;
          if (siIndex === -1) {
            imgs.push(i);
          } else {
            imgs[siIndex] = i;
          }
          msi.scanImages = imgs;
          msi.scanLog.push(new MilestoneScanLog("Add Photo", "Unauthorised"));
          let uInf: UserInfo = { ...u, isAuthorised: false };
          onUnAuth(uInf, msi);
          break;

        default:
          i.fileLoadStatus = TcbFileLoadStatusEnum.Error;
          if (siIndex === -1) {
            imgs.push(i);
          } else {
            imgs[siIndex] = i;
          }
          msi.scanImages = imgs;
          msi.scanLog.push(new MilestoneScanLog("Load Scan", "Unknown Return Status - " + res.callStatusMessage));
          onError(msi);
      }
    })
    .catch(err => {
      msi.scanStatus = MilestoneScanStatusEnum.Error;
      msi.scanLog.push(new MilestoneScanLog("Load Scan", "Unknown Return Status - " + err.toString()));
      onError(msi);
    });
}

//GG - Not sure using this??
export function deleteImage(u: UserInfo, msi: MilestoneScanHistory, i: TcbFileItem,
  onSuccess: (e: MilestoneScanHistory) => void,
  onUnAuth: (u: UserInfo, e: MilestoneScanHistory) => void,
  onError: (e: MilestoneScanHistory) => void) {


  let url = u.currProject.apiUrl + '/api/file/DeleteTcbFile';

  let body = {
    fileId: i.fileId
  }

  fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + u.token } })
    .then(resp => resp.json())
    .then(res => {

      switch (res.callStatus) {
        case "OK":
          msi.scanImages = msi.scanImages.filter(x => x.fileId !== i.fileId);
          msi.scanLog.push(new MilestoneScanLog("Delete Photo", "Photo " + i.fileName + " deleted"));
          onSuccess(msi);
          break;
        case "UNAUTH":
          msi.scanLog.push(new MilestoneScanLog("Delete Photo", "Unauthorised"));
          let uInf: UserInfo = { ...u, isAuthorised: false };
          onUnAuth(uInf, msi);
          break;

        default:
          msi.scanLog.push(new MilestoneScanLog("Delete Photo", "Unknown Return Status - " + res.callStatusMessage));
          onError(msi);
      }
    })
    .catch(err => {
      msi.scanStatus = MilestoneScanStatusEnum.Error;
      msi.scanLog.push(new MilestoneScanLog("Delete Photo", "Unknown Return Status - " + err.toString()));
      onError(msi);
    });
}

export function errorHander(u: UserInfo, module: string, message: string) {

  alert("An error has occurred\n" + message);
}
