import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import './ErrorMsg.css';

interface ErrorMsgProps {
    message:string;
    onClose: () => void;
 }

interface ErrorMsgState {
}

class ErrorMsg extends React.Component<ErrorMsgProps, ErrorMsgState> {

    
    render() {

        return (
            <Dialog width={250}  >
                <table className="errorMsgTbl">
                    <tbody>
                        <tr className="errorMsgHdr">
                            <th colSpan={2}>Error Alert</th>
                        </tr>
                        <tr>
                            <td colSpan={2} className="errorMsgCell">{this.props.message}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}><Button className="errorMsgBtn" onClick={() => {this.props.onClose()}} >Close</Button></td>
                        </tr>
                    </tbody>
                </table>
            </Dialog>
        );
    }
}
export default ErrorMsg;
