import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Popup, Offset, PopupOpenEvent } from '@progress/kendo-react-popup';
import { TreeList, TreeListColumnProps, TreeListRowProps, TreeListCellProps, TreeListHeaderCellProps, TreeListColumnResizeEvent } from "@progress/kendo-react-treelist";
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import {
    UserInfo, TcbObjInfo, SplSearchFilterResultItem, SplColDataDesc, TcbFileItem,
    TcbObjTreeItem, MenuItemModel, ClientQueryItem, TcbObjContextMenuItem, TcbObjTreeColItem, SplFetchQueueItem, SplFetchColDataResult
} from '../models/models';
import { addPageViewClass } from '../functions/generalFunctions'
import { downloadFileItem } from '../functions/componentFunctions';
import { CustomWindow } from '../models/custom.window'
import { PageViewTypeEnum, SplColumnDataTypeEnum, TcbObjClassEnum, TcbFileLoadStatusEnum } from '../models/enums';
import { Menu, MenuSelectEvent } from "@progress/kendo-react-layout";
import moment from 'moment'

import './SplSearch.css';
import CheckBoxInputCtl from './InputCtls/CheckBoxInputCtl';
import ReactDOM from 'react-dom';
// import { parseNumber } from '@telerik/kendo-intl';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
declare let window: CustomWindow;

type SplSearchProps = PropsFromRedux & {
    menuItemId: number;
    stateToLoad?: SplSearchState;
    securityId: number;
    pageName: string;
    onOpenObjAction?: (e: TcbObjInfo) => void;
    onOpenImageAction?: (e: TcbObjInfo) => void;
    onOpenCqAction?: (securityId: number, cqId: number, tcbObjs: TcbObjInfo[]) => void;
    onOpenScanAction?: (tcbObjs: TcbObjInfo[]) => void;
    onSaveState?: (menuItemId: number, state: SplSearchState) => void;
    onSaveMenuItem?: (menuItemId: number, state: SplSearchState, menuCustomTxt: string) => void;
}

enum SearchResultsViewEnum {
    Blank,
    LoadingData,
    SearchComplete
}
enum ContextMenuStatus {
    LoadingMenu,
    Menu,
    CameraRunning,
    SavingPhoto,
    CancelPhoto,
    Closed
}



const filterByList = [
    { key: 'General', display: 'Search' },
    { key: 'Name', display: 'Filter by Name' },
    { key: 'Type', display: 'Filter by Type' },
]

interface SplSearchState {
    packlistTypeId: number;
    securityId: number;
    searchStr: string;
    searchResults: TcbObjTreeItem[];
    searchResultsFiltered: TcbObjTreeItem[];
    searchResultsStatus: SearchResultsViewEnum;
    selectedFilterBy: {};
    summaryResults: SplSearchFilterResultItem[];
    selectedSummaryResult?: SplSearchFilterResultItem;
    headerSelectVal: boolean;
    treeListStyle: React.CSSProperties;
    treeListTableStyle: React.CSSProperties;
    headerStyle: React.CSSProperties;
    treeListCols: TreeListColumnProps[];
    treeListColDataDesc: SplColDataDesc[];
    descColWidth: number;
    popupStatus: ContextMenuStatus;
    popupOpen: boolean;
    popupMenuDataDict: TcbObjContextMenuItem[];
    popupMenuData: MenuItemModel[];
    popupMenuDataMultiSelect: MenuItemModel[];
    popupMenuDataFetching: boolean;
    popupDataItems: TcbObjTreeItem[];
    popupMenuDataMultiSelectFetching: boolean;
    popupMenuDataMultiSelectFetched: boolean;
    fetchQueue: SplFetchQueueItem[];
    selectedItemId: string;
    scrollPosition: number;
}

declare function invokeEnableZebraScannerAction(callBackFunc: string, callBackId: string): void;
declare function invokeDisableZebraScannerAction(): void;
declare function invokeOpenCameraAction(arg: any): any;
declare function invokeOpenCameraScannerAction(callBackFunc: string, callBackId: string): void;

class SplSearch extends React.Component<SplSearchProps, SplSearchState> {

    containerRef: React.RefObject<HTMLDivElement>;
    treeListScrollElement: HTMLDivElement | null = null;

    shouldResetScroll = true; // default is true

    constructor(props: SplSearchProps) {
        super(props);

        this.containerRef = React.createRef();

        //Camera function needs ref
        window.splSrchRef = this;

        if (this.props.stateToLoad) {
            this.state = {
                ...this.props.stateToLoad,
                popupOpen: false,
                popupStatus: ContextMenuStatus.Closed,
                popupDataItems: [],
                popupMenuDataDict: [],
                popupMenuData: [],
                popupMenuDataFetching: false,
                fetchQueue: [],
                popupMenuDataMultiSelect: [],
                popupMenuDataMultiSelectFetching: false,
                popupMenuDataMultiSelectFetched: false
            };
            this._isReload = true;
        } else {

            this.state = {
                packlistTypeId: 0,
                securityId: 0,
                searchStr: '',
                searchResults: [],
                searchResultsFiltered: [],
                searchResultsStatus: SearchResultsViewEnum.Blank,
                selectedFilterBy: filterByList.find(x => x.key === 'General')!,
                summaryResults: [],
                headerSelectVal: false,
                treeListStyle: {},
                treeListTableStyle: {},
                headerStyle: {},
                treeListCols: [],
                treeListColDataDesc: [],
                descColWidth: 200,
                popupOpen: false,
                popupStatus: ContextMenuStatus.Closed,
                popupDataItems: [],
                popupMenuDataDict: [],
                popupMenuData: [],
                popupMenuDataFetching: false,
                fetchQueue: [],
                popupMenuDataMultiSelect: [],
                popupMenuDataMultiSelectFetching: false,
                popupMenuDataMultiSelectFetched: false,
                selectedItemId: '',
                scrollPosition: 0
            };
            this._isReload = false;
        }
    }

    private _isReload: boolean = true;
    private _popupOffSet: Offset = { top: 0, left: 0 };
    private _popupBlurTimeoutRef: any;
    private _popupDivRef: any;
    private _mounted = false;

    componentDidMount() {
        this._mounted = true;

        //Treelist needs height set
        window.addEventListener("resize", this.updateDimensions);

        //Enable Scanner
        if (typeof invokeEnableZebraScannerAction !== 'undefined') {
            invokeEnableZebraScannerAction('window.splSrchRef.loadScanCode', this.props.menuItemId.toString());
        }

        // Find the scrollable element with class "splSearchTreeList" after mounting
        if (this.containerRef.current) {
            this.treeListScrollElement = this.containerRef.current.querySelector('.splSearchTreeList');
        }

        if (this._isReload) {

            let tcols = this.buildTreeListCol(this.state.treeListColDataDesc);
            this.updateDimensions();
            this.setState({ treeListCols: tcols }, () => {
                let fqs = this.findAllUnFetchedCols(this.state.searchResults);
                this.processFetchQueue(fqs);
            });
            //this.restoreScrollPosition();

        } else {
            this.fetchGridColumns(SearchResultsViewEnum.LoadingData, () => {
                let tcols = this.buildTreeListCol(this.state.treeListColDataDesc);
                this.updateDimensions();
                this.setState({ treeListCols: tcols }, () => {
                    this.runSplSearch();
                });
            });
        }
    }


    componentWillUnmount() {
        this._mounted = false;
        if (typeof invokeDisableZebraScannerAction !== 'undefined') {
            invokeDisableZebraScannerAction();
        }
        window.removeEventListener("resize", this.updateDimensions);


    }

    componentDidUpdate(prevProps) {
        if (this.state.scrollPosition > 0) {
            this.restoreScrollPosition();

            if (this.shouldResetScroll){
                this.setState({ scrollPosition: 0 });
            }

        }

    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            let hs: React.CSSProperties = { ...this.state.headerStyle };
            let gs: React.CSSProperties = { ...this.state.treeListStyle };
            let ts: React.CSSProperties = { ...this.state.treeListTableStyle };
            gs.height = body.clientHeight - 120;
            if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser) {
                gs.width = body.clientWidth - 250;
            } else {
                gs.width = body.clientWidth;
            }
            // gs.width = this.calcColWidthTotal() - 240;
            ts.width = this.calcColWidthTotal();
            if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
                hs.width = body.clientWidth;
                // gs.width = body.clientWidth;
            }
            this.setState({
                headerStyle: hs,
                treeListStyle: gs,
                treeListTableStyle: ts
            });
        }
    }

    calcColWidthTotal = () => {
        let wt = 0;
        this.state.treeListColDataDesc.filter(x => x.visible).forEach(x => { wt += x.pixels });
        return wt;
    }

    searchCriteriaChange = (e: InputChangeEvent) => {
        this.setState({ searchStr: e.value });
    }

    searchBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.state.searchStr.trim().length === 0 || this.state.searchStr.trim().length >= 3)
            this.runSplSearch();
    }

    cameraScanClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (process.env.REACT_APP_RunMode === "DEV") {
            this.loadScanCode('0', 'AS|569550|20231011102347|');
        } else {

            if (typeof invokeOpenCameraScannerAction !== 'undefined') {
                this.setState({ searchResultsStatus: SearchResultsViewEnum.LoadingData });
                invokeOpenCameraScannerAction('window.splSrchRef.loadScanCode', '');
            }
            else {
                alert('invokeOpenCameraScannerAction not defined');
            }
        }
    }

    searchTxtKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (this.state.searchStr.trim().length === 0 || this.state.searchStr.trim().length >= 3)
                this.runSplSearch();
        }
    }


    fetchGridColumns = (newStatus: SearchResultsViewEnum, callback?: () => void) => {
        this.setState({ searchResultsStatus: SearchResultsViewEnum.LoadingData, treeListCols: [] });

        let url = this.props.userInf.currProject.apiUrl + '/api/ui/GetTcbObjTreeListColumns';

        let body = {
            securityId: this.props.securityId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let plt = res.retValInt;
                        let tcps: SplColDataDesc[] = [...res.results];
                        //This list includes hidden cols, so we can fetch the description column width
                        let descWth = 200;
                        if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser && tcps.some(x => x.id === "description")) {
                            descWth = tcps.find(x => x.id === "description")!.pixels;
                        }

                        this.setState({
                            packlistTypeId: plt,
                            securityId: this.props.securityId,
                            treeListColDataDesc: tcps.filter(x => x.visible && x.id !== "description"),
                            descColWidth: descWth,
                            searchResultsStatus: newStatus
                        }, () => { if (callback) callback(); });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchResultsStatus: SearchResultsViewEnum.Blank });
                        break;
                    default:
                        throw (res.callStatusMessage)
                }
            })
            .catch(err => {
                alert('fetchGridColumns' + err);
                this.setState({ searchResultsStatus: SearchResultsViewEnum.Blank });
            });
    }

    buildTreeListCol = (colData: SplColDataDesc[]) => {
        let tcols: TreeListColumnProps[] = [];

        tcols.push({ title: "", width: 27, field: "selected", cell: this.renderSelectCell, locked: true, resizable: false });
        tcols.push({ title: "Description", width: this.state.descColWidth, cell: this.renderDescriptionCell, locked: true });

        colData.sort((a, b) => a.columnOrder - b.columnOrder)
            .forEach(x => {
                if (x.visible) {
                    let tcol: TreeListColumnProps = {
                        title: x.value,
                        width: x.pixels,
                        // width: "200px",
                        // width: x.pixels.toString + "px",
                        cell: this.renderDataColCell,
                        expandable: false,
                        locked: false
                    };
                    if (x.columnDesc && x.columnDesc.length > 0)
                        tcol.title = x.columnDesc;
                    tcols.push(tcol);
                }
            });
        return tcols;
    }

    rebindTreeListCol = () => {
        let tcols = this.state.treeListCols;
        tcols.forEach(x => { x.cell = this.renderDataColCell });
        tcols[0].cell = this.renderSelectCell;
        tcols[1].cell = this.renderDescriptionCell;
        return tcols;
    }


    selectedFilterByChange = (e: DropDownListChangeEvent) => {
    }

    loadScanCode = (callBackId: string, scannedCode: string) => {
        if (scannedCode.length === 0) {
            alert('No scanned code received');
            this.setState({
                searchResultsStatus: SearchResultsViewEnum.SearchComplete,
            });
            return;
        }

        this.setState({ searchResultsStatus: SearchResultsViewEnum.LoadingData });

        //Check for std MMG codes
        let sdt = this.getTcbObjFromScanCode(scannedCode);

        if (!sdt.tcbObjClass || !sdt.tcbObjId) {
            //Search barcode table for match
            let url = this.props.userInf.currProject.apiUrl + '/api/search/SearchByBarcode';
            let body = {
                barcodeStr: scannedCode
            }

            fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                .then(resp => resp.json())
                .then(res => {
                    switch (res.callStatus) {
                        //Search API uses callStatus to indicate of barcode found
                        case "OK":
                            let sdt: TcbObjInfo = res.result;
                            this.setState({ searchResultsStatus: SearchResultsViewEnum.SearchComplete });
                            if (this.props.onOpenObjAction)
                                this.props.onOpenObjAction(sdt);

                            break;


                        case "UNAUTH":
                            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                            this.props.updateUserInfo(uInf);
                            break;

                        default:
                            //Couldn't find scan.... fall back to  text search
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.LoadingData,
                                searchStr: scannedCode
                            }, () => this.runSplSearch());

                    }
                })
                .catch(err => {
                    alert('Error scanning barcode ' + err.toString());
                    this.setState({
                        searchResultsStatus: SearchResultsViewEnum.SearchComplete
                    });
                });

        } else {

            //Verify object exists and/or user can view
            let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjInfo';

            let body = {
                tcbObjClass: sdt.tcbObjClass,
                tcbObjId: sdt.tcbObjId
            }

            fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                .then(resp => resp.json())
                .then(res => {
                    switch (res.callStatus) {
                        case "OK":
                            let sdt: TcbObjInfo = res.result;
                            this.setState({ searchResultsStatus: SearchResultsViewEnum.SearchComplete });
                            if (this.props.onOpenObjAction)
                                this.props.onOpenObjAction(sdt);
                            break;
                        case "UNAUTH":
                            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                            this.props.updateUserInfo(uInf);
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.Blank,
                            });
                            break;

                        default:
                            //Couldn't find scan.... fall back to  text search
                            this.setState({
                                searchResultsStatus: SearchResultsViewEnum.LoadingData,
                                searchStr: scannedCode
                            }, () => this.runSplSearch());
                    }
                })
                .catch(err => {
                    alert('Error fetching info for ' + sdt.toString());
                    this.setState({
                        searchResultsStatus: SearchResultsViewEnum.Blank,
                    });
                });

        }
    }

    getTcbObjFromScanCode = (scannedCode: string) => {
        let sdt = new TcbObjInfo();

        if (scannedCode.startsWith('AS|') || scannedCode.startsWith('IT|')) {
            sdt.tcbObjClass = TcbObjClassEnum.Item;
        }
        if (scannedCode.startsWith('PL|')) {
            sdt.tcbObjClass = TcbObjClassEnum.PackList;
        }

        let id = parseInt(scannedCode.substring(3, scannedCode.indexOf('|', 3)));
        if (!isNaN(id))
            sdt.tcbObjId = id;
        return sdt;
    }




    runSplSearch = () => {

        this.setState({ searchResultsStatus: SearchResultsViewEnum.LoadingData, searchResults: [], summaryResults: [], fetchQueue: [] });

        let url = this.props.userInf.currProject.apiUrl + '/api/search/SplSearchByDesc';

        let body = {
            searchObjTypeId: this.state.packlistTypeId,
            searchTxt: this.state.searchStr,
            securityId: this.state.securityId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let sResults: TcbObjTreeItem[] = res.results;
                        this.clearHighlighted(sResults);
                        this.initialiseColData(sResults);   //Need to initialise coldata array before saving state!
                        this.updateButtonStatus(sResults);  // Set which context buttons are enabled

                        let summ: SplSearchFilterResultItem[] = [];
                        if (this.state.searchStr && this.state.searchStr.length > 0)
                            summ = this.buildSearchResultSummary(sResults);


                        this.setState({ searchResults: sResults, summaryResults: summ, searchResultsStatus: SearchResultsViewEnum.SearchComplete }, () => {
                            if (this.state.searchStr && this.state.searchStr.length > 0) {
                                if (this.props.onSaveMenuItem)
                                    this.props.onSaveMenuItem(this.props.menuItemId, this.state, this.state.searchStr);
                            } else if (this.props.onSaveState) {
                                this.props.onSaveState(this.props.menuItemId, this.state);
                            }

                            if (this.state.searchResults) {
                                let fq = this.state.fetchQueue;
                                this.state.searchResults.forEach(sr => {
                                    this.state.treeListColDataDesc.forEach((x, i) => {
                                        let fqItm: SplFetchQueueItem = new SplFetchQueueItem();
                                        fqItm.tcbObj = sr.tcbObj;
                                        fqItm.treeId = sr.id;
                                        fqItm.colDataDesc = x;
                                        fqItm.colIndex = i;
                                        fq.push(fqItm);
                                    });
                                });

                                this.processFetchQueue(fq);
                            }

                        });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        throw (res.callStatusMessage)

                }
            })
            .catch(err => {
                console.log('runSplSearch: ' + err);
            });
    }

    fetchSplChilds = (itm: TcbObjTreeItem) => {
        //Set Fetching indicator true
        let sr = this.state.searchResults;
        let fetItm = this.findTreeItem(itm.id, sr);
        if (fetItm) {
            fetItm.childItmsFetching = true;
            this.setState({ searchResults: sr });
        }

        let url = this.props.userInf.currProject.apiUrl + '/api/search/SplFetchChildren';
        let body = {
            tcbObj: itm.tcbObj,
            treeId: itm.id,
            securityId: this.state.securityId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let sr = this.state.searchResults;
                        if (res.results) {
                            let sResults: TcbObjTreeItem[] = res.results;
                            this.initialiseColData(sResults);

                            if (!itm.childItems) itm.childItems = [];
                            itm.childItems.push(...sResults);
                        }
                        itm.childItmsFetching = false;
                        itm.childItmsFetched = true;
                        this.updateButtonStatus(sr);
                        this.setState({ searchResults: sr }, () => {
                            if (this.props.onSaveState) {
                                this.props.onSaveState(this.props.menuItemId, this.state);
                            }

                            let fq: SplFetchQueueItem[] = [];
                            let sResults: TcbObjTreeItem[] = res.results;
                            if (sResults) {
                                sResults.forEach(sr => {
                                    this.state.treeListColDataDesc.forEach((x, i) => {
                                        let fqItm: SplFetchQueueItem = new SplFetchQueueItem();
                                        fqItm.tcbObj = sr.tcbObj;
                                        fqItm.treeId = sr.id;
                                        fqItm.colDataDesc = x;
                                        fqItm.colIndex = i;
                                        fq.push(fqItm);
                                    });
                                });
                            }

                            this.processFetchQueue(fq);

                        });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                }
            })
            .catch(err => {
                alert('fetchSplChilds: ' + err);
                let sr = this.state.searchResults;
                itm.childItmsFetching = false;
                this.setState({ searchResults: sr });
            });
    }

    checkForUnfetchedData = () => {
        let fqs = this.findAllUnFetchedCols(this.state.searchResults);
        this.processFetchQueue(fqs);
    }


    buildSearchResultSummary = (sRes: TcbObjTreeItem[]) => {
        let summ: SplSearchFilterResultItem[] = [];

        sRes.forEach(x => {
            summ.push({ key: x.id, value: x.tcbObj.tcbObjDesc });
            summ.push(...this.buildSearchResultSummLoop(x, 1));
        });

        return summ;
    }

    buildSearchResultSummLoop = (res: TcbObjTreeItem, level: number) => {
        let summ: SplSearchFilterResultItem[] = [];

        let prefix = '';
        let i = level;
        while (i > 0) {
            prefix += '-';
            i--;
        }

        summ.push({ key: res.id, value: prefix + res.tcbObj.tcbObjDesc });
        if (res.childItems && res.childItems.length > 0) {
            res.childItems.forEach(x => {
                summ.push(...this.buildSearchResultSummLoop(x, level + 1));
            });
        }

        return summ;
    }


    initialiseColData = (rws: TcbObjTreeItem[]) => {
        if (rws) {
            rws.forEach(x => {
                x.colData = [];
                this.state.treeListColDataDesc.forEach((y, i) => {
                    var tci: TcbObjTreeColItem = new TcbObjTreeColItem();
                    tci.id = i;
                    tci.valueFetched = false;
                    x.colData.push(tci);
                });
                if (x.hasChildren) {
                    this.initialiseColData(x.childItems);
                }
            });
        }
    }

    processFetchQueue = (fq: SplFetchQueueItem[]) => {

        if (fq.length === 0) return;

        //Attachments icon first
        let at = fq.filter(x => x.colDataDesc.columnType.toLowerCase() === "attachments");
        let rem = fq.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "attachments"));

        if (at.length > 0) {
            this.fetchColQueueItems(at, 0);
        }

        //Images Icon
        let im = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "images");
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "images"));

        if (im.length > 0) {
            this.fetchColQueueItems(im, 0)
        }

        //Process Basic - Type first...quick!
        let pbt = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "basic" && (x.colDataDesc.id === "PLIQuantity" || x.colDataDesc.id === "unitOfMeasure"));
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "basic" && (x.colDataDesc.id === "PLIQuantity" || x.colDataDesc.id === "unitOfMeasure")));

        if (pbt.length > 0)
            this.populateBasicColumnValues(pbt);

        //Process Basic - Status
        let pbs = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "basic" && x.colDataDesc.value.toLowerCase() === "status");
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "basic" && x.colDataDesc.value.toLowerCase() === "status"));

        if (pbs.length > 0)
            this.fetchColQueueItems(pbs, 0);


        let ms = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "milestone");
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "milestone"));

        let msList: string[] = [];
        ms.forEach(m => msList.push(m.colDataDesc.id));
        let msGrps = new Set(msList);
        msGrps.forEach(g => {
            let grpMs = ms.filter(x => x.colDataDesc.id === g);
            this.fetchColQueueItems(grpMs, 100);
        });

        let attrs = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "attr" && !x.colDataDesc.metadataId);
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "attr" && !x.colDataDesc.metadataId));

        let attrsList: string[] = [];
        attrs.forEach(m => attrsList.push(m.colDataDesc.id));
        let attrsGrps = new Set(attrsList);
        attrsGrps.forEach(g => {
            let grpAttrs = attrs.filter(x => x.colDataDesc.id === g);
            this.fetchColQueueItems(grpAttrs, 100);
        });


        let itmAttrs = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "item_attr" && !x.colDataDesc.metadataId);
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "item_attr" && !x.colDataDesc.metadataId));

        let itmAttrsList: string[] = [];
        itmAttrs.forEach(m => itmAttrsList.push(m.colDataDesc.id));
        let itmAttrsGrps = new Set(itmAttrsList);
        itmAttrsGrps.forEach(g => {
            let grpitmAttrs = itmAttrs.filter(x => x.colDataDesc.id === g);
            this.fetchColQueueItems(grpitmAttrs, 100);
        });

        let pliAttrs = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "packlist_item_attr" && !x.colDataDesc.metadataId);
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "packlist_item_attr" && !x.colDataDesc.metadataId));

        let pliAttrsList: string[] = [];
        pliAttrs.forEach(m => pliAttrsList.push(m.colDataDesc.id));
        let pliAttrsGrps = new Set(pliAttrsList);
        pliAttrsGrps.forEach(g => {
            let grpPliAttrs = pliAttrs.filter(x => x.colDataDesc.id === g);
            this.fetchColQueueItems(grpPliAttrs, 100);
        });



        //Meta Attributes
        let metaAttrs = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "attr" && x.colDataDesc.metadataId);
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "attr" && x.colDataDesc.metadataId));

        let metaAttrsList: string[] = [];
        metaAttrs.forEach(m => metaAttrsList.push(m.colDataDesc.id));
        let metaAttrsGrps = new Set(metaAttrsList);
        metaAttrsGrps.forEach(g => {
            let grpMetaAttrs = metaAttrs.filter(x => x.colDataDesc.id === g);
            this.fetchColQueueItems(grpMetaAttrs, 10);
        });

        //Meta PacklistAttributes
        let metaPlAttrs = rem.filter(x => x.colDataDesc.columnType.toLowerCase() === "packlist_item_attr" && x.colDataDesc.metadataId);
        rem = rem.filter(x => !(x.colDataDesc.columnType.toLowerCase() === "packlist_item_attr" && x.colDataDesc.metadataId));

        let metaPlAttrsList: string[] = [];
        metaPlAttrs.forEach(m => metaPlAttrsList.push(m.colDataDesc.id));
        let metaPlAttrsGrps = new Set(metaPlAttrsList);
        metaPlAttrsGrps.forEach(g => {
            let grpMetaPlAttrs = metaPlAttrs.filter(x => x.colDataDesc.id === g);
            this.fetchColQueueItems(grpMetaPlAttrs, 10);
        });


        //Any left over?
        if (this._mounted && rem.length > 0)
            this.fetchColQueueItems(rem, 100);

    }

    findAllUnFetchedCols = (tlist: TcbObjTreeItem[]) => {
        let cdds = this.state.treeListColDataDesc;
        let fqs: SplFetchQueueItem[] = [];
        if (tlist) {
            tlist.forEach(x => {
                x.colData.forEach((c, i) => {
                    if (!c.valueFetched) {
                        let fqItm: SplFetchQueueItem = new SplFetchQueueItem();
                        fqItm.tcbObj = x.tcbObj;
                        fqItm.treeId = x.id;
                        if (cdds[i]) {
                            fqItm.colDataDesc = cdds[i];
                        }
                        fqItm.colIndex = i;
                        fqs.push(fqItm);
                    }
                });

                if (x.itemExpanded || x.childItmsFetching || x.childItmsFetched) {
                    fqs.push(...this.findAllUnFetchedCols(x.childItems));
                }
            });
        }
        return fqs;

    }

    findTreeItem = (treeId: string, tlist: TcbObjTreeItem[]): TcbObjTreeItem | null => {
        if (!tlist) {
            return null;
        }
        let f1 = tlist.find(x => x.id === treeId);
        if (f1)
            return f1;
        else {
            if (tlist.length > 0) {
                for (let tItm of tlist) {
                    let fti = this.findTreeItem(treeId, tItm.childItems);
                    if (fti)
                        return fti;
                }
            }
        }
        return null;
    }

    findTreeColItem = (treeId: string, colIndex: number, tlist: TcbObjTreeItem[]) => {
        let _treeRow = this.findTreeItem(treeId, tlist);
        if (_treeRow) {
            let treeRow: TcbObjTreeItem = _treeRow;
            return treeRow.colData[colIndex];
        } else {
            return null;
        }
    }

    populateBasicColumnValues = (fqis: SplFetchQueueItem[]) => {
        if (fqis) {
            fqis.forEach(x => {

                let sr = this.state.searchResults;
                let _ci = this.findTreeColItem(x.treeId, x.colIndex, sr);
                if (_ci) {
                    let ci = _ci;

                    switch (x.colDataDesc.id) {
                        case "PLIQuantity":

                            ci.value = x.tcbObj.tcbObjQty;
                            ci.dataType = SplColumnDataTypeEnum.Number;
                            ci.valueFetched = true;
                            break;

                        case "unitOfMeasure":
                            ci.value = x.tcbObj.tcbObjUOM;
                            ci.dataType = SplColumnDataTypeEnum.String;
                            ci.valueFetched = true;
                            break;
                    }
                }


            })
        }
    }

    fetchColQueueItems = (fqis: SplFetchQueueItem[], batchsize: number) => {

        let url = this.props.userInf.currProject.apiUrl + '/api/search/SplFetchColData';

        let nowItems: SplFetchQueueItem[] = [];
        let nextItems: SplFetchQueueItem[] = [];

        if (batchsize === 0 || batchsize > fqis.length) {
            nowItems = fqis;
        } else {
            nowItems = fqis.slice(0, batchsize);
            nextItems = fqis.slice(batchsize);
        }

        let body = {
            colDataItms: nowItems,
            securityId: this.state.securityId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        if (res.results.length > 0) {
                            let sr = this.state.searchResults;
                            let colData: SplFetchColDataResult[] = res.results;
                            colData.forEach(x => {
                                let _ci = this.findTreeColItem(x.treeId, x.colIndex, sr);
                                if (_ci) {
                                    let ci = _ci;
                                    ci.dataType = x.dataType;
                                    switch (x.dataType) {
                                        case SplColumnDataTypeEnum.AccessDenied:
                                            ci.value = null;
                                            break;
                                        case SplColumnDataTypeEnum.Date:
                                            ci.value = x.dateVal;
                                            break;
                                        case SplColumnDataTypeEnum.Attachment:
                                            ci.value = x.boolVal;
                                            //Set value of TreeItem
                                            let trItm = this.findTreeItem(x.treeId, this.state.searchResults);
                                            if (trItm) {
                                                trItm.hasAttachments = ci.value;
                                                trItm.attachmentFiles = x.attachmentList;
                                            }
                                            break;
                                        case SplColumnDataTypeEnum.Image:
                                            ci.value = x.boolVal;
                                            //Set value of TreeItem
                                            let imgItm = this.findTreeItem(x.treeId, this.state.searchResults);
                                            if (imgItm) {
                                                imgItm.hasImages = ci.value;
                                            }
                                            break;

                                        case SplColumnDataTypeEnum.String:
                                        case SplColumnDataTypeEnum.Number:
                                        default:
                                            ci.value = x.stringVal;
                                            break;
                                    }
                                    ci.valueFetched = true;
                                }
                            });
                            if (this._mounted) {
                                this.setState({ searchResults: sr }, () => {
                                    if (nextItems.length > 0)
                                        this.fetchColQueueItems(nextItems, batchsize);
                                });
                            }
                        }
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                }
            })
            .catch(err => {
                console.log('fetchColQueueItems: ' + err);
            });
    }


    expandChange = (itm: TcbObjTreeItem) => {

        this.saveScrollPosition(() => {

        let sr = this.state.searchResults;

        let expItm = this.findTreeItem(itm.id, sr);
        if (expItm) {
            expItm.itemExpanded = !expItm.itemExpanded;
            //These updated search results should force a re-render, but it doesn't!!... so manual call
            this.setState({ searchResults: sr });

            //Do we need to fetch any data?
            if (!expItm.childItmsFetched) {
                this.fetchSplChilds(itm);
            }
            else {
                this.checkForUnfetchedData();
            }
        
        }
    });
    }

    selectedAllCellChanged = (selectVal: boolean) => {
        let srs = this.state.searchResults;

        if (!selectVal) {
            //Unselect everything, just in case
            this.updateSelectVals(selectVal, srs);
        } else {
            this.updateSelectVals(selectVal, srs);
        }

        this.updateButtonStatus(srs);

        this.setState({ headerSelectVal: selectVal, searchResults: srs });
    }

    selectedSummaryResultChange = (e: DropDownListChangeEvent) => {
        var gg = e.value.key;
        let sr = this.state.searchResults;
        let tiF = this.findTreeItem(gg, sr);
        if (tiF) {
            let ti: TcbObjTreeItem = tiF;
            this.clearHighlighted(sr);
            this.setAllContracted(sr);
            ti.itemHighlighted = true;
            this.setParentsExpanded(ti, sr)
            this.setState({ searchResults: sr }, () => this.checkForUnfetchedData());
        }
    }

    clearHighlighted = (sr: TcbObjTreeItem[]) => {
        sr.forEach(x => {
            x.itemHighlighted = false;
            if (x.childItems && x.childItems.length > 0)
                this.clearHighlighted(x.childItems);
        });
    }

    setAllContracted = (sr: TcbObjTreeItem[]) => {
        sr.forEach(x => {
            x.itemExpanded = false;
            if (x.childItems && x.childItems.length > 0)
                this.setAllContracted(x.childItems);
        });
    }

    setParentsExpanded = (ti: TcbObjTreeItem, sr: TcbObjTreeItem[]) => {
        if (ti.parentId) {
            let piF = this.findTreeItem(ti.parentId, sr);
            if (piF) {
                let pi: TcbObjTreeItem = piF;
                pi.itemExpanded = true;
                if (pi.parentId) {
                    this.setParentsExpanded(pi, sr);
                }
            }
        }
    }


    updateSelectVals = (e: boolean, srs: TcbObjTreeItem[]) => {
        let updCount = 0;
        srs.forEach(x => {
            x.itemSelected = e;
            updCount++;
            if (x.hasChildren && x.itemExpanded)
                updCount += this.updateSelectVals(e, x.childItems);
        })
        return updCount;
    }

    getSelectedRows = (srs: TcbObjTreeItem[]) => {
        let selRows: TcbObjTreeItem[] = [];
        srs.forEach(x => {
            if (x.itemSelected)
                selRows.push(x);
            if (x.hasChildren && x.itemExpanded) {
                this.getSelectedRows(x.childItems).forEach(r => {
                    selRows.push(r);
                });
            }
        })
        return selRows;
    }

    getSelectedRowCount = (srs: TcbObjTreeItem[]) => {
        let selCount = 0;
        srs.forEach(x => {
            if (x.itemSelected)
                selCount++;
            if (x.hasChildren && x.itemExpanded)
                selCount += this.getSelectedRowCount(x.childItems);
        })
        return selCount;

    }

    updateButtonStatus = (srs: TcbObjTreeItem[]) => {

        let selCount = this.getSelectedRowCount(srs);

        if (selCount > 0) {
            // Disable every visible unselected button
            this.updateButtonStatusLoop(srs)
        } else {
            this.enableButtonStatusLoop(srs);
        }
    }

    enableButtonStatusLoop = (srs: TcbObjTreeItem[]) => {
        srs.forEach(x => {
            x.contextMenuEnabled = true;
            if (x.hasChildren)
                this.enableButtonStatusLoop(x.childItems);
        })
    }

    updateButtonStatusLoop = (srs: TcbObjTreeItem[]) => {
        srs.forEach(x => {
            x.contextMenuEnabled = x.itemSelected;
            if (x.hasChildren)
                this.updateButtonStatusLoop(x.childItems);
        })
    }


    selectedCellChange = (d: TcbObjTreeItem, val: boolean) => {
        let sr = this.state.searchResults;
        let di = this.findTreeItem(d.id, sr);
        // let di = sr.find(x => x.id == d.id);
        if (di) {
            di.itemSelected = val;
            this.updateButtonStatus(sr);
            this.setState({ searchResults: sr });
        }
    }


    getTreeListWidth = () => {
        let tw = 0;
        this.state.treeListCols.forEach(x => {
            let wd = x.width as number;
            tw += wd;
        });
        return tw;
    }
    onColumnResize = (event: TreeListColumnResizeEvent) => {

        if (event.end) {
            let colDescs = this.state.treeListColDataDesc;
            if (event.index >= 2) {
                colDescs[event.index - 2].pixels = event.newWidth;
            }

            // this.setState({ treeListCols: event.columns }, () => {
            this.setState({ treeListColDataDesc: colDescs }, () => {
                if (this.props.onSaveState) {
                    this.props.onSaveState(this.props.menuItemId, this.state);
                }
            });
        }
    };

    //#region Pop / Context Menu events
    popupOpen = (e: PopupOpenEvent) => {
        this._popupDivRef?.focus();
    }

    onFocusHandler = () => {
        clearTimeout(this._popupBlurTimeoutRef);
        this._popupBlurTimeoutRef = undefined;
    };

    onBlurTimeout = () => {
        this.setState({
            popupStatus: ContextMenuStatus.Closed,
            popupOpen: false,
            popupDataItems: [],
        });

        this._popupBlurTimeoutRef = undefined;
    };

    onBlurHandler = (event) => {
        clearTimeout(this._popupBlurTimeoutRef);
        this._popupBlurTimeoutRef = setTimeout(this.onBlurTimeout);
    };

    openContextMenuClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, dataItem: TcbObjTreeItem) => {

        this.saveScrollPosition(() => {

        this._popupOffSet = { left: e.clientX, top: e.clientY };

        let srs = this.state.searchResults;
        let selCount = this.getSelectedRowCount(srs);
        let mitms: TcbObjContextMenuItem | null | undefined = null;
        if (selCount <= 1) {
            //use dataitem, not selected
            mitms = this.getContextMenuItemsForObj(dataItem.tcbObj);
            let popupDataItems: TcbObjTreeItem[] = [];
            popupDataItems.push(dataItem);

            if (mitms == null) {
                this.setState({
                    popupOpen: true,
                    popupStatus: ContextMenuStatus.LoadingMenu,
                    popupDataItems: popupDataItems,
                    popupMenuData: [],
                    popupMenuDataFetching: true
                }, () => {
                    this.fetchContextMenuItemsForObjType(dataItem.tcbObj);
                });
            } else {
                this.setState({
                    popupOpen: true,
                    popupStatus: ContextMenuStatus.Menu,
                    popupDataItems: popupDataItems,
                    popupMenuData: mitms.menuItems,
                    popupMenuDataFetching: false
                });
            }
        } else {
            //Multiselect
            let selItems = this.getSelectedRows(this.state.searchResults);
            if (this.state.popupMenuDataMultiSelectFetched) {
                this.setState({
                    popupStatus: ContextMenuStatus.Menu,
                    popupOpen: true,
                    popupDataItems: selItems,
                });
            } else {
                this.setState({
                    popupStatus: ContextMenuStatus.LoadingMenu,
                    popupOpen: true,
                    popupDataItems: selItems,
                }, () => {
                    if (!this.state.popupMenuDataMultiSelectFetching) {
                        this.fetchContextMenuItemsForMultiSelect()
                    }
                });
            }

        }
    });
    }

    fetchContextMenuItemsForMultiSelect = () => {

        let url = this.props.userInf.currProject.apiUrl + '/api/search/FetchClientQueriesForSPLMultiSelect?securityId=' + this.props.securityId.toString();
        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let menuItms: MenuItemModel[] = [];
                        menuItms.push({ text: 'These actions will apply to ALL selected items', action: 'Message', cssClass: 'menuTitle', disabled: true, items: [] });
                        menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });

                        // menuItms.push({ text: 'Update Milestone', icon: "paper-plane", action: 'MilestoneScan', cssClass: 'menuItem', disabled: false, items: [] });
                        menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });


                        if (res.results.length === 0) {
                            menuItms.push({ text: 'No ' + this.props.pageName + ' actions are available', action: 'Message', cssClass: 'menuMessage', disabled: true, items: [] });
                        } else {
                            let cqItms = res.results as ClientQueryItem[];
                            cqItms.sort((a, b) => {
                                if (a.sortOrder < b.sortOrder)
                                    return -1;
                                else if (a.sortOrder > b.sortOrder)
                                    return 1;
                                else
                                    return a.description.localeCompare(b.description);
                            });
                            cqItms.forEach((x: ClientQueryItem) => {
                                let newMdl: MenuItemModel = new MenuItemModel();
                                newMdl.text = x.description;
                                newMdl.action = 'ClientQuery';
                                newMdl.cssClass = 'menuItem';
                                newMdl.disabled = false;
                                newMdl.items = [];
                                newMdl.data = x.id;
                                menuItms.push(newMdl);
                            });
                        }


                        this.setState({
                            popupStatus: ContextMenuStatus.Menu,
                            popupMenuDataMultiSelect: menuItms,
                            popupMenuDataMultiSelectFetched: true,
                            popupMenuDataMultiSelectFetching: false
                        })


                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        throw (res.callStatusMessage)

                }
            })
            .catch(err => {
                alert('fetchContextMenuItemsForMultiSelect : ' + err);
            });
    }


    getContextMenuItemsForObj = (obj: TcbObjInfo) => {
        let mdd = this.state.popupMenuDataDict.find(x => x.tcbObjClass === obj.tcbObjClass && x.tcbObjTypeId === obj.tcbObjTypeId);
        if (mdd) {
            let tmpMdd: TcbObjContextMenuItem = new TcbObjContextMenuItem();
            tmpMdd.menuItems.push(...mdd.menuItems);
            //Append object specific header
            tmpMdd.menuItems.unshift({ text: 'Actions for : ' + obj.tcbObjDesc, action: 'Message', cssClass: 'menuTitle', disabled: true, items: [] });
            return tmpMdd;
        }

        return null;
    }

    fetchContextMenuItemsForObjType = (itm: TcbObjInfo) => {

        let url = "";
        switch (itm.tcbObjClass) {
            case TcbObjClassEnum.PackList:
                url = this.props.userInf.currProject.apiUrl + '/api/search/FetchClientQueriesForPacklistType?securityId=' + this.props.securityId.toString() + '&packlistTypeid=' + itm.tcbObjTypeId.toString();
                break;

            case TcbObjClassEnum.Item:
            case TcbObjClassEnum.PackListItem:
                url = this.props.userInf.currProject.apiUrl + '/api/search/FetchClientQueriesForItemType?securityId=' + this.props.securityId.toString() + '&itemTypeId=' + itm.tcbObjTypeId.toString();;
                break;
        }

        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        //Build new menu for this type
                        let ctxMenuItm: TcbObjContextMenuItem = new TcbObjContextMenuItem();
                        ctxMenuItm.tcbObjClass = itm.tcbObjClass;
                        ctxMenuItm.tcbObjTypeId = itm.tcbObjTypeId;

                        let menuItms: MenuItemModel[] = [];
                        // menuItms.push({ text: 'Actions for : ' + itm.tcbObjDesc, action: 'Message', cssClass: 'menuTitle', disabled: true, items: [] });
                        menuItms.push({ text: 'Show Details', icon: "aggregate-fields", action: 'ShowDetails', cssClass: 'menuItem', disabled: false, items: [] });
                        // menuItms.push({ text: 'Update Milestone', icon: "paper-plane", action: 'MilestoneScan', cssClass: 'menuItem', disabled: false, items: [] });
                        menuItms.push({ text: 'Refresh', icon: "reload", action: 'Refresh', cssClass: 'menuItem', disabled: false, items: [] });
                        // menuItms.push({ text: 'Refresh', action: 'Refresh', cssClass: 'menuItem', disabled: false, items: [] });
                        menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });

                        if (res.results.length !== 0) {
                            let cqItms = res.results as ClientQueryItem[];
                            cqItms.sort((a, b) => {
                                if (a.sortOrder < b.sortOrder)
                                    return -1;
                                else if (a.sortOrder > b.sortOrder)
                                    return 1;
                                else
                                    return a.description.localeCompare(b.description);
                            });
                            cqItms.forEach((x: ClientQueryItem) => {
                                let newMdl: MenuItemModel = new MenuItemModel();
                                if (x.id === -1) {
                                    if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile || this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet) {
                                        //Special code for Add Photo option
                                        newMdl.text = x.description;
                                        newMdl.icon = "camera";
                                        newMdl.action = 'Camera';
                                        newMdl.cssClass = 'menuItem';
                                        newMdl.disabled = false;
                                        newMdl.items = [];
                                        newMdl.data = x.id;
                                        menuItms.push(newMdl);
                                    }
                                } else {
                                    newMdl.text = x.description;
                                    newMdl.icon = "circle";
                                    newMdl.action = 'ClientQuery';
                                    newMdl.cssClass = 'menuItem';
                                    newMdl.disabled = false;
                                    newMdl.items = [];
                                    newMdl.data = x.id;
                                    menuItms.push(newMdl);
                                }
                            });
                        }


                        ctxMenuItm.menuItems = menuItms;
                        let dd = this.state.popupMenuDataDict;
                        dd.push(ctxMenuItm);

                        //Create current menu as well
                        let tmpMim: MenuItemModel[] = [];
                        tmpMim.push(...ctxMenuItm.menuItems);
                        tmpMim.unshift({ text: 'Actions for : ' + itm.tcbObjDesc, action: 'Message', cssClass: 'menuTitle', disabled: true, items: [] });


                        this.setState({
                            popupStatus: ContextMenuStatus.Menu,
                            popupMenuData: tmpMim,
                            popupMenuDataDict: dd,
                            popupMenuDataFetching: false
                        })


                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        throw (res.callStatusMessage)

                }
            })
            .catch(err => {
                alert('fetchContextMenuItemsForObjType : ' + err);
            });
    }


    contextMenuClick = (e: MenuSelectEvent) => {

        this.shouldResetScroll = false;
        this.saveScrollPosition(() => {
        let dataItm = e.item as MenuItemModel;
        let itms = this.state.popupDataItems;

        if (itms && itms.length > 0) {

            this.setState({ selectedItemId: itms[0].id }, () => {
                if (this.props.onSaveState) {
                    this.props.onSaveState(this.props.menuItemId, this.state);
                }
            });
        }


        switch (dataItm.action) {
            case "Camera":
                this.setState({
                    popupOpen: true,
                    popupStatus: ContextMenuStatus.CameraRunning,
                    popupMenuData: [],
                    popupMenuDataFetching: false
                }, () => {
                    this.openCamera();
                });
                break;
            case "ShowDetails":
                this.setState({
                    popupOpen: false,
                    popupStatus: ContextMenuStatus.Closed,
                    popupDataItems: [],
                }, () => {

                    if (this.props.onOpenObjAction && itms.length === 1) {
                        this.props.onOpenObjAction(itms[0].tcbObj);
                    }
                });
                break;
            case "MilestoneScan":
                this.setState({
                    popupOpen: false,
                    popupStatus: ContextMenuStatus.Closed,
                    popupDataItems: [],
                }, () => {

                    if (this.props.onOpenScanAction) {

                        let tcbObjs: TcbObjInfo[] = [];

                        for (let obj of itms) {
                            tcbObjs.push(obj.tcbObj);
                        }

                        this.props.onOpenScanAction(tcbObjs);
                    }
                });
                break;
            case "Refresh":
                this.setState({
                    popupOpen: false,
                    popupStatus: ContextMenuStatus.Closed,
                    popupDataItems: [],
                }, () => {
                    if (itms.length === 1) {
                        this.refreshNode(itms[0]);
                    }
                });
                break;
            case "ClientQuery":
                this.setState({
                    popupOpen: false,
                    popupStatus: ContextMenuStatus.Closed,
                    popupDataItems: [],
                }, () => {

                    if (this.props.onOpenCqAction && itms.length > 0) {
                        let cqId = dataItm.data;
                        let tcbObjs: TcbObjInfo[] = [];
                        itms.forEach(x => { tcbObjs.push(x.tcbObj); });

                        this.props.onOpenCqAction(this.state.securityId, cqId, tcbObjs);
                    }
                });

                // alert('Run CQ id ' + e.item['data'] + ' for ' + this.state.popupDataItem?.tcbObj.tcbObjDesc);
                break;
        }
        // this.setState({
        //     popupOpen: false,
        //     popupDataItems: [],
        // });
    });
    }

    openCamera = () => {

        if (typeof invokeOpenCameraAction !== 'undefined') {
            invokeOpenCameraAction('window.splSrchRef.loadCameraImage');
        }
        else {
            if (process.env.REACT_APP_RunMode === "DEV") {
                this.loadCameraImage('', 'IMG_DevTesting.png', 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=');
            } else {
                this.setState({
                    popupOpen: false,
                    popupStatus: ContextMenuStatus.Closed,
                    popupDataItems: [],
                });
                alert('invokeOpenCameraAction not defined');
            }
        }

    }

    loadCameraImage = (callbackId: string, fileName: string, fileBase64: string) => {

        if (fileBase64.length === 0) {
            this.setState({
                popupOpen: false,
                popupStatus: ContextMenuStatus.Closed,
                popupDataItems: [],
                popupMenuDataFetching: false
            });
            return;
        } else {
            this.setState({
                popupStatus: ContextMenuStatus.SavingPhoto,
            });
        }

        let itms = this.state.popupDataItems;
        let itm = itms[0].tcbObj

        let last = fileName.lastIndexOf(".");
        let ext = "";
        if (last > 0) {
            ext = fileName.substring(last);
        }

        fileName = itm.tcbObjDesc + "-" + moment().format("YYYYMMDDHHmm") + ext;

        let docType = "Item Photo";
        if (itm.tcbObjClass === "P") {
            docType = "Packlist Photo"
        }

        let url = this.props.userInf.currProject.apiUrl + '/api/file/SaveFileBase64';

        //add file props before we add
        let body = {
            fileName: fileName,
            documentDefaultType: docType,
            fileBase64: fileBase64
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    fi.fileDataStr = fileBase64;
                    fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
                    fi.canEdit = true;
                    fi.canDelete = true;
                    fi.documentTypeDesc = docType;

                    let url = this.props.userInf.currProject.apiUrl + '/api/details/LinkFileToTcbObj';

                    let tcbObjs: TcbObjInfo[] = [];
                    tcbObjs.push(itm);

                    let body = {
                        fileId: fi.fileId,
                        tcbObjs: tcbObjs
                    }

                    fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                        .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
                        .then(resp => resp.json())
                        .then(res => {
                            if (res.callStatus !== "OK") {
                                alert("Photo link error - " + res.CallStatusMessage);
                            }
                            this.setState({
                                popupOpen: false,
                                popupStatus: ContextMenuStatus.Closed,
                                popupDataItems: [],
                                popupMenuDataFetching: false
                            });
                        })
                        .catch(err => {
                            alert("Photo link error - " + err.toString());
                            this.setState({
                                popupOpen: false,
                                popupStatus: ContextMenuStatus.Closed,
                                popupDataItems: [],
                                popupMenuDataFetching: false
                            });
                        });
                }
            })
            .catch(err => {
                alert('Error saving image - ' + err.toString())
                this.setState({
                    popupOpen: false,
                    popupStatus: ContextMenuStatus.Closed,
                    popupDataItems: [],
                    popupMenuDataFetching: false
                });
            });

    }

    descriptionClick = (itm: TcbObjTreeItem) => {

        this.saveScrollPosition();

        if (this.props.onOpenObjAction && itm.tcbObj) {
            this.setState({ selectedItemId: itm.id }, () => {
                if (this.props.onSaveState) {
                    this.props.onSaveState(this.props.menuItemId, this.state);
                }
                this.props.onOpenObjAction?.(itm.tcbObj); // Move this here
            });
        }
    }

    imageIconClick = (trItem: TcbObjTreeItem) => {
        if (this.props.onOpenImageAction && trItem.tcbObj) {
            this.setState({ selectedItemId: trItem.id }, () => {
                if (this.props.onSaveState) {
                    this.props.onSaveState(this.props.menuItemId, this.state);
                }
            });

            this.saveScrollPosition(() => {
                if (this.props.onOpenImageAction) {
                    this.props.onOpenImageAction(trItem.tcbObj);
                }

            });
        }
    }


    attachmentMenuClick = (m: MenuSelectEvent, trItem: TcbObjTreeItem) => {
        let mdl = m.item as MenuItemModel;

        if (mdl.action !== "download") return;

        this.setState({ selectedItemId: trItem.id }, () => {
            if (this.props.onSaveState) {
                this.props.onSaveState(this.props.menuItemId, this.state);
            }
        });

        let trItm = this.findTreeItem(trItem.id, this.state.searchResults);
        if (trItm) {
            trItm.attachmentDownloading = true;
            this.setState({ searchResults: this.state.searchResults });
        }

        let url = this.props.userInf.currProject.apiUrl + '/api/file/GetTcbFileInfo';
        let body = {
            fileId: mdl.id,
            fetchFileData: true
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let fi = res.result as TcbFileItem;
                        downloadFileItem(fi);
                        break;

                    default:
                        alert(res.callStatusMessage);
                }

                let trItm = this.findTreeItem(trItem.id, this.state.searchResults);
                if (trItm) {
                    trItm.attachmentDownloading = false;
                    this.setState({ searchResults: this.state.searchResults });
                }
            })
            .catch(err => {
                alert(err);
                let trItm = this.findTreeItem(trItem.id, this.state.searchResults);
                if (trItm) {
                    trItm.attachmentDownloading = false;
                    this.setState({ searchResults: this.state.searchResults });
                }
            });
    }


    //#endregion Pop / Context Menu events

    refreshNode = (e: TcbObjTreeItem) => {

        e.colData.forEach((x) => {
            x.value = null;
            x.valueFetched = false;
        });
        let itms: TcbObjTreeItem[] = [];
        itms.push(e);
        let fq = this.findAllUnFetchedCols(itms)
        this.processFetchQueue(fq);

        if (e.hasChildren) {
            e.childItems = [];
            e.childItmsFetched = false;
            this.setState({ searchResults: this.state.searchResults }, () => {
                if (e.itemExpanded) this.fetchSplChilds(e);
            })
        }
    }

    saveScrollPosition = (callback?: () => void) => {
        
        if (this.treeListScrollElement) {
            const newScrollPosition = this.treeListScrollElement.scrollTop;
            this.setState({ scrollPosition: newScrollPosition }, () => {
                // Call the callback after the state update, if provided
                if (callback) callback();
            });
        }
    };

    restoreScrollPosition = () => {
        if (this.treeListScrollElement) {
            this.treeListScrollElement.scrollTop = this.state.scrollPosition;
        }
    };


    refreshGrid = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            popupOpen: false,
            popupStatus: ContextMenuStatus.Closed,
            popupDataItems: [],
            popupMenuDataDict: [],
            popupMenuDataFetching: false,
            fetchQueue: [],
            popupMenuDataMultiSelect: [],
            popupMenuDataMultiSelectFetching: false,
            popupMenuDataMultiSelectFetched: false,
            searchResultsStatus: SearchResultsViewEnum.LoadingData,
        }, () => {
            this.fetchGridColumns(SearchResultsViewEnum.LoadingData, () => {
                let tcols = this.buildTreeListCol(this.state.treeListColDataDesc);
                this.setState({ treeListCols: tcols }, () => {
                    this.runSplSearch();
                });
            });
        })
    }

    renderSelectCell = (e: TreeListCellProps) => {
        let dataItm = e.dataItem as TcbObjTreeItem;
        if (dataItm && dataItm.tcbObj) {
            if (dataItm.childItmsFetching)
                return (<td className={'selectRowCell' + addPageViewClass(this.props.pageInf)}><span className="k-icon k-font-icon k-i-loading" /></td>);
            else
                return (<td className={'selectRowCell' + addPageViewClass(this.props.pageInf)}><CheckBoxInputCtl value={dataItm.itemSelected} onUpdate={(e) => { if (e != null) this.selectedCellChange(dataItm, e) }} /></td>);
        }
        else
            return (<td>sel</td>);
    }


    renderDescriptionCell = (e: TreeListCellProps) => {
        let dataItm = e.dataItem as TcbObjTreeItem;
        if (dataItm && dataItm.tcbObj) {
            return (<td className={'descriptionRowCell k-text-nowrap' + addPageViewClass(this.props.pageInf)}>
                {this.renderIndentSpans(e.level.length)}
                {this.renderExpanderSpan(dataItm)}
                {this.renderContextMenuButton(dataItm)}
                {this.renderIconCode(dataItm)}
                <span className='descSpan' onClick={() => this.descriptionClick(dataItm)} title={dataItm.tcbObj.tcbObjDesc}>{dataItm.tcbObj.tcbObjDesc}</span>
            </td>);
        }
        else
            return (<td>desc</td>);
    }

    renderIndentSpans = (e: number) => {
        let spns: JSX.Element[] = [];
        let i = 1;
        while (i < e) {
            spns.push(<span className={'indentSpan' + addPageViewClass(this.props.pageInf)} />)
            i++;
        }
        return spns;
    }

    renderExpanderSpan = (dataItm: TcbObjTreeItem) => {
        if (dataItm.hasChildren) {
            if (!dataItm.itemExpanded)
                return <span className={'k-icon k-font-icon k-i-expand expanderSpan' + addPageViewClass(this.props.pageInf)} onClick={() => this.expandChange(dataItm)} />;
            else
                return <span className={'k-icon k-font-icon k-i-collapse expanderSpan' + addPageViewClass(this.props.pageInf)} onClick={() => this.expandChange(dataItm)} />;
        } else {
            return <span className={'expanderSpan' + addPageViewClass(this.props.pageInf)} />;
        }
    }

    renderContextMenuButton = (dataItm: TcbObjTreeItem) => {
        return (<Button className={'contextBtn' + addPageViewClass(this.props.pageInf)} onClick={(e) => this.openContextMenuClick(e, dataItm)} disabled={!dataItm.contextMenuEnabled} ><span className="k-icon k-font-icon k-i-more-vertical" /></Button>);
    }

    renderIconCode = (dataItm: TcbObjTreeItem) => {
        if (dataItm && dataItm.tcbObj && dataItm.tcbObj.tcbObjTypeIconCode) {
            let iconCode = '&#' + dataItm.tcbObj.tcbObjTypeIconCode.toString() + ';';
            return <span className={'k-icon iconSpan' + addPageViewClass(this.props.pageInf)} dangerouslySetInnerHTML={{ __html: iconCode }} />
        } else {
            return null;
        }
    }

    renderDataColCell = (e: TreeListCellProps) => {
        var colIdx = e.colIndex;
        let wdth = this.state.treeListCols[colIdx].width;
        let tdSt: React.CSSProperties = {};
        tdSt.width = wdth;

        let trItem = e.dataItem as TcbObjTreeItem;
        if (!trItem || !trItem.colData) {
            return (<td style={tdSt}>No Data</td>);
        }

        let colItm = trItem.colData[colIdx - 2];


        if (!colItm.valueFetched) {
            // return (<td className='DataFetching'></td>);
            return (<td style={tdSt} title='Data Loading...'><span className="k-icon k-font-icon k-i-loading" /></td>);
        } else {
            switch (colItm.dataType) {
                case SplColumnDataTypeEnum.AccessDenied:
                    return (<td style={tdSt} className="accessDenied" title='Access to data denied'></td>);

                case SplColumnDataTypeEnum.String:
                    return (<td style={tdSt} className='colDataStr'>{colItm.value}</td>)

                case SplColumnDataTypeEnum.Number:
                    return (<td style={tdSt} className='colDataNum'>{colItm.value}</td>)

                case SplColumnDataTypeEnum.Date:
                    let dt = colItm.value as Date;
                    let dateStr: string = moment(dt).format("DD-MMM-YY");
                    return (<td style={tdSt} className='colDataDate'>{dateStr}</td>);

                case SplColumnDataTypeEnum.DateTime:
                    let dt2 = colItm.value as Date;
                    let dateStr2: string = moment(dt2).format("DD-MMM-YY");
                    return (<td style={tdSt} className='colDataDate'>{dateStr2}</td>);

                case SplColumnDataTypeEnum.Attachment:

                    //tcbObj was updated when this column was fetched... contains file info we need.
                    if (trItem.attachmentDownloading) {
                        return (<td style={tdSt} className='colDataAttach'><span className="k-icon k-font-icon k-i-loading" /></td>);
                    } else if (trItem.hasAttachments) {
                        let itemMenuItms: MenuItemModel[] = [];
                        let cxtMenu: MenuItemModel = { items: [], linkRender: () => { return <span className="k-icon k-font-icon k-i-clip-45" /> }, cssClass: "paperclip" };
                        itemMenuItms.push(cxtMenu);

                        cxtMenu.items.push({ text: 'Attachments', action: 'Header', cssClass: 'menuTitle', disabled: true, items: [] });
                        trItem.attachmentFiles.forEach(x => {
                            cxtMenu.items.push({ id: x.fileId, text: x.displayText + " (" + moment(x.lastChangedOn).format("YYYY-MM-DD HH:mm") + ")", action: 'download', icon: 'download', cssClass: 'menuItem', disabled: false, items: [] });
                        });
                        return (<td><Menu className="splSearchAttMenu" items={itemMenuItms} openOnClick={true} vertical={true} onSelect={(m) => this.attachmentMenuClick(m, trItem)} /></td>);
                    } else {
                        return (<td style={tdSt} className='colDataAttach'></td>);
                    }
                case SplColumnDataTypeEnum.Image:

                    //tcbObj was updated when this column was fetched... contains file info we need.

                    if (trItem.hasImages) {

                        return (<td style={tdSt} className='colDataAttach'><span className="k-icon k-font-icon k-i-photo-camera" onClick={(e) => this.imageIconClick(trItem)} /></td>);
                    } else {
                        return (<td style={tdSt} className='colDataAttach'></td>);
                    }
                default:
                    return (<td style={tdSt}>&nbsp;</td>);
            }
        }
    }



    renderNoRecords = () => {

        if (this.state.searchResultsStatus === SearchResultsViewEnum.LoadingData) {
            return (<div className="splLoadingIndicator"><span className="k-icon k-font-icon k-i-loading" /></div>);
        } else {
            return (<div>No Records</div>);
        }

    }

    renderHeaderCell = (defaultRendering: React.ReactNode, props: TreeListHeaderCellProps) => {
        switch (props.field) {
            case "selected":
                return <span title='Select All'><CheckBoxInputCtl value={this.state.headerSelectVal} onUpdate={(e) => { if (e != null) this.selectedAllCellChanged(e) }} /></span>
            default:
                return (<span title={props.title}>{props.title}</span>);
        }
    }

    renderRow = (rw: React.ReactElement<HTMLTableRowElement>, props: TreeListRowProps) => {

        const di = props.dataItem as TcbObjTreeItem;

        if (di.itemHighlighted) {
            return (<rw.type {...rw.props} className="highlightedRow" />);
        }

        if (di.isLinked) {
            return (<rw.type {...rw.props} className="linkedRow" />);
        }
        else {
            //return rw;
            return <rw.type {...rw.props} />;
        }

    }
    renderContextPopup = () => {

        let popupTitle = "";
        switch (this.state.popupStatus) {
            case ContextMenuStatus.CameraRunning:
                popupTitle = "Camera Running";
                break;
            case ContextMenuStatus.CancelPhoto:
                popupTitle = "Cancelling";
                break;
            case ContextMenuStatus.LoadingMenu:
                popupTitle = "Loading Menu";
                break;
            case ContextMenuStatus.SavingPhoto:
                popupTitle = "Saving Photo";
                break;
            case ContextMenuStatus.Menu:

                break;
        }

        switch (this.state.popupStatus) {
            case ContextMenuStatus.Menu:
                let selCount = this.getSelectedRowCount(this.state.searchResults);
                if (selCount <= 1) {
                    return (<Menu className="cellMenu" items={this.state.popupMenuData} openOnClick={true} vertical={true}
                        onSelect={(e) => this.contextMenuClick(e)} />);
                } else {
                    return (<Menu className="cellMenu" items={this.state.popupMenuDataMultiSelect} openOnClick={true} vertical={true}
                        onSelect={(e) => this.contextMenuClick(e)} />);
                }
            default:
                return (<div id="splSearchPopupLoading" ><div id="splSearchPopupLoadingTitle">{popupTitle}</div><span className="k-icon k-font-icon k-i-loading" /></div>);
        }
    }

    render() {
        return (
            <div data-component="splSrchRef" id="splSearchMainDiv" className={addPageViewClass(this.props.pageInf)} ref={this.containerRef}>
                <div id="splSearchHdrDiv" className={addPageViewClass(this.props.pageInf)} style={this.state.headerStyle}>
                    <div id="splSearchHdrDivP1">
                        {/* <DropDownList className="filterByList" data={filterByList} dataItemKey="key" textField="display" value={this.state.selectedFilterBy} onChange={this.selectedFilterByChange} /> */}
                        <Input className={"filterText " + addPageViewClass(this.props.pageInf)} placeholder="Enter Search Text (3 chars min)" value={this.state.searchStr} onChange={this.searchCriteriaChange} onKeyDown={this.searchTxtKeyDown} />
                        <Button className={"filterSearchBtn " + addPageViewClass(this.props.pageInf)} onClick={this.searchBtnClick} ><span className="k-icon k-font-icon k-i-search srchIconsBtn" /></Button>

                        {this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser &&
                            <Button className={"filterSearchBtn " + addPageViewClass(this.props.pageInf)} onClick={this.cameraScanClick} title="Scan barcode using Camera" ><span className="k-icon k-font-icon k-i-qr-code-outline srchIconsBtn" /></Button>
                        }
                        {this.state.summaryResults.length > 0 &&
                            <div className="filterResultsLabel">Results</div>}
                        {this.state.summaryResults.length > 0 &&
                            <DropDownList className="filterResultsList" data={this.state.summaryResults} dataItemKey="key" textField="value" value={this.state.selectedSummaryResult} onChange={this.selectedSummaryResultChange} />
                        }
                    </div>
                    <div id="splSearchHdrDivP3">
                        <Button className="refreshButton" onClick={this.refreshGrid}><span className="k-icon k-font-icon k-i-reload" /></Button>
                    </div>
                </div>
                <div id="splSearchBdyDiv">
                    <Popup id="contextPopup"
                        offset={this._popupOffSet}
                        show={this.state.popupStatus !== ContextMenuStatus.Closed}
                        onOpen={this.popupOpen}
                    >
                        <div
                            ref={(el) => (this._popupDivRef = el)}
                            onFocus={this.onFocusHandler}
                            onBlur={this.onBlurHandler}
                            tabIndex={-1}>
                            {this.renderContextPopup()}
                        </div>
                    </Popup>
                    <TreeList
                        className="splSearchTreeList"
                        style={this.state.treeListStyle}
                        // tableProps={{ style: this.state.treeListTableStyle }}
                        data={this.state.searchResults}
                        dataItemKey="id"
                        subItemsField="childItems"
                        expandField="itemExpanded"
                        rowHeight={22}
                        scrollable="virtual"
                        onColumnResize={this.onColumnResize}
                        resizable={true}
                        noRecords={this.renderNoRecords()}
                        columns={this.state.treeListCols}
                        headerCellRender={this.renderHeaderCell}
                        rowRender={this.renderRow}
                    />
                </div>
            </div>
        );
    }
}

export default connector(SplSearch);
