import React, { useEffect, useState } from "react";
import {
  Button,
  Toolbar,
} from "@progress/kendo-react-buttons";
import {
  Signature,
  SignatureChangeEvent,
} from "@progress/kendo-react-inputs";
import { Input } from '@progress/kendo-react-inputs'
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { InputCtlViewMode, PageViewTypeEnum } from '../../models/enums';
//import TextInputCtl from './TextInputCtl'
import "./SignatureCtl.css";

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SignatureProps = PropsFromRedux & {
  keyProp?:any;
  mode?: InputCtlViewMode;
  readOnlyAfterSave: boolean;
  value?: string | null;
  textValue?: string|null;
  onUpdate?: (e: string | null, setViewMode?: (newMode: InputCtlViewMode) => void) => void;
  onFocus?: (callback: any|undefined) => void;
  barcodeCallback?: any;
}

interface tcbObjSignature {
  TextValue: string,
  SigValue: string
}


function SignatureCtl(this: any, props: SignatureProps) {
  const [sigValue, setSigValue] = useState<string>();
  const [textValue, setTextValue] = useState<string>();
  const [mode, setMode] = useState<InputCtlViewMode>();
  const strokeWidth = 3;



  useEffect(() => {


    if (props.value) {


      let obj: tcbObjSignature = JSON.parse(props.value);
      
      setTextValue(obj.TextValue);

if (obj.SigValue){
  setSigValue(obj.SigValue);
}


    
      if (props.readOnlyAfterSave)
        setMode(InputCtlViewMode.ReadOnly);
      else
        setMode(InputCtlViewMode.Edit);
     }

     else{

       setMode(InputCtlViewMode.Edit);
     }
    
     if(props.textValue){
      setTextValue(props.textValue);
      props.textValue=undefined;
    }
    

   
  }

    , [mode, props]);

  const onChange = (e: SignatureChangeEvent) => {
    setSigValue(e.value);
  };


  const onSave = () => {

    setMode(InputCtlViewMode.Saving);

    if (sigValue && textValue) {

      let output: any = {
        "TextValue": textValue,
        "SigValue": sigValue
      };

      let json = JSON.stringify(output);

      if (props.onUpdate && json) {
        props.onUpdate(json, setViewMode);
      }

      //props.value=json;

    } else {

      //props.value = undefined;
    }
  };

  const onClear = () => {

    setMode(InputCtlViewMode.Saving);

    setTextValue(undefined);

    setSigValue(undefined);

    let textField = document.getElementById("sigtextField");
    if (textField !== null){
      textField.nodeValue="";
    }

    //props.value=undefined;

    if (props.onUpdate) {
      props.onUpdate(null, setViewMode);
    }
  };

  const onFocus = () => {
    if (props.onFocus)
        props.onFocus(scanCallback);
}

const scanCallback = (e: string) =>
  {
      props.textValue = e;
  }

  const setViewMode = (e: InputCtlViewMode) => {
    setMode(e);
  }
 
  const updateTextValue = (e:InputChangeEvent) => {

    setTextValue(e.value)
  }

  let popupScale = 3;
  if (props.pageInf.pageViewMode === PageViewTypeEnum.Mobile)
    popupScale = 1.2;

  switch (mode) {
    case InputCtlViewMode.ReadOnly:
    default:
      return (

        <div className="SignatureCtl">
          <Signature
            value={sigValue}
            strokeWidth={strokeWidth}
            smooth={true}
            maximizable={true}
            hideLine={true}
            readOnly={true}
            popupScale={popupScale}
          />
          <Input
            value={textValue}
            readOnly={true}
            //mode={InputCtlViewMode.ReadOnly}
          />
        </div>

      );
    case InputCtlViewMode.Edit:
    case InputCtlViewMode.Saving:
    case InputCtlViewMode.View:
      return (
        <div className="SignatureCtl">
          <Signature
            value={sigValue}
            onChange={onChange}
            strokeWidth={strokeWidth}
            smooth={true}
            maximizable={true}
            hideLine={true}
            popupScale={popupScale}
          />
          <Input
            id="sigtextField"
            value={textValue}
            //mode={InputCtlViewMode.View}
            placeholder="Print Name"
            onFocus={onFocus}
            onChange={updateTextValue}
            //onUpdate={(e: string, svm?: (newMode: InputCtlViewMode) => void) => updateTextValue(e, svm)}
          />
          <Toolbar>
            <Button
              icon="save"
              themeColor="primary"
              disabled={!sigValue || !textValue}
              onClick={onSave}
              hidden={mode !== InputCtlViewMode.Edit}
            >
              Save
            </Button>


            <Button
              onClick={onClear}
              disabled={!sigValue || !textValue}
              hidden={mode !== InputCtlViewMode.Edit}
            >
              Clear
            </Button>
            <div className='saving' hidden={mode !== InputCtlViewMode.Saving}><span className="k-icon k-font-icon k-i-loading" /></div>
          </Toolbar>
        </div>
      );
  }


}
export default connector(SignatureCtl);

