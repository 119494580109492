import { UserInfo, ValidationColumn, TcbObjInfo, FpcModel, FpcParam, StepperItem, ClientQueryParamsView } from '../models/models';
import { fpcTypeEnum, CQStatusEnum, StepItemIndexEnum } from '../models/enums';
import moment from 'moment';


export class FpcLib {
    
    public BuildParamValsFromTcbObjs(tcbObjs: TcbObjInfo[], securityId? : number) {
        var plStr = "";
        var pliStr = "";
        var itmStr = "";
        var secStr = "";

        if (securityId){
            secStr=securityId.toString()
        }

        tcbObjs.forEach(x => {
            switch (x.tcbObjClass) {
                case "P":
                    plStr += x.tcbObjId.toString() + ",";
                    break;
                case "T":
                    pliStr += x.tcbObjGuidStr + ",";
                    break;
                case "I":
                    itmStr += x.tcbObjId.toString() + ",";
                    break;
            }
        });
        
        let fpcParams: FpcParam[] = [];
        if (plStr.length > 0) {
            fpcParams.push({ParameterName: "CqPackListIDs", ParameterValue: plStr.substring(0, plStr.length-1)});
        }
        if (pliStr.length > 0) {
            fpcParams.push({ParameterName: "CqPackListItemIDs", ParameterValue: pliStr.substring(0, pliStr.length-1)});
        }
        if (itmStr.length > 0) {
            fpcParams.push({ParameterName: "CqItemIds", ParameterValue: itmStr.substring(0, itmStr.length-1)});
        }
        if (secStr.length > 0){
            fpcParams.push({ParameterName: "CqSecurityId", ParameterValue: secStr});
        }


        return  JSON.stringify(fpcParams);
    }


    public InitialiseFpcModel(usr: UserInfo, cqGrpId?: number, cqId?: number, tcbObjs?: TcbObjInfo[]) {

    }

    public SetErrorMessage(fpc:FpcModel, errTitle: string, errMsg: string) {
        fpc.fpcStatus = CQStatusEnum.DisplayError;
        fpc.ErrorData.ErrorTitle = errTitle;
        var col = new ValidationColumn("Error Message", "ErrorMessage");
        fpc.ErrorData.Columns = [];
        fpc.ErrorData.Data = [];
        fpc.ErrorData.Columns.push(col);
        fpc.ErrorData.Data.push({ "ErrorMessage": errMsg })

        //Disable Stepper Items
        fpc.stepperItems.forEach(x => { x.disabled = true });
        fpc.showStepper = false;
    }

    public LoadStepperItems(fpc:FpcModel, onSuccess?: (para: FpcModel) => void, onError?: (para: FpcModel) => void) {
        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/GetClientQuerySteps';
        let body = {
            clientQueryId: fpc.clientQueryId,
            enableSelect: fpc.canSelectQuery
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw (res.callStatusMessage);
                } else {
                    fpc.stepperItems = res.results;
                    fpc.stepperCurrentIndex = this.setStartingStepperIndex(fpc.stepperItems);
                }
                if (onSuccess) onSuccess(fpc);
            })
            .catch(err => {
                this.SetErrorMessage(fpc, "Error Loading Steps", err.toString());
                if (onError) onError(fpc);
            });

    }

    public LoadClientQuery(fpc: FpcModel, onLoad?: (para: FpcModel) => void, onError?: (para: FpcModel) => void) {
        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/GetClientQueryDetails';
        let body = {
            clientQueryId: fpc.clientQueryId,
            clientQueryParamValues: fpc.clientQueryParamValues,
        }
        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token }) })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    this.SetErrorMessage(fpc, "Load Process failed", res.callStatusMessage);
                    if (onError) {
                        onError(fpc);
                    }
                }
                else {
                    fpc.cqSQL = res.result.clientQuerySQL;
                    fpc.cqValidationTypeId = res.result.validationTypeId;
                    fpc.cqValidationSQL = res.result.validationSQL;
                    fpc.cqHelpDescription = res.result.helpDescription;

                    fpc.clientQueryDesc = res.result.clientQueryDesc;
                    fpc.clientQueryParams = [];

                    res.result.clientQueryParams.forEach((e: ClientQueryParamsView) => {
                        if (e.parameterClassCode === "Yes or No" || e.parameterClassCode === "CQ - Yes or No") {
                            e.parameterValue = "0";
                        }

                        if (e.defaultValueSQL) {
                            e.defaultValueFetchInProg = false;
                            e.defaultValueFetched = false;
                        }

                        fpc.clientQueryParams.push(e)
                    });


                    switch (res.result.resultsType) {
                        case "ssrs_report":
                            fpc.fpcType = fpcTypeEnum.ReportViewer;
                            break;
                        case "imageviewer":
                            fpc.fpcType = fpcTypeEnum.ImageViewer;
                            break;
                        case "data_grid":
                        default:
                            fpc.fpcType = fpcTypeEnum.ClientQuery;
                            break;
                    }

                    if (onLoad) onLoad(fpc);

                }
            })
            .catch(err => {
                this.SetErrorMessage(fpc, "Error fetching Authenticate response", err.toString());
            });

    }

    public UpdateStepperStatusWithRequiredParamsStatus = (fpc: FpcModel) => {
        let reqParamsExist = fpc.clientQueryParams.some((x) => x.isRequired && !x.parameterValue);

        let validateStep = fpc.stepperItems.find(x => x.index === StepItemIndexEnum.Validate);
        if (validateStep) {
            if (!fpc.cqValidationSQL || reqParamsExist)
                validateStep.disabled = true;
            else
                validateStep.disabled = false;
        }

        let resultsStep = fpc.stepperItems.find(x => x.index === StepItemIndexEnum.Results);
        if (resultsStep) {
            if (reqParamsExist || fpc.cqValidationSQL)
                resultsStep.disabled = true;
            else
                resultsStep.disabled = false;
        }

    }

    public UpdateStepperStatusWithValidationStatus = (fpc: FpcModel) => {
        let resultsStep = fpc.stepperItems.find(x => x.index === StepItemIndexEnum.Results);
        if (resultsStep) {
            resultsStep.disabled = false;
        }
    }

    public RunCqValidation(fpc: FpcModel, callBackFunc: (para: FpcModel) => void) {

        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/RunCqValidation';


        let body = {
            clientQueryId: fpc.clientQueryId,
            clientQueryParams: fpc.clientQueryParams
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (process.env.REACT_APP_RunMode === "TST") {
                    console.log('Validation SQL;')
                    console.log(res.result.debugSQL);
                }

                if (res.callStatus !== "OK") {
                    this.SetErrorMessage(fpc, "Validation failed", res.callStatusMessage);
                }
                else {
                    fpc.ValidationInfo.ActionCode = res.result.actionCode;
                    fpc.ValidationInfo.Columns = res.result.columns.map((x: ValidationColumn) => ({ ...x }));
                    fpc.ValidationInfo.Columns.forEach((c) => { c.minWidth = c.width });
                    fpc.ValidationInfo.Data = JSON.parse(res.result.data);
                    switch (fpc.ValidationInfo.ActionCode.toLowerCase()) {
                        case "run":
                            fpc.fpcStatus = CQStatusEnum.RunCq;
                            break;
                        case "confirm":
                        case "validate":
                        case "validate_dirtyonly":
                        case "validate_all":
                            fpc.fpcStatus = CQStatusEnum.DisplayConfirmResults;
                            fpc.ValidationInfo.DataKeyName = fpc.ValidationInfo.Columns[0].fieldName;
                            this.UpdateStepperStatusWithValidationStatus(fpc);
                            break;
                        case "confirm_with_multiple_column_selection":
                        case "confirm_with_multiple_selection":
                        case "confirm_with_single_selection":
                        case "validatemultiselect":
                        case "validatemultiselect_all":
                        case "validatemultiselect_dirtyonly":
                        case "validatesingleselect":
                        case "validatesingleselect_all":
                        case "validatesingleselect_dirtyonly":
                            fpc.fpcStatus = CQStatusEnum.DisplayConfirmResults;
                            fpc.ValidationInfo.DataKeyName = fpc.ValidationInfo.Columns[0].fieldName;

                            //Add Selected property to validation columns
                            let valCol: ValidationColumn = new ValidationColumn("selected", "selected");
                            valCol["dataType"] = "select";
                            valCol["width"] = 50;
                            fpc.ValidationInfo.Columns.unshift(valCol);

                            //Add Selected property to data 
                            fpc.ValidationInfo.Data.forEach(x => x.selected = false);

                            this.UpdateStepperStatusWithValidationStatus(fpc);
                            break;
                        case "fail":
                            fpc.fpcStatus = CQStatusEnum.DisplayError;
                            fpc.ErrorData.ErrorTitle = "Validation failed";
                            fpc.ErrorData.Columns = fpc.ValidationInfo.Columns;
                            fpc.ErrorData.Data = fpc.ValidationInfo.Data;
                            break;
                        default:
                            this.SetErrorMessage(fpc, "Invalid Action Code", "Action code of \"" + fpc.ValidationInfo.ActionCode + "\" is not valid");
                    }
                }
            })
            .catch(err => {
                this.SetErrorMessage(fpc, "Error running CQ Validation", err.toString());
            })
            .finally(() => { callBackFunc(fpc); });
    }

    public RunCq(fpc: FpcModel, callBackFunc: (para: FpcModel) => void) {

        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/RunCq';


        let body = {
            clientQueryId: fpc.clientQueryId,
            clientQueryParams: fpc.clientQueryParams,
            validationData: ''
        }

        if (fpc.ValidationInfo.ActionCode.toLowerCase() === "validate_all" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validatesingleselect_all" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validatemultiselect_all" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "confirm" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "confirm_with_multiple_column_selection" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "confirm_with_multiple_selection" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "confirm_with_single_selection"
        ) {

            body.validationData = JSON.stringify(fpc.ValidationInfo.Data)
        }

        if (fpc.ValidationInfo.ActionCode.toLowerCase() === "validate" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validatesingleselect" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validatemultiselect" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validate_dirtyonly" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validatesingleselect_dirtyonly" ||
            fpc.ValidationInfo.ActionCode.toLowerCase() === "validatemultiselect_dirtyonly") {

            body.validationData = JSON.stringify(fpc.ValidationInfo.Data.filter(x => x.dirty))
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (process.env.REACT_APP_RunMode === "TST") {
                    console.log('Results Returned... local processing');
                    console.log('Run SQL;')
                    console.log(res.result.debugSQL);
                }

                if (res.callStatus !== "OK") {

                    this.SetErrorMessage(fpc, "Process failed", res.callStatusMessage);
                }
                else {
                    fpc.ResultData.ActionCode = res.result.actionCode;
                    fpc.ResultData.Columns = res.result.columns.map((x: ValidationColumn) => ({ ...x }));
                    fpc.ResultData.Columns.forEach((c) => { c.minWidth = c.width });
                    if (res.result.clientQueryResultId) {
                        fpc.ResultData.ClientQueryResultId = res.result.clientQueryResultId;
                        fpc.ResultData.ClientQueryResultRowCount = res.result.clientQueryResultRowCount
                        fpc.resultJsonStr = '';
                    }
                    else {
                        fpc.ResultData.ClientQueryResultId = '';
                        fpc.resultJsonStr = res.result.data;
                        this.ConvertFetchedData(fpc);
                    }

                    switch (fpc.ResultData.ActionCode.toLowerCase()) {
                        case "success":
                        default:
                            fpc.fpcStatus = CQStatusEnum.DisplayCqResults;
                            break;
                        case "grid":
                        case "gridfixed":
                        case "gridfull":
                        case "gridauto":
                            fpc.fpcStatus = CQStatusEnum.DisplayCqResultsGrid;
                            break;
                        case "fail":
                            fpc.fpcStatus = CQStatusEnum.DisplayError;
                            fpc.ErrorData.ErrorTitle = "Error running process";
                            fpc.ErrorData.Columns = fpc.ResultData.Columns;
                            fpc.ErrorData.Data = fpc.ResultData.Data;

                            break;
                    }
                }
            })
            .catch(err => {
                this.SetErrorMessage(fpc, "Error fetching Run CQ data", err.toString());
            })
            .finally(() => { callBackFunc(fpc); });
    }

    FetchCqData(fpc: FpcModel, rowNum: number, onSuccess: (r: FpcModel) => void, onFailure: (e: string) => void) {
        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/GetClientQueryResultRow';

        let body = {
            clientQueryResultId: fpc.ResultData.ClientQueryResultId,
            rowNumber: rowNum
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    onFailure(res.callStatusMessage);
                    this.SetErrorMessage(fpc, "Fetching Results failed", res.callStatusMessage);
                } else {
                    fpc.resultJsonStr += res.stringResponse;
                    onSuccess(fpc);
                }
            });

    }

    ConvertFetchedData(fpc: FpcModel) {
        fpc.ResultData.Data = JSON.parse(fpc.resultJsonStr);
        // Replace JSON dates with real dates (for Excel export)
        fpc.ResultData.Columns.filter((c) => c.dataType.toLowerCase() === "date" || c.dataType.toLowerCase() === "datetime").forEach((e) => {
            fpc.ResultData.Data.filter((d) => d[e.fieldName]).forEach((d) => {
                var tmp: moment.Moment;
                if (e.formatCode) {
                    tmp = moment(d[e.fieldName], e.formatCode);
                    if (tmp.isValid()) {
                        d[e.fieldName] = tmp.toDate();
                        return;
                    }
                }
                // Try ISO format 
                tmp = moment(d[e.fieldName], moment.ISO_8601);
                if (tmp.isValid()) {
                    d[e.fieldName] = tmp.toDate();
                    return;
                }

                // Try local (non US) format last
                tmp = moment(d[e.fieldName], "DD/MM/YYYY");
                if (tmp.isValid()) {
                    d[e.fieldName] = tmp.toDate();
                    return;
                }

                // Try local (non US) format last
                tmp = moment(d[e.fieldName], "DD-MM-YYYY");
                if (tmp.isValid()) {
                    d[e.fieldName] = tmp.toDate();
                    return;
                }


                d[e.fieldName] = "Invalid Date";
            });
        });

    }

    RunCqSSRS(fpc: FpcModel, callBackFunc: (para: FpcModel) => void) {

        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/RunCqSSRS';

        //Search for "OutputFormat" parameter in orig params, otherwise default to PDF
        let outFmt = "pdf";
        let of = fpc.clientQueryParams.find(x => x.parameterClassCode.toLowerCase() === "outputformat");
        if (of && of.defaultValueSQL) {
            outFmt = of.defaultValueSQL.toLowerCase();
        }


        let body = {
            outputFormat:  outFmt,
            clientQueryParams: fpc.ResultData.Data
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    this.SetErrorMessage(fpc, "Error generating SSRS report", res.callStatusMessage);
                } else {

                    fpc.fpcStatus = CQStatusEnum.ReportGen;

                    var gg = atob(res.result.fileBody);
                    var len = gg.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                        bytes[i] = gg.charCodeAt(i);
                    }

                    var file: Blob = new Blob();
                    if (!res.result.fileType) {
                        fpc.fpcStatus = CQStatusEnum.DisplayError;
                        fpc.errorTitle = "Report Type undefined";
                        fpc.errorMessage = "Report file type is not defined";
                    } else {

                        switch (res.result.fileType.toUpperCase()) {
                            case "DOC":
                            case "DOCX":
                                file = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                                break;
                            case "XLS":
                            case "XLSX":
                                // file = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                file = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                break;
                            case "PPTX":
                                file = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
                                break;
                            case "PDF":
                                file = new Blob([bytes], { type: 'application/pdf' });
                                break;
                            case "TIF":
                                file = new Blob([bytes], { type: 'image/tiff' });
                                break;
                            case "MHTML":
                                file = new Blob([bytes], { type: 'text/html' });
                                break;
                            case "CSV":
                                file = new Blob([bytes], { type: 'text/csv' });
                                break;
                            case "XML":
                                file = new Blob([bytes], { type: 'text/xml' });
                                break;
                            default:
                                fpc.fpcStatus = CQStatusEnum.DisplayError;
                                fpc.errorTitle = "Unsupported Report Output Type";
                                fpc.errorMessage = res.result.fileType + " is not a supported type";
                        }
                    }

                    if (fpc.fpcStatus !== CQStatusEnum.DisplayError) {

                        var fileURL = URL.createObjectURL(file);
                        var link = document.createElement('a');
                        link.href = fileURL;
                        link.download = res.result.fileName;
                        link.click();
                        fpc.fpcStatus = CQStatusEnum.ReportGen;
                    }
                }
            })
            .catch(err => {
                this.SetErrorMessage(fpc, "Error fetching SSRS data", err.toString());
            })
            .finally(() => { callBackFunc(fpc); });
    }

    RemoveCqResultData(fpc: FpcModel, callBack?: (e: FpcModel) => void) {

        if (!fpc.ResultData.ClientQueryResultId) {
            if (callBack) callBack(fpc);
            return;
        }

        let url = fpc.userInfo.currProject.apiUrl + '/api/Fpc/RemoveCqResultData';
        let body = {
            clientQueryResultId: fpc.ResultData.ClientQueryResultId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpc.userInfo.token } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    alert("Error removing CQ result data"); //using alert at moment because prefer to see results than error
                }
            })
            .catch(err => {
                alert("Error removing CQ result data");
            })
            .finally(() => {if (callBack) callBack(fpc)});
    }


    RestartCq(fpc: FpcModel) {
        if (fpc.canSelectQuery) {
            fpc.fpcStatus = CQStatusEnum.SelectCQ;
        } else {
            if (this.HasParams(fpc))
                fpc.fpcStatus = CQStatusEnum.DisplayParams;
        }
    }

    IsItemEnabled = (fpc: FpcModel, idx: Number) => {
        let itm = fpc.stepperItems.find(x => x.index === idx);
        if (itm)
            return !itm.disabled;
        else
            return false;
    }


    public SetCurrentStepperItem = (fpc: FpcModel, currIdx: number) => {
        // this.updateStepperStatusWithRequiredParamsStatus();

        fpc.stepperCurrentIndex = currIdx;
        fpc.stepperItems.forEach((x) => { x.current = false });
        let itm = fpc.stepperItems.find(x => x.index === currIdx);
        if (itm) {
            itm.current = true;
        }

        fpc.stepperEnableBackButton = false;
        fpc.stepperEnableNextButton = false;

        switch (fpc.stepperCurrentIndex) {
            case StepItemIndexEnum.Select:
                if (fpc.clientQueryId !== 0)
                    fpc.stepperEnableNextButton = true;
                break;
            case StepItemIndexEnum.Parameters:
                if (this.isItemEnabled(fpc, StepItemIndexEnum.Select))
                    fpc.stepperEnableBackButton = true;

                if (this.isItemEnabled(fpc, StepItemIndexEnum.Validate) || this.isItemEnabled(fpc, StepItemIndexEnum.Results))
                    fpc.stepperEnableNextButton = true;
                break;
            case StepItemIndexEnum.Validate:
                if (this.isItemEnabled(fpc, StepItemIndexEnum.Select) || this.isItemEnabled(fpc, StepItemIndexEnum.Parameters))
                    fpc.stepperEnableBackButton = true;

                if (this.isItemEnabled(fpc, StepItemIndexEnum.Results))
                    fpc.stepperEnableNextButton = true;
                break;

            case StepItemIndexEnum.Results:
                if (this.isItemEnabled(fpc, StepItemIndexEnum.Select) || this.isItemEnabled(fpc, StepItemIndexEnum.Parameters) || this.isItemEnabled(fpc, StepItemIndexEnum.Validate))
                    fpc.stepperEnableBackButton = true;
                break;

        }

    }

    private isItemEnabled = (fpc: FpcModel, idx: Number) => {
        let itm = fpc.stepperItems.find(x => x.index === idx);
        if (itm)
            return !itm.disabled;
        else
            return false;
    }

    public HasParams(fpc: FpcModel): boolean {
        return fpc.clientQueryParams.some((x) => x.isVisible)
    }

    public HandleErrors(response: any) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    private setStartingStepperIndex(itms: StepperItem[]): number {
        let retVal = 0;

        let tmp = itms.find(x => !x.disabled);
        if (tmp)
            retVal = tmp.index;

        return retVal;
    }


}


