import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import './SuperUser.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SuperUserProps = PropsFromRedux;

interface SuperUserState {
    refreshInProgress: boolean;
    refreshResultMsg: string;
    refreshMaxFiles: number;

}

class SuperUser extends React.Component<SuperUserProps, SuperUserState> {

    constructor(props: SuperUserProps) {
        super(props);

        this.state = {
            refreshInProgress: false,
            refreshResultMsg: '',
            refreshMaxFiles: 50
        }
    }

    componentDidMount() {
    }


    refreshImageThumbs = () => {

        if (!window.confirm('Are you sure you want to build any missing Thumbnails?'))
        return;

            this.setState({ refreshInProgress: true, refreshResultMsg: '' });
    
            let url = this.props.userInf.currProject.apiUrl + '/api/file/RefreshFileThumbnails';
            let body = {
                maxFiles: this.state.refreshMaxFiles
            }
    
            fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        this.setState({ refreshInProgress: false, refreshResultMsg: res.stringResponse });
                        break;
                    case "UNAUTH":
                        this.setState({ refreshInProgress: false, refreshResultMsg: "API call Not Authorised" });
                        break;
                    default:
                        this.setState({
                            refreshInProgress: false,
                        });
                }
            })
            .catch(err => {
                this.setState({refreshInProgress: false, refreshResultMsg: err.toString()});
            });
    }

    maxRefreshFilesChange = (e:NumericTextBoxChangeEvent) => {
        if (e.value)
            this.setState({refreshMaxFiles: e.value});
    }

    render() {

        return (
            <div className="SuperUserOuterDiv">
                <table className="SuperUserTable">
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <table className='projDetails'>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>Project Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Name:</th>
                                            <td>{this.props.userInf.currProject.projectName}</td>
                                        </tr>
                                        <tr>
                                            <th>Desc:</th>
                                            <td>{this.props.userInf.currProject.projectDesc}</td>
                                        </tr>
                                        <tr>
                                            <th>API:</th>
                                            <td>{this.props.userInf.currProject.apiUrl}</td>
                                        </tr>
                                        <tr>
                                            <th>SQL Server:</th>
                                            <td>{this.props.userInf.currProject.connInfo.sqlServerName}</td>
                                        </tr>
                                        <tr>
                                            <th>Database:</th>
                                            <td>{this.props.userInf.currProject.connInfo.sqlDatabaseName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br /><br /><br />
                <Button icon="gear" disabled={this.state.refreshInProgress} onClick={() => this.refreshImageThumbs()} >Build Missing File Thumbnails</Button>&nbsp;
                    <NumericTextBox          value={this.state.refreshMaxFiles}       onChange={this.maxRefreshFilesChange}   min={0}  /><br/>
                {this.state.refreshInProgress && <span className="k-icon k-font-icon k-i-loading" />}
                <span>{this.state.refreshResultMsg}</span>
            </div>);
    }
}



export default connector(SuperUser);
