import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import packageJson from '../package.json';
import Login from './login/Login';
import { ll, addPageViewClass, addDBEnvironment, errorHander } from './functions/generalFunctions'
import Home from './components/Home';
import SearchMain from './components/SearchMain';
import SplSearch from './components/SplSearch';
import ItemDetails from './components/ItemDetails';
import PdaAddMember from './components/PdaAddMember/PdaAddMember';
import About from './components/About';
import Settings from './components/Settings';
import SuperUser from './components/SuperUser';
import MilestoneScan from './components/MilestoneScan/MilestoneScan';
import DashboardViewer from './components/DashboardViewer';
import TestDrawerContent from './components/TestDrawerContent';
import { SearchResultsCriteria, UserInfo, TcbObjInfo, DrawerItemsType, GetMenuItemRow, MenuItemModel, DropDownListData } from './models/models';
import { InputCtlDdlDataSource, InputCtlViewMode, MainPageViewType, MenuItemTypeEnum, PageViewTypeEnum, SearchModeEnum, fpcClientQueryGrpEnum } from './models/enums';
import tcbLogoSml from './images/Tcb-50.gif';
import tcbLogoLg from './images/Tcb-Lg.gif';
import { Button } from '@progress/kendo-react-buttons';
import { Drawer, DrawerContent, DrawerProps, DrawerItem, Menu, MenuSelectEvent } from '@progress/kendo-react-layout';
import { CustomWindow } from './models/custom.window'
import { mapStateToProps, mapDispatchToProps } from './redux/reduxActions';
import { Popup, Offset, PopupOpenEvent } from '@progress/kendo-react-popup';
import Head from "react-document-configuration";
import { AccountInfo,  PublicClientApplication } from "@azure/msal-browser";
import { MsalContext, MsalProvider } from "@azure/msal-react";
import '@progress/kendo-theme-default/dist/all.css'
import './App.css';
import { DrawerSelectEvent } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import FpcCtl from './components/Fpc/FpcCtl';
import DropDownListCtl from './components/InputCtls/DropDownListCtl';

declare function invokeCloseApplicationAction(): void;
declare function invokeGetAndroidModelAction(retCall: string): void;
declare function invokeRefreshPageAction(): void;
declare function invokeSetGoBackScriptAction(retCall: string): void;
declare function invokeGetDefaultUserAction(): void;

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface AppState {
  pageTitle: JSX.Element;
  pageTitleTxt: string;
  appDivStyle: React.CSSProperties;
  appBodyDivStyle: React.CSSProperties;
  appBackgroundStyle: React.CSSProperties;

  isMenuExpanded: boolean;
  currentContentView: MainPageViewType;

  searchListCriteria: SearchResultsCriteria;
  searchDetailsInfo: TcbObjInfo;

  menuItems: DrawerItemsType[];
  showCloseVerify: boolean;

  dashboardPopupOpen: boolean;
  dashboardItems: MenuItemModel[];
  dashboardItemsFetched: boolean;
  appBodyPageHeight: number;

  defaultTab?: string;
  showSwitchRoleList: boolean;

  switchToUserRole?: number;
  account?: AccountInfo;
}

const homePageItemId = 0;
declare let window: CustomWindow;

const getRedirectUri = () => {
  return process.env.PUBLIC_URL;

};

const msalConfig = {
  auth: {
      clientId: "7cae904a-8a1c-450a-b912-9d1c569ced5d",
      authority: "https://login.microsoftonline.com/9596af7d-79e1-4fa1-9453-f5e803aa45a9",
      redirectUri: getRedirectUri(),  // Dynamically set the redirect URI
  },
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(msalConfig);

class App extends React.Component<PropsFromRedux, AppState> {

  static contextType = MsalContext;

  context!: React.ContextType<typeof MsalContext>
  //declare context: React.ContextType<typeof MsalContext>

  constructor(props: PropsFromRedux) {
    super(props);

    window.appRef = this;

    var baseMenuItems = this.fetchBaseMenuItems();

    this.state = {
      pageTitle: this.renderPageTitle('', '', 'TracBASE'),
      pageTitleTxt: 'TracBASE',
      appDivStyle: {},
      appBodyDivStyle: {},
      appBackgroundStyle: {},
      isMenuExpanded: this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser,
      currentContentView: MainPageViewType.Home,
      searchListCriteria: new SearchResultsCriteria(),
      searchDetailsInfo: new TcbObjInfo(),
      menuItems: baseMenuItems,
      showCloseVerify: false,
      appBodyPageHeight: 0,
      dashboardPopupOpen: false,
      dashboardItems: [],
      dashboardItemsFetched: false,
      showSwitchRoleList: false
    };

  }

  componentDidMount() {
    // window.addEventListener('popstate', (e) => {
    //   alert('here');
    //   window.history.go(1);
    // });

    const { instance } = this.context; // Access MSAL instance from context

    const currentAccounts = instance.getAllAccounts();
    if (currentAccounts && currentAccounts.length > 0) {
      this.setState({ account: currentAccounts[0] });
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

  }

  componentWillUnmount() {
  }

  private _popupOffSet: Offset = { top: 0, left: 0 };
  private _popupBlurTimeoutRef: any;
  private _popupDivRef: any;

  updateDimensions = () => {
    let body = document.body;
    if (body) {
      let appSt: React.CSSProperties = { ...this.state.appDivStyle };
      let bdySt: React.CSSProperties = { ...this.state.appBodyDivStyle };
      appSt.height = body.clientHeight;
      bdySt.height = body.clientHeight - 80;

      this.setState({
        appDivStyle: appSt,
        appBodyDivStyle: bdySt
      });
    }
  }


  //Called from injected javascript (on Mobile only)
  invokeStartupActions() {
    try {

      if (typeof invokeSetGoBackScriptAction !== 'undefined') {
        invokeSetGoBackScriptAction('window.appRef.backButtonPressed();');
      }
    } catch (err) {
      alert(err);
    }

    try {
      if (typeof invokeGetAndroidModelAction !== 'undefined') {
        invokeGetAndroidModelAction('window.appRef.setDeviceModel');
      }
    } catch (err) {
      alert(err);
    }

  }


invokeGetDefaultUserAction = function(defaultUser) {
// Store user data in session storage
    sessionStorage.setItem("defaultUser", defaultUser);
    window.dispatchEvent(new Event("defaultUserSet"));
  }


  setDeviceModel(model: string, vendor: string, viewHeight: number, viewWidth: number) {
    let pi = this.props.pageInf;
    pi.deviceModel = model;
    pi.deviceVendor = vendor;
    this.props.updatePageInfo(pi);
  }

  isAuthorised = () => {
    return (this.props.userInf.userId !== 0);
  }

  switchRole = () => {
    if (this.props.userInf.username !== "softline" && (this.props.userInf.userData !== null || this.props.userInf.currProjectUserRoleName.toLowerCase() === "superuser")) {
      this.setState({ showSwitchRoleList: true });
    }

  }

  setSwitchRole = (e: DropDownListData) => {
    this.setState({ switchToUserRole: parseInt(e.value) });
  }

  switchRoleAction = () => {
    if (this.props.userInf) {

      let url = this.props.userInf.currProject.apiUrl + '/api/SwitchRole';

      let body = {
        roleId: this.state.switchToUserRole
      }

      fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
        .then(r => r.json())
        .then(res => {
          switch (res.callStatus) {
            case "OK":

              window.location.reload();

              break;
            case "UNAUTH":
              let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
              this.props.updateUserInfo(uInf);
              break;

            default:
              errorHander(this.props.userInf, 'App.tsx', res.callStatusMessage)
          }
        })

        .catch(err => {
          errorHander(this.props.userInf, 'App.tsx', err.toString())
        });
    }
  }

  cancelSwitchRoleAction = () => {
    this.setState({ showSwitchRoleList: false });
  }

  fetchBaseMenuItems = () => {
    var baseMenuItems: DrawerItemsType[] = [];
    //  baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Home, menuitemid: 0, fixedItem: true, textBase: 'Home', text: 'Home', iconCode: '&#127969;', iconImgStr: '', titleview: this.renderPageTitle('', '', 'TracBASE'), disabled: false, separator: false, visible: true, displaygroup: -1, displayorder: 1, selected: true, groupMenuItems: []  });

    baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Loading, menuitemid: 1, fixedItem: true, textBase: 'Loading...', text: 'Loading...', iconCode: 'k-i-loading', iconImgStr: '', titleview: this.renderPageTitle('', '', this.getSearchPageTitleTxt()), disabled: true, separator: false, visible: true, displaygroup: 0, displayorder: 0, selected: false, groupMenuItems: [] });

    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Reports, menuitemid: 10, fixedItem: true, textBase: 'Reports', text: 'Reports', iconCode: '&#128195;', iconImgStr: '', titleview: this.renderPageTitle('&#128195;', '', 'Reports'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 1, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Actions, menuitemid: 11, fixedItem: true, textBase: 'Actions', text: 'Actions', iconCode: '&#128203;', iconImgStr: '', titleview: this.renderPageTitle('&#128203;', '', 'Actions'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 2, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Dashboards, menuitemid: 12, fixedItem: true, textBase: 'Dashboards', text: 'Dashboards', iconCode: '&#128064;', iconImgStr: '', titleview: this.renderPageTitle('&#128064;', '', 'Dashboards'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 3, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.NonAction, menuitemid: 13, fixedItem: true, textBase: '', text: '', iconCode: '', iconImgStr: '', titleview: this.renderPageTitle('', '', ''), disabled: false, separator: true, visible: true, displaygroup: 1, displayorder: 4, selected: false });

    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Search, menuitemid: 2, fixedItem: true, textBase: 'Search', text: 'Search', iconCode: 'k-i-search', iconImgStr: '', titleview: this.renderPageTitle('k-i-search', '', this.getSearchPageTitleTxt()), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 5, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.MilestoneScan, menuitemid: 3, fixedItem: true, textBase: 'Milestone Scan', text: 'Milestone Scan', iconCode: '&#9889;', iconImgStr: '', titleview: this.renderPageTitle('&#9889;', '', 'Milestone Scan'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 6, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.NonAction, menuitemid: 4, fixedItem: true, textBase: '', text: '', iconCode: '', iconImgStr: '', titleview: this.renderPageTitle('', '', ''), disabled: false, separator: true, visible: true, displaygroup: 1, displayorder: 7, selected: false });

    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.About, menuitemid: 2, fixedItem: true, textBase: 'About', text: 'About', iconCode: 'k-font-icon k-i-info', iconImgStr: '', titleview: this.renderPageTitle('', 'k-font-icon k-i-info', 'About'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 4, selected: false, groupMenuItems: [] });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.RefreshPage, menuitemid: 6, fixedItem: true, textBase: 'Refresh', text: 'Refresh', iconCode: '', iconImgStr: 'k-i-reload', titleview: this.renderPageTitle('', 'k-i-reload', 'Refresh'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 5, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Admin, menuitemid: 14, fixedItem: true, textBase: 'Admin', text: 'Admin', iconCode: '&#128373;', iconImgStr: '', titleview: this.renderPageTitle('&#128373;', '', 'Admin'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 8, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.TestPage, menuitemid: 15, fixedItem: true, textBase: 'Test', text: 'Test', iconCode: '&#128373;', iconImgStr: '', titleview: this.renderPageTitle('&#128373;', '', 'Test'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 8, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.TestPage2, menuitemid: 16, fixedItem: true, textBase: 'Test2', text: 'Test2', iconCode: '&#128373;', iconImgStr: '', titleview: this.renderPageTitle('&#128373;', '', 'Test2'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 8, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Settings, menuitemid: 7, fixedItem: true, textBase: 'Settings', text: 'Settings', iconCode: 'k-i-gear', iconImgStr: '', titleview: this.renderPageTitle('k-i-gear', '', 'Settings'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 9, selected: false });
    // baseMenuItems.push({ menutypeid: MenuItemTypeEnum.NonAction, menuitemid: 9, fixedItem: true, textBase: '', text: '', iconCode: '', iconImgStr: '', titleview: this.renderPageTitle('', '', ''), disabled: false, separator: true, visible: true, displaygroup: 1, displayorder: 11, selected: false });

    baseMenuItems.push({ menutypeid: MenuItemTypeEnum.Logout, menuitemid: 3, fixedItem: true, textBase: 'Logout', text: 'Logout', iconCode: 'k-font-icon k-i-logout', iconImgStr: '', titleview: this.renderPageTitle('k-font-icon k-i-logout', '', 'Login'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 10, selected: false, groupMenuItems: [] });
    return baseMenuItems;
  }


  hamburgerMenuClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ isMenuExpanded: !this.state.isMenuExpanded });
  }

  componentStateUpdate = (componentId: number, state: any) => {
    let mitms = this.state.menuItems;
    let mitm = mitms.find(x => x.menuitemid === componentId);
    if (mitm) {
      mitm.itemstate = state;
      this.setState({ menuItems: mitms });
    }
  }

  onLoginSuccess = (u: UserInfo, roleSwitch?: boolean) => {

    //Set Background image for project data
    let abs: React.CSSProperties = {}
    if (u.currProject.backgroundImg && u.currProject.backgroundImg.length > 0) {
      let bi = 'url("data:image/jpg;base64,' + u.currProject.backgroundImg + '")';
      let op = 1;
      if (u.currProject.backgroundImgOpacity)
        op = u.currProject.backgroundImgOpacity / 100;
      abs.backgroundImage = bi;
      abs.opacity = op;
    }
    this.setState({ appBackgroundStyle: abs });

    let url = this.props.userInf.currProject.apiUrl + '/api/LoginEvent';

    let body = {
      userName: this.props.userInf.username
    }

    fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
      .catch(err => {
        errorHander(this.props.userInf, 'App.tsx', err.toString())
      });

    url = this.props.userInf.currProject.apiUrl + '/api/ui/GetMenuItemsForRole';

    if (roleSwitch) {
      url = this.props.userInf.currProject.apiUrl + '/api/ui/GetMenuItemsForRole?roleId=' + this.props.userInf.currProjectUserRoleId;
    }

    fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
      .then(r => r.json())
      .then(res => {
        switch (res.callStatus) {
          case "OK":
            let mitms = this.state.menuItems.filter(x => x.displaygroup !== 0);
            let addItems: GetMenuItemRow[] = [...res.results].filter(x => (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser && x.browserEnable === true) ||
              (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile && x.mobileEnable === true) ||
              (this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet && x.tabletEnable === true));

            addItems.sort((a, b) => (a.sortOrder < b.sortOrder) ? -1 : 1).forEach(x => {

              let mitm: DrawerItemsType = new DrawerItemsType({
                menuitemid: Math.max.apply(Math, mitms.map(y => { return y.menuitemid ?? 100 })) + 1,
                menutypeid: x.securityActionTypeId,
                fixedItem: true,
                text: x.description,
                securityid: x.securityId,
                clientqueryid: x.clientQueryId,
                iconCode: x.iconCode,
                iconImgStr: x.iconImageStr,
                disabled: false,
                separator: false,
                visible: true,
                displaygroup: 0,
                displayorder: res.sortOrder,
                selected: false,
                groupMenuItems: this.buildMenuModelList(x)
              });


              if ((!mitm.iconCode || mitm.iconCode.length === 0) &&
                (!mitm.iconImgStr || mitm.iconImgStr.length === 0)) {
                mitm.iconCode = '&#10024;';
              }
              mitm.titleview = this.renderPageTitle(mitm.iconCode, mitm.iconImgStr, mitm.text)

              mitms.push(mitm);
            });

            //Does Home Page exist... if not we add a default one.
            if (!mitms.some(x => x.fixedItem && x.menutypeid === MenuItemTypeEnum.Home)) {
              mitms.splice(0, 0, { menutypeid: MenuItemTypeEnum.Home, menuitemid: 0, fixedItem: true, textBase: 'Home', text: 'Home', iconCode: '&#127969;', iconImgStr: '', titleview: this.renderPageTitle('', '', 'TracBASE'), disabled: false, separator: false, visible: true, displaygroup: 0, displayorder: 1, selected: true, groupMenuItems: [] });
            }

            //Set Home Page as selected
            let hp = mitms.find(x => x.fixedItem && x.menutypeid === MenuItemTypeEnum.Home);
            if (hp) hp.selected = true;

            //Super User?
            if (this.props.userInf.isSuperUser)
              mitms.push({ menutypeid: MenuItemTypeEnum.SuperUserPage, menuitemid: 2, fixedItem: true, textBase: 'MMG Only', text: 'MMG Only', iconCode: 'k-font-icon k-i-gear', iconImgStr: '', titleview: this.renderPageTitle('k-font-icon k-i-gear', '', 'MMG Maintenance'), disabled: false, separator: false, visible: true, displaygroup: 1, displayorder: 11, selected: false, groupMenuItems: [] });


            this.setState({ menuItems: mitms });
            break;
          case "UNAUTH":
            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
            this.props.updateUserInfo(uInf);
            break;

          default:
            errorHander(this.props.userInf, 'App.tsx', res.callStatusMessage)
        }
      })
      .catch(err => {
        errorHander(this.props.userInf, 'App.tsx', err.toString())
      });
  }

  buildMenuModelList = (e: GetMenuItemRow) => {
    let retVal: MenuItemModel[] = [];
    retVal.push(this.buildMenuModel(e));
    return retVal;
  }

  buildMenuModel = (e: GetMenuItemRow) => {
    let mnuItm: MenuItemModel = new MenuItemModel();
    mnuItm.id = e.securityId.toString();
    mnuItm.text = e.description;
    mnuItm.cssClass = "menuItmClass"
    mnuItm.data = e;
    e.items.forEach(x => {
      mnuItm.items.push(this.buildMenuModel(x));
    });
    return mnuItm;
  }


  updateMenuItem = (menuItemId: number, itemState: any, menuTxt: string, callback?: () => void) => {
    let mitms = this.state.menuItems;
    let mitm = mitms.find(x => x.menuitemid === menuItemId)!;
    let newText = mitm.textBase;
    if (menuTxt.length > 0) {
      newText = menuTxt;
    }
    if (mitm.fixedItem) {
      let newItm: DrawerItemsType = { ...mitm, text: newText, itemstate: itemState, titleview: this.renderPageTitle(mitm.iconCode, mitm.iconImgStr, newText) }
      mitms = this.createNewMenuItem(mitms, newItm);
      this.setState({ menuItems: mitms, pageTitle: newItm.titleview }, () => {
        if (callback) {
          callback();
        }
      });
    } else {
      mitm.text = newText;
      mitm.titleview = this.renderPageTitle(mitm.iconCode, mitm.iconImgStr, newText);
      mitm.itemstate = itemState;
      this.setState({ menuItems: mitms, pageTitle: mitm.titleview }, callback);
    }
  }

  createNewMenuItem = (menuItems: DrawerItemsType[], mitm: DrawerItemsType) => {
    let newMenuItemId = Math.max.apply(Math, menuItems.map(x => { return x.menuitemid ?? 100 })) + 1;
    let newMenuItemDisplayOrder = 1;
    if (menuItems.some(x => x.displaygroup === 2))
      newMenuItemDisplayOrder = (Math.max.apply(Math, menuItems.filter(x => x.displaygroup === 2).map(x => { return x.displayorder })) ?? 0) + 1;
    let newItm: DrawerItemsType = {
      ...mitm,
      menuitemid: newMenuItemId,
      fixedItem: false,
      displaygroup: 2,
      displayorder: newMenuItemDisplayOrder,
      selected: true,
    }

    menuItems.forEach(x => { x.selected = false }); //Deselect others
    menuItems.push(newItm);

    return menuItems;
  }

  overlayClick = () => {
    if (this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser)
      this.setState({ isMenuExpanded: false });
  }

  private closeInProg: boolean = false;

  menuItemSelectFromHome = (e: GetMenuItemRow) => {
    //First check if this item on main menu
    let itm = this.state.menuItems.find(x => x.securityid === e.securityId && x.fixedItem);
    if (itm)
      this.menuItemSelect(itm);
    else
      //Must be a child item
      this.grpMenuItemSelect(e);
  }

  menuItemSelectFromDrw = (e: DrawerSelectEvent) => {
    let itm = this.state.menuItems.find(x => x.menuitemid === e.itemTarget.props.menuitemid);
    if (itm) { this.menuItemSelect(itm) };
  }

  menuItemSelect = (itm: DrawerItemsType) => {

    if (!this.closeInProg) {


      switch (itm.menutypeid) {

        case MenuItemTypeEnum.MenuGroup:
        case MenuItemTypeEnum.DashboardGroup:

          break;


        default:
          let itms = this.selectMenuItem(this.state.menuItems, itm.menuitemid);
          let isExpanded = this.state.isMenuExpanded && (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser);
          let selItm = itms.find(x => x.selected);
          if (selItm)
            this.setState({ menuItems: itms, dashboardPopupOpen: false, isMenuExpanded: isExpanded, pageTitle: selItm.titleview, pageTitleTxt: selItm.text });

          break;

      }
    }
  }

  //Prepare state for displaying new page
  selectMenuItem = (menuItems: DrawerItemsType[], menuitemid: number) => {
    let mitm = menuItems.find(x => x.menuitemid === menuitemid)!;

    if(mitm){

    switch (mitm.menutypeid) {

      case MenuItemTypeEnum.Logout:

        window.location.reload();

        break;

      case MenuItemTypeEnum.RefreshPage:
        if (typeof invokeRefreshPageAction !== 'undefined') {
          invokeRefreshPageAction();
        }
        break;


      default:
        menuItems.forEach(x => x.selected = false);
        mitm.selected = true;
        break;

    }

  }
    return menuItems;

  }

  //Grp Menu Items always create a new, non-fixed Menu item
  grpMenuItemSelectEvent = (e: MenuSelectEvent) => {
    let mItm = e.item as MenuItemModel;
    let iDat = mItm.data as GetMenuItemRow;

    this.grpMenuItemSelect(iDat);
  }

  grpMenuItemSelect = (iDat: GetMenuItemRow) => {

    if (iDat.securityActionTypeId !== MenuItemTypeEnum.MenuGroup) {
      let mti: DrawerItemsType;
      //Does menu item already exist for this
      let mtif = this.state.menuItems.find(x => x.menutypeid === iDat.securityActionTypeId && !x.fixedItem && (x.securityid === iDat.securityId && x.dashboardUrl === iDat.dashboardUrl));
      if (mtif) {
        let itms = this.selectMenuItem(this.state.menuItems, mtif.menuitemid);
        this.setState({ pageTitle: mtif.titleview, menuItems: itms });
      } else {
        mti = {
          menutypeid: iDat.securityActionTypeId, menuitemid: -1, securityid: iDat.securityId, fixedItem: false, textBase: iDat.description, text: iDat.description, iconCode: iDat.iconCode,
          iconImgStr: iDat.iconImageStr, separator: false, titleview: this.renderPageTitle(iDat.iconCode, iDat.iconImageStr, iDat.description), dashboardUrl: iDat.dashboardUrl,
          visible: true, displaygroup: 2, displayorder: -1, disabled: false, selected: true, groupMenuItems: [], clientqueryid: iDat.clientQueryId
        }
        let dbItm = this.state.menuItems.find(x => x.menutypeid === MenuItemTypeEnum.Dashboards);
        if (dbItm) {
          mti.iconCode = dbItm.iconCode;
          mti.iconImgStr = dbItm.iconImgStr;
          mti.titleview = this.renderPageTitle(dbItm.iconCode, dbItm.iconImgStr, iDat.description);
        }

        let newMenuItems = this.createNewMenuItem(this.state.menuItems, mti);
        this.setState({ pageTitle: mti.titleview, menuItems: newMenuItems });

      }
    }
  }


  closeMenuItem = (itmId: number) => {
    this.closeInProg = true;
    let menuItems = this.state.menuItems;
    let cpFind = menuItems.find(x => x.menuitemid === itmId);
    let pt = this.renderPageTitle('', '', 'TracBASE');

    if (cpFind) {
      let cp = cpFind;

      //Should only be closing non-fixed items
      if (!cp.fixedItem) {

        menuItems = menuItems.filter(x => x.menuitemid !== cp.menuitemid);


        let gg = menuItems.filter(x => x.displaygroup === cp.displaygroup);
        gg.sort((a, b) => (a.displayorder < b.displayorder) ? 1 : -1);
        let prevPage = gg.find(x => x.displaygroup === cp.displaygroup && x.displayorder < cp.displayorder);
        if (prevPage) {
          menuItems = this.selectMenuItem(menuItems, prevPage.menuitemid);

        } else {

          if (cp.previousMenu) {
            menuItems = this.selectMenuItem(menuItems, cp.previousMenu);
          }
          else {
            menuItems = this.selectMenuItem(menuItems, homePageItemId);
          }

        }

        if(!menuItems.find(x => x.selected)){
          menuItems = this.selectMenuItem(menuItems, homePageItemId);  
        }

        pt = menuItems.find(x => x.selected)!.titleview
        //}
      }
    }
    let isExpanded = this.state.isMenuExpanded && (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser);
    this.setState({ menuItems: menuItems, isMenuExpanded: isExpanded, pageTitle: pt }, () => { this.closeInProg = false });
  }


  //This on used by back button
  closeSelectedMenuItem = (menuItems: DrawerItemsType[]) => {

    let cpFind = menuItems.find(x => x.selected);
    if (cpFind) {
      let cp = cpFind;

      //Should only be closing non-fixed items
      if (!cp.fixedItem) {

        menuItems = menuItems.filter(x => x.menuitemid !== cp.menuitemid);
        let gg = menuItems.filter(x => x.displaygroup === cp.displaygroup);
        gg.sort((a, b) => (a.displayorder < b.displayorder) ? 1 : -1);
        let prevPage = gg.find(x => x.displaygroup === cp.displaygroup && x.displayorder < cp.displayorder);
        if (prevPage) {
          menuItems = this.selectMenuItem(menuItems, prevPage.menuitemid);
        } else {

          if (cp.previousMenu) {
            menuItems = this.selectMenuItem(menuItems, cp.previousMenu);
          }
          else {
            menuItems = this.selectMenuItem(menuItems, homePageItemId);
          }
        }
      }
    }
    return menuItems;
  }




  backButtonPressed = () => {

    let menuItems = this.state.menuItems;
    let pt = this.renderPageTitle('', '', 'TracBASE');
    let mnuFind = menuItems.find(x => x.selected);
    if (mnuFind) {

      if (mnuFind.fixedItem) {

        if (mnuFind.menutypeid === MenuItemTypeEnum.Home) {
          if (menuItems.some(x => !x.fixedItem)) {
            this.setState({ showCloseVerify: true });
          } else {
            this.closeApplication();
          }
        } else {
          menuItems = this.selectMenuItem(menuItems, homePageItemId);
          pt = menuItems.find(x => x.selected)!.titleview
          this.setState({ menuItems: menuItems, pageTitle: pt });
        }
      } else {
        menuItems = this.closeSelectedMenuItem(menuItems);
        pt = menuItems.find(x => x.selected)!.titleview
        this.setState({ menuItems: menuItems, pageTitle: pt });
      }
    }
  }

  closeCloseVerifyDialog = () => {
    this.setState({ showCloseVerify: false });
  }

  closeApplication = () => {
    if (typeof invokeCloseApplicationAction !== 'undefined') {
      invokeCloseApplicationAction();
    }
  }


  showSearchResults = (e: SearchResultsCriteria) => {
    this.setState({ currentContentView: MainPageViewType.SearchResults, searchListCriteria: e });
  }

  showItemDetails = (e: TcbObjInfo, s?: string, m?: number) => {

    if (!s) {
      this.setState({ defaultTab: undefined });
    }

     let prevSelected = this.state.menuItems.find(x => x.selected)!.menuitemid;

    let mti: DrawerItemsType = {
      menutypeid: MenuItemTypeEnum.ItemDetails,
      menuitemid: -1,
      fixedItem: false,
      textBase: 'Item Details',
      text: e.tcbObjDesc,
      iconCode: 'k-font-icon k-i-aggregate-fields',
      iconImgStr: '',
      separator: false,
      tcbobj: e,
      titleview: this.renderPageTitle(!e.tcbObjTypeIconCode ? 'k-font-icon k-i-aggregate-fields' : '&#' + e.tcbObjTypeIconCode.toString(), '', e.tcbObjDesc),
      visible: true,
      displaygroup: 2,
      displayorder: -1,
      disabled: false,
      selected: true,
      groupMenuItems: [], previousMenu: prevSelected,
      //todo: pass reference of DrawerItemsType that called this
    }


    let newMenuItems = this.createNewMenuItem(this.state.menuItems, mti);
    this.setState({ pageTitle: mti.titleview, menuItems: newMenuItems });
  }

  showItemImagePage = (e: TcbObjInfo, m?: number) => {
    this.showItemDetails(e, "Images", m);
    this.setState({ defaultTab: "Images" });
  }

  showMilestoneScanPage = (tcbObjs: TcbObjInfo[]) => {
    let mti: DrawerItemsType = {
      menutypeid: MenuItemTypeEnum.MilestoneScan,
      menuitemid: -1,
      fixedItem: false,
      textBase: 'Update Milestone',
      text: tcbObjs[0].tcbObjDesc,
      iconCode: 'k-font-icon k-i-aggregate-fields',
      iconImgStr: '',
      separator: false,
      tcbobjs: tcbObjs,
      titleview: this.renderPageTitle(!tcbObjs[0].tcbObjTypeIconCode ? 'k-font-icon k-i-aggregate-fields' : '&#' + tcbObjs[0].tcbObjTypeIconCode.toString(), '', tcbObjs[0].tcbObjDesc),
      visible: true,
      displaygroup: 2,
      displayorder: -1,
      disabled: false,
      selected: true,
      groupMenuItems: []
    }


    let newMenuItems = this.createNewMenuItem(this.state.menuItems, mti);
    this.setState({ pageTitle: mti.titleview, menuItems: newMenuItems });
  }

  startClientQuery = (securityId: number, cqId: number, tcbObjs: TcbObjInfo[], mitm: DrawerItemsType) => {
    let mti: DrawerItemsType = {
      menutypeid: MenuItemTypeEnum.ClientQuery, menuitemid: -1, fixedItem: false, textBase: 'Action', text: mitm.text, iconCode: mitm.iconCode,
      iconImgStr: mitm.iconImgStr, separator: false, securityid: securityId, clientqueryid: cqId, tcbobjs: tcbObjs, titleview: this.renderPageTitle(mitm.iconCode, mitm.iconImgStr, mitm.text), visible: true, displaygroup: 2, displayorder: -1, disabled: false, selected: true, groupMenuItems: [], previousMenu: mitm.menuitemid,
    }

    let newMenuItems = this.createNewMenuItem(this.state.menuItems, mti);
    this.setState({ pageTitle: mti.titleview, menuItems: newMenuItems });

  }

  showDashboard = (e: MenuSelectEvent) => {
    let dataItm = e.item as MenuItemModel;

    let mti: DrawerItemsType = {
      menutypeid: MenuItemTypeEnum.Dashboards, menuitemid: -1, fixedItem: false, textBase: 'Dashboard', text: dataItm.text!, iconCode: '&#128064;',
      iconImgStr: '', separator: false, dashboardUrl: dataItm.data, titleview: this.renderPageTitle('&#128064;', '', dataItm.text!), visible: true, displaygroup: 2, displayorder: -1, disabled: false, selected: true, groupMenuItems: []
    }

    let dbItm = this.state.menuItems.find(x => x.menutypeid === MenuItemTypeEnum.Dashboards);
    if (dbItm) {
      mti.iconCode = dbItm.iconCode;
      mti.iconImgStr = dbItm.iconImgStr;
      mti.titleview = this.renderPageTitle(dbItm.iconCode, dbItm.iconImgStr, dataItm.text!);
    }


    let newMenuItems = this.createNewMenuItem(this.state.menuItems, mti);
    this.setState({ pageTitle: mti.titleview, menuItems: newMenuItems, dashboardPopupOpen: false });


  }

  getSearchPageTitleTxt = () => {
    if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile)
      return "Search / Scan";
    else
      return "Search";
  }

  //#region Pop / Context Menu events
  openDashboardPopup = (e: PopupOpenEvent) => {
    this._popupDivRef?.focus();
  }

  onFocusHandler = () => {
    clearTimeout(this._popupBlurTimeoutRef);
    this._popupBlurTimeoutRef = undefined;
  };

  onBlurTimeout = () => {
    this.setState({
      dashboardPopupOpen: false
    });

    this._popupBlurTimeoutRef = undefined;
  };

  onBlurHandler = (event) => {
    clearTimeout(this._popupBlurTimeoutRef);
    this._popupBlurTimeoutRef = setTimeout(this.onBlurTimeout);
  };

  renderPageTitle = (iconCode: string, iconImageStr: string, txt: string) => {
    return (<table id="pageTitleTbl" className={addPageViewClass(this.props.pageInf)}>
      <tbody>
        <tr>
          {((iconCode && iconCode.length > 0) || (iconImageStr && iconImageStr.length > 0)) &&
            <th>
              {this.renderDrawerIcon(iconCode, iconImageStr,false)}
            </th>}
          <td>{ll(this.props.userInf.langlookups, txt)}</td>
        </tr>
      </tbody>
    </table>);
  }


  //TODO - Change this to stop using danderouslySetInnterHTML!
  renderDrawerIcon = (iconCode: string, iconImgStr: string, noPadding: boolean) => {

    let style: React.CSSProperties  = {};
    if (noPadding){
      style.marginRight = "0px";
    };


    if (iconImgStr && iconImgStr.length > 0) {
      return <img style={style} src={'data:image/jpeg;base64,' + iconImgStr} alt='' />
    } else if (iconCode && iconCode.length > 0) {
      if (iconCode.startsWith("&#"))
        return <span style={style} className='k-icon' dangerouslySetInnerHTML={{ __html: iconCode }} />
      else
        return <span style={style} className={"k-icon " + iconCode} />
    }
    else
      return null;
  }

  //Have to use any here, otherwise Drawer tag chucks a sad
  renderDrawerItem = (props: any) => {

if (props.menutypeid === MenuItemTypeEnum.MenuGroup || props.menutypeid === MenuItemTypeEnum.DashboardGroup){
  return (
    <DrawerItem {...props}>
      {this.renderDrawerIcon(props.iconCode, props.iconImgStr, true)}
      {this.renderDrawerItemBody(props)}
    </DrawerItem>
  );
}
else {
 
    return (
      <DrawerItem {...props}>
        {this.renderDrawerIcon(props.iconCode, props.iconImgStr, false)}
        {this.renderDrawerItemBody(props)}
      </DrawerItem>
    ); 
}


  }

  renderDrawerItemBody = (props: DrawerItemsType) => {
    if (this.state.isMenuExpanded) {
      if (props.menutypeid === MenuItemTypeEnum.MenuGroup || props.menutypeid === MenuItemTypeEnum.DashboardGroup) {
        return <Menu vertical={true} className="appDrawerItmDiv" items={props.groupMenuItems} onSelect={this.grpMenuItemSelectEvent} />
      }
      return (<div className={"appDrawerItmDiv" + addPageViewClass(this.props.pageInf)}>
        <div className="appDrawerItmDivText">{props.text}</div>
        {(props.fixedItem !== true) && <div className="appDrawerItmDivClose"><span className="k-icon k-font-icon k-i-close-outline closeBtn" onClick={() => { this.closeMenuItem(props.menuitemid) }} /></div>}
      </div>)
    } else {
      return null;
    }
  }

  renderDrawerContent() {
    let _mitm = this.state.menuItems.find(x => x.selected);
    if (_mitm) {
      let mitm = _mitm;
      switch (mitm.menutypeid) {
        case MenuItemTypeEnum.Home:
          return (<Home onSelectAction={this.menuItemSelectFromHome} />);

        case MenuItemTypeEnum.Search:
          return (<SearchMain
            key={mitm.menuitemid}
            menuItemId={mitm.menuitemid}
            stateToLoad={mitm.itemstate}
            mode={SearchModeEnum.ViewDetails}
            offsetHeight={30}
            onOpenObjAction={this.showItemDetails}
            onSaveState={this.componentStateUpdate}
            onSaveMenuItem={this.updateMenuItem}
          />);

        case MenuItemTypeEnum.SPLSearch:
          if (mitm.securityid)
            return (<SplSearch key={mitm.menuitemid}
              menuItemId={mitm.menuitemid}
              stateToLoad={mitm.itemstate}
              securityId={mitm.securityid}
              pageName={mitm.text}
              onSaveState={this.componentStateUpdate}
              onSaveMenuItem={this.updateMenuItem}
              onOpenObjAction={this.showItemDetails}
              onOpenImageAction={this.showItemImagePage}
              onOpenCqAction={(securityId, id, objs) => this.startClientQuery(securityId, id, objs, mitm)}
              onOpenScanAction={this.showMilestoneScanPage}
            />);
          else
            return null;

        case MenuItemTypeEnum.ItemDetails:
          if (mitm.tcbobj) {
            return (<ItemDetails
              key={mitm.menuitemid}
              menuItemId={mitm.menuitemid}
              stateToLoad={mitm.itemstate}
              tcbObj={mitm.tcbobj}
              defaultTab={this.state.defaultTab}
              onSaveState={this.componentStateUpdate}
            />);
          } else {
            return null;
          }

        case MenuItemTypeEnum.MilestoneScan:
          return (<MilestoneScan key={mitm.menuitemid}
            menuItemId={mitm.menuitemid}
            tcbObsj={mitm.tcbobjs}
            onSaveState={this.componentStateUpdate}
            onSaveMenuItem={this.updateMenuItem}
            onViewItem={this.showItemDetails}
            stateToLoad={mitm.itemstate} />);

        case MenuItemTypeEnum.Admin:
          return (<FpcCtl clientQueryGrpId={fpcClientQueryGrpEnum.Admin}
            key={mitm.menuitemid}
            menuItem={mitm}
            stateToLoad={mitm.itemstate}
            onSaveState={this.componentStateUpdate}
            onSaveMenuItem={this.updateMenuItem}
          />);

        case MenuItemTypeEnum.Actions:
          return (<FpcCtl clientQueryGrpId={fpcClientQueryGrpEnum.Actions}
            key={mitm.menuitemid}
            menuItem={mitm}
            stateToLoad={mitm.itemstate}
            onSaveState={this.componentStateUpdate}
            onSaveMenuItem={this.updateMenuItem}
          />);

        case MenuItemTypeEnum.Reports:
          return (<FpcCtl clientQueryGrpId={fpcClientQueryGrpEnum.Reports}
            key={mitm.menuitemid}
            menuItem={mitm}
            stateToLoad={mitm.itemstate}
            onSaveState={this.componentStateUpdate}
            onSaveMenuItem={this.updateMenuItem}
          />)

        case MenuItemTypeEnum.ClientQuery:
          return (<FpcCtl clientQueryId={mitm.clientqueryid}
            tcbObjs={mitm.tcbobjs}
            key={mitm.menuitemid}
            menuItem={mitm}
            stateToLoad={mitm.itemstate}
            securityId={mitm.securityid}
            onSaveState={this.componentStateUpdate}
            onSaveMenuItem={this.updateMenuItem}
          />)

        case MenuItemTypeEnum.AddMbr:
          return (<PdaAddMember
            key={mitm.menuitemid}
            menuItemId={mitm.menuitemid}
          />);

        case MenuItemTypeEnum.Blank:
          return null;

        case MenuItemTypeEnum.About:
          return (<About key={mitm.menuitemid} />);

        case MenuItemTypeEnum.Settings:
          return (<Settings key={mitm.menuitemid} />);

        case MenuItemTypeEnum.TestPage:
          return (<TestDrawerContent key={1} type={3} />);

        case MenuItemTypeEnum.TestPage2:
          return (<TestDrawerContent key={2} type={2} />);

        case MenuItemTypeEnum.Dashboards:
          return (<DashboardViewer dashboardUrl={mitm.dashboardUrl!} />);

        case MenuItemTypeEnum.SuperUserPage:
          return (<SuperUser key={mitm.menuitemid} />);
      }
    }
  }


  renderAppContent() {
    if (!this.isAuthorised())
{
var defuser;

      if (sessionStorage.getItem("defaultUser")){
        defuser = sessionStorage.getItem("defaultUser");
      }

      return (<Login defaultUserName={defuser} onSuccess={this.onLoginSuccess} />);
    }
    else {


      var drwProps: DrawerProps = {
        expanded: this.state.isMenuExpanded,
        mode: 'push',
        mini: true,
        position: 'start',
        animation: { duration: 400 },
        items: this.state.menuItems.filter(x => x.visible === true).sort((a, b) => (a.displaygroup - b.displaygroup || a.displayorder - b.displayorder)),
        item: this.renderDrawerItem,
        className: "appDrawer" + addPageViewClass(this.props.pageInf),
        onOverlayClick: this.overlayClick,
        onSelect: this.menuItemSelectFromDrw,
      };
      if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
        drwProps.mode = 'overlay';
        drwProps.mini = false;
      }
    }

    return (<div className="appDrawer">

      
      <Drawer {...drwProps} >
      <DrawerContent>
        {this.renderDrawerContent()}
      </DrawerContent>
    </Drawer>
      {this.state.showCloseVerify &&
        <Dialog title={"Confirm Close"} onClose={this.closeCloseVerifyDialog}>
          <p>Close TracBASE?</p>
          <DialogActionsBar>
            <button className="k-button" onClick={this.closeCloseVerifyDialog}>No</button>
            <button className="k-button" onClick={this.closeApplication}>Yes</button>
          </DialogActionsBar>
        </Dialog>
      }
    </div>)

  }

  renderSwitchList = () => {

    var dropdown = (!this.props.userInf.userData ?
      <td style={{ width: "300px" }}>
        <DropDownListCtl
          key="SwitchList"
          mode={InputCtlViewMode.Select}
          dataSource={InputCtlDdlDataSource.General}
          lookupKey="UserRoleForSwitch"
          onSelectChange={this.setSwitchRole}
        /></td>
      : <td></td>)


    if (this.state.showSwitchRoleList === true) {


      return (
        <div >
          <table>
            <tr>
              {(dropdown)}
              <td><Button id="switchButton" onClick={this.switchRoleAction} ><span className="k-icon k-font-icon k-i-check menuButtonImage " />Switch</Button></td>
              <td><Button id="switchCancelButton" onClick={this.cancelSwitchRoleAction} ><span className="k-icon k-font-icon k-i-close cancelButtonImage " />Cancel</Button></td>
            </tr>

          </table>
        </div>
      )
    }
  }

  renderFooter = () => {
    if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
      return (
        <div className="appFtr">
          <div className="appFtrLeft mobile" onClick={this.switchRole}>{ll(this.props.userInf.langlookups, 'User') + ': ' + (this.isAuthorised() ? this.props.userInf.displayName : 'Not Logged In')}</div>
          <div className="appFtrRight mobile">{ll(this.props.userInf.langlookups, 'Version') + ': ' + packageJson.version}</div>
        </div>);
    } else {
      let versionStr = ll(this.props.userInf.langlookups, 'Version') + ': ' + packageJson.version;
      if (process.env.REACT_APP_RunMode === "DEV")
        versionStr = "DEV : " + versionStr;
      if (process.env.REACT_APP_RunMode === "TST")
        versionStr = "TEST : " + versionStr;

      return (
        <div className="appFtr">
          <div className="appFtrLeft" onClick={this.switchRole}>{ll(this.props.userInf.langlookups, 'User') + ': ' + (this.isAuthorised() ? this.props.userInf.displayName + ' [' + this.props.userInf.currProjectUserRoleName + ']' : 'Not Logged In')}</div>
          <div className="appFtrCentre"><span>{ll(this.props.userInf.langlookups, 'Project') + ': ' + this.props.userInf.currProject.projectName}</span></div>
          <div className="appFtrRight">{versionStr}</div>
        </div>);
    }
  }

  renderDashboardPopup = () => {

    if (!this.state.dashboardItemsFetched) {
      return (<div id="splSearchPopupLoading" ><span className="k-icon k-font-icon k-i-loading" /></div>);
    } else {
      if (this.state.dashboardItems.length === 0) {
        return <div className="dashBoardNoResults">No dashboard items available</div>
      } else {
        return (<Menu className="DashboardMenu" items={this.state.dashboardItems} openOnClick={true} vertical={true}
          onSelect={(e) => this.showDashboard(e)} />);
      }

    }
  }

  LoadingComponent = () => {
    return <p>Authentication in progress...</p>;
  };

  handleLogin = () => {
    const { instance } = this.context; // Access MSAL instance

    instance.loginPopup().then((response) => {
      this.setState({ account: response.account });
      console.log("User successfully logged in:", response.account);
    }).catch((error) => {
      console.error("Login error:",error);
    });
  };

  handleLogout = () => {
    const { instance } = this.context; // Access MSAL instance

    instance.logout().then((response) => {
      this.setState({ account: undefined });
    }).catch((error) => {
      console.error(error);
    });
  };

  numberOfAccounts = () => {
    return this.context.accounts.length;
  }

  getTcbLogo = () => {
    switch (this.props.pageInf.pageViewMode){
case PageViewTypeEnum.Mobile:
  return tcbLogoSml;
  default:
return tcbLogoLg;

    } 


  }

  render() {

    return (
      <MsalProvider instance={publicClientApplication}>
        <div id="appDiv" style={this.state.appDivStyle}>

          <Head title={this.state.pageTitleTxt} />
          <div className={"appHdr" + addPageViewClass(this.props.pageInf)}>
            <div className="appHdrLeft">
              <Button id="menuButton" onClick={this.hamburgerMenuClick} ><span className="k-icon k-font-icon k-i-menu menuButtonImage " /></Button>
            </div>
            <div className="appHdrCentre">{this.state.pageTitle}</div>
            <div className="appHdrRight"><img id="logoImg" src={this.getTcbLogo()} alt="TracBASE" /></div>
          </div>
          <div className="appBody" style={this.state.appBodyDivStyle}>
            <div className={"appBgnd" + addDBEnvironment(this.props.userInf)} style={this.state.appBackgroundStyle} />
            {/* <TestDrawerContent /> */}
            {this.renderAppContent()}
          </div>
          {this.renderSwitchList()}
          {this.renderFooter()}
          <Popup id="dashboardsPopup"
            offset={this._popupOffSet}
            show={this.state.dashboardPopupOpen}
            onOpen={this.openDashboardPopup}
          >
            <div
              ref={(el) => (this._popupDivRef = el)}
              onFocus={this.onFocusHandler}
              onBlur={this.onBlurHandler}
              tabIndex={-1}>
              {this.renderDashboardPopup()}
            </div>
          </Popup>
        </div>
      </ MsalProvider>
    );

  }
}
export default connector(App);
