import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { UserInfo, TcbFileItem, TcbObjInfo, MenuItemModel, GridColConfig } from '../../models/models';
import { loadingDiv } from '../../functions/componentFunctions';
import FileViewerDlg from '../Dialogs/FileViewerDlg';
import CheckBoxInputCtl from '../InputCtls/CheckBoxInputCtl';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import ErrorMsg from '../ErrorMsg';
import { TcbFileLoadStatusEnum, FileDisplayModeEnum, FileDialogModeEnum, PageViewTypeEnum } from '../../models/enums';
import moment from 'moment'
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridHeaderCellProps, GridCellProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Menu, MenuSelectEvent } from '@progress/kendo-react-layout';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { downloadFileItem } from '../../functions/componentFunctions';
import './TcbObjAttachments.css';

//todo: implement filtering.  https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-custom-header-cell-with-sorting-and-columnmenu/

const connector = connect(mapStateToProps, mapDispatchToProps);

const initialSort: Array<SortDescriptor> = [
    { field: "lastChangedOn", dir: "desc" },
  ];

type PropsFromRedux = ConnectedProps<typeof connector>;


type TcbObjAttachmentsProps = PropsFromRedux & {
    tcbObj: TcbObjInfo;
    fileMode: FileDisplayModeEnum;
    canEdit: boolean;
    stateToLoad?: TcbObjAttachmentsState;
    onSaveState?: (e: TcbObjAttachmentsState) => void;
}

interface TcbObjAttachmentsState {
    searchInProg: boolean;

    tcbObjFiles: TcbFileItem[];
    filesFetched: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;

    gridColumns: GridColConfig[];
    gridSelectAllVal: boolean;

    fileDialogMode: FileDialogModeEnum;
    showFileDlg: boolean;
    fileDialogFile?: TcbFileItem;
    fileDialogFiles?: TcbFileItem[];

    addDlgUploadFiles: UploadFileInfo[];
    addDlgMessage: string;

    sort: Array<SortDescriptor>;

}

class TcbObjAttachments extends React.Component<TcbObjAttachmentsProps, TcbObjAttachmentsState> {
    constructor(props: TcbObjAttachmentsProps) {
        super(props);

        if (this.props.fileMode === FileDisplayModeEnum.Attachments) {
            this._fileModeStr = "Attachment";
        } else {
            this._fileModeStr = "Image";
        }

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad };
        } else {

            this.state = {
                searchInProg: true,
                tcbObjFiles: [],
                filesFetched: false,
                fetchError: false,
                fetchErrorMsg: '',
                gridColumns: this.buildGridCols(),
                gridSelectAllVal: false,
                fileDialogMode: FileDialogModeEnum.Closed,
                addDlgUploadFiles: [],
                addDlgMessage: '',
                showFileDlg: false,
                sort: initialSort,
            };
        }


    }

    private _fileModeStr: string = "";

    componentDidMount() {
        //if (!this.state.filesFetched) {
            this.getTcbObjFiles();
        //}
    }

    componentDidUpdate(prevProps: TcbObjAttachmentsProps) {
    }

    buildGridCols = () => {
        let retVal: GridColConfig[] = [];

        retVal.push({ name: 'Select', title: '', field: 'select', width: 30, resizable: false, canSort: false, canFilter: false  });
        retVal.push({ name: 'Context', title: '', field: 'context', width: 30, resizable: false, canSort: false, canFilter: false  });

        if (this.props.fileMode === FileDisplayModeEnum.Images) {
            retVal.push({ name: 'Thumb', title: 'Preview', field: 'thumbnailStr', width: 200, resizable: true, canSort: false, canFilter: false  });
        } else {
            retVal.push({ name: 'Filename', title: 'Filename', field: 'fileName', width: 200, resizable: true, canSort: false, canFilter: false  });
        }

        retVal.push({ name: 'FileDesc', title: 'Description', field: 'fileDesc', width: 150, resizable: true, canSort: false, canFilter: false  });
        retVal.push({ name: 'RefNo', title: 'Ref No', field: 'fileRefNo', width: 100, resizable: true, canSort: false, canFilter: false  });
        retVal.push({ name: 'DocType', title: 'Doc Type', field: 'documentTypeDesc', width: 100, resizable: true, canSort: false, canFilter: false  });
        retVal.push({ name: 'ImageSrcDesc', title: 'Source', field: 'fileSrcDesc', width: 150, resizable: true, canSort: false, canFilter: false  });

        retVal.push({ name: 'LastChangedBy', title: 'Changed By', field: 'lastChangedBy', width: 100, resizable: true, canSort: false, canFilter: false  });
        retVal.push({ name: 'LastChangedOn', title: 'Changed On', field: 'lastChangedOn', width: 150, resizable: true, canSort: false, canFilter: false  });

        return retVal;
    }

    getTcbObjFiles = () => {
        this.setState({ tcbObjFiles: [], searchInProg: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjFiles';

        let body = {
            tcbObj: this.props.tcbObj,
            type: this.props.fileMode
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let img: TcbFileItem[] = res.results;
                        img.forEach(y => { y.fileLoadStatus = TcbFileLoadStatusEnum.NotLoaded; y.selected = false; });

                        this.setState({
                            tcbObjFiles: img,
                            filesFetched: true,
                            searchInProg: false
                        }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg: false });
                        break;

                    default:
                        this.setState({
                            searchInProg: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage
                        });
                }
            })
            .catch(err => {
                this.setState({
                    searchInProg: false,
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                });
            });

    }


    selectedAllCellChanged = (selectVal: boolean) => {
        let fils = this.state.tcbObjFiles;
        fils.forEach(x => { x.selected = selectVal });
        this.setState({ tcbObjFiles: fils });
    }

    selectedCellChanged = (selectVal: boolean, fi: TcbFileItem) => {
        let fils = this.state.tcbObjFiles;
        let selAll = this.state.gridSelectAllVal;

        let fil = fils.find(x => x.fileId === fi.fileId);
        if (fil) {
            fil.selected = selectVal;

            if (fils.some(x => !x.selected))
                selAll = false;
            else
                selAll = true;

            this.setState({ tcbObjFiles: fils, gridSelectAllVal: selAll });
        }
    }



    contextMenuClick = (m: MenuSelectEvent, e: TcbFileItem) => {
        switch (m.item['action']) {
            case "Download":
                if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser)
                    this.downloadFileCheck(e);
                else
                    this.setState({ showFileDlg: true, fileDialogMode: FileDialogModeEnum.View, fileDialogFile: e });
                break;

            case "DownloadSelected":
                if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser) {
                    let fils = this.state.tcbObjFiles.filter(x => x.selected);
                    fils.forEach(x => {
                        this.downloadFileCheck(x);
                    });
                }
                break;

            case "View":
                this.setState({ showFileDlg: true, fileDialogMode: FileDialogModeEnum.View, fileDialogFile: e });
                break;

            case "Edit":
                this.setState({ showFileDlg: true, fileDialogMode: FileDialogModeEnum.Edit, fileDialogFile: e });
                break;

            case "Delete":
                this.setState({ showFileDlg: true, fileDialogMode: FileDialogModeEnum.Delete, fileDialogFile: e });
                break;
        }
    }

    viewFileClick = (f: TcbFileItem) => {
        this.setState({ showFileDlg: true, fileDialogMode: FileDialogModeEnum.View, fileDialogFile: f });
    }

    addNewFileClick = () => {
        this.setState({ showFileDlg: true, fileDialogMode: FileDialogModeEnum.Add });
    }


    downloadFileCheck = (f: TcbFileItem) => {
        if (f.fileDataStr)
            downloadFileItem(f);
        else {
            f.fileLoadStatus = TcbFileLoadStatusEnum.InProgress;
            this.setState({ tcbObjFiles: this.state.tcbObjFiles });

            let url = this.props.userInf.currProject.apiUrl + '/api/file/GetTcbFileBase64';
            let body = {
                fileId: f.fileId
            }

            fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                .then(resp => resp.json())
                .then(res => {
                    switch (res.callStatus) {
                        case "OK":

                            f.fileDataStr = res.stringResponse;
                            f.fileLoadStatus = TcbFileLoadStatusEnum.OK;
                            downloadFileItem(f);
                            this.setState({ tcbObjFiles: this.state.tcbObjFiles }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                            break;
                        case "UNAUTH":
                            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                            this.props.updateUserInfo(uInf);
                            this.setState({ searchInProg: false });
                            break;

                        default:
                            f.fileLoadStatus = TcbFileLoadStatusEnum.Error;
                            f.fileLoadErrorMsg = res.callStatusMessage;
                            this.setState({ tcbObjFiles: this.state.tcbObjFiles });
                    }
                })
                .catch(err => {
                    this.setState({
                        searchInProg: false,
                        fetchError: true,
                        fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                    });
                });
        }


    }

    errorClose = () => {
        this.setState({ fetchError: false, fetchErrorMsg: '' });
    }


    dlgClose = () => {
        this.setState({ showFileDlg: false });
    }

    dlgAdd = (e: TcbFileItem) => {
        let fls = this.state.tcbObjFiles;
        fls.push(e);
        this.setState({ tcbObjFiles: fls });
    }

    dlgUpdate = (e: TcbFileItem) => {
        let fls = this.state.tcbObjFiles;
        let fl = fls.find(x => x.fileId === e.fileId);
        if (fl) {
            fl.fileName = e.fileName;
            fl.fileDesc = e.fileDesc;
            fl.fileRefNo = e.fileRefNo;
            fl.documentTypeId = e.documentTypeId;
            fl.documentTypeDesc = e.documentTypeDesc;
            fl.lastChangedBy = e.lastChangedBy;
            fl.lastChangedOn = e.lastChangedOn;

            this.setState({ tcbObjFiles: fls });
        }
    }

    dlgDelete = (e: TcbFileItem[]) => {
        let fls = this.state.tcbObjFiles;
        e.forEach(f => {
            fls = fls.filter(x => x.fileId !== f.fileId);
        });
        this.setState({ tcbObjFiles: fls });
    }


    renderHeaderCell = (n: React.ReactNode, e: GridHeaderCellProps) => {
        switch (e.field) {
            case "select":
                return (<div className="tcbObjAttHdrDiv">
                    <CheckBoxInputCtl key="0" value={this.state.gridSelectAllVal} onUpdate={(e) => { if (e !== null) this.selectedAllCellChanged(e) }} />
                </div>)

            case "context":
                if (this.props.canEdit) {
                    return (<div className="tcbObjAttHdrDiv">
                        <span className="k-icon k-font-icon k-i-plus-outline addNewSpan" onClick={() => this.addNewFileClick()} title="Add New" />
                    </div>)
                } else {
                    return e.title;
                }

            default:
                //return (e.title);

                return (
                    <a className="k-link" onClick={e.onClick}>
                      <span
                      >
                        {e.title}
                      </span>
                      {e.children} 
                    </a>
                  );

        }
    }

    renderDataCell = (e: any, p: GridCellProps) => {
        let fi: TcbFileItem = p.dataItem;

        switch (p.field) {
            case "select":
                return (<td><CheckBoxInputCtl key={fi.fileId} value={fi.selected} onUpdate={(e) => { if (e != null) this.selectedCellChanged(e, fi) }} /></td>)


            case "context":
                let itemMenuItms: MenuItemModel[] = [];
                let cxtMenu: MenuItemModel = { items: [], linkRender: () => { return <span className="k-icon k-font-icon k-i-more-vertical" /> } };
                itemMenuItms.push(cxtMenu);
                if (this.state.tcbObjFiles.filter(x => x.selected).length <= 1) {
                    cxtMenu.items.push({ text: 'Actions for: ' + fi.fileName, action: 'Header', cssClass: 'menuTitle', disabled: true, items: [] });
                    cxtMenu.items.push({ text: 'View ', action: 'View', icon: 'zoom', cssClass: 'menuItem', disabled: false, items: [] });
                    cxtMenu.items.push({ text: 'Download ', action: 'Download', icon: 'download', cssClass: 'menuItem', disabled: false, items: [] });
                    cxtMenu.items.push({ text: '', action: 'Separator', cssClass: 'separator', disabled: true, items: [] });
                    if (fi.canEdit) {
                        cxtMenu.items.push({ text: 'Edit', action: 'Edit', icon: 'edit', cssClass: 'menuItem', disabled: false, items: [] });
                    }
                    if (fi.canDelete) {
                        cxtMenu.items.push({ text: 'Delete', action: 'Delete', icon: 'delete', cssClass: 'menuItem', disabled: false, items: [] });
                    }
                } else {
                    cxtMenu.items.push({ text: 'Actions for ALL selected items', action: 'Header', cssClass: 'menuTitle', disabled: true, items: [] });
                    cxtMenu.items.push({ text: 'Download selected', action: 'DownloadSelected', icon: 'download', cssClass: 'menuItem', disabled: false, items: [] });
                    //cxtMenu.items.push({ text: 'Delete selected', action: 'DeleteSelected', icon: 'delete', cssClass: 'menuItem', disabled: false, items: [] });
                }
                return (<td><Menu className="tcbObjAttMenu" items={itemMenuItms} openOnClick={true} vertical={true} onSelect={(m) => this.contextMenuClick(m, fi)} /></td>);


            case "fileName":
                return (<td><span className='fileNameSpan' onClick={() => this.viewFileClick(fi)} title={fi.fileName}>{fi.fileName}</span></td>);

            case "thumbnailStr":
                if (fi.thumbnailStr && fi.thumbnailStr.length > 0) {
                    let imgStr = 'data:image/jpeg;base64,' + fi.thumbnailStr;
                    return (
                        <td onClick={() => this.viewFileClick(fi)}>
                            <img src={imgStr} alt={fi.fileName} className="imgPreviewImg" />
                        </td>
                    );
                } else {
                    return (<td>Thumbnail Unavailable</td>)
                }



            case "lastChangedOn":
                let dateStr: string = "";
                if (fi.lastChangedOn) {
                    dateStr = moment(fi.lastChangedOn).format("DD-MMM-YYYY hh:mm A");
                }
                return (<td>{dateStr}</td>)

        }
        return e;   //All others render normally
    }

setSort(e: Array<SortDescriptor>){
    this.setState({ sort: e });
}

    render() {
        if (this.state.fetchError) {
            return (<ErrorMsg message={this.state.fetchErrorMsg} onClose={this.errorClose} />);
        }
        if (this.state.searchInProg) {
            return loadingDiv();
        }

        let sort = this.state.sort;
        let tcbObjFiles = this.state.tcbObjFiles

        return (<div id="tcbObjAttOuterDiv">
            <Grid
                className="tcbObjAttachmentsGrid"
                data={orderBy(tcbObjFiles,sort)}
                headerCellRender={this.renderHeaderCell}
                cellRender={this.renderDataCell}
                resizable={true}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => this.setSort(e.sort)
                }
            >
                {
                    this.state.gridColumns.map((c) => {
                        return (<GridColumn field={c.field} width={c.width} title={c.title} resizable={c.resizable} sortable={true} />);
                    })
                }
            </Grid>
            {this.state.showFileDlg &&
                <FileViewerDlg dialogMode={this.state.fileDialogMode} display={this.props.fileMode} tcbObj={this.props.tcbObj}
                    file={this.state.fileDialogFile}
                    fileList={this.state.fileDialogFiles}
                    onAdd={this.dlgAdd}
                    onUpdate={this.dlgUpdate}
                    onDelete={this.dlgDelete}
                    onClose={this.dlgClose} />
            }
        </div>);

    }

}

export default connector(TcbObjAttachments);