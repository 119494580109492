import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { UserInfo, TcbObjInfo, MenuItemModel, TcbObjMember, GridColConfig, AddItemForPacklist } from '../../models/models';
import { MembersPageTypeEnum, PageViewTypeEnum, TcbObjClassEnum } from '../../models/enums';
import { loadingDiv } from '../../functions/componentFunctions';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import CheckBoxInputCtl from '../InputCtls/CheckBoxInputCtl';
import ErrorMsg from '../ErrorMsg';
import { CustomWindow } from '../../models/custom.window'


import { Grid, GridColumn, GridHeaderCellProps, GridCellProps, GridColumnResizeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Menu, MenuSelectEvent } from '@progress/kendo-react-layout';

import './TcbObjMembers.css';
import PackListAddItemsDlg from '../Dialogs/PackListAddItemsDlg';
import PackListDelItemsDlg from '../Dialogs/PackListDelItemsDlg';
import MemberAddByScanDlg from '../Dialogs/MemberAddByScanDlg';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

declare let window: CustomWindow;

type TcbObjMembersProps = PropsFromRedux & {
    pageType: MembersPageTypeEnum;
    tcbObj: TcbObjInfo;
    canEdit: boolean;
    stateToLoad?: TcbObjMembersState;
    onSaveState?: (e: TcbObjMembersState) => void;
}

interface TcbObjMembersState {
    searchInProg: boolean;

    tcbObjMemberList: TcbObjMember[];
    membersFetched: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;
    gridColumns: GridColConfig[];
    gridSelectAllVal: boolean;
    gridSortDescriptor: SortDescriptor[];

    showAddItemDlg: boolean;
    showAddByScanDlg: boolean;
    showDelItemDlg: boolean;
    showMoveItemDlg: boolean;
    delItemList: TcbObjMember[];
    onProgress: boolean;
}

class TcbObjMembers extends React.Component<TcbObjMembersProps, TcbObjMembersState> {
    constructor(props: TcbObjMembersProps) {
        super(props);

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad };
        } else {

            this.state = {
                searchInProg: true,
                tcbObjMemberList: [],
                membersFetched: false,
                fetchError: false,
                fetchErrorMsg: '',
                gridColumns: this.buildGridCols(),
                gridSortDescriptor: [],
                gridSelectAllVal: false,
                showAddItemDlg: false,
                showAddByScanDlg: false,
                showDelItemDlg: false,
                showMoveItemDlg: false,
                delItemList: [],
                onProgress: false
            };
        }


    }


    componentDidMount() {
        if (!this.state.membersFetched) {
            this.getTcbObjMembers();
        }
    }

    componentDidUpdate(prevProps: TcbObjMembersProps) {
    }

    buildGridCols = () => {
        let retVal: GridColConfig[] = [];

        retVal.push({ name: 'Select', title: '', field: 'select', width: 30, resizable: false, canSort: false, canFilter: false });
        retVal.push({ name: 'Context', title: '', field: 'context', width: 30, resizable: false, canSort: false, canFilter: false });
        retVal.push({ name: 'Description', title: 'Description', field: 'memberDesc', width: 350, resizable: true, canSort: false, canFilter: false });
        retVal.push({ name: 'Type', title: 'Type', field: 'tcbObj.tcbObjTypeDesc', width: 200, resizable: true, canSort: false, canFilter: false });

        if (!(this.props.pageType === MembersPageTypeEnum.Members && this.props.tcbObj.tcbObjClass === "I"))
            retVal.push({ name: 'Status', title: 'Status', field: 'memberStatus', width: 80, resizable: true, canSort: false, canFilter: false });

        if (!(this.props.pageType === MembersPageTypeEnum.MembersOf && this.props.tcbObj.tcbObjClass === "P")){
            retVal.push({ name: 'Quantity', title: 'Quantity', field: 'quantity', width: 80, resizable: true, canSort: false, canFilter: false });
            retVal.push({ name: 'UOM', title: 'UOM', field: 'tcbObj.tcbObjUOM', width: 80, resizable: true, canSort: false, canFilter: false });

        }
 
        // if (!(this.props.pageType === MembersPageTypeEnum.Members && this.props.tcbObj.tcbObjClass === "I") &&
        //     !(this.props.pageType === MembersPageTypeEnum.MembersOf && this.props.tcbObj.tcbObjClass === "P")) {
        //     retVal.push({ name: 'MilestoneInDesc', title: 'Milestone In', field: 'milestoneInDesc', width: 120, resizable: true, canSort: false, canFilter: false });
        //     retVal.push({ name: 'MilestoneOutDesc', title: 'Milestone Out', field: 'milestoneOutDesc', width: 120, resizable: true, canSort: false, canFilter: false });
        // }

        return retVal;
    }


    getTcbObjMembers = () => {
        this.setState({ tcbObjMemberList: [], searchInProg: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjMembers';
        if (this.props.pageType === MembersPageTypeEnum.MembersOf)
            url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjMemberOf';

        fetch(url, { method: 'POST', body: JSON.stringify(this.props.tcbObj), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let mbrList: TcbObjMember[] = res.results;
                        let tmpData = orderBy(mbrList, this.state.gridSortDescriptor);

                        this.setState({ tcbObjMemberList: tmpData, membersFetched: true, searchInProg: false },
                            () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg: false });
                        break;

                    default:
                        this.setState({
                            searchInProg: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage
                        });
                }
            })
            .catch(err => {
                this.setState({
                    searchInProg: false,
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                });
            });

    }

    errorClose = () => {
        this.setState({ fetchError: false, fetchErrorMsg: '' });
    }

    gridSortChange = (e: GridSortChangeEvent) => {
        const tmpData = orderBy(this.state.tcbObjMemberList, e.sort);

        this.setState({
            tcbObjMemberList: tmpData,
            gridSortDescriptor: e.sort
        });

    }


    selectedAllCellChanged = (selectVal: boolean) => {
        let data = this.state.tcbObjMemberList;
        data.forEach(x => { x.selected = selectVal });
        this.setState({ tcbObjMemberList: data });
    }

    selectedCellChanged = (mi: TcbObjMember) => {
        let mbrs = this.state.tcbObjMemberList;
        mi.selected = !mi.selected;
        let selAll = this.state.gridSelectAllVal;
        if (mbrs.some(x => !x.selected))
            selAll = false;
        else
            selAll = true;

        this.setState({ tcbObjMemberList: mbrs, gridSelectAllVal: selAll });
    }

    columnResize = (e: GridColumnResizeEvent) => {
        if (e.end) {
            let gc = this.state.gridColumns;
            let gg1 = gc[e.index];
            gg1.width = e.newWidth;
            this.setState({ gridColumns: gc });
        }
    }

    addMemberClick = () => {
        this.setState({ showAddItemDlg: true });
    }

    contextMenuClick = (m: MenuSelectEvent, mi: TcbObjMember) => {
        let diLst: TcbObjMember[] = [];
        switch (m.item['action']) {
            case "ViewDetails":
                if (window.appRef) {
                    window.appRef.showItemDetails(mi.tcbObj);
                }
                break;

            case "RemoveMember":
                diLst.push(mi);
                this.setState({ delItemList: diLst, showDelItemDlg: true })
                break;

            case "RemoveSelMembers":
                diLst = this.state.tcbObjMemberList.filter(x => x.selected);
                this.setState({ delItemList: diLst, showDelItemDlg: true })
                break;

                case "MoveMember":
                    diLst.push(mi);
                    this.setState({ delItemList: diLst, showMoveItemDlg: true })
                    break;
    
                case "MoveSelMembers":
                    diLst = this.state.tcbObjMemberList.filter(x => x.selected);
                    this.setState({ delItemList: diLst, showMoveItemDlg: true })
                    break;
    

        }
    }

    // showAddMemberDialog = (e: any) => {
    //     this.setState({ showAddItemDlg: true });
    // }

    dlgActionClose = () => {
        this.setState({ showAddItemDlg: false, showDelItemDlg: false, showAddByScanDlg: false, showMoveItemDlg: false });
    }

    addMenuClick = (e: MenuSelectEvent) => {
        switch (e.item['action']) {
            case "Scan":
                this.setState({ showAddByScanDlg: true });
                break;

            case "Add":
                this.setState({ showAddItemDlg: true });
                break;
        }
    }

    addItem = (items: AddItemForPacklist[]) => {
        if (items.length > 0)
            this.getTcbObjMembers();
    }

    addPacklist = (items: AddItemForPacklist[]) => {
        if (items.length > 0)
            this.getTcbObjMembers();
    }

    dlgActionDel = () => {
        this.getTcbObjMembers();
    }



    renderHeaderCell = (n: React.ReactNode, e: GridHeaderCellProps) => {
        switch (e.field) {

            case "select":
                return (<div className="tcbObjMbrHdrDiv">
                    <CheckBoxInputCtl key="0" value={this.state.gridSelectAllVal} onUpdate={(e) => { if (e != null) this.selectedAllCellChanged(e) }} />
                </div>)

            case "context":
                if (this.props.canEdit) {
                    if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser) {
                        return (<div className="tcbObjMbrHdrDiv">
                            <span className="k-icon k-font-icon k-i-plus-outline addNewSpan" onClick={() => this.addMemberClick()} title="Add New" />
                        </div>);
                    } else {
                        let itemMenuItms: MenuItemModel[] = [];
                        let cxtMenu: MenuItemModel = { items: [], linkRender: () => { return <span className="k-icon k-font-icon k-i-plus-outline addNewSpan" /> } };
                        itemMenuItms.push(cxtMenu);
                        // cxtMenu.items.push({ text: 'Actions for: ' + fi.fileName, action: 'Header', cssClass: 'menuTitle', disabled: true, items: [] });
                        cxtMenu.items.push({ text: 'Add / Remove by Scanner', action: 'Scan', icon: 'qr-code-outline ', cssClass: 'menuItem', disabled: false, items: [] });
                        cxtMenu.items.push({ text: 'Add New ', action: 'Add', icon: 'plus-outline', cssClass: 'menuItem', disabled: false, items: [] });
                        return (<td><Menu className="tcbObjAttMenu" items={itemMenuItms} openOnClick={true} vertical={true} onSelect={(m) => this.addMenuClick(m)} /></td>);

                    }
                } else {
                    return (n);
                }

            default:
                return (n);
            // return (e.title);

        }
    }

    renderDataCell = (e: any, p: GridCellProps) => {
        let mi: TcbObjMember = p.dataItem;

        switch (p.field) {
            case "select":
                return (<td><CheckBoxInputCtl key={mi.tcbObj.tcbObjId} value={mi.selected} onUpdate={(e) => { if (e != null) this.selectedCellChanged(mi) }} /></td>)

            case "context":
                let mbrMenuItms: MenuItemModel[] = [];
                let cxtMenu: MenuItemModel = { items: [], linkRender: () => { return <span className="k-icon k-font-icon k-i-more-vertical" /> } };
                mbrMenuItms.push(cxtMenu);
                cxtMenu.items.push({ text: 'View Details', icon: 'aggregate-fields', action: 'ViewDetails', cssClass: 'menuItem', disabled: false, items: [] });
                if (this.props.canEdit) {
                    if (mi.tcbObj.tcbObjClass !== TcbObjClassEnum.PackList) {
                    //     cxtMenu.items.push({ text: '', action: '', cssClass: 'k-separator', disabled: true, items: [] });
                    //     if (this.state.tcbObjMemberList.filter(x => x.selected).length > 1) {
                    //         cxtMenu.items.push({ text: 'Move this Member only', icon: 'arrows-move', action: 'MoveMember', cssClass: 'menuItem', disabled: false, items: [] });
                    //         cxtMenu.items.push({ text: 'Move All Selected', icon: 'arrows-move', action: 'MoveSelMembers', cssClass: 'menuItem', disabled: false, items: [] });
                    //     }
                    //     else
                    //         cxtMenu.items.push({ text: 'Move', icon: 'arrows-move', action: 'MoveMember', cssClass: 'menuItem', disabled: false, items: [] });
                    // }


                    // else {



                        cxtMenu.items.push({ text: '', action: '', cssClass: 'k-separator', disabled: true, items: [] });
                        if (this.state.tcbObjMemberList.filter(x => x.selected).length > 1) {
                            cxtMenu.items.push({ text: 'Remove this Member only', icon: 'unlink-horizontal', action: 'RemoveMember', cssClass: 'menuItem', disabled: false, items: [] });
                            cxtMenu.items.push({ text: 'Remove All Selected', icon: 'unlink-horizontal', action: 'RemoveSelMembers', cssClass: 'menuItem', disabled: false, items: [] });
                        }
                        else
                            cxtMenu.items.push({ text: 'Remove', icon: 'unlink-horizontal', action: 'RemoveMember', cssClass: 'menuItem', disabled: false, items: [] });
                    }
                }
                return (<td><Menu className="tcbObjMbrMenu" items={mbrMenuItms} openOnClick={true} vertical={true} onSelect={(m) => this.contextMenuClick(m, mi)} /></td>);

            // case "type":
            //     if (mi.tcbObj.tcbObjTypeIconCode) {
            //         let iconCode = '&#' + mi.tcbObj.tcbObjTypeIconCode.toString() + ';';
            //         return <td className='centre'><span className={'k-icon iconSpan' + addPageViewClass(this.props.pageInf)} title={mi.tcbObj.tcbObjTypeDesc} dangerouslySetInnerHTML={{ __html: iconCode }} /></td>
            //     }
            //     return <td>&nbsp;</td>;

            case "quantity":
                return <td className='centre'>{mi.tcbObj.tcbObjQty}</td>
        }
        return e;
    }

    render() {
        if (this.state.fetchError) {
            return (<ErrorMsg message={this.state.fetchErrorMsg} onClose={this.errorClose} />);
        }
        if (this.state.searchInProg) {
            return loadingDiv();
        }

        return (
            <div id="tcbObjMembersOuterDiv">
                <Grid
                    className="tcbObjMbrGrid"
                    data={this.state.tcbObjMemberList}
                    headerCellRender={this.renderHeaderCell}
                    cellRender={this.renderDataCell}
                    resizable
                    onColumnResize={this.columnResize}
                    sortable
                    sort={this.state.gridSortDescriptor}
                    onSortChange={this.gridSortChange}
                >
                    {
                        this.state.gridColumns.map((c) => {
                            return (<GridColumn field={c.field} width={c.width} title={c.title} resizable={c.resizable} />);
                        })
                    }
                </Grid>

                {this.state.showAddItemDlg && <PackListAddItemsDlg tcbObj={this.props.tcbObj} onClose={this.dlgActionClose} onAdd={this.addItem} />}
                {this.state.showDelItemDlg && <PackListDelItemsDlg tcbObj={this.props.tcbObj} delObjs={this.state.delItemList} onClose={this.dlgActionClose} onDel={this.dlgActionDel} />}
                {this.state.showAddByScanDlg && <MemberAddByScanDlg tcbObj={this.props.tcbObj} onClose={this.dlgActionClose} />}
                {this.state.showMoveItemDlg && <PackListAddItemsDlg tcbObj={this.props.tcbObj} onClose={this.dlgActionClose} onAdd={this.addPacklist} />}

            </div>
        );

    }

}

export default connector(TcbObjMembers);