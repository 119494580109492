import React from 'react';   
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import "./map.css";

type MapProps = {
	location: string;
}

function Map(props: MapProps){

let centerLoc: any = null;
if (props.location && props.location.length > 0) {
    const tmp = props.location.split(',');
    centerLoc = {
        lat: parseFloat(tmp[0]),
        lng: parseFloat(tmp[1])
    }
}
// this.state = {
//     centerLoc: centerLoc,
const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAFA1QGv3USTM4ELzxxKEKsbzHAdPhK-s8",
  });

  const options = {
    disableDefaultUI: true,
    mapTypeId:'satellite'
  }

    return ( <div className="App">
    {!isLoaded ? (
      <h1>Loading...</h1>
    ) : (
      <GoogleMap
        mapContainerClassName="map-container"
        center={centerLoc}
        zoom={16}
        options={options}
        >

        <Marker position={centerLoc}  />
        </GoogleMap>
    )}
  </div>);
}

export default Map;




