import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { TcbFileItem } from '../../models/models';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { loadingDiv } from '../../functions/componentFunctions';
import { TcbFileLoadStatusEnum } from '../../models/enums';

import './MilestoneScanImages.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type MilestoneScanImagesProps = PropsFromRedux & {
    imgs: TcbFileItem[] ;
    editable: boolean;
    onChange?: (milestoneScanHistoryId: string, scanImages: TcbFileItem[]) => void;
    onImageDoubleClick?: (scanImage: TcbFileItem) => void;
}


interface MilestoneScanImagesState {
    imageList: TcbFileItem[] ;
}

class MilestoneScanImages extends React.Component<MilestoneScanImagesProps, MilestoneScanImagesState> {
    public static defaultProps = {
        editable: false
    }
    constructor(props: MilestoneScanImagesProps) {
        super(props);

        this.state = {
            imageList: this.props.imgs
        }

    }

    componentDidUpdate(prevProps:MilestoneScanImagesProps) {
        if (this.props.imgs !== prevProps.imgs) {
            this.setState({ imageList: this.props.imgs });
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onImageDoubleClick = (e: TcbFileItem) => {
        if (this.props.onImageDoubleClick) {
            this.props.onImageDoubleClick(e);
        }
    }

    render() {

        return (<div id="msiPreviewDiv">
            {this.state.imageList.map((e) => {
                switch (e.fileLoadStatus) {
                    case TcbFileLoadStatusEnum.OK:
                        return (<img id={e.fileId} key={e.fileId} src={'data:image/jpeg;base64,' + e.thumbnailStr}
                            alt={e.fileName}
                            className="ImagePreview"
                            // onClick={() => { this.onImageDoubleClick(e)}}
                            onDoubleClick={() => { this.onImageDoubleClick(e) }} />);

                    case TcbFileLoadStatusEnum.InProgress:
                        return (<div className="loadingDiv">{loadingDiv()}</div>)

                    case TcbFileLoadStatusEnum.Error:
                        return (<div>Error<br />Loading!</div>)
                    default: return null;

                }
            })}
        </div>
        );
    }

}

export default connector(MilestoneScanImages);
