import React from 'react';
//import ReactDOM from 'react-dom/client';  // Import from react-dom/client for React 18
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './redux/rootReducer';
import { isMobileOnly, isTablet, mobileModel, mobileVendor } from "react-device-detect";
import { PageInfo } from './models/models';
import {PageViewTypeEnum} from './models/enums';
import {updatePageInfo} from './redux/reduxActions';

const store = createStore(rootReducer);

//Set page view and save to Redux store (in PageInfo)
let pi = new PageInfo();
if (isMobileOnly) {
  pi.pageViewMode = PageViewTypeEnum.Mobile;
  pi.hasCamera = true;
  pi.hasGPS = true;
} else if (isTablet) {
  pi.pageViewMode = PageViewTypeEnum.Tablet;
  pi.hasCamera = true;
} else
  pi.pageViewMode = PageViewTypeEnum.Browser;
pi.deviceModel = mobileModel;
pi.deviceVendor = mobileVendor;

store.dispatch(updatePageInfo(pi));


ReactDOM.render(
    <Provider store={store}>
      <App  />
    </Provider>,
  document.getElementById('root')
);

// const root = ReactDOM.createRoot(document.getElementById('root')!);  // Create root

// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
