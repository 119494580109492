import React from 'react';
import { connect,  ConnectedProps } from 'react-redux';
import { DropDownList, DropDownListFilterChangeEvent, DropDownListChangeEvent, DropDownsPopupSettings } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import { FilterDescriptor } from '@progress/kendo-data-query';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { UserInfo, DropDownListData } from '../../models/models';
import { TcbObjDataCatagory, InputCtlViewMode, InputCtlDdlDataSource } from '../../models/enums';
import checkImg from '../../images/Check-2-64.png';
import crossImg from '../../images/Cross-2-64.png';

import './DropDownListCtl.css';
//import { TreeListHeaderSelectionCell } from '@progress/kendo-react-treelist';

const connector = connect(mapStateToProps, mapDispatchToProps,null ,{forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

type DropDownListCtlProps = PropsFromRedux & {
    keyProp?: any;
    dataSource: InputCtlDdlDataSource;
    lookupSql?: string;
    lookupData?: Array<DropDownListData>;
    lookupKey?: string;
    lookupKeyId?: number;
    mode?: InputCtlViewMode;
    selectedValue?: string | null;
    height?: string | null;
    onSelectChange?: (dataVal: DropDownListData) => void;
    onSave?: (newVal?: DropDownListData, setViewMode?: (newMode: InputCtlViewMode) => void) => void;
    onFocus?: (e:any) => void;
}

interface DropDownListCtlState {
    allData: Array<DropDownListData>;
    filteredData: Array<DropDownListData>;
    filteredViewTotal: number;
    dataLoaded: boolean;
    dataLoadInProgress: boolean;
    ddlValue?: DropDownListData;
    ddlValueOrig?: DropDownListData;
    filterText?:string;
    mode: InputCtlViewMode;
}

// type WrappedComponentType<C> = C extends ConnectedComponent<any, infer T>
//   ? T extends { ref?: React.LegacyRef<infer WrappedComponent> | undefined }
//     ? WrappedComponent
//     : never
//   : never;

class DropDownListCtl extends React.Component<DropDownListCtlProps, DropDownListCtlState> {
    //ddlRef: React.RefObject<DropDownListCtl>;
    
    constructor(props: DropDownListCtlProps) {
        super(props);

         //this.ddlRef = React.createRef()

        this.state = {
            allData: [],
            filteredData: [],
            filteredViewTotal: 0,
            dataLoadInProgress: false,
            dataLoaded: false,
            mode: this.props.mode ?? InputCtlViewMode.ReadOnly,

        }


    };

    componentDidUpdate(prevProps: DropDownListCtlProps) {
    }

    componentDidMount() {
         
        // this.setState(
        // {ref: React.createRef<WrappedComponentType<typeof DropDownListCtl>>()}
        // );


        if (!this.state.dataLoaded) {
            switch (this.props.dataSource) {
                case InputCtlDdlDataSource.Data:
                    this.loadLookupData(this.props.lookupData);
                    break;

                // case InputCtlDdlDataSource.Sql:
                //     this.loadSqlData(this.props.lookupSql);
                //     break;

                case InputCtlDdlDataSource.PlProp:
                    this.loadPropList("P", TcbObjDataCatagory.Property, this.props.lookupKey)
                    break;

                case InputCtlDdlDataSource.PlAttr:
                    this.loadPropList("P", TcbObjDataCatagory.Attribute, this.props.lookupKey)

                    break;

                case InputCtlDdlDataSource.ItProp:
                    this.loadPropList("I", TcbObjDataCatagory.Property, this.props.lookupKey)

                    break;

                case InputCtlDdlDataSource.ItAttr:
                    this.loadPropList("I", TcbObjDataCatagory.Attribute, this.props.lookupKey)

                    break;

                case InputCtlDdlDataSource.AttrCl:
                    this.loadAttributeLookup(this.props.lookupKey);
                    break;

                case InputCtlDdlDataSource.General:
                    this.loadPropList("G", TcbObjDataCatagory.Property, this.props.lookupKey)
                    break;
            }

        }
    }

    getDisplayText = (e?: DropDownListData) => {
        if (!e)
            return '';
        else
            return e.display;
    }

    onFocus = () => {
        if (this.props.onFocus)
            this.props.onFocus(this);
}


 scanCallback = (e: string) =>
    {
        this.setState({ filterText: e });

    }

    onFilterChange = (event: DropDownListFilterChangeEvent) => {

        const tmpData = this.filterData(event.filter);
        this.setState({
            filteredData: tmpData,
            filterText: event.filter.value,
            filteredViewTotal: tmpData.length,
            dataLoadInProgress: false
        });


    }

    filterData = (filter: FilterDescriptor): Array<DropDownListData> => {
        return filterBy(this.state.allData, filter);
    }


    selectChange = (event: DropDownListChangeEvent) => {
        this.setState({ ddlValue: event.target.value });
        if (this.state.mode === InputCtlViewMode.Select)
            if (this.props.onSelectChange)
                this.props.onSelectChange(event.target.value);
    }

    loadLookupData = (data?: Array<DropDownListData>) => {
        if (!data)
            return;

        let retState: any = {};
        let defVal: DropDownListData | undefined;

        if (this.props.selectedValue) {
            defVal = data.find(x => x.value === this.props.selectedValue);
        }

        if (defVal) {

            retState = {
                allData: data,
                filteredData: data.slice(),

                filteredViewTotal: data.length,
                ddlValue: defVal
            }


        } else {
            retState = {
                allData: data,
                filteredData: data,
                filteredViewTotal: data.length,
            }
        }

        this.setState({ ...retState, dataLoaded: true })
        //     }

        //     loadSqlData = (sql?: string) => {
        //         if (!sql)
        //             return;

        //         this.setState({ dataLoadInProgress: true });

        //         let url = this.props.userInf.currProject.apiUrl + '/api/GetSqlLookupListData';
        //         let body = {
        //             sql: sql
        //         }

        //         fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
        //             .then(r => r.json())
        //             .then(res => {
        //                 switch (res.callStatus) {
        //                     case "OK":
        //                         const tmp: Array<DropDownListData> = res.results;

        //                         let defVal: DropDownListData | undefined;
        //                         if (this.props.selectedValue) {
        //                             defVal = tmp.find(x => x.value.toLowerCase() === this.props.selectedValue?.toLowerCase());
        //                         }

        //                         this.setState({
        //                             allData: tmp,
        //                             filteredData: tmp,
        //                             filteredViewData: tmp.slice(0, ddlPageSize),
        //                             filteredViewSkip: 0,
        //                             filteredViewTotal: tmp.length,
        //                             dataLoadInProgress: false,
        //                             dataLoaded: true,
        //                             // ddlWidth: newWidth,
        //                             ddlValue: defVal
        //                         });
        //                         break;
        //                     case "UNAUTH":
        //                         let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
        //                         this.props.updateUserInfo(uInf);
        //                         this.setState({ dataLoadInProgress: false });
        //                         break;

        //                     default:
        //                         alert('Error loading select list:' + res.CallStatusMessage);
        //                         this.setState({ dataLoadInProgress: false });
        //                 }
        //             })
        //             .catch(err => {
        //                 alert('Error loading select list:' + err.toString());
        //                 this.setState({ dataLoadInProgress: false });
        //             });
    }

    loadPropList = (tcbCls: string, tcbCat: TcbObjDataCatagory, propName?: string) => {
        if (!propName) return;

        this.setState({ dataLoadInProgress: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/GetLookupListData';
        let body = {
            lookupClass: tcbCls,
            tcbObjDataCat: tcbCat,
            propertyName: propName,
            keyId: this.props.lookupKeyId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        const tmp: Array<DropDownListData> = res.results;

                        let defVal: DropDownListData | undefined;
                        if (this.props.selectedValue) {
                            defVal = tmp.find(x => x.value.toLowerCase() === this.props.selectedValue?.toLowerCase());
                        }

                        this.setState({
                            allData: tmp,
                            filteredData: tmp,
                            filteredViewTotal: tmp.length,
                            dataLoadInProgress: false,
                            dataLoaded: true,
                            ddlValue: defVal
                        });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ dataLoadInProgress: false });
                        break;

                    default:
                        alert('Error loading select list:' + res.CallStatusMessage);
                        this.setState({ dataLoadInProgress: false });
                }
            })
            .catch(err => {
                alert('Error loading select list:' + err.toString());
                this.setState({ dataLoadInProgress: false });
            });
    }


    loadAttributeLookup = (attribClassId?: string) => {
        if (!attribClassId || isNaN(parseInt(attribClassId)))
            return;

        this.setState({ dataLoadInProgress: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/GetAttributeClassLookupListData';
        let body = {
            attributeClassId: parseInt(attribClassId)
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        const tmp: Array<DropDownListData> = res.results;

                        let defVal: DropDownListData | undefined;
                        if (this.props.selectedValue) {
                            defVal = tmp.find(x => x.value.toLowerCase() === this.props.selectedValue?.toLowerCase());
                        }

                        this.setState({
                            allData: tmp,
                            filteredData: tmp,
                            filteredViewTotal: tmp.length,
                            dataLoadInProgress: false,
                            dataLoaded: true,
                            ddlValue: defVal
                        });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ dataLoadInProgress: false });
                        break;

                    default:
                        alert('Error loading select list:' + res.CallStatusMessage);
                        this.setState({ dataLoadInProgress: false });
                }
            })
            .catch(err => {
                alert('Error loading select list:' + err.toString());
                this.setState({ dataLoadInProgress: false });
            });
    }

   

    ddlChangeToEdit = () => {
        this.setState({ mode: InputCtlViewMode.Edit, ddlValueOrig: this.state.ddlValue });
    }

    saveChange = () => {
        this.setState({ mode: InputCtlViewMode.Saving }, () => {
            if (this.props.onSave)
                this.props.onSave(this.state.ddlValue, this.setViewMode);
        });

    }

    cancelChange = () => {
        this.setState({ mode: InputCtlViewMode.View, ddlValue: this.state.ddlValueOrig });
    }

    popupOpen = () =>{
        this.setState({ filterText: "" });
        this.onFocus();
    }

    setViewMode = (e: InputCtlViewMode) => {
        this.setState({ mode: e });
    }

   
    renderDDL = (disabled: boolean) => {
        let popupSettings: DropDownsPopupSettings = {};

        if (this.props.height) {
            popupSettings = {
                height: this.props.height,
            };
        }


        return (
            <div id={this.props.keyProp}>
            <DropDownList
                data={this.state.filteredData}
                dataItemKey="value"
                textField="display"
                filter={this.state.filterText}
                filterable={true}
                onFilterChange={this.onFilterChange}
                onOpen={this.popupOpen}
                loading={this.state.dataLoadInProgress}
                onChange={this.selectChange}
                value={this.state.ddlValue}
                style={{ width: '100%' }}
                disabled={disabled}
                popupSettings={popupSettings}
            />
        </div>);


    }




    render() {

        switch (this.state.mode) {
            case InputCtlViewMode.ReadOnly:
            default:
                return (<div className='DropDownListCtl readonly txtInpCtlInput k-input k-input-md k-rounded-md k-input-solid'>{this.getDisplayText(this.state.ddlValue)}</div>)

            case InputCtlViewMode.Select:
                return (this.renderDDL(false));

            case InputCtlViewMode.View:
                return (<div className='DropDownListCtl readonly txtInpCtlInput k-input k-input-md k-rounded-md k-input-solid' onClick={() => this.ddlChangeToEdit()}>{this.getDisplayText(this.state.ddlValue)}</div>)

            case InputCtlViewMode.Edit:
                return (<div className='DropDownListCtl edit' >
                    {this.renderDDL(false)}
                    <img className='check' hidden={this.state.mode !== InputCtlViewMode.Edit} src={checkImg} alt="Save" title='Save' onClick={this.saveChange} />
                    <img className='cross' hidden={this.state.mode !== InputCtlViewMode.Edit} src={crossImg} alt="Cancel" title='Cancel' onClick={this.cancelChange} />
                </div>);

            case InputCtlViewMode.Saving:
                return (<div className='DropDownListCtl edit' >
                    {this.renderDDL(true)}
                    <div className='saving' hidden={this.state.mode !== InputCtlViewMode.Saving}><span className="k-icon k-font-icon k-i-loading" /></div>
                </div>);

        }
    }
}
        export default connector(DropDownListCtl);
        
