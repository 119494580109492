import React, { CSSProperties } from 'react';
import { ValidationColumn, FpcModel } from '../../models/models';
import { Grid, GridColumn, GridPageChangeEvent, GridColumnProps, GridHeaderCellProps, GridCellProps, GridSortChangeEvent, GridFilterChangeEvent, GridColumnMenuFilter, GridColumnMenuSort } from '@progress/kendo-react-grid';
import { SplitButton, SplitButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { ExcelExport, ExcelExportProps, ExcelExportColumnProps, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { filterBy, CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import moment from 'moment'
import numeral from 'numeral'

import './ResultsGrid.css';
//import { CellOptions } from '@progress/kendo-react-excel-export';
import { Checkbox } from '@progress/kendo-react-inputs';

interface ResultsGridProps {
    model: FpcModel;
}

interface ResultsGridState {
    fpc: FpcModel;
    gridFilteredData: any[];
    gridFilteredDataView: any[];
    gridFilteredDataViewSkip: number;
    gridFilteredDataTotal: number;
    colDefs: JSX.Element[];
    colExcelDefs: JSX.Element[];
    gridStyle: React.CSSProperties;
    gridSortDescriptor: SortDescriptor[];
    gridFilterDescriptor: CompositeFilterDescriptor | undefined;
    exportButtonEnabled: boolean;
    showDialog: boolean;
}

const footerExportButtonItems = [{key: 'excel', text: 'Export to Excel', icon: 'k-icon k-font-icon k-i-excel'},
                                 {key: 'csv', text: 'Export to CSV', icon: 'k-icon k-font-icon k-i-csv'}
                                ];
class ResultsGrid extends React.Component<ResultsGridProps, ResultsGridState> {


    constructor(props: ResultsGridProps) {
        super(props);

        this.state = {
            fpc: props.model,
            gridFilteredData: props.model.ResultData.Data,
            gridFilteredDataView: props.model.ResultData.Data.slice(0, this.gridPageSize),
            gridFilteredDataViewSkip: 0,
            gridFilteredDataTotal: props.model.ResultData.Data.length,
            colDefs: props.model.ResultData.Columns.filter((f) => f.visible).map((c: ValidationColumn) => this.renderGridCol(c, undefined)),
            colExcelDefs: props.model.ResultData.Columns.filter((f) => f.visible).map((c: ValidationColumn) => this.renderExcelCol(c)),
            gridStyle: { },
            gridSortDescriptor: [],
            gridFilterDescriptor: undefined,
            exportButtonEnabled: true,
            showDialog: false
        }

    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            var gs: React.CSSProperties = { ...this.state.gridStyle };
            gs.height = body.clientHeight - 230;
            this.setState({
                gridStyle: gs
            });
        }
    }


    private gridPageSize = 50;

    gridPageChange = (e: GridPageChangeEvent) => {
        this.setState({
            gridFilteredDataViewSkip: e.page.skip,
            gridFilteredDataView: this.state.gridFilteredData.slice(e.page.skip, e.page.skip + this.gridPageSize)
        });
    }

    gridSortChange = (e: GridSortChangeEvent) => {
        const tmpData = orderBy(this.state.gridFilteredData, e.sort);

        this.setState({
            gridFilteredData: tmpData,
            gridFilteredDataView: tmpData.slice(0, this.gridPageSize),
            gridFilteredDataViewSkip: 0,
            gridSortDescriptor: e.sort
        });

    }

    gridFilterChange = (event: GridFilterChangeEvent) => {

        const tmpData = orderBy(filterBy(this.state.fpc.ResultData.Data, event.filter), this.state.gridSortDescriptor);
        const newColDefs = this.state.fpc.ResultData.Columns.filter((f) => f.visible).map((c: ValidationColumn) => this.renderGridCol(c, event.filter));

        this.setState({
            gridFilteredData: tmpData,
            gridFilteredDataView: tmpData.slice(0, this.gridPageSize),
            gridFilteredDataViewSkip: 0,
            gridFilteredDataTotal: tmpData.length,
            gridFilterDescriptor: event.filter,
            colDefs: newColDefs
        });
    }


    // setGridHeight = (onSuccess?: () => void) => {
    //     let d = document,
    //         body = d.getElementById('appContent');
    //     if (body) {
    //         var gs: React.CSSProperties = { ...this.state.gridStyle };
    //         gs.height = body.clientHeight - footerHeight;
    //         this.setState({
    //             gridStyle: gs
    //         }, onSuccess);
    //     }
    // }

    // setGridwidth = (onSuccess: () => void) => {
    //     let d = document,
    //         body = d.getElementById('appContent');
    //     if (body) {
    //         let fpc = this.state.fpc;
    //         let gs: React.CSSProperties = { ...this.state.gridStyle };
    //         let fs: React.CSSProperties = { ...this.state.footerStyle };


    //         if (fpc.ResultData.Columns.find(x => x.visible && x.width === 0)) {
    //             gs.width = undefined;
    //             fs.width = undefined;
    //         } else {
    //             let colwidthTot = 0;
    //             fpc.ResultData.Columns.forEach((c) => colwidthTot += c.width);
    //             gs.width = colwidthTot + gridScrollBarwidth;
    //             fs.width = gs.width;
    //         }

    //         this.setState({
    //             gridStyle: gs,
    //             footerStyle: fs
    //         }, onSuccess);

    //     }
    // }


    exportClick = (e: SplitButtonItemClickEvent) => {
        let selItm = e.item;
        switch (selItm.key) {
            case "excel":
                this.exportExcelStart();
                break;
            case "csv":
                this.exportCSVStart();
                break;
        }
    }

    private excelProps: ExcelExportProps = {};
    private _export: ExcelExport | null = new ExcelExport(this.excelProps);
    exportExcelStart = () => {
        if (this._export) {
            this.setState({
                exportButtonEnabled: false,
            }, () => {
                this.excelExcelSave(this._export);
                this.setState({ exportButtonEnabled: true });
            });
        }
    }
    excelExcelSave = (e: ExcelExport | null) => {
        if (e) {
            const opt = e.workbookOptions();
            if (opt && opt.sheets && opt.sheets[0]) {
                var gg = opt.sheets[0];
                gg.name = this.state.fpc.clientQueryDesc.substr(0, 31);
                // gg.rows[0].cells[0].format = 
            }
            e.save(opt);
        }
    }

    exportCSVStart = () => {
        this.setState({
            exportButtonEnabled: false,
        }, () => {
            this.exportCSV();
            this.setState({ exportButtonEnabled: true });
        });
    }
    exportCSV = () => {

        let fpc = this.state.fpc;
        // let csvContent = "data:text/csv;charset=utf-8,";
        let rows = this.state.fpc.ResultData.Data;

        let csvContent = this.state.fpc.ResultData.Columns.map(e => e.displayName).join(",") + "\n";

        rows.forEach((r) => {
            let csvRow = '';
            fpc.ResultData.Columns.forEach((c) => {
                if (r[c.fieldName]) {
                    switch (c.dataType.toLowerCase()) {
                        case "datetime":
                            let dateFmt = moment(r[c.fieldName]);
                            csvRow += dateFmt.format("YYYY-MM-DD HH:mm:SS") + ",";
                            break;
                        case "date":
                            let dateFmt2 = moment(r[c.fieldName]);
                            csvRow += dateFmt2.format("YYYY-MM-DD") + ",";
                            break;
                        case "number":
                        case "int":
                        case "float":
                            csvRow += r[c.fieldName] + ",";
                            break;
                        case "string":
                        default:
                            csvRow += "\"" + r[c.fieldName].toString().replace(/"/g, '""').replace(",", " ") + "\",";
                            break;
                    }
                } else {
                    csvRow += ",";
                }
            });
            csvContent += csvRow.slice(0, csvRow.length - 1) + "\n";
        });

        var file = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        var fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = this.state.fpc.clientQueryDesc + ".csv"
        link.click();

    }

    renderGridCol = (c: ValidationColumn, fd: CompositeFilterDescriptor | undefined) => {

        var colProps: GridColumnProps = {};

        colProps.field = c.fieldName;
        colProps.title = c.displayName;
         if (c.width !== 0){
            colProps.width = c.width;
         }
         else{
            //colProps.width = colProps.title.length * 20;

         }



        //        colProps.headerCell = ProductNameHeader;
        colProps.headerCell = (e) => this.headerCell(e, c.fieldName);
        if (GridColumnMenuFilter.active(c.fieldName, fd))
            colProps.headerClassName = 'active';

        switch (c.dataType.toLowerCase()) {
            case "boolean":
                colProps.cell = this.checkBoxCell;
                break;
            case "datetime":
                colProps.cell = this.dateTimeCell;
                colProps.filter = "date";
                break;
            case "date":
                colProps.cell = this.dateCell;
                colProps.filter = "date";
                break;
            case "number":
            case "int":
            case "float":
                colProps.cell = (e: GridCellProps) => this.numberCell(e, c.formatCode);
                colProps.filter = "numeric";
                break;
            case "html":
                colProps.cell = this.htmlCell;
                colProps.filter = "text";
                break;

            case "string":
            default:
                colProps.cell = this.textCell;
                colProps.filter = "text";
                break;
        }

        return (<GridColumn key={c.fieldName} headerClassName="resultsGridHeader"
            columnMenu={props => <div><GridColumnMenuSort {...props} />
                <GridColumnMenuFilter  {...props} />
            </div>}  {...colProps} ></GridColumn>);
    }

    renderExcelCol = (c: ValidationColumn) => {

        var excelProps: ExcelExportColumnProps = {};
        excelProps.field = c.fieldName;
        excelProps.title = c.displayName;
        excelProps.cellOptions = {};
        excelProps.cellOptions.format = this.getExcelFormat(c);

        return (<ExcelExportColumn key={c.fieldName} {...excelProps} />);
    }

    headerCell = (e: React.PropsWithChildren<GridHeaderCellProps>, toolTip: string) => {
        return (
            <a className="k-link" onClick={e.onClick}>
                <span title={toolTip}>{e.title}</span>
                {e.children}
            </a>
        );
    }

    numberCell = (e: GridCellProps, formatCode: string) => {
        if (e.field && !isNaN(e.dataItem[e.field])) {

            var gg: CSSProperties = { textAlign: 'right' };
            if (formatCode.length > 0) {
                let numFmt = numeral(e.dataItem[e.field]).format(formatCode);
                return (<td style={gg}>{numFmt}</td>);
            }
            else
                return (<td style={gg}>{e.dataItem[e.field]}</td>);
        } else
            return (<td>Invalid Number</td>);
    }

    checkBoxCell = (e: GridCellProps) => {
        if (e.field) {
            return (<td><Checkbox checked={e.dataItem[e.field]} disabled={true} /></td>);
        } else
            return (<td>Error</td>);
    }

    dateCell = (e: GridCellProps) => {
        if (e.field) {
            var gg: CSSProperties = { textAlign: 'center' };
            if (e.dataItem[e.field]) {
                let dateFmt = moment(e.dataItem[e.field]);
                if (dateFmt.isValid())
                    return (<td style={gg}>{dateFmt.format("DD/MM/YYYY")}</td>);
                else
                    return (<td style={gg}>{e.dataItem[e.field]}</td>);
            } else
                return (<td style={gg}></td>);
        } else
            return (<td>Error</td>);
    }

    dateTimeCell = (e: GridCellProps) => {
        if (e.field) {
            let dateFmt = moment(e.dataItem[e.field]);
            var gg: CSSProperties = { textAlign: 'center' };
            if (dateFmt.isValid())
                return (<td style={gg}>{dateFmt.format("DD/MM/YYYY HH:mm")}</td>);
            else
                return (<td style={gg}>{e.dataItem[e.field]}</td>);
        } else
            return (<td>Error</td>);
    }

    textCell = (e: GridCellProps) => {
        if (e.field) {
            return (<td >{e.dataItem[e.field]}</td>);
        } else
            return (<td>Error</td>);
    }

    htmlCell = (e: GridCellProps) => {
        if (e.field) {
            return (<td dangerouslySetInnerHTML={{ __html: e.dataItem[e.field] }}></td>);
        } else
            return (<td>Error</td>);
    }

    getExcelFormat = (c: ValidationColumn) => {
        switch (c.dataType.toLowerCase()) {
            case "datetime":
                return "dd-MMM-yyyy hh:mm:ss";
            case "date":
                return "dd-MMM-yyyy";
            case "number":
            case "int":
            case "float":
                return "General";
            case "string":
            default:
                return "Text";
        }

    }

    rowCountSummary = () => {
        if (this.state.gridFilterDescriptor) {
            return (<div className="rowCountSumm">Filtered Rows = {this.state.gridFilteredData.length.toString()}  (from {this.state.fpc.ResultData.Data.length.toString()})</div>);
        } else {
            return (<div className="rowCountSumm">Row Count = {this.state.gridFilteredData.length.toString()}</div>);
        }
    }

    render() {
        let fpc = this.state.fpc;
        if (fpc.ResultData.Data.length === 0) {
            return (
                <div className='resultsDiv'>No results returned</div>
            );
        } else {
            return (
                <div className="resultsOuterDiv">
                    <div className="resultsGridDiv">
                    <Grid
                        className="resultsGrid"
                        data={this.state.gridFilteredDataView}
                        rowHeight={25}
                        pageSize={this.gridPageSize}
                        total={this.state.gridFilteredDataTotal}
                        skip={this.state.gridFilteredDataViewSkip}
                        scrollable={'virtual'}
                        onPageChange={this.gridPageChange}
                        style={this.state.gridStyle}
                        resizable
                        sortable
                        sort={this.state.gridSortDescriptor}
                        onSortChange={this.gridSortChange}
                        filter={this.state.gridFilterDescriptor}
                        onFilterChange={this.gridFilterChange}
                    >
                        {this.state.colDefs}
                    </Grid>
                    </div>
                    <ExcelExport
                        data={fpc.ResultData.Data}
                        ref={exporter => this._export = exporter}
                        fileName={fpc.clientQueryDesc + ".xlsx"} >
                        {this.state.colExcelDefs}
                    </ExcelExport>


                    <div className="resultsFooter">
                        <table className="resultsFooterTbl">
                            <tbody>
                                <tr>
                                    <th><SplitButton className="resultsBtn" text="Export" items={footerExportButtonItems} onItemClick={this.exportClick} disabled={!this.state.exportButtonEnabled} /></th>
                                    <td>{this.rowCountSummary()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    };

}

export default ResultsGrid;