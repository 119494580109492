import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { UserInfo, TcbObjInfo, TcbObjQuarantine } from '../../models/models';
import { loadingDiv } from '../../functions/componentFunctions';
import { addPageViewClass } from '../../functions/generalFunctions'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import ErrorMsg from '../ErrorMsg';
import moment from 'moment';

import './TcbObjQuarantineItems.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


type TcbObjQuarantineProps = PropsFromRedux & {
    tcbObj: TcbObjInfo;
    stateToLoad?: TcbObjQuarantineState;
    onSaveState?: (e: TcbObjQuarantineState) => void;
}

interface TcbObjQuarantineState {
    searchInProg: boolean;

    tcbObjQuarantines: TcbObjQuarantine[];
    QuarantineFetched: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;

}

class TcbObjQuarantineItems extends React.Component<TcbObjQuarantineProps, TcbObjQuarantineState> {
    constructor(props: TcbObjQuarantineProps) {
        super(props);

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad };
        } else {

            this.state = {
                searchInProg: true,
                tcbObjQuarantines: [],
                QuarantineFetched: false,
                fetchError: false,
                fetchErrorMsg: '',
            };
        }
    }


    componentDidMount() {
        if (!this.state.QuarantineFetched) {
            this.getTcbObjQuarantine();
        }
    }

    componentDidUpdate(prevProps: TcbObjQuarantineProps) {
    }


    getTcbObjQuarantine = () => {
        this.setState({ tcbObjQuarantines: [], searchInProg: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjQuarantine';

        fetch(url, { method: 'POST', body: JSON.stringify(this.props.tcbObj), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        // let nqs: TcbObjQuarantine[] = res.results;
                        this.setState({
                            tcbObjQuarantines: res.results,
                            QuarantineFetched: true,
                            searchInProg: false
                        }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg: false });
                        break;

                    default:
                        this.setState({
                            searchInProg: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage
                        });
                }
            })
            .catch(err => {
                this.setState({
                    searchInProg: false,
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                });
            });

    }

    errorClose = () => {
        this.setState({ fetchError: false, fetchErrorMsg: '' });
    }


    renderQuarantineItem = (n: TcbObjQuarantine) => {


        return (
            <tr>

                        <td className="singleQuarantineDate">{moment(n.quarantinedOn).format("DD-MMM-YYYY HH:mm")}</td>
                        <td className="singleQuarantineComment">{n.comments}</td>
                        <td className="singleQuarantineActive">{n.isActive}</td>

            </tr>
        )
    }


    renderQuarantine = () => {
        if (this.state.tcbObjQuarantines.length > 0) {
            return (
                <table className={"tcbObjQuarantineTable" + addPageViewClass(this.props.pageInf)}>
                    <thead>
                        <tr>
                            <td colSpan={3}>
                                Quarantine Details
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="quarantineHeaderRow">
                                     <td className="singleQuarantineDate">Date Quarantined</td>
                                    <td className="singleQuarantineComment">Comments</td>
                                    <td className="singleQuarantineActive">Quarantined?</td>

                        </tr>

                        {this.state.tcbObjQuarantines.map((c) => { return this.renderQuarantineItem(c) })}


                    </tbody>
                </table>
            )
        } else {
            return (<div className="NoQuarantineMessage">Nothing to report</div>);
        }
    }
    render() {
        if (this.state.fetchError) {
            return (<ErrorMsg message={this.state.fetchErrorMsg} onClose={this.errorClose} />);
        }
        if (this.state.searchInProg) {
            return loadingDiv();
        }

        return (
            <div id="TcbObjQuarantineDiv" className={addPageViewClass(this.props.pageInf)}>
                {this.renderQuarantine()}
            </div>
        );
    }

}

export default connector(TcbObjQuarantineItems);