import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { Grid, GridColumn, GridPageChangeEvent, GridSortChangeEvent, GridFilterChangeEvent, GridColumnMenuFilter, GridColumnMenuSort, GridRowProps, GridHeaderCellProps, GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, Input, CheckboxChangeEvent, InputChangeEvent, NumericTextBox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { addPageViewClass } from '../../functions/generalFunctions'
import { filterBy, CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import './PackListAddItemsDlg.css';
import { SaveButtonStatusEnum} from '../../models/enums';
import { UserInfo, TcbObjInfo, AddItemForPacklist, GetAddItemsForPacklistResult } from '../../models/models';
import tcbLogoSml from '../../images/Tcb-50.gif';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PackListAddItemsDlgProps = PropsFromRedux & {
	tcbObj: TcbObjInfo;
	onClose: () => void;
	onAdd: (addItems: AddItemForPacklist[]) => void;
}

interface PackListAddItemsDlgState {
	searchStr: string;
	srchIncChildren: boolean;
	copyItemAttrib: boolean;
	enableSearch: boolean;
	searchInProg: boolean;
	firstMilestoneId: number;
	askInherit: boolean;
	doInherit: boolean;
	addItemsSearchResult: AddItemForPacklist[];
	gridFilteredData: AddItemForPacklist[];
	gridFilteredDataView: AddItemForPacklist[];
	gridFilteredDataViewSkip: number;
	gridFilteredDataTotal: number;
	gridSortDescriptor: SortDescriptor[];
	gridFilterDescriptor: CompositeFilterDescriptor | undefined;
	// selectedItems: TcbObjInfo[];
	gridStyle: React.CSSProperties;
	gridStatus: string;
	saveStatus: SaveButtonStatusEnum;
}

class PackListAddItemsDlg extends React.Component<PackListAddItemsDlgProps, PackListAddItemsDlgState> {
	constructor(props: PackListAddItemsDlgProps) {
		super(props);

		this.state = {
			searchStr: '',
			srchIncChildren: false,
			copyItemAttrib: false,
			enableSearch: false,
			searchInProg: false,
			firstMilestoneId: 0,
			askInherit: false,
			doInherit: false,
			addItemsSearchResult: [],
			gridFilteredData: [],
			gridFilteredDataView: [],
			gridFilteredDataViewSkip: 0,
			gridFilteredDataTotal: 0,
			gridSortDescriptor: [],
			gridFilterDescriptor: undefined,
			// selectedItems: [],
			gridStyle: { height: 400 },
			gridStatus: '',
			saveStatus: SaveButtonStatusEnum.Disabled
		}
	}

	private gridPageSize = 50;

	componentDidMount() {
		//Manually setting height so we can use virtual scrolling in the grid
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);

	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}


	updateDimensions = () => {
		let gridDiv = document.getElementById('plItmsAddBody');
		if (gridDiv) {
			let gs: React.CSSProperties = {};
			gs.height = gridDiv.clientHeight - 61;
			this.setState({ gridStyle: gs });
		}
	}



	dlgActionClose = () => {
		this.props.onClose();
	}


	searchCriteriaChange = (e: InputChangeEvent) => {
		let canSearch = false;
		if (e.value.length >= 3) canSearch = true;

		this.setState({ searchStr: e.value, enableSearch: canSearch });
	}

	searchBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.runItemSearch();
	}

	searchTxtKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (this.state.searchStr.trim().length >= 3)
				this.runItemSearch();
		}
	}

	srchChildrenChanged = (e: CheckboxChangeEvent) => {
		this.setState({ srchIncChildren: e.value });
	}
	copyItemAttribChanged = (e: CheckboxChangeEvent) => {
		this.setState({ copyItemAttrib: e.value });
	}



	runItemSearch = () => {
		this.setState({ searchInProg: true, addItemsSearchResult: [] });

		let url = this.props.userInf.currProject.apiUrl + '/api/search/GetAddItemsForPacklist';

		let body = {
			searchString: this.state.searchStr,
			includeChildren: this.state.srchIncChildren,
			destinationPacklistId: this.props.tcbObj.tcbObjId,
			userRoleId: this.props.userInf.currProjectUserRoleId
		}

		fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
			.then(r => r.json())
			.then(res => {
				switch (res.callStatus) {
					case "OK":
						let plr = res.result as GetAddItemsForPacklistResult;

						this.setState({
							searchInProg: false,
							firstMilestoneId: plr.firstMilestoneId,
							askInherit: plr.askInherit,
							doInherit: plr.doInherit,
							addItemsSearchResult: plr.itemResults,
							gridFilteredData: plr.itemResults,
							gridFilteredDataView: plr.itemResults.slice(0, this.gridPageSize),
							gridFilteredDataViewSkip: 0,
							gridFilteredDataTotal: plr.itemResults.length,
							gridSortDescriptor: [],
							gridFilterDescriptor: undefined,
							gridStatus: plr.itemResults.length.toString() + ' rows'
						});

						break;
					case "UNAUTH":
						let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
						this.props.updateUserInfo(uInf);
						break;

					default:
						throw (res.callStatusMessage)

				}
			})
			.catch(err => {
				console.log('runSplSearch: ' + err);
			});
	}


	gridPageChange = (e: GridPageChangeEvent) => {
		this.setState({
			gridFilteredDataViewSkip: e.page.skip,
			gridFilteredDataView: this.state.gridFilteredData.slice(e.page.skip, e.page.skip + this.gridPageSize)
		});
	}

	gridSortChange = (e: GridSortChangeEvent) => {
		const tmpData = orderBy(this.state.gridFilteredData, e.sort);

		this.setState({
			gridFilteredData: tmpData,
			gridFilteredDataView: tmpData.slice(0, this.gridPageSize),
			gridFilteredDataViewSkip: 0,
			gridSortDescriptor: e.sort
		});

	}

	gridFilterChange = (event: GridFilterChangeEvent) => {

		const tmpData = orderBy(filterBy(this.state.addItemsSearchResult, event.filter), this.state.gridSortDescriptor);
		let stat = '';
		if (tmpData.length === this.state.addItemsSearchResult.length)
			stat = this.state.addItemsSearchResult.length.toString() + ' rows';
		else
			stat = tmpData.length.toString() + ' filtered rows / ' + this.state.addItemsSearchResult.length.toString() + ' total';

		this.setState({
			gridFilteredData: tmpData,
			gridFilteredDataView: tmpData.slice(0, this.gridPageSize),
			gridFilteredDataViewSkip: 0,
			gridFilteredDataTotal: tmpData.length,
			gridFilterDescriptor: event.filter,
			gridStatus: stat,
			saveStatus: this.getAddButtonStatus(tmpData)
		});
	}

	gridSelectAllChange = (e: CheckboxChangeEvent) => {
		let data = this.state.gridFilteredData;
		data.forEach(x => { x.selected = e.value });
		this.setState({ gridFilteredData: data, saveStatus: this.getAddButtonStatus(data) });
	}

	gridSelectChange = (e: AddItemForPacklist) => {
		let data = this.state.gridFilteredData;

		let itm = data.find((x) => x.itemId === e.itemId);
		if (itm) {
			itm.selected = !itm.selected;
		}

		this.setState({ gridFilteredData: data, saveStatus: this.getAddButtonStatus(data) });
	}

	addQtyChange = (value: any, dataItem: AddItemForPacklist) => {
		let data = this.state.gridFilteredData;
		let itm = data.find((x) => x.itemId === dataItem.itemId);
		if (itm) {
			itm.changeQty = value;
			if (itm.changeQty) {
				itm.selected = true;
			}
			else {
				itm.changeQty = undefined;	//reset any zeros to null
				itm.selected = false;
			}

			this.setState({ gridFilteredData: data, saveStatus: this.getAddButtonStatus(data) });
		}
	}

	getAddButtonStatus = (e: AddItemForPacklist[]) => {
		if (this.state.saveStatus === SaveButtonStatusEnum.InProgress) return SaveButtonStatusEnum.InProgress;
		if (!e.some(x => x.selected)) return SaveButtonStatusEnum.Disabled;

		if (e.some(x => (x.selected && x.sundry && !x.changeQty)))
			return SaveButtonStatusEnum.Disabled;
		else
			return SaveButtonStatusEnum.Enabled;
	}


	dlgActionSave = (close: boolean) => {
		//Assume save will work and update dataset
		let data = this.state.gridFilteredData;
		let selItems = data.filter(x => x.selected);

		let addItems = selItems.map(x => {
			let cq = 1;
			if (x.sundry && x.changeQty) cq = x.changeQty;
			return { itemId: x.itemId, changeQty: cq };
		});

		if (!close) {
			selItems.forEach(x => {
				x.selected = false;
				x.changeQty = undefined;
				if (x.sundry) {
					x.existingQty = (x.existingQty ?? 0) + (x.changeQty ?? 0);
				} else {
					x.existingQty = 1;
				}
			});
		}

		this.setState({ saveStatus: SaveButtonStatusEnum.InProgress, gridFilteredData: data });

		let url = this.props.userInf.currProject.apiUrl + '/api/details/AddItemsToTcbObj';

		let body = {
			tcbObjClass: this.props.tcbObj.tcbObjClass,
			tcbObjId: this.props.tcbObj.tcbObjId,
			addItems: addItems
		}

		fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
			.then(r => r.json())
			.then(res => {
				switch (res.callStatus) {
					case "OK":
						this.props.onAdd(selItems);
						if (close) this.props.onClose();
						this.setState({ saveStatus: SaveButtonStatusEnum.Enabled });
						break;
					case "UNAUTH":
						let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
						this.props.updateUserInfo(uInf);
						break;

					default:
						throw (res.callStatusMessage)

				}
			})
			.catch(err => {
				console.log('packlistAddItemsDlg: ' + err);
			});
	}


	renderTitle = () => {
		let titleDesc = "Add Items to " + this.props.tcbObj.tcbObjDesc;
		return (
			<table id="plItmsAddTitle">
				<thead>
					<tr>
						<td className='left'><img style={{ height: "48px" }} src={tcbLogoSml} alt="TracBASE" /> </td>
						<th>{titleDesc}</th>
						<td className='right'><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.dlgActionClose()}></span></td>
					</tr>
				</thead>
			</table>)
	}

	gridHeaderCellRender = (n: React.ReactNode, e: GridHeaderCellProps) => {
		switch (e.field) {
			case "selected":
				return <div><Checkbox id="srchResultsSelAll" onChange={this.gridSelectAllChange} /></div>
			default:
				return (e.title);
		}
	}

	gridRowRender = (row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactNode => {
		let cn = "";
		if (rowProps.dataItem.selected) {
			cn = "selected";
		} else if (rowProps.isAltRow) {
			cn = "altRow";
		}

		if (cn !== "")
			return (<tr className={cn} {...rowProps}>{rowProps.children}</tr>);
		else
			return (<tr {...rowProps}>{rowProps.children}</tr>);
	}

	gridCellRender = (cell: any, cellProps: GridCellProps) => {
		let di = cellProps.dataItem as AddItemForPacklist;
		switch (cellProps.field) {
			case "selected":
				// if (cellProps.dataItem.selected)
				//     return (<td style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center", width:20 }}><span className="k-icon k-font-icon k-i-check" /></td>)
				// else
				//     return (<td style={{width:20}}></td>)

				return (<td style={{ width: 20 }} ><Checkbox key={di.itemId} checked={di.selected} disabled={!di.isAvailable} title={di.toolTip} onChange={() => this.gridSelectChange(di)} /></td>);

			case "changeQty":
				if (!di.sundry) {
					return (<td>1</td>)
				} else {
					var clsNam = '';
					if (di.selected && !di.changeQty) clsNam = 'dataReq';
					return (<td><NumericTextBox className={clsNam}
						disabled={!di.sundry}
						value={di.changeQty}
						min={1}
						onChange={(e) => this.addQtyChange(e.value, di)}
					/></td>);
				}
			case "itemDisplayText":
				if (di.isAvailable)
					return cell;
				else
					return (<td className='disabled' title={di.toolTip}>{di.itemDisplayText}</td>)

			default:
				return cell;
		}
	}


	renderGrid = () => {
		if (this.state.searchInProg) {
			return (<div className='loading'><span className="k-icon k-font-icon k-i-loading" /></div>);
		} else {
			return (
				<Grid
					className="plItmsResultsGrid"
					style={this.state.gridStyle}
					data={this.state.gridFilteredDataView}
					rowHeight={25}
					pageSize={this.gridPageSize}
					total={this.state.gridFilteredDataTotal}
					skip={this.state.gridFilteredDataViewSkip}
					scrollable={'virtual'}
					resizable
					sortable
					onPageChange={this.gridPageChange}
					sort={this.state.gridSortDescriptor}
					onSortChange={this.gridSortChange}
					filter={this.state.gridFilterDescriptor}
					onFilterChange={this.gridFilterChange}
					selectedField="selected"
					headerCellRender={this.gridHeaderCellRender}
					rowRender={this.gridRowRender}
					cellRender={this.gridCellRender}
				>
					<GridColumn field="selected" width={35} title="Select to Add" resizable={false} sortable={false} filterable={false} />
					<GridColumn field="itemDisplayText" width={400} title="Item Description" resizable={true} sortable={true} filterable={true}
						columnMenu={props => <div><GridColumnMenuSort {...props} />
							<GridColumnMenuFilter  {...props} />
						</div>}
						className="descCol" />
					<GridColumn field="existingQty" width={120} title="Current Qty" resizable={false} sortable={true} filterable={true}
						columnMenu={props => <div><GridColumnMenuSort {...props} />
							<GridColumnMenuFilter  {...props} />
						</div>}
					/>
					<GridColumn field="changeQty" width={100} title="Qty to Add" resizable={true} sortable={false} filterable={false} />

				</Grid>

			)
		}
	}

	renderBody = () => {
		return (<div id='plItmsAddBody'>
			<div className='plItmsAddHdr'>
				<div className='left'>
					<Input className={"filterText " + addPageViewClass(this.props.pageInf)} placeholder="Enter search text (3 char min)" value={this.state.searchStr} onChange={this.searchCriteriaChange} onKeyDown={this.searchTxtKeyDown} />
					<Button className={"filterSearchBtn " + addPageViewClass(this.props.pageInf)} onClick={this.searchBtnClick} disabled={!this.state.enableSearch} >Search</Button>
				</div>
				<div className='right'><Checkbox checked={this.state.srchIncChildren} onChange={this.srchChildrenChanged} >Include Children</Checkbox></div>
			</div>
			<div id='plItmsAddGrid'>{this.renderGrid()}</div>
			<div className='plItmsAddFtr'>
				<div className='left'>{this.state.gridStatus}</div>
				<div className='right'><Checkbox checked={this.state.copyItemAttrib} onChange={this.copyItemAttribChanged} >Copy Attributes on Add</Checkbox></div>
			</div>
		</div>);
	}

	renderSaveButtonTxt = (e: string) => {
		if (this.state.saveStatus === SaveButtonStatusEnum.InProgress)
			return (<span className='k-icon k-font-icon k-i-loading' />);
		else
			return (e);
	}

	render() {
		return (
			<Dialog className={"plItmsAddDlg" + addPageViewClass(this.props.pageInf)} title={this.renderTitle()} closeIcon={false}>
				{this.renderBody()}
				<DialogActionsBar>
					<Button onClick={e => this.dlgActionSave(false)} disabled={(this.state.saveStatus !== SaveButtonStatusEnum.Enabled)} >{this.renderSaveButtonTxt('Add Selected')}</Button>
					<Button onClick={e => this.dlgActionSave(true)} disabled={(this.state.saveStatus !== SaveButtonStatusEnum.Enabled)} >{this.renderSaveButtonTxt('Add Selected & Close')}</Button>
					<Button onClick={e => this.dlgActionClose()}>Close</Button>
				</DialogActionsBar>
			</Dialog>
		)
	}
}

export default connector(PackListAddItemsDlg);
