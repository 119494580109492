import { PageInfo } from '../models/models';
import { UPDATE_PAGEINFO, PageInfoActionType} from './reduxActionTypes';


const istate:PageInfo = new PageInfo();

function pageInfo(state:PageInfo=istate, action:PageInfoActionType) {
    switch (action.type) {
        case UPDATE_PAGEINFO:
            return action.pageInf;

        default:
            return state;
    }
}

export default pageInfo;