import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input, Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridRowClickEvent } from '@progress/kendo-react-grid';
import { UserInfo } from '../models/models';
import { CustomWindow } from '../models/custom.window'
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import { MsalProvider, useMsal } from '@azure/msal-react';
import '@progress/kendo-theme-default/dist/all.css';
import './Login.css';
import { PageViewTypeEnum } from '../models/enums';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type LoginProps = PropsFromRedux & {
    defaultUserName?: string;
    onSuccess?: (u: UserInfo) => void;
}

type LoginState = {
    statusMessage: string;
    userName: string;
    password: string;
    mFACode: string;
    resetCode: string;
    passwordNew: string;
    passwordChk: string;
    passwordNewValid: boolean;
    passwordNewMsg: string;
    passwordChkValid: boolean;
    passwordChkMsg: string;
    requestSent: boolean;
    useDefaultProject: boolean;
    setDefaultProject: boolean;
    loginBtnEnabled: boolean;
    loginResetBtnEnabled: boolean;
    mFAResetBtnEnabled: boolean;
    showForgotPwd: boolean;
    showProjectSelect: boolean;
    showMFARequest: boolean;
    selectedPrj: string;
    selectProjectBtnEnabled: boolean;
    usrInf: UserInfo;
    accessToken: string;
    forceTcBLogin: boolean;
    defaultUser: string;
}


declare function invokeSetDefaultUserAction(userDetail: string): void;
declare function invokeOpenSettingsAction(): void;

declare let window: CustomWindow;


function Login(props: LoginProps) {

    const loginRef = useRef(null);

    let usrName = '';
    let usrPass = '';
    let accessToken = '';

    const [state, setState] = useState<LoginState>({
        statusMessage: '',
        userName: usrName,
        password: usrPass,
        mFACode: '',
        resetCode: '',
        passwordNew: '',
        passwordChk: '',
        loginBtnEnabled: true,
        loginResetBtnEnabled: false,
        mFAResetBtnEnabled: false,
        passwordNewValid: false,
        passwordNewMsg: '',
        passwordChkValid: false,
        passwordChkMsg: '',
        showForgotPwd: false,
        requestSent: false,
        useDefaultProject: true,
        setDefaultProject: false,
        showProjectSelect: false,
        showMFARequest: false,
        selectedPrj: '',
        selectProjectBtnEnabled: false,
        usrInf: new UserInfo(),
        accessToken: accessToken,
        forceTcBLogin: false,
        defaultUser: '',
    });

    // Setting the component reference on mount
    useEffect(() => {
        window.loginRef = loginRef;

        return () => {
            // Cleanup reference on unmount
            window.loginRef = null;
        };
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            const defaultUser = sessionStorage.getItem("defaultUser");
            setState(prevState => ({
                ...prevState,
                userName: defaultUser!,
            }));
        };
    
        window.addEventListener("defaultUserSet", handleStorageChange);
    
        // Initial load
        handleStorageChange();
    
        return () => window.removeEventListener("defaultUserSet", handleStorageChange);
    }, []);

    
    
    const { instance, accounts } = useMsal();

    const loginScopes = ["api://181eae50-46a8-4a4b-a888-e954363c34c4/.default"];

    useEffect(() => {
        const handleLogin = async () => {
            if (accounts.length === 0) {
                try {
                    // Try silent authentication first

                    await instance.initialize();

                    const response = await instance.acquireTokenSilent({
                        scopes: loginScopes,  // Define your scopes
                    })

                    console.log("User successfully logged in:", response.account);

                    instance.setActiveAccount(response.account);

                    state.statusMessage = 'Logging in...';
                    state.loginBtnEnabled = false;
                    state.accessToken = response.accessToken;

                    setState({ ...state })



                } catch (error) {
                    // No session, or silent token acquisition failed
                    try {


                        const response = await instance.loginPopup({
                            scopes: loginScopes  // Use the scopes from JSON file
                        });

                        instance.setActiveAccount(response.account);

                        state.statusMessage = 'Logging in...';
                        state.loginBtnEnabled = false;
                        state.accessToken = response.accessToken;

                        setState({ ...state })
                    }
                    catch (popupError) {
                        //fall back to normal login
                        state.forceTcBLogin = true;
                        setState({ ...state })
                    }

                }
            } else {
                try {
                    await instance.initialize();

                    const response = await instance.acquireTokenSilent({
                        scopes: loginScopes,  // Define your scopes
                    })

                    instance.setActiveAccount(response.account);

                    state.statusMessage = 'Logging in...';
                    state.loginBtnEnabled = false;
                    state.accessToken = response.accessToken;

                    setState({ ...state })

                    executeMSALLogin(response.account.username)
                }
                catch {
                    //fall back to normal login
                    state.forceTcBLogin = true;
                    setState({ ...state })
                }
            }

        };

        if (!state.forceTcBLogin) {
            handleLogin();
        }
    }, [instance, accounts]);


    const userNameChanged = (event: InputChangeEvent) => {

        state.statusMessage = '';
        state.userName = event.value;
        state.loginBtnEnabled = (event.value !== '' && state.password !== '');
        setState({ ...state });

    }

    const passwordChanged = (event: InputChangeEvent) => {

        state.statusMessage = '';
        state.password = event.value;
        state.loginBtnEnabled = (event.target.value !== '' && state.userName !== '');
        setState({ ...state });

    }

    const passwordNewChanged = (event: InputChangeEvent) => {
        validateNewPasswords(event.value, state.passwordChk, state.resetCode);
    }

    const passwordChkChanged = (event: InputChangeEvent) => {
        validateNewPasswords(state.passwordNew, event.value, state.resetCode);
    }

    const validateNewPasswords = (newPwd: string, chkPwd: string, resetCode: string) => {
        let newPwdTooltip = '';
        let chkPwdTooltip = '';
        if (newPwd === '')
            newPwdTooltip = "Password is required";
        else if (newPwd !== chkPwd)
            newPwdTooltip = "New Password not same as Check Password";
        if (chkPwd === '')
            chkPwdTooltip = "Password is required";
        else if (newPwd !== chkPwd)
            chkPwdTooltip = "New Password not same as Check Password";


        state.statusMessage = '';
        state.passwordNew = newPwd;
        state.passwordChk = chkPwd;
        state.loginResetBtnEnabled = (newPwd !== '' && chkPwd !== '' && state.resetCode !== '') && (newPwd === chkPwd);
        state.passwordNewValid = (newPwd !== '' && newPwd === chkPwd);
        state.passwordNewMsg = newPwdTooltip;
        state.passwordChkValid = (chkPwd !== '' && chkPwd === newPwd);
        state.passwordChkMsg = chkPwdTooltip;
        setState({ ...state });

    }

    const resetCodeChanged = (event: InputChangeEvent) => {

        state.statusMessage = '';
        state.resetCode = event.value;
        state.loginResetBtnEnabled = (event.value !== '' && state.passwordNew !== '' && state.passwordChk !== '') && (state.passwordNew === state.passwordChk);
        setState({ ...state });

    }

    const mFACodeChanged = (event: InputChangeEvent) => {

        state.statusMessage = '';
        state.mFACode = event.value;
        state.loginResetBtnEnabled = (event.value !== '');
        setState({ ...state });

    }

    const loginClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        state.statusMessage = 'Logging in...';
        state.loginBtnEnabled = false;
        setState({ ...state });

        executeLogin();
    }

    const keyDownAction = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (state.loginBtnEnabled) {

                state.statusMessage = 'Logging in...';
                state.loginBtnEnabled = false;
                setState({ ...state });

                executeLogin();
            }
        }
    }

    const loginResetClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        state.statusMessage = 'Resetting password and logging in...';
        state.loginResetBtnEnabled = false;
        state.showForgotPwd = false;
        setState({ ...state });

        executeResetLogin();
    }

    const mFAResetClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        state.statusMessage = 'Logging in...';
        state.mFAResetBtnEnabled = false;
        state.showMFARequest = false;
        setState({ ...state });

        executeResetMFA();
    }

    const useDefProjChanged = (e: CheckboxChangeEvent) => {

        state.useDefaultProject = e.value;
        setState({ ...state });

    }

    const forgotPwdClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (state.showForgotPwd) {

            state.showForgotPwd = false;
            state.resetCode = '';
            state.statusMessage = '';
            setState({ ...state });

        } else {

            state.showForgotPwd = true;
            state.password = '';
            state.resetCode = '';
            state.passwordChk = '';
            state.statusMessage = '';
            setState({ ...state });

        }
    }

    const sendResetCodeClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        state.statusMessage = '';
        setState({ ...state });

        let url = process.env.REACT_APP_LOGIN_URL + 'api/RequestResetCode';

        let body = {
            username: state.userName,
        };

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        state.requestSent = true;

                        break;

                    default:

                        state.statusMessage = 'Reset code request failed : ' + res.callStatusMessage;

                };
                setState({ ...state });

            })
            .catch(err => {

                state.statusMessage = 'Error : ' + err.toString();
                state.loginBtnEnabled = true;
                setState({ ...state });

            });
    }

    const resendMFACodeClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        state.statusMessage = '';
        setState({ ...state });

        let url = process.env.REACT_APP_LOGIN_URL + 'api/ResendMFACode';

        let body = {
            username: state.userName,
        };

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        break;

                    default:

                        state.statusMessage = 'Resend code request failed : ' + res.callStatusMessage;
                        setState({ ...state });

                }
            })
            .catch(err => {
                state.statusMessage = 'Error : ' + err.toString();
                state.loginBtnEnabled = true;
                setState({ ...state });
            });
    }

    const executeMSALLogin = (username: string) => {
        let url = process.env.REACT_APP_LOGIN_URL + 'api/MSALLogin';

        let body = {
            username: username,
        };

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.accessToken } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let uinf: UserInfo = { ...res.result };

                        uinf.locale = 'en-AU';

                        if (typeof invokeSetDefaultUserAction !== 'undefined') {
                            invokeSetDefaultUserAction(uinf.username);
                        }

                        if (uinf.userProjects.length > 1) {


                            uinf.userProjects.map((project) =>
                                project.projectId === state.usrInf.currProject.projectId
                                    ? { ...project, selected: true }
                                    : { ...project, selected: false }
                            )

                            showProjectSelect(uinf);
                            state.selectProjectBtnEnabled = true;
                            state.userName = uinf.username;
                        } else {
                            props.updateUserInfo(uinf);
                            if (props.onSuccess) props.onSuccess(uinf);
                        }

                        break;

                    case "CHALLENGE":

                        state.showMFARequest = true;

                        break;

                    case "NO_SSO":
                        //force standard login
                        state.statusMessage = "";
                        state.forceTcBLogin = true;
                        state.loginBtnEnabled = true;

                        break;

                    default:

                        alert(res.callStatusMessage);
                        state.statusMessage = "";
                        state.forceTcBLogin = true;
                        state.loginBtnEnabled = true;

                };
                setState({ ...state });
            })
            .catch(err => {
                state.statusMessage = 'TracBASE is currently unavailable.  This may be due to system maintenance, or a connectivity problem.  If you have not been made aware of an outage then please contact your system administrator.';
                state.loginBtnEnabled = true;
                state.forceTcBLogin = true;
                setState({ ...state });
            });
    }


    const executeLogin = () => {
        let url = process.env.REACT_APP_LOGIN_URL + 'api/Login';

        let body = {
            username: state.userName,
            password: state.password,
            useDefPrj: state.useDefaultProject,
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let uinf: UserInfo = { ...res.result };

                        uinf.locale = 'en-AU';

                        if (typeof invokeSetDefaultUserAction !== 'undefined') {
                            invokeSetDefaultUserAction(uinf.username);
                        }

                        if (!state.useDefaultProject || !uinf.currProject) {
                            uinf.userProjects.map((project) =>
                                project.projectId === state.usrInf.currProject.projectId
                                    ? { ...project, selected: true }
                                    : { ...project, selected: false }
                            )
                            showProjectSelect(uinf);
                            state.selectProjectBtnEnabled = (uinf.currProject !== null)
                        } else {
                            props.updateUserInfo(uinf);
                            if (props.onSuccess) props.onSuccess(uinf);
                        }

                        break;

                    case "CHALLENGE":

                        state.showMFARequest = true;

                        break;

                    default:

                        state.statusMessage = 'Login failed : ' + res.callStatusMessage;
                        state.loginBtnEnabled = true;

                };
                setState({ ...state });
            })
            .catch(err => {
                state.statusMessage = 'Unable to log in.  This may be due to system maintenance, or a connectivity problem.  If you have not been made aware of an outage then please contact your system administrator.';
                state.loginBtnEnabled = true;
                setState({ ...state });
            });
    }

    const showProjectSelect = (uinf: UserInfo) => {

        state.usrInf = uinf;
        state.showProjectSelect = true;
        state.statusMessage = '';
        setState({ ...state });

    }

    const executeResetLogin = () => {
        let url = process.env.REACT_APP_LOGIN_URL + 'api/LoginReset';

        let body = {
            username: state.userName,
            password: state.passwordNew,
            resetCode: state.resetCode
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let uinf: UserInfo = { ...res.result };
                        props.updateUserInfo(uinf);
                        break;

                    default:

                        state.statusMessage = 'Reset failed : ' + res.callStatusMessage;
                        state.loginBtnEnabled = true;
                        setState({ ...state });

                }
            })
            .catch(err => {
                state.statusMessage = 'Error : ' + err.toString();
                state.loginBtnEnabled = true;
                setState({ ...state });
            });
    }

    const executeResetMFA = () => {
        let url = process.env.REACT_APP_LOGIN_URL + 'api/MFAReset';

        let body = {
            username: state.userName,
            resetCode: state.mFACode
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let uinf: UserInfo = { ...res.result };
                        props.updateUserInfo(uinf);
                        if (props.onSuccess) props.onSuccess(uinf);
                        break;

                    default:
                        state.statusMessage = 'Reset failed : ' + res.callStatusMessage;
                        state.loginBtnEnabled = true;
                        setState({ ...state });

                }
            })
            .catch(err => {
                state.statusMessage = 'Error : ' + err.toString();
                state.loginBtnEnabled = true;
                setState({ ...state });
            });
    }

    const renderListItem = (e: any) => {
        let data = e.dataItem;
        return (<div>{data.projectName}</div>);
    }

    const selectProjectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        let uinf = state.usrInf;
        //let sel = uinf.userProjects.find(x => x.selected === true);
        //if (sel) {

        let url = process.env.REACT_APP_LOGIN_URL + 'api/ChangeUserProject';

        let body = {
            username: state.userName,
            projectId: uinf.currProject.projectId,
            setDefault: state.setDefaultProject,
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + uinf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let uinf: UserInfo = { ...res.result };
                        uinf.locale = 'en-AU';

                        if (uinf.currProject) {
                            props.updateUserInfo(uinf);
                            if (props.onSuccess) props.onSuccess(uinf);

                        } else {
                            showProjectSelect(uinf);
                        }

                        break;

                    case "CHALLENGE":

                        state.showMFARequest = true;
                        setState({ ...state });

                        break;


                    default:

                        state.statusMessage = 'Select Project failed : ' + res.callStatusMessage;
                        state.loginBtnEnabled = true;
                        setState({ ...state });

                }
            })
            .catch(err => {
                state.statusMessage = 'Error SELECT PROJ : ' + err.toString();
                state.loginBtnEnabled = true;
                setState({ ...state });
            });
        //}
    }

    const cancelSelectProjectClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        state.usrInf = new UserInfo();
        state.loginBtnEnabled = true;
        state.showProjectSelect = false;
        state.selectProjectBtnEnabled = false;
        state.statusMessage = '';
        setState({ ...state });

    }

    const setDefProjectChange = (e: CheckboxChangeEvent) => {

        state.setDefaultProject = !state.setDefaultProject;
        setState({ ...state });

    }

    const setProjectSelected = (e: GridRowClickEvent) => {
        let prj = state.usrInf;

        prj.userProjects.forEach((x) => { x.selected = false });
        let sel = prj.userProjects.find(x => x.projectId === e.dataItem.projectId)!;
        sel.selected = true;
        prj.currProject = sel;
        state.usrInf = prj;
        state.selectProjectBtnEnabled = true;
        setState({ ...state });

    }

    const setDefaultUser = (userName: string) => {

        state.userName = userName;
        state.password = '';
        setState({ ...state });

    }


    const openMobileSettings = () => {
        if (typeof invokeOpenSettingsAction !== 'undefined') {
            invokeOpenSettingsAction();
        } else {
            alert("invokeOpenSettingsAction is not defined");
        }
    }



    const handleLogout = () => {
        instance.logoutPopup()
            .then(() => {
                console.log("User successfully logged out");
            })
            .catch(error => {
                console.error("Logout error:", error);
            });
    };


    return (
        <MsalProvider instance={instance}>
            <Dialog id="loginDlg" className="loginDlg" >

                <table id="loginTbl">
                    <tbody>
                        {(state.showForgotPwd) &&

                            <tr className="loginHdr">
                                <th colSpan={2}>Reset Password</th>
                            </tr>
                        }
                        {(state.showForgotPwd) &&
                            <tr>
                                {(!state.requestSent) &&
                                    <td colSpan={2} className="resetPwdTxt">
                                        <p><Button fillMode={"outline"} className="sendResetBtn" onClick={sendResetCodeClick}>Click here</Button> to send a reset code to your e-mail account.  </p>
                                        <p>Enter the reset code and a new password in the boxes below.</p>
                                        <p></p>
                                    </td>
                                }
                                {(state.requestSent) &&
                                    <td colSpan={2} className="resetPwdTxt">
                                        <p>An e-mail has been sent to your account.</p>
                                        <p>Enter the reset code and a new password in the boxes below.</p>
                                        <p><Button fillMode={"outline"} className="sendResetBtn" onClick={sendResetCodeClick}>Click here</Button> to request the reset code again.  </p>
                                    </td>
                                }
                            </tr>
                        }
                        {(state.showMFARequest) &&
                            <tr className="loginHdr">
                                <th colSpan={2}>Login Confirmation</th>
                            </tr>
                        }
                        {(state.showMFARequest) &&
                            <tr>
                                <td colSpan={2} className="resetPwdTxt">
                                    <p>An e-mail has been sent to your account.</p>
                                    <p>Enter the code from the email in the box below.</p>
                                    <p><Button fillMode={"outline"} className="sendResetBtn" onClick={resendMFACodeClick}>Click here</Button> to request the code again.  </p>
                                </td>

                            </tr>
                        }
                        {(!state.showMFARequest) &&
                            <tr className="loginHdr">
                                <th colSpan={2}>
                                    <table cellPadding="0" cellSpacing="0" >
                                        <tbody><tr>
                                            <td ></td>
                                            <th>Log In</th>
                                            <td>
                                                {props.pageInf.pageViewMode !== PageViewTypeEnum.Browser &&
                                                    <Button icon="gear" onClick={() => openMobileSettings()} title="Open Mobile App Settings" />
                                                }
                                            </td>
                                        </tr></tbody>
                                    </table>
                                </th>
                            </tr>
                        }
                        {(state.forceTcBLogin || (!state.showMFARequest && state.accessToken === "")) &&
                            <tr>
                                <td className="loginLabelCell">Username : </td>
                                <td className="loginInputCell"><Input className="loginInput" id="loginUsername"  placeholder="Enter Username" required={true} disabled={state.showProjectSelect} onChange={userNameChanged} value={state.userName} defaultValue={state.defaultUser} />  </td>
                            </tr>
                        }
                        {(state.forceTcBLogin || (!state.showForgotPwd && !state.showMFARequest && state.accessToken === "")) &&
                            <tr>
                                <td className="loginLabelCell">Password : </td>
                                <td><Input className="loginInput" type="password" placeholder="Enter Password" required={true} disabled={state.showProjectSelect} onChange={passwordChanged} value={state.password} onKeyDown={keyDownAction} />  </td>
                            </tr>
                        }
                        {(state.showForgotPwd && !state.showMFARequest && state.accessToken === "") &&
                            <tr>
                                <td className="loginLabelCell">New Password : </td>
                                <td><Input className="loginInput" type="password" placeholder="Enter Password" valid={state.passwordNewValid} title={state.passwordNewMsg} onChange={passwordNewChanged} value={state.passwordNew} />  </td>
                            </tr>
                        }
                        {(state.showForgotPwd && !state.showMFARequest && state.accessToken === "") &&
                            <tr>
                                <td className="loginLabelCell">Check Password : </td>
                                <td><Input className="loginInput" type="password" placeholder="Verify Password" valid={state.passwordChkValid} title={state.passwordChkMsg} onChange={passwordChkChanged} value={state.passwordChk} />  </td>
                            </tr>
                        }
                        {(state.showForgotPwd && !state.showMFARequest && state.accessToken === "") &&
                            <tr>
                                <td className="loginLabelCell">Reset Code : </td>
                                <td><Input className="loginInput" placeholder="Enter Reset Code" required={true} onChange={resetCodeChanged} />  </td>
                            </tr>
                        }
                        {(!state.showForgotPwd && state.showMFARequest) && //state.accessToken === "") &&
                            <tr>
                                <td className="loginLabelCell">Code : </td>
                                <td><Input className="loginInput" placeholder="Enter Code" required={true} onChange={mFACodeChanged} />  </td>
                            </tr>
                        }

                        {(state.forceTcBLogin || (!state.showForgotPwd && !state.showMFARequest && state.accessToken === "")) &&
                            <tr>
                                {/* <td colSpan={2} className="loginBtnCell"><Button fillMode={"outline"} className="loginBtn" disabled={!state.loginBtnEnabled} onClick={loginClick} >Log In</Button></td> */}
                                <td colSpan={2} className="loginBtnCell"><Button fillMode={"outline"} className="loginBtn" disabled={!state.loginBtnEnabled} onClick={loginClick} >Log In</Button></td>
                            </tr>
                        }
                        {(state.showForgotPwd && !state.showMFARequest && state.accessToken === "") &&
                            <tr>
                                <td colSpan={2} className="loginBtnCell"><Button fillMode={"outline"} className="loginBtn" disabled={!state.loginResetBtnEnabled} onClick={loginResetClick} >Reset Password / Log In</Button></td>
                            </tr>
                        }
                        {(!state.showForgotPwd && state.showMFARequest) && //state.accessToken === "") &&
                            <tr>
                                <td colSpan={2} className="loginBtnCell"><Button fillMode={"outline"} className="loginBtn" disabled={!state.loginResetBtnEnabled} onClick={mFAResetClick} >Log In</Button></td>
                            </tr>
                        }
                        {(state.forceTcBLogin || (!state.showProjectSelect && !state.showMFARequest && state.accessToken === "")) &&
                            <tr>
                                <td colSpan={2}>
                                    <table className="optionsTbl">
                                        <tbody>
                                            <tr>
                                                <td><Checkbox className="defProjCbx" onChange={useDefProjChanged} value={state.useDefaultProject} /></td>
                                                <td>Use Default Project </td>
                                                <td align="right" ><Button fillMode={"flat"} className="forgotPwdButton" disabled={state.showProjectSelect} onClick={forgotPwdClick}>Forgot Password</Button> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        {(state.showProjectSelect && !state.showMFARequest) &&
                            <tr className="loginHdr">
                                <th colSpan={2}>Select Project</th>
                            </tr>
                        }
                        {(state.showProjectSelect && !state.showMFARequest) &&

                            <tr>
                                <td colSpan={2} >
                                    <Grid
                                        data={state.usrInf.userProjects.map((project) =>
                                            project.projectId === state.usrInf.currProject.projectId
                                                ? { ...project, selected: true }
                                                : { ...project, selected: false }
                                        )}
                                        selectedField="selected"
                                        selectable={{
                                            enabled: true,
                                            drag: true,
                                            cell: false,
                                            mode: "single",
                                        }}

                                        onRowClick={setProjectSelected}
                                    >
                                        <GridColumn field="projectName" headerCell={(e) => null} />
                                    </Grid>
                                </td>
                            </tr>
                        }
                        {(state.showProjectSelect && !state.showMFARequest) &&
                            <tr>
                                <td colSpan={2}>
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td className="loginBtnCell" ><Button fillMode={"outline"} disabled={!state.selectProjectBtnEnabled} onClick={selectProjectClick} >Continue</Button></td>

                                                {(state.accessToken === "") &&
                                                    <td className="loginBtnCell" ><Button fillMode={"outline"} onClick={cancelSelectProjectClick} >Cancel</Button></td>
                                                }
                                            </tr>
                                            <tr>
                                                <td colSpan={2} align="center" >
                                                    <Checkbox className="defProjCbx" defaultChecked={false} onChange={setDefProjectChange} /> Set as Default Project
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        {(state.statusMessage !== '') &&
                            <tr>
                                <td colSpan={2} className="loginMsgCell">{state.statusMessage}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </Dialog>
        </MsalProvider>
    )
}


export default connector(Login);
