import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserInfo, TcbObjMilestoneGrp, TcbObjMilestone, TcbObjInfo, TcbFileItem } from '../../models/models';
import FileViewerDlg from '../Dialogs/FileViewerDlg';
import { loadingDiv } from '../../functions/componentFunctions';
//import { addPageViewClass } from '../../functions/generalFunctions'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';

import { Grid, GridColumn, GridExpandChangeEvent, GridHeaderCellProps, GridDetailRowProps, GridCellProps } from '@progress/kendo-react-grid';

//import inProgressLogo24 from '../../images/loading-24.gif';
import inProgressLogo64 from '../../images/loading-64.gif';

import './TcbObjMilestones.css';
import { FileDisplayModeEnum, FileDialogModeEnum, PageViewTypeEnum, TcbFileLoadStatusEnum } from '../../models/enums';

import moment from 'moment'
import MapDlg from '../Dialogs/MapDlg';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TcbObjMilestonesProps = PropsFromRedux & {
    tcbObj: TcbObjInfo;
    stateToLoad?: TcbObjMilestonesState;
    onSaveState: (e: TcbObjMilestonesState) => void;
}

type TcbObjMilestonesState = {
    milestonesFetched: boolean;
    expandAllExpanded: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;
    fetchInProg: boolean;
    // fetchingImages: boolean;
    // fetchingLines: boolean;
    showImagePreview: boolean;
    imagePreviewList: TcbFileItem[];
    selectedPreviewImage?: TcbFileItem;
    showMapDlg: boolean;
}

function TcbObjMilestones(props: TcbObjMilestonesProps) {

    const [data, setData] = useState<Array<TcbObjMilestoneGrp>>([]);

    const [state, setState] = useState<TcbObjMilestonesState>({
        milestonesFetched: false,
        expandAllExpanded: false,
        fetchError: false,
        fetchErrorMsg: '',
        fetchInProg: false,
        // fetchingImages: false,
        // fetchingLines: false,
        showImagePreview: false,
        imagePreviewList: [],
        showMapDlg: false
    });


    useEffect(() => {

        setState((prevState) => ({
            ...prevState,
            fetchErrorMsg: 'FetchInProg',
            fetchInProg: true,
        }));


        let url = props.userInf.currProject.apiUrl + '/api/details/GetTcbObjMilestones';

        fetch(url, { method: 'POST', body: JSON.stringify(props.tcbObj), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let ml: Array<TcbObjMilestoneGrp> = res.results;

                        ml.forEach(g => {
                            g.latestMilestoneDate = new Date(g.latestMilestoneDate);
                            g.milestoneItems.forEach(x => {
                                x.milestoneDate = new Date(x.milestoneDate);
                                if (x.lastChangedOn) x.lastChangedOn = new Date(x.lastChangedOn);
                                x.sourcePathStr = '';
                                x.sourcePaths.forEach(y => {
                                    x.sourcePathStr += y.pathDesc + ' ';
                                });
                                x.isExpanded = false;
                                x.milestoneLinesFetched = false;
                                x.milestoneImgsFetched = false;
                            });
                        });

                        // setData((prevData) => ({
                        //     ...prevData,
                        //     ml
                        // }));

                        setData(ml);

                        setState((prevState) => ({
                            ...prevState,
                            milestonesFetched: true,
                            fetchInProg: false,
                        }));

                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...props.userInf, isAuthorised: false };
                        props.updateUserInfo(uInf);

                        setState((prevState) => ({
                            ...prevState,
                            fetchInProg: false,
                        }));

                        break;

                    default:
                        setState((prevState) => ({
                            ...prevState,
                            fetchInProg: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage,
                        }));

                }
            })
            .catch(err => {

                setState((prevState) => ({
                    ...prevState,
                    fetchInProg: false,
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString(),
                }));

            });
    }, []);

    const getMilestoneLines = (m: TcbObjMilestone) => {
        let url = props.userInf.currProject.apiUrl + '/api/details/GetTcbObjMilestoneLines';

        let body = {
            tcbObjMilestoneId: m.tcbObjMilestoneId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        let newData = data.map((mlg) => {
                            if (mlg.milestoneId === m.milestoneId) {
                                let ml = mlg.milestoneItems.find(x => x.tcbObjMilestoneId === m.tcbObjMilestoneId);
                                if (ml) {
                                    ml.milestoneLines = res.results;
                                    ml.milestoneLinesFetched = true;
                                }
                            }
                            return mlg;

                        });


                        setData(newData);

                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...props.userInf, isAuthorised: false };
                        props.updateUserInfo(uInf);

                        setState((prevState) => ({
                            ...prevState,
                            fetchInProg: false,
                        }));

                        break;

                    default:
                }
            })
            .catch(err => {
            });
    }

    const getMilestoneImages = (m: TcbObjMilestone) => {
        let url = props.userInf.currProject.apiUrl + '/api/details/GetTcbObjMilestoneImages';

        let body = {
            tcbObjMilestoneId: m.tcbObjMilestoneId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        let newData = data.map((mlg) => {
                            if (mlg.milestoneId === m.milestoneId) {
                                let ml = mlg.milestoneItems.find(x => x.tcbObjMilestoneId === m.tcbObjMilestoneId);
                                if (ml) {
                                    ml.milestoneImgs = res.results;
                                    ml.milestoneImgs.forEach(x => { x.fileLoadStatus = TcbFileLoadStatusEnum.NotLoaded });
                                    ml.milestoneImgsFetched = true;
                                }

                            }

                            return mlg;
                        });

                        setData(newData);

                        if (!m.milestoneLinesFetched) {
                            getMilestoneLines(m);
                        }

                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...props.userInf, isAuthorised: false };
                        props.updateUserInfo(uInf);

                        setState((prevState) => ({
                            ...prevState,
                            fetchInProg: false,
                        }));
                        break;

                    default:
                }
            })
            .catch(err => {
            });
    }

    const expandAllToggle = () => {

    }

    const expandGrpChange = (event: GridExpandChangeEvent) => {
        let newData = data.map((item: TcbObjMilestoneGrp) => {
            if (item.milestoneId === event.dataItem.milestoneId) {
                item.isExpanded = !event.dataItem.isExpanded;
            }
            return item;
        });


        setData(newData);
    }

    const expandChange = (event: GridExpandChangeEvent) => {
        let dr: TcbObjMilestone = event.dataItem;

        let newData = data.map((grp: TcbObjMilestoneGrp) => {

            let mi = grp.milestoneItems.find(x => x.tcbObjMilestoneId === dr.tcbObjMilestoneId);
            if (mi) {
                mi.isExpanded = !event.dataItem.isExpanded;

                if (!mi.milestoneLinesFetched)
                    getMilestoneLines(mi);

                if (!mi.milestoneImgsFetched)
                    getMilestoneImages(mi);
            }

            return grp;
        });

        setData(newData);

    }

    const openImagePreview = (l: TcbFileItem[], i: TcbFileItem) => {
        setState({ ...state, showImagePreview: true, imagePreviewList: l, selectedPreviewImage: i });
    }

    const closeImagePreview = () => {
        setState({ ...state, showImagePreview: false, imagePreviewList: [], selectedPreviewImage: undefined });
    }

    const mapImageClick = () => {
        setState((prevState) => ({
            ...prevState,
            showMapDlg: true
        }));

    }

    const mapImageClose = () => {
        setState((prevState) => ({
            ...prevState,
            showMapDlg: false
        }));

    }
    //Render Functions

    const renderGrpHeaderCell = (n: React.ReactNode, e: GridHeaderCellProps) => {
        switch (e.field) {
            case "isExpanded":
                let cn = "k-icon k-font-icon k-plus";
                if (state.expandAllExpanded)
                    cn = "k-icon k-font-icon k-minus";
                return (<span className={cn + " tcbObjMilestoneExpandHeader"} onClick={() => expandAllToggle()} />);

            case "imgQty":
                return (<span className="k-icon k-font-icon k-i-photo-camera" />)

            default:
                return (e.title);

        }
    }

    const renderHeaderCell = (n: React.ReactNode, e: GridHeaderCellProps) => {
        switch (e.field) {
            case "isExpanded":
                let cn = "k-icon k-font-icon k-plus";
                if (state.expandAllExpanded)
                    cn = "k-icon k-font-icon k-minus";
                return (<span className={cn + " tcbObjMilestoneExpandHeader"} onClick={() => expandAllToggle()} />);

            case "imgQty":
                return (<span className="k-icon k-font-icon k-i-photo-camera" />)

            default:
                return (e.title);

        }
    }


    const renderDataCell = (e: any, p: GridCellProps) => {
        let dataItem: TcbObjMilestone = p.dataItem;

        if (p.field === "gpsCoordinates") {
            return (
                <>
                    <td className="TcbObjMilestoneColGPS" onClick={(e) => mapImageClick()}>{dataItem.gpsCoordinates}</td>

                    {state.showMapDlg && <MapDlg location={dataItem.gpsCoordinates} onClose={() => mapImageClose()} />}
                </>

            )
        }

        return e;
    }

    const renderMilestoneDetails = (e: GridDetailRowProps) => {

        let grp: TcbObjMilestoneGrp = e.dataItem;

        let formatStr = "{0:dd-MMM-yy HH:mm }"
        let msFormatStr = formatStr;

        if (!grp.includeTime) {
            msFormatStr = "{0:dd-MMM-yy }";
        }

        if (props.pageInf.pageViewMode !== PageViewTypeEnum.Mobile) {

            if (grp.includeTime) {
                formatStr = "{0:dd-MMM-yyyy HH:mm }";
                msFormatStr = formatStr;
            } else {
                msFormatStr = "{0:dd-MMM-yyyy }";
            }

            return (
                <Grid
                    className="tcbObjMilestoneGrid"
                    data={grp.milestoneItems}
                    detail={renderMilestoneItemDetails}
                    expandField="isExpanded"
                    onExpandChange={expandChange}
                    headerCellRender={renderHeaderCell}
                    cellRender={renderDataCell}
                    resizable
                >
                    <GridColumn field="milestoneDate" title="Date" format={msFormatStr} className="TcbObjMilestoneGridMainCell" />
                    <GridColumn field="quantity" title="Qty" className="TcbObjMilestoneGridCell" />
                    <GridColumn field="gpsCoordinates" title="GPS Loc" className="TcbObjMilestoneGridCell" />
                    <GridColumn field="sourceName" title="Source" className="TcbObjMilestoneGridCell" />
                    <GridColumn field="sourcePathStr" title="Updated In" className="TcbObjMilestoneGridCell" />
                    <GridColumn field="lastChangedOn" title="Updated On" width="180px" format={formatStr} className="TcbObjMilestoneGridCell" />
                    <GridColumn field="lastChangedBy" title="Changed By" width="120px" className="TcbObjMilestoneGridCell" />
                    <GridColumn field="imageQty" title="Imgs" width="45px" className="TcbObjMilestoneGridCell" />

                </Grid>
            );
        } else {
            return (
                <Grid
                    className="tcbObjMilestoneGrid"
                    data={grp.milestoneItems}
                    detail={renderMilestoneItemDetails}
                    expandField="isExpanded"
                    onExpandChange={expandChange}
                    headerCellRender={renderHeaderCell}
                    //cellRender={renderDataCell}
                    resizable
                >
                    <GridColumn field="milestoneDate" title="Date" format={msFormatStr} className="TcbObjMilestoneGridMainCell" />
                    <GridColumn field="quantity" title="Qty" className="TcbObjMilestoneGridCell" />
                </Grid>
            );
        }
    }


    const renderMilestoneItemDetails = (e: GridDetailRowProps) => {
        let dr: TcbObjMilestone = e.dataItem;

        if (!dr.milestoneLinesFetched && !dr.milestoneImgsFetched)
            return (<div className="tcbObjMilestoneLinesInProg"><img src={inProgressLogo64} alt="In Progress" /></div>);
        else {
            let mslJsx: JSX.Element;
            if (dr.milestoneLinesFetched) {
                mslJsx = <table key={dr.tcbObjMilestoneId} className="tcbObjMilestoneLinesTable">
                    <tbody>
                        {dr.milestoneLines.map(x => {
                            return (<tr key={x.milestoneLineId}>
                                <th>{x.milestoneLineDesc}</th>
                                <td>{x.answer}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            } else {
                mslJsx = <div key={dr.tcbObjMilestoneId} className="tcbObjMilestoneLinesInProg"><img src={inProgressLogo64} alt="In Progress" /></div>;
            }

            let msiJsx: JSX.Element;
            if (dr.milestoneImgsFetched) {
                msiJsx = <div className="tcbObjMilestoneLinesDiv">
                    {dr.milestoneImgs.map((x, i) => {
                        return (<img key={i} src={'data:image/jpeg;base64,' + x.thumbnailStr} alt={x.fileName} onClick={() => openImagePreview(dr.milestoneImgs, x)} />);
                    })}
                </div>;
            } else {
                msiJsx = <div className="tcbObjMilestoneLinesInProg"><img src={inProgressLogo64} alt="In Progress" /></div>;
            }

            return (<div>
                {mslJsx}
                {msiJsx}
                {(state.showImagePreview && state.selectedPreviewImage) &&
                    <FileViewerDlg display={FileDisplayModeEnum.Images} dialogMode={FileDialogModeEnum.View} tcbObj={props.tcbObj} file={state.selectedPreviewImage} fileList={state.imagePreviewList} onClose={closeImagePreview} />}
            </div>);

        }
    }

    const latestMilestoneDateCell = (e: GridCellProps) => {
        let grp: TcbObjMilestoneGrp = e.dataItem;

        let msFormatStr = "DD-MMM-yy HH:mm"

        if (!grp.includeTime) {
            msFormatStr = "DD-MMM-yy";
        }

        if (props.pageInf.pageViewMode !== PageViewTypeEnum.Mobile) {

            if (grp.includeTime) {
                msFormatStr = "DD-MMM-yyyy HH:mm ";
            } else {
                msFormatStr = "DD-MMM-yyyy";
            }
        }

        var strDate = moment(grp.latestMilestoneDate).format(msFormatStr);

        return (
            <td className="TcbObjMilestoneGridCell">
                {strDate}
            </td>
        );
    }



    if (state.fetchInProg)
        return loadingDiv();

    let formatStr = "{0:dd-MMM-yy }"
    if (props.pageInf.pageViewMode !== PageViewTypeEnum.Mobile) {
        formatStr = "{0:dd-MMM-yyyy }";

        return (
            <Grid
                className="tcbObjMilestoneGrpGrid"
                data={data}
                detail={renderMilestoneDetails}
                expandField="isExpanded"
                onExpandChange={expandGrpChange}
                headerCellRender={renderGrpHeaderCell}
                resizable
            >
                <GridColumn field="milestoneDesc" title="Milestone" className="TcbObjMilestoneGridMainCell" />
                <GridColumn field="latestMilestoneDate" title="Latest" width="180px" cell={latestMilestoneDateCell} />
                <GridColumn field="latestSource" title="Source" className="TcbObjMilestoneGridCell" />
            </Grid>
        );
    }
    else {
        return (
            <Grid
                className="tcbObjMilestoneGrpGrid"
                data={data}
                detail={renderMilestoneDetails}
                expandField="isExpanded"
                onExpandChange={expandGrpChange}
                headerCellRender={renderGrpHeaderCell}
                resizable
            >
                <GridColumn field="milestoneDesc" title="Milestone" className="TcbObjMilestoneGridMainCell" />
                <GridColumn field="latestMilestoneDate" title="Latest" format={formatStr} width="90px" className="TcbObjMilestoneGridCell" />
                <GridColumn field="latestSource" title="Source" width="120px" className="TcbObjMilestoneGridCell" />
            </Grid>
        );
    }
}


export default connector(TcbObjMilestones);

