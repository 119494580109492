import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../redux/reduxActions';
import { DropDownList, DropDownListFilterChangeEvent, DropDownListChangeEvent, DropDownListPageChangeEvent, DropDownListBlurEvent } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ClientQueryLookupData } from '../../../models/fpcModel';

import './DropDownListCtl.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type DropDownListCtlProps = PropsFromRedux & {
    authToken?: string,
    lookupSql?: string,
    connectionId?: string,
    lookupData?: Array<ClientQueryLookupData>,
    value?: string,
    filterable?: boolean,
    width?: string,
    onSelectChange: (dataVal: string) => void;
    onCancelChange: () => void;
}

interface DropDownListCtlState {
    allData: Array<ClientQueryLookupData>;
    filteredData: Array<ClientQueryLookupData>;
    filteredViewData: Array<ClientQueryLookupData>;
    filteredViewSkip: number;
    filteredViewTotal: number;
    isLoading: boolean;
    ddlValue?: ClientQueryLookupData;
}

const delay = 500;
const pageSize = 10;

class DropDownListCtl extends React.Component<DropDownListCtlProps, DropDownListCtlState> {
    constructor(props: DropDownListCtlProps) {
        super(props);


        if (props.lookupData) {

            let defVal: ClientQueryLookupData | undefined;
            if (this.props.value) {
                defVal = props.lookupData.find(x => x.value === this.props.value?.toString());
            }

            if (defVal) {
                if (props.lookupData.length <= pageSize) {
                    //show all data
                    this.state = {
                        allData: props.lookupData,
                        filteredData: props.lookupData,
                        filteredViewData: props.lookupData.slice(0, pageSize),
                        filteredViewSkip: 0,
                        filteredViewTotal: props.lookupData.length + 1,
                        isLoading: false,
                        ddlValue: defVal
                    }
    
                } else {
                    let rowIdx = props.lookupData.findIndex(x => x.value === this.props.value?.toString());

                    if (rowIdx + pageSize <= props.lookupData.length) {
                        //show data starting from selected row
                        this.state = {
                            allData: props.lookupData,
                            filteredData: props.lookupData,
                            filteredViewData: props.lookupData.slice(rowIdx, rowIdx + pageSize),
                            filteredViewSkip: rowIdx,
                            filteredViewTotal: props.lookupData.length + 1,
                            isLoading: false,
                            ddlValue: defVal
                        }        
                    } else {
                        //show last "pagesize" rows, since this will include the selected row
                        this.state = {
                            allData: props.lookupData,
                            filteredData: props.lookupData,
                            filteredViewData: props.lookupData.slice(props.lookupData.length - pageSize, props.lookupData.length),
                            filteredViewSkip: props.lookupData.length - pageSize,
                            filteredViewTotal: props.lookupData.length + 1,
                            isLoading: false,
                            ddlValue: defVal
                        }        
                    }

                }
            } else {
                this.state = {
                    allData: props.lookupData,
                    filteredData: props.lookupData,
                    filteredViewData: props.lookupData.slice(0, pageSize),
                    filteredViewSkip: 0,
                    filteredViewTotal: props.lookupData.length + 1,
                    isLoading: false
                }
            }
        } else {
            this.state = {
                allData: [],
                filteredData: [],
                filteredViewData: [],
                filteredViewSkip: 0,
                filteredViewTotal: 0,
                isLoading: true,
            }

            if (props.lookupSql)
                this.loadData(props.connectionId, props.lookupSql)
        }

    };

    loadData = (connectionId?: string, sql?: string) => {

        let url = this.props.userInf.currProject.apiUrl + '/api/Fpc/GetCqLookupData';
        let body = {
            lookupSQL: sql,
            connectionId: connectionId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.authToken } })
            .then(this.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    alert('Error loading select list:' + res.callStatusMessage);
                    this.setState({ isLoading: false });
                } else {
                    const tmp: Array<ClientQueryLookupData> = res.results;

                    let defVal: ClientQueryLookupData | undefined;
                    if (this.props.value) {
                        defVal = tmp.find(x => x.value.toLowerCase() === this.props.value?.toLowerCase());
                    }


                    this.setState({
                        allData: tmp,
                        filteredData: tmp,
                        filteredViewData: tmp.slice(0, pageSize),
                        filteredViewSkip: 0,
                        filteredViewTotal: tmp.length + 1,
                        isLoading: false,
                        // ddlWidth: newWidth,
                        ddlValue: defVal
                    });
                }
            })
            .catch(err => {
                alert('Error loading select list:' + err.toString());
                this.setState({ isLoading: false });
            });
    }

    HandleErrors(response: any) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    private filterTimeout: any
    filterChange = (event: DropDownListFilterChangeEvent) => {
        clearTimeout(this.filterTimeout);

        const tmpData = this.filterData(event.filter);
        this.filterTimeout = setTimeout(() => {
            this.setState({
                filteredData: tmpData,
                filteredViewData: tmpData.slice(0, pageSize),
                filteredViewSkip: 0,
                filteredViewTotal: tmpData.length + 1,
                isLoading: false
            });
        }, delay);

        this.setState({
            isLoading: true
        });
    }

    filterData = (filter: FilterDescriptor): Array<ClientQueryLookupData> => {
        return filterBy(this.state.allData, filter);
    }

    pageChange = (event: DropDownListPageChangeEvent) => {
        const skip = event.page.skip;
        const take = event.page.take;
        const newSubsetData = this.state.filteredData.slice(skip, skip + take);

        this.setState({
            filteredViewData: newSubsetData,
            filteredViewSkip: skip
        });
    }

    // private defVal1: ClientQueryLookupData = new ClientQueryLookupData();

    // setDefDDLValue = () => {
    //     if (this.props.defaultValue) {
    //         const defVal1: ClientQueryLookupData = new ClientQueryLookupData();
    //         defVal1.Value = this.props.defaultValue;
    //     }
    // }

    selectChange = (event: DropDownListChangeEvent) => {
        // this.defVal1 = event.value;
        this.setState({ ddlValue: event.target.value });
        // this.setState({ddlValue: event.value.Value});
        this.props.onSelectChange(event.value.value);
    }

    cancelChange = (event: DropDownListBlurEvent) => {
        this.props.onCancelChange();
    }


    render() {

        let  gs:React.CSSProperties = {};
        if (this.props.width)
            gs.width = this.props.width;
        else
            gs.width = '100%';
            
            // if (this.state.filteredViewTotal > pageSize) {
                return (
                    <DropDownList data={this.state.filteredViewData} dataItemKey="value" textField="display"
                    filterable={this.props.filterable??true}
                    virtual={{ total: this.state.filteredViewTotal, pageSize: pageSize, skip: this.state.filteredViewSkip }}
                    onFilterChange={this.filterChange} onPageChange={this.pageChange}
                    loading={this.state.isLoading}
                    onChange={this.selectChange}
                    // onBlur={this.cancelChange}
                    value={this.state.ddlValue}
                    style={gs}
                />
                )//}
            // else {
            //     return (
                    
            //             <DropDownList data={this.state.filteredViewData} dataItemKey="value" textField="display"
            //             filterable={this.props.filterable??true}
            //             onFilterChange={this.filterChange} 
            //             onPageChange={this.pageChange}
            //             loading={this.state.isLoading}
            //             onChange={this.selectChange}
            //             // onBlur={this.cancelChange}
            //             value={this.state.ddlValue}
            //             style={gs}
            //             />
            //     )
        
            //}
   
    };

}

export default connector(DropDownListCtl);