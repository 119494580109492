import React from 'react';
import { ValidationColumn, FpcModel } from '../../models/models';

import './Error.css';

interface ErrorProps {
    model: FpcModel;
}

interface ErrorState {
    fpc: FpcModel;

}

class Error extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);

        this.state = {
            fpc: props.model
        }
    };


    render() {
        let fpc = this.state.fpc;

        return (
            <div className='centreAlign'>
                <h2>{fpc.ErrorData.ErrorTitle}</h2>

                <br />
                <table className="errorTable">
                    <thead>
                        <tr>
                            {fpc.ErrorData.Columns.map((col: ValidationColumn, index:number) => <th key={index}>{col.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {fpc.ErrorData.Data.map((row, index: number) => <tr key={index}>{fpc.ErrorData.Columns.map((col: ValidationColumn, index: number) => <td key={index}>{row[col.fieldName]}</td>)}</tr>)}
                    </tbody>
                </table>
            </div>
        )
    };

}

export default Error;