import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { PDFViewer, PDFViewerTool } from '@progress/kendo-react-pdf-viewer';

import { UserInfo, TcbFileItem, TcbObjInfo, DropDownListData } from '../../models/models';
import { addPageViewClass } from '../../functions/generalFunctions'
import { downloadFileItem } from '../../functions/componentFunctions';
import FileUploadCtl from '../InputCtls/FileUploadCtl';
import DropDownListCtl from '../InputCtls/DropDownListCtl';
import { Input } from '@progress/kendo-react-inputs'
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { PageViewTypeEnum, TcbFileLoadStatusEnum, FileDialogModeEnum, FileDisplayModeEnum, InputCtlViewMode, InputCtlDdlDataSource } from '../../models/enums';
import { CustomWindow } from '../../models/custom.window'
import moment from 'moment';

import tcbLogoSml from '../../images/Tcb-50.gif';
import './FileViewerDlg.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
declare let window: CustomWindow;

type FileViewerDlgProps = PropsFromRedux & {
    display: FileDisplayModeEnum;
    dialogMode: FileDialogModeEnum;
    tcbObj: TcbObjInfo;
    file?: TcbFileItem;
    fileList?: TcbFileItem[];
    onAdd?: (e: TcbFileItem) => void;
    onUpdate?: (e: TcbFileItem) => void;
    onClose: () => void;
    onDelete?: (e: TcbFileItem[]) => void;
}


interface FileViewerDlgState {
    dialogMode: FileDialogModeEnum;
    fileItem: TcbFileItem;
    saveValid: boolean;
    viewHeight: number;
    viewWidth: number;
    edtFileName: string;
    edtFileDesc: string;
    edtFileRefNo: string;
    edtFileDocTypeId?: number;
}

declare function invokeOpenCameraAction(arg: any): any;

class FileViewerDlg extends React.Component<FileViewerDlgProps, FileViewerDlgState> {

    constructor(props: FileViewerDlgProps) {
        super(props);

        //Camera function needs ref
        window.imgViewerRef = this;

        let fi: TcbFileItem = new TcbFileItem();
        switch (this.props.dialogMode) {
            case FileDialogModeEnum.Add:

                break;

            case FileDialogModeEnum.Delete:
                if (!this.props.file && (!this.props.fileList || this.props.fileList.length === 0)) {
                    alert("File or Filelist required");
                    return;
                }
                if (this.props.file)
                    fi = this.props.file;
                else
                    fi = this.props.fileList![0];
                break;

            default:
                if (!this.props.file) {
                    alert("File required");
                    return;
                }
                fi = this.props.file;
        }

        this.state = {
            dialogMode: this.props.dialogMode,
            fileItem: fi,
            saveValid: this.checkSaveValid(fi.fileName, fi.documentTypeId),
            viewHeight: 200,
            viewWidth: 800,
            edtFileName: fi.fileName,
            edtFileDesc: fi.fileDesc,
            edtFileRefNo: fi.fileRefNo,
            edtFileDocTypeId: fi.documentTypeId,
        };
    }

    componentDidUpdate(prevProps: FileViewerDlgProps) {
    }

    componentDidMount() {
        if (this.state.dialogMode === FileDialogModeEnum.View) {
            let fi = this.state.fileItem;
            if (fi.fileLoadStatus === TcbFileLoadStatusEnum.NotLoaded) {
                fi.fileLoadStatus = TcbFileLoadStatusEnum.InProgress;
                this.setState({ fileItem: fi });
                this.getFileDataStr(fi.fileId, (ds) => {
                    if (ds === '') {
                        fi.fileDataStr = '';
                        fi.fileLoadStatus = TcbFileLoadStatusEnum.Error;
                    } else {
                        fi.fileDataStr = ds;
                        fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
                    }
                    this.setState({ fileItem: fi });
                    if (this.props.onUpdate) this.props.onUpdate(fi);
                });
            }
        }
    }

    componentWillUnmount() {
    }


    getFileDataStr = (fileId: string, callBack?: (dataStr: string) => void) => {

        let url = this.props.userInf.currProject.apiUrl + '/api/file/GetTcbFileBase64';

        let body = {
            fileId: fileId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        if (callBack)
                            callBack(res.stringResponse);
                        break;
                    case "Error":
                        if (callBack)
                            callBack('');
                        break;

                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        break;
                }
            })
            .catch(err => {
            });
    }







    //Action Button events

    dlgActionConfirmDelete = () => {
        let fl = this.state.fileItem;
        fl.fileLoadStatus = TcbFileLoadStatusEnum.Deleting;
        this.setState({ fileItem: fl });
        let tcbObjs: TcbObjInfo[] = [];
        tcbObjs.push(this.props.tcbObj);

        let url = this.props.userInf.currProject.apiUrl + '/api/details/UnLinkFileToTcbObj';

        let body = {
            fileId: fl.fileId,
            tcbObjs: tcbObjs,
            deleteFile: true
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let tmp: TcbFileItem[] = [];
                    tmp.push(fl);
                    if (this.props.onDelete)
                        this.props.onDelete(tmp);
                    this.props.onClose();
                }
            })
            .catch(err => {
                alert(err.toString());
            });

    }



    dlgActionClose = () => {
        if (this.state.fileItem.fileName !== this.state.edtFileName ||
            this.state.fileItem.fileDesc !== this.state.edtFileDesc ||
            this.state.fileItem.fileRefNo !== this.state.edtFileRefNo ||
            this.state.fileItem.documentTypeId !== this.state.edtFileDocTypeId) {
            if (!window.confirm('You have unsaved data... verify you want to close'))
                return;
        }

        if (this.state.dialogMode === FileDialogModeEnum.AddEdit) {
            if (this.state.fileItem.fileId !== '') {
                //File added by FileUploadCtl... clean it up
                let url = this.props.userInf.currProject.apiUrl + '/api/file/DeleteTcbFile';

                let body = {
                    fileId: this.state.fileItem.fileId
                }
                fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                    .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
                    .then(resp => resp.json())
                    .then(res => {
                        if (res.callStatus !== "OK") {
                            throw Error(res.CallStatusMessage);
                        }
                    })
                    .catch(err => {
                        alert(err.toString());
                    });


            }
        }

        this.props.onClose();
    }

    dlgActionDownload = () => {
        let fi = this.state.fileItem;
        if (fi.fileLoadStatus === TcbFileLoadStatusEnum.NotLoaded) {
            this.getFileDataStr(fi.fileId, (ds) => {
                fi.fileDataStr = ds;
                fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
                this.setState({ fileItem: fi });
                downloadFileItem(fi);
            });
        } else {
            downloadFileItem(this.state.fileItem);
        }


    }
    dlgActionDelete = () => {
        this.setState({ dialogMode: FileDialogModeEnum.Delete });
    }
    dlgActionEdit = () => {
        this.setState({ dialogMode: FileDialogModeEnum.Edit });
    }
    dlgActionSave = () => {
        switch (this.state.dialogMode) {
            case FileDialogModeEnum.Edit:
                this.setState({ saveValid: false }, () => {
                    let fi = this.state.fileItem;
                    // if (this.state.edtFileName) fi.fileName = this.state.edtFileName;
                    // if (this.state.edtFileDesc) fi.fileDesc = this.state.edtFileDesc;
                    // if (this.state.edtFileRefNo) fi.fileRefNo = this.state.edtFileRefNo;
                    // if (this.state.edtFileDocTypeId) fi.documentTypeId = this.state.edtFileDocTypeId;
                    this.saveFileDetails(fi.fileId, this.state.edtFileName, this.state.edtFileDesc, this.state.edtFileRefNo, this.state.edtFileDocTypeId, (f) => {
                        if (this.props.onUpdate)
                            this.props.onUpdate(f);
                        this.props.onClose();
                    });
                });
                break;

            case FileDialogModeEnum.AddEdit:
            case FileDialogModeEnum.AddFromCamera:
                this.setState({ saveValid: false }, () => {
                    let fi = this.state.fileItem;
                    if (this.state.edtFileName) fi.fileName = this.state.edtFileName;
                    if (this.state.edtFileDesc) fi.fileDesc = this.state.edtFileDesc;
                    if (this.state.edtFileRefNo) fi.fileRefNo = this.state.edtFileRefNo;
                    if (this.state.edtFileDocTypeId) fi.documentTypeId = this.state.edtFileDocTypeId;

                    //Added files need to be linked to the TcbObj

                    //Files from Camera have no FileId
                    if (fi.fileId === '') {
                        this.saveCameraFile(fi.fileName, fi.fileDataStr, fi.fileDesc, fi.fileRefNo, fi.documentTypeDesc, (f) => {
                            this.linkImageToTcbObj(f.fileId);
                            if (this.props.onAdd) this.props.onAdd(f);
                            this.props.onClose();
                        })
                    } else {
                        //Files from uploadControl are already created... just update details and link
                        this.linkImageToTcbObj(fi.fileId);
                        this.saveFileDetails(fi.fileId, fi.fileName, fi.fileDesc, fi.fileRefNo, fi.documentTypeId, (n) => {
                            n.fileLoadStatus = fi.fileLoadStatus;
                            n.fileDataStr = fi.fileDataStr;
                            n.canEdit = true;
                            n.canDelete = true;
                            if (this.props.onAdd) this.props.onAdd(n);
                            this.props.onClose();
                        });
                    }
                });

                break;
        }
    }


    //Edit Mode Events
    fileNameOnChange = (e: InputChangeEvent) => {
        if (this.state.edtFileName !== e.value) {
            this.setState({ edtFileName: e.value, saveValid: this.checkSaveValid(e.value, this.state.edtFileDocTypeId) });
        }
    }
    fileRefOnChange = (e: InputChangeEvent) => {
        //if (this.state.edtFileRefNo !== e.value) {
            this.setState({ edtFileRefNo: e.value });
        //}
    }
    fileDescOnChange = (e: InputChangeEvent) => {
        if (this.state.edtFileDesc !== e.value) {
            this.setState({ edtFileDesc: e.value });
        }
    }
    fileDocTypeOnChange = (newVal: DropDownListData) => {
        let newId = parseInt(newVal.value);
        if (!this.state.edtFileDocTypeId || this.state.edtFileDocTypeId !== newId)
            this.setState({ edtFileDocTypeId: newId, saveValid: this.checkSaveValid(this.state.edtFileName, newId) });
    }

    checkSaveValid = (fn: string, dtId?: number): boolean => {
        if (fn.length === 0) return false;
        if (!dtId) return false;
        return true;
    }



    openCamera = () => {

        if (typeof invokeOpenCameraAction !== 'undefined') {
            invokeOpenCameraAction('window.imgViewerRef.loadCameraImage');
        }
        else
            alert('invokeOpenCameraAction not defined');

    }

    fileUploadCompleted = (files: UploadFileInfo[]) => {
        let fi = files[0];
        this.getFileDetails(fi.uid, true, (fi) => {
            fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
            fi.canDelete = true;
            fi.canEdit = true;
            //in case they've already updated any fields... check edt values
            let eFn = (this.state.edtFileName !== '') ? this.state.edtFileName : fi.fileName;
            let eFd = (this.state.edtFileDesc !== '') ? this.state.edtFileDesc : fi.fileDesc;
            let eFr = (this.state.edtFileRefNo !== '') ? this.state.edtFileRefNo : fi.fileRefNo;
            let eFt = (this.state.edtFileDocTypeId) ? this.state.edtFileDocTypeId : fi.documentTypeId;
            this.setState({
                fileItem: fi,
                edtFileName: eFn,
                edtFileDesc: eFd,
                edtFileRefNo: eFr,
                edtFileDocTypeId: eFt,
                saveValid: this.checkSaveValid(eFn, eFt), dialogMode: FileDialogModeEnum.AddEdit
            });
        });
    }



    loadCameraImage = (callbackId: string, fileName: string, fileBase64: string) => {

        let fi = this.state.fileItem;
        let ext = fileName.split('.').pop();

        fileName = this.props.tcbObj.tcbObjDesc + "-" + moment().format("YYYYMMDDHHmm") + ext;

        let docType = "Item Photo";
        if (this.props.tcbObj.tcbObjClass === "P") {
            docType = "Packlist Photo"
        }

        fi.fileName = fileName;
        fi.documentTypeDesc = docType;

        if (ext) fi.fileExt = ext;
        fi.fileDataStr = fileBase64;
        fi.isImage = true;
        fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
        fi.canEdit = true;
        fi.canDelete = true;

        this.setState({ fileItem: fi, edtFileName: fi.fileName, dialogMode: FileDialogModeEnum.AddFromCamera });
        return;



    }


    saveCameraFile = (fileName: string, fileDataStr: string, fileDesc: string, fileRefNo: string, docTypeDesc: string, callBack?: (fi: TcbFileItem) => void) => {
        let url = this.props.userInf.currProject.apiUrl + '/api/file/SaveFileBase64';

        //add file props before we add
        let body = {
            fileName: fileName,
            fileDesc: fileDesc,
            refNo: fileRefNo,
            documentDefaultType: docTypeDesc,
            fileBase64: fileDataStr
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    fi.fileDataStr = fileDataStr;
                    fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
                    fi.canEdit = true;
                    fi.canDelete = true;
                    if (callBack) callBack(fi);
                }
            })
            .catch(err => {
                alert('Error saving image - ' + err.toString())
            });
    }

    linkImageToTcbObj = (fileId: string) => {
        let url = this.props.userInf.currProject.apiUrl + '/api/details/LinkFileToTcbObj';

        let tcbObjs: TcbObjInfo[] = [];
        tcbObjs.push(this.props.tcbObj);

        let docType = "Item Photo";
        if (this.props.tcbObj.tcbObjClass === "P") {
            docType = "Packlist Photo"
        }

        let body = {
            fileId: fileId,
            tcbObjs: tcbObjs,
            documentDefaultType: docType
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                }
            })
            .catch(err => {
                // this.setState({
                //     fetchError: true,
                //     fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                // });
            });

    }

    loadNewImageInfo = (fileId: string, callBack?: (fi: TcbFileItem) => void) => {
        let url = this.props.userInf.currProject.apiUrl + '/api/file/GetTcbFileInfo';

        let body = {
            fileId: fileId,
            fetchFileData: false
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    if (callBack)
                        callBack(fi);
                    //Now load image
                    // fi.fileLoadStatus = TcbFileLoadStatusEnum.NotLoaded;
                    // fi.canEdit = true;
                    // this.setState({ dialogMode: FileDialogModeEnum.Edit, fileItem: fi });
                    // if (this.props.onAdd)
                    //     this.props.onAdd(fi);
                }
            })
            .catch(err => {
                alert('Error loading Image - ' + err.toString());
            });

    }

    getFileDetails = (fileId: string, fetchFileData: boolean, callBack?: (fi: TcbFileItem) => void) => {
        let url = this.props.userInf.currProject.apiUrl + '/api/file/GetTcbFileInfo';

        let body = {
            fileId: fileId,
            fetchFileData: fetchFileData
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    if (callBack)
                        callBack(fi);
                }
            })
            .catch(err => {
                alert('Error saving details - ' + err.toString());
            });
    }

    saveFileDetails = (fileId: string, fileName: string, fileDesc: string, fileRefNo: string, docTypeId?: number, callBack?: (fi: TcbFileItem) => void) => {
        let url = this.props.userInf.currProject.apiUrl + '/api/file/SetTcbFileDetails';

        let body = {
            fileId: fileId,
            fileName: fileName,
            refNo: fileRefNo,
            desc: fileDesc,
            docTypeId: docTypeId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    //This fi does not contain the full data str
                    if (callBack)
                        callBack(fi);
                }
            })
            .catch(err => {
                alert('Error saving details - ' + err.toString());
            });

    }



    //Render Functions

    renderTitle = () => {
        let img = this.state.fileItem
        let dlgTxt = "";
        switch (this.state.dialogMode) {
            case FileDialogModeEnum.Add:
                if (this.props.display === FileDisplayModeEnum.Images)
                    dlgTxt = "Add New Image";
                else
                    dlgTxt = "Add New Attachment";
                break;
            case FileDialogModeEnum.View:
                dlgTxt = img.fileName;
                break;

            case FileDialogModeEnum.Edit:
                dlgTxt = img.fileName + " (editing)";
                break;
            case FileDialogModeEnum.Delete:
                dlgTxt = "Confirm Delete - " + img.fileName;
                break;
        }

        return (<table id="dlgTitleStd">
            <thead>
                <tr>
                    <td className='left'><img style={{ height: "50px" }} src={tcbLogoSml} alt="TracBASE" /> </td>
                    <th>{dlgTxt}</th>
                    <td className='right'><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.dlgActionClose()}></span></td>
                </tr>
            </thead>
        </table>)
    }

    setViewHeight = (event: any): void => {
        if (event?.target?.offsetParent?.clientHeight)
            this.setState({
                viewHeight: event.target.offsetParent.clientHeight - 200
            });
    }

    setViewWidth = () => {
        const wrapperDiv = document.getElementById('fileViewerViewDiv')
        if (wrapperDiv?.offsetParent?.clientWidth)
            this.setState({
                viewWidth: wrapperDiv.offsetParent.clientWidth - 50
            });
    }

    renderFilePreview = () => {
        let fi = this.state.fileItem;
        switch (fi.fileLoadStatus) {
            case TcbFileLoadStatusEnum.InProgress:
                return (<div id="fileViewerViewOuterDiv"><span className="k-icon k-font-icon k-i-loading" /></div>);
            case TcbFileLoadStatusEnum.Error:
                return (
                    <div id="fileViewerViewOuterDiv"><span className="k-icon k-font-icon k-i-warning" /><br />
                        <p>An error occurred retrieving image;</p>
                        <p>{fi.fileLoadErrorMsg}</p>
                    </div>);

            default:
                if (fi.isImage) {
                    if (fi.fileDataStr) {
                        return (
                            <div id="fileViewerViewOuterDiv">
                                <div id="fileViewerViewDiv" className="fullHeight">
                                    <img key={fi.fileId} src={'data:image/jpeg;base64,' + fi.fileDataStr}
                                        alt={fi.fileName}
                                        height={this.state.viewHeight}
                                        onLoad={this.setViewHeight}
                                        className="fileViewerFullImage" />
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div id="fileViewerViewOuterDiv">
                                <div id="fileViewerViewDiv" className="fullHeight">
                                    <img key={fi.fileId} src={'data:image/jpeg;base64,' + fi.thumbnailStr}
                                        alt={fi.fileName}
                                        height={this.state.viewHeight}
                                        onLoad={this.setViewHeight}
                                        className="fileViewerFullImage" />
                                </div>
                            </div>
                        );
                    }
                } else {
                    if (fi.fileDataStr && fi.fileExt != null && fi.fileExt.toLowerCase() === ".pdf") {
                        const ch = document.body ? ((document.body.clientHeight - 120) * 0.9) - 120 : 200;
                        let tools: PDFViewerTool[] = ["pager", "spacer", "zoomInOut", "zoom", "selection", "spacer", "search", "download", "print"];
                        return (
                            <PDFViewer
                                data={fi.fileDataStr}
                                tools={tools}
                                style={{ height: ch }}
                            />
                        );
                    } else if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser &&
                        fi.fileDataStr &&
                        fi.fileExt.toLowerCase() === ".ifc") {
                        const ch = document.body ? ((document.body.clientHeight - 124) * 0.9) - 124 : 200;
                        return (<>
                            <input type='hidden' id='fileDataStr' value={fi.fileDataStr} />
                            <iframe id="ifcViewer" src="./ifc/index.html" height={ch} width="100%" title="viewer"></iframe>
                        </>);
                    } else {
                        //Render image type icon
                        let cn = this.buildIconClassStr(fi.fileExt);
                        return (
                            <div id="fileViewerViewOuterDiv">
                                <span className={cn} />
                            </div>
                        );
                    }
                }
        }
    }

    buildIconClassStr = (fileExt: string) => {
        let cn = "k-icon ";
        if(fileExt != null){
         switch (fileExt.toLowerCase()) {
            case ".pdf":
                cn += "k-i-file-pdf";
                break;
            case ".xls":
            case ".xlsx":
                cn += "k-i-file-excel";
                break;
            case ".doc":
            case ".docx":
                cn += "k-i-file-word";
                break;
            case ".ppt":
            case ".pptx":
                cn += "k-i-file-ppt";
                break;
            case ".txt":
                cn += "k-i-file-txt";
                break;

            default:
                cn += "k-i-file";
                break;
        }          
        }
 
        return cn;
    }

    renderFileDetails = () => {



        let fi = this.state.fileItem;
        let lco = moment(fi.lastChangedOn);
        let lcoStr = '';
        if (lco.isValid()) {
            lcoStr = lco.format("DD-MMM-YY h:mma");
        }

        switch (this.state.dialogMode) {
            case FileDialogModeEnum.Add:
            case FileDialogModeEnum.AddEdit:
            case FileDialogModeEnum.Edit:
            case FileDialogModeEnum.AddFromCamera:
                let fName = fi.fileName;
                //let fRef = fi.fileRefNo;
                let fDesc = fi.fileDesc;
                let fDocType = fi.documentTypeId;

                // if (this.state.edtFileName) fName = this.state.edtFileName;
                // if (this.state.edtFileRefNo) fRef = this.state.edtFileRefNo;
                // if (this.state.edtFileDesc) fDesc = this.state.edtFileDesc;
                // if (this.state.edtFileDocTypeId) fDocType = this.state.edtFileDocTypeId;

                let docTypeLookup = "DocumentTypeId";
                if (this.state.dialogMode === FileDialogModeEnum.Add || this.state.dialogMode === FileDialogModeEnum.AddEdit)
                    docTypeLookup = "DocumentTypeIdForUpload";
                if (this.state.dialogMode === FileDialogModeEnum.Edit)
                    docTypeLookup = "DocumentTypeIdForEdit";

                if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
                    return (
                        <div id="fileViewerDetailsDiv">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Filename :</th>
                                        <td><Input value={fName} onChange={this.fileNameOnChange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Ref No :</th>
                                        <td><Input value={this.state.edtFileRefNo} onChange={this.fileRefOnChange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Description :</th>
                                        <td><Input value={fDesc} onChange={this.fileDescOnChange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Doc Type :</th>
                                        <td><DropDownListCtl
                                            key="FVList"
                                            mode={InputCtlViewMode.Select} dataSource={InputCtlDdlDataSource.General} lookupKey={docTypeLookup}
                                            selectedValue={fDocType?.toString()} onSelectChange={this.fileDocTypeOnChange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Last Changed By :</th>
                                        <td>{fi.lastChangedBy}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Changed On :</th>
                                        <td>{lcoStr}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                }
                else {
                    return (
                        <div id="fileViewerDetailsDiv">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Filename :</th>
                                        <td><Input value={fName} onChange={this.fileNameOnChange} /></td>
                                        <th>Ref No :</th>
                                        {/* <td><Input value={fRef} onChange={this.fileRefOnChange} /></td> */}
                                        <td><Input  value={this.state.edtFileRefNo} onChange={this.fileRefOnChange}/></td>
                                    </tr>
                                    <tr>
                                        <th>Description :</th>
                                        <td><Input value={fDesc} onChange={this.fileDescOnChange} /></td>
                                        <th>Doc Type :</th>
                                        <td><DropDownListCtl 
                                        key="FVList"
                                        mode={InputCtlViewMode.Select} dataSource={InputCtlDdlDataSource.General} lookupKey={docTypeLookup}
                                            selectedValue={fDocType?.toString()} onSelectChange={this.fileDocTypeOnChange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Last Changed By :</th>
                                        <td>{fi.lastChangedBy}</td>
                                        <th>Last Changed On :</th>
                                        <td>{lcoStr}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                }
            default:
                return (
                    <div id="fileViewerDetailsDiv">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Filename :</th>
                                    <td>{fi.fileName}</td>
                                    <th>Ref No :</th>
                                    <td>{fi.fileRefNo}</td>
                                </tr>
                                <tr>
                                    <th>Description :</th>
                                    <td>{fi.fileDesc}</td>
                                    <th>Doc Type :</th>
                                    <td>{fi.documentTypeDesc}</td>
                                </tr>
                                <tr>
                                    <th>Last Changed By :</th>
                                    <td>{fi.lastChangedBy}</td>
                                    <th>Last Changed On :</th>
                                    <td>{lcoStr}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
        }

    }


    renderAddFile = () => {
        return (<table id="fileViewerAddTable">
            <tbody>
                {this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser &&
                    <tr>
                        <td><span className="k-icon k-font-icon k-i-photo-camera" onClick={() => { this.openCamera() }} />
                            <p>Add From Camera</p></td>
                    </tr>}
                <tr>
                    <td><span className="k-icon k-font-icon k-i-attachment" />
                        <div><FileUploadCtl mode={this.props.display} onChange={this.fileUploadCompleted} /></div>
                    </td>
                </tr>
            </tbody>
        </table>);
    }


    renderDeleteFileList = () => {
        return (<div>File List</div>);
    }

    renderAddFileDialogActions = () => {
        let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
        return (<DialogActionsBar>
            <Button className={cn + " save"} style={{ backgroundColor: 'rgb(149,207,168)' }}
                disabled={!this.state.saveValid}
                onClick={e => this.dlgActionSave()}>Save</Button>
            <Button className={cn + " cancel"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.dlgActionClose()}>Cancel</Button>
        </DialogActionsBar>
        );
    }

    renderViewDialogActions = () => {
        let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
        return (<DialogActionsBar>
            {this.state.fileItem.canEdit && <Button className={cn + " edit"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.dlgActionEdit()}>Edit</Button>}
            {(this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser) && <Button className={cn + " download"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.dlgActionDownload()}>Download</Button>}
            <Button className={cn + " cancel"} onClick={e => this.dlgActionClose()}>Close</Button>
        </DialogActionsBar>
        );
    }

    renderAddEditDialogActions = () => {
        let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
        return (<DialogActionsBar>
            <Button className={cn + " save"} style={{ backgroundColor: 'rgb(149,207,168)' }} disabled={!this.state.saveValid} onClick={e => this.dlgActionSave()}>Save</Button>
            <Button className={cn + " cancel"} onClick={e => this.dlgActionClose()}>Cancel</Button>
        </DialogActionsBar>
        );
    }

    renderAddEditFromCameraDialogActions = () => {
        let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
        return (<DialogActionsBar>
            <Button className={cn + " save"} style={{ backgroundColor: 'rgb(149,207,168)' }} disabled={false} onClick={e => this.dlgActionSave()}>Save</Button>
            <Button className={cn + " cancel"} onClick={e => this.dlgActionClose()}>Cancel</Button>
        </DialogActionsBar>
        );
    }

    renderEditDialogActions = () => {
        let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
        return (<DialogActionsBar>
            <Button className={cn + " save"} style={{ backgroundColor: 'rgb(149,207,168)' }} disabled={!this.state.saveValid} onClick={e => this.dlgActionSave()}>Save</Button>
            {this.state.fileItem.canDelete && <Button className={cn + " delete"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.dlgActionDelete()}>Delete</Button>}
            <Button className={cn + " cancel"} onClick={e => this.dlgActionClose()}>Cancel</Button>
        </DialogActionsBar>
        );
    }

    renderDeleteDialogActions = () => {
        let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
        return (<DialogActionsBar>
            <Button className={cn + " close"} onClick={e => this.dlgActionConfirmDelete()}>Confirm Delete</Button>
            <Button className={cn + " cancel"} onClick={e => this.dlgActionClose()}>Cancel</Button>
        </DialogActionsBar>
        );
    }


    // renderDialogActions = () => {
    //     let cn = "fileViewerDlgActionBtn" + addPageViewClass(this.props.pageInf);
    //     return (<DialogActionsBar>
    //         {this.showButton("Download", this.state.dialogMode) && <Button className={cn + " download"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.dlgActionDownload()}>Download</Button>}
    //         {this.showButton("Cancel", this.state.dialogMode) && <Button className={cn + " cancel"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.dlgActionClose()}>Cancel</Button>}
    //         {/* {this.showButton("Close", this.state.dialogMode) && <Button className={cn + " close"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.dlgActionClose()}>Close</Button>} */}
    //         {this.showButton("Edit", this.state.dialogMode) && <Button className={cn + " edit"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.dlgActionEdit()}>Edit</Button>}
    //         {this.showButton("Delete", this.state.dialogMode) && <Button className={cn + " delete"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.dlgActionDelete()}>Delete</Button>}
    //         {this.showButton("Save", this.state.dialogMode) &&
    //             <Button className={cn + " save"} style={{ backgroundColor: 'rgb(149,207,168)' }}
    //                 disabled={!this.state.saveValid}
    //                 onClick={e => this.dlgActionSave()}>Save</Button>}
    //         {this.showButton("Confirm", this.state.dialogMode) && <Button className={cn + " close"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.dlgActionConfirmDelete()}>Confirm Delete</Button>}
    //     </DialogActionsBar>
    //     );
    // }

    renderDialog = () => {
        switch (this.state.dialogMode) {
            case FileDialogModeEnum.Add:
                return (<div className='fileViewerBody'>
                    {this.renderAddFile()}
                    {this.renderFileDetails()}
                    {this.renderAddFileDialogActions()}
                </div>
                );


            case FileDialogModeEnum.View:
                return (<div className='fileViewerBody'>
                    {this.renderFilePreview()}
                    {this.renderFileDetails()}
                    {this.renderViewDialogActions()}
                </div>
                );

            case FileDialogModeEnum.AddEdit:
                return (<div className='fileViewerBody'>
                    {this.renderFilePreview()}
                    {this.renderFileDetails()}
                    {this.renderAddEditDialogActions()}
                </div>
                );

            case FileDialogModeEnum.AddFromCamera:
                return (<div className='fileViewerBody'>
                    {this.renderFilePreview()}
                    {this.renderFileDetails()}
                    {this.renderAddEditFromCameraDialogActions()}
                </div>
                );

            case FileDialogModeEnum.Edit:
                return (<div className='fileViewerBody'>
                    {this.renderFilePreview()}
                    {this.renderFileDetails()}
                    {this.renderEditDialogActions()}
                </div>
                );

            case FileDialogModeEnum.Delete:
                if (this.props.fileList && this.props.fileList.length > 1) {
                    return (<div className='fileViewerBody'>
                        {this.renderDeleteFileList()}
                        {this.renderFileDetails()}
                        {this.renderDeleteDialogActions()}
                    </div>
                    );
                } else {
                    return (<div className='fileViewerBody'>
                        {this.renderFilePreview()}
                        {this.renderFileDetails()}
                        {this.renderDeleteDialogActions()}
                    </div>
                    );
                }

        }
    }

    render() {
        let body = document.body;
        if (body) {
            var bs: React.CSSProperties = {};
            bs.height = body.clientHeight - 115;
            //mobiles need width to prevent tabstrip push out page width            
            if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
                bs.width = body.clientWidth;
            }
        }

        return (
            <Dialog className={"fileViewerDlg" + addPageViewClass(this.props.pageInf)} title={this.renderTitle()} closeIcon={false}
            >
                {this.renderDialog()}
            </Dialog>
        );
    }
}

export default connector(FileViewerDlg);
