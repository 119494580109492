import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import { UserInfo, GetMenuItemRow, MenuItemModel } from '../models/models';
import { PageViewTypeEnum } from '../models/enums';
import { Offset } from '@progress/kendo-react-popup';
import { Menu, MenuSelectEvent } from '@progress/kendo-react-layout';


import './Home.css';
import { Button } from '@progress/kendo-react-buttons';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type HomeProps = PropsFromRedux & {
    onSelectAction?: (e: GetMenuItemRow) => void;
}


interface HomeState {
    loading: boolean;
    actionItems: GetMenuItemRow[];
    hpDivStyle: React.CSSProperties;
    // popupOpen: boolean;

}

class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);




        this.state = {
            loading: false,
            actionItems: [],
            hpDivStyle: {},
            // popupOpen: false,
        }
    }

    componentDidMount() {

        //Treelist needs height set
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);


        if (this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser) {
            this.fetchActions();
        }
    }

    componentDidUpdate(prevProps: HomeProps) {
    }

    componentWillUnmount() {
    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            let hs: React.CSSProperties = { ...this.state.hpDivStyle };
            hs.height = body.clientHeight - 80;
            hs.width = body.clientWidth
            this.setState({
                hpDivStyle: hs,
            });
        }
    }


    fetchActions = () => {
        this.setState({ loading: true, actionItems: [] });

        let url = this.props.userInf.currProject.apiUrl + '/api/ui/GetMenuItemsForRole';

        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        //Need to remove "Home" action from list
                        let tmpRes: GetMenuItemRow[] = res.results;

                        //this.setState({ actionItems: tmpRes.filter(x => x.securityActionTypeId !== MenuItemTypeEnum.Home), loading: false });
     
                        this.setState({ actionItems: tmpRes.filter(x => (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser && x.browserEnable === true) ||
                            (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile && x.mobileEnable === true) ||
                            (this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet && x.tabletEnable === true))
                        , loading: false });
    
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        alert('Error fetching actions - ' + res.callStatusMessage);
                }
            })
            .catch(err => {
                alert('Error fetching actions - ' + err.toString());
            });
    }


    selectAction = (e: GetMenuItemRow) => {
        if (this.props.onSelectAction)
            this.props.onSelectAction(e);
    }


    contextMenuClick = (e: MenuSelectEvent) => {
        let dataItm = e.item as MenuItemModel;

        let itmRow = dataItm.data as GetMenuItemRow;

        if (itmRow && this.props.onSelectAction)
            this.props.onSelectAction(itmRow);
    }


    renderURL = () => {
        return (<iframe src={this.props.userInf.currProject.homePageUrl} className='homePageIframe' title='HomePage' />);
    }

    renderActions = () => {
        return (<div id="homePageDiv" style={this.state.hpDivStyle}>
            <table id="homePageTbl" >
                <tbody>
                    {
                        this.renderActionItems(this.state.actionItems)
                    }
                </tbody>
            </table>
        </div>
        );

    }

    renderActionItems = (itms: GetMenuItemRow[]) => {
        let retVal: JSX.Element[] = [];

        let is2nd = false;
        let firstCell: JSX.Element[] = [];
        itms.forEach(x => {


            if(is2nd && x.items.length > 0){
                if (firstCell.length > 0){
                    retVal.push(<tr>
                        {firstCell}
                        <td>&nbsp;</td>
                    </tr>);
                }
                retVal.push(<tr>
                    {this.renderActionItemCell(x)}
                    <td>&nbsp;</td>
                </tr>);
                is2nd = false;
                firstCell = [];
            }

        else{


            if (is2nd) {
                retVal.push(<tr>
                    {firstCell}
                    {this.renderActionItemCell(x)}
                </tr>);
                is2nd = false;
                firstCell = [];
            } else {
                is2nd = true;
                firstCell.push(this.renderActionItemCell(x));
            }
        }
    }
    
    );
        if (is2nd) {
            retVal.push(<tr>
                {firstCell}
                <td>&nbsp;</td>
            </tr>);
        }
        return retVal;
    }

    private lastColorSelect = 0;
    renderActionItemCell = (itm: GetMenuItemRow) => {
        //let btnStyle: React.CSSProperties = {};


        if (itm.securityActionTypeId !== 1) {
            return (<td><Button  onClick={() => { this.selectAction(itm)}}  >
                <div className='buttonLogo'>{this.renderIconCode(itm)}</div>
                <span>{itm.description}</span>
            </Button></td>);
        } else {

// let cssStyle:string = firstItm ? "subMenuBtnDivRight" : "subMenuBtnDivLeft";
let cssStyle:string = "subMenuBtnDivRight";

            let itemMenuItms: MenuItemModel[] = [];
            let cxtMenu: MenuItemModel = {
                items: [], linkRender: () => {
                    return <Button>
                        <div className='buttonLogo'>{this.renderIconCode(itm)}</div>
                        <span>{itm.description}</span>
                        <div className= {cssStyle}>
                            <span className='k-icon k-font-icon k-i-expand' />
                        </div>    </Button>
                }
            };
            itemMenuItms.push(cxtMenu);
            itm.items.forEach(x => {
                cxtMenu.items.push({ text: x.description, action: 'openAction', data: x, cssClass: 'homeMenuItm', disabled: false, items: [] });

            });

            return (<td><Menu items={itemMenuItms} openOnClick={true} vertical={true} onSelect={(m) => this.contextMenuClick(m)} /></td>);

        }
    }

    renderIconCode = (itm: GetMenuItemRow) => {
        if (itm) {
            if (itm.iconImageStr)
                return (<img src={'data:image/jpeg;base64,' + itm.iconImageStr} alt='' />);
            else if (itm.iconCode)
                return <span className={'k-icon'} dangerouslySetInnerHTML={{ __html: itm.iconCode }} />
        }
        return null;
    }


    render() {
        if (this.state.loading) {
            return (<div id="homePageDiv">
                <span className='k-icon k-font-icon k-i-loading' />
            </div>);
        } else {
            if (this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser || this.props.userInf.currProject.isUAT) {
                return (this.renderActions());
            } else {
                return (this.renderURL());
            }
        }
    }
}

export default connector(Home);
