import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { FpcModel, ClientQueryLookupData } from '../../models/models';
import { DropDownList, DropDownListChangeEvent,DropDownListFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { addPageViewClass } from '../../functions/generalFunctions'

import './SelectCQ.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SelectCQProps = PropsFromRedux & {
    model: FpcModel;
    onSelectChanged?: (e:FpcModel) => void;
}

interface SelectCQState {
    fpc: FpcModel;
    cqsLoading: boolean;
    cqData: Array<ClientQueryLookupData>;
    cqDataFilt: Array<ClientQueryLookupData>;
}

class SelectCQ extends React.Component<SelectCQProps, SelectCQState> {
    constructor(props: SelectCQProps) {
        super(props);

        this.selectedLookupData = props.model.defClientQueryLookupVal;

        this.state = {
            fpc: props.model,
            cqsLoading: true,
            cqData: [],
            cqDataFilt: []
        }
    };

    private selectedLookupData: ClientQueryLookupData;

    componentDidMount() {
        if (this.state.fpc.clientQueryGrpId)
        {
        let url = this.props.userInf.currProject.apiUrl + '/api/Fpc/FetchClientQueriesForQueryGrp?clientQueryGrpId=' + this.state.fpc.clientQueryGrpId.toString();
        fetch(url, { method: 'GET', headers: new Headers({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.state.fpc.userInfo.token }) })
            .then(res => { 
                if (!res.ok) 
                    throw Error(res.statusText);
                else
                    return res; 
                })
                .then(resp => resp.json())
                .then(res => {

                if (res.callStatus !== "OK") {
                    throw Error(res.callStatusMessage);
                } else {
                    this.setState({cqData: res.results, cqDataFilt: res.results, cqsLoading: false});
                }
            })
        }
      }

      selectChange = (event: DropDownListChangeEvent) => {
        this.selectedLookupData = event.value;
        let fpc = this.state.fpc;
        fpc.clientQueryId = Number(this.selectedLookupData.value);
        fpc.clientQueryDesc = this.selectedLookupData.display;
        fpc.defClientQueryLookupVal = this.selectedLookupData;
        if (this.props.onSelectChanged)
            this.props.onSelectChanged(fpc);
        // this.setState({fpc: fpc, enableNextButton: Number(event.value.Value) !== 0});
    }

    private delay = 500;
    private filterTimeout: any
    filterChange = (event: DropDownListFilterChangeEvent) => {
        clearTimeout(this.filterTimeout);

        const tmpData = this.filterData(event.filter);
        this.filterTimeout = setTimeout(() => {
            this.setState({
                cqDataFilt: tmpData,
                cqsLoading: false
            });
        }, this.delay);

        this.setState({
            cqsLoading: true
        });
    }

    filterData = (filter: FilterDescriptor): Array<ClientQueryLookupData> => {
        return filterBy(this.state.cqData, filter);
    }


    // NextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     let fpc = this.state.fpc;
    //     fpc.clientQueryId = Number(this.selectedLookupData.Value);
    //     fpc.clientQueryDesc = this.selectedLookupData.Display;
    //     fpc.defClientQueryLookupVal = this.selectedLookupData;
    //     this.props.onSubmit(fpc);
    // }

    // private submitToolTip(): string {
    //     if (!this.state.enableNextButton) {
    //         return 'A process must be selected';
    //     } else {
    //         return 'Proceed to Parameters page';
    //     }
    // }



    render() {
        return (
            <div className={'selectCqDiv' + addPageViewClass(this.props.pageInf)}>
                <br />
                <br />
                <br />
                <br />
                <table className={'selectCQTable' + addPageViewClass(this.props.pageInf)}>
                    <tbody>
                        <tr>
                            <th>{this.state.fpc.cqHeader} : </th>
                            <td><DropDownList data={this.state.cqDataFilt} dataItemKey="value" textField="display" loading={this.state.cqsLoading}
                                    onChange={this.selectChange} className={"selectCqDdl"  + addPageViewClass(this.props.pageInf)}
                                    // popupSettings={{height:"400px"}}
                                    filterable={true}
                                    onFilterChange={this.filterChange}
                                    defaultValue={this.state.fpc.defClientQueryLookupVal} /></td>
                        </tr>
                        {this.state.fpc.cqHelpDescription ? (<tr><td colSpan={2}><br/><br/></td></tr>) : null }
                        {this.state.fpc.cqHelpDescription ? (
                        <tr>
                            <th><span className="k-icon k-font-icon k-i-information" /></th>
                            <td style={{textAlign:"left"}} >{this.state.fpc.cqHelpDescription}</td>                            
                        </tr>) : null
                        }
                    </tbody>
                </table>
            </div>
        )
    };

}

export default connector(SelectCQ);