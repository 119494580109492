import React from 'react';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs'
import { Button } from '@progress/kendo-react-buttons';

import './PasswordCtl.css';


interface PasswordCtlProps {
    onChange: (password: string) => void;
}

interface PasswordCtlState {
    showPassword: boolean;
    password: string;
}


class PasswordCtl extends React.Component<PasswordCtlProps, PasswordCtlState> {
    constructor(props: PasswordCtlProps) {
        super(props);


        this.state = {
            showPassword: false,
            password: ''
        };

    };


    toggleShowPwd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    passwordTextChange = (e: InputChangeEvent) => {
        this.setState({ password: e.value }, () => this.props.onChange(e.value));
    }

    render() {
        let inpType = "text";
        if (!this.state.showPassword)
            inpType = "password";


        return (
            <table className="pwdCtlTbl">
                <tbody>
                    <tr>
                        <td className="pwdCtlTblInpCell"><Input type={inpType} autoComplete="new-password" value={this.state.password} onChange={this.passwordTextChange} /></td>
                        <td className="pwdCtlTblToggleBtn"><Button className="searchResultsBtn" onClick={this.toggleShowPwd} title="Show/Hide Password" ><span className="k-icon k-font-icon k-i-eye" /></Button></td>
                    </tr>
                </tbody>
            </table>
        );

    }
}

export default PasswordCtl;