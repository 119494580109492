import React from 'react';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { NumberFormatOptions } from "@progress/kendo-react-intl";
import { InputCtlViewMode } from '../../models/enums';
import checkImg from '../../images/Check-2-64.png';
import crossImg from '../../images/Cross-2-64.png';


import './NumberInputCtl.css';

interface NumberInputCtlProps {
    value: number | null;
    mode?: InputCtlViewMode;
    style?: React.CSSProperties;
    placeholder?: string;
    isRequired?: boolean;
    autoFocus?: boolean;
    onUpdate?: (e: number | null, setViewMode?: (newMode: InputCtlViewMode) => void) => void;
}

const ref = React.createRef<any>();

interface NumberInputCtlState {
    origInputValue: number | null;
    inputValue: number | null;
    mode: InputCtlViewMode;
}

class NumberInputCtl extends React.Component<NumberInputCtlProps, NumberInputCtlState> {
    constructor(props: NumberInputCtlProps) {
        super(props);

        this.state = {
            origInputValue: this.props.value,
            inputValue: this.props.value,
            mode: this.props.mode ?? InputCtlViewMode.ReadOnly,
        }

    }

    componentDidUpdate(prevProps: NumberInputCtlProps) {
    }

    componentDidMount() {
        if (ref && ref.current) {
            ref.current.focus();
        }
    }

    componentWillUnmount() {
    }

    inputNumOnChange = (e: NumericTextBoxChangeEvent) => {
        this.setState({ inputValue: e.value }, () => {
            if (this.props.mode === InputCtlViewMode.Select && this.props.onUpdate) {
                this.props.onUpdate(this.state.inputValue)
            }
        });
    }

    inputNumChangeToEdit = () => {
        this.setState({ mode: InputCtlViewMode.Edit, origInputValue: this.state.inputValue });
    }

    inputNumCancelEdit = () => {
        this.setState({ mode: InputCtlViewMode.View, inputValue: this.state.origInputValue });
    }

    inputNumSaveChange = () => {
        this.setState({ mode: InputCtlViewMode.Saving }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.inputValue, this.setViewMode);
        });
    }

    setViewMode = (e: InputCtlViewMode) => {
        this.setState({ mode: e });
    }

    renderNumInp = (ro: boolean) => {
        let fo: NumberFormatOptions = {
            style: 'decimal',
            maximumFractionDigits: 6
        }
        return (<div className='NumberInputInner'>
            <NumericTextBox
                ref={ref}
                value={this.state.inputValue}
                format={fo}
                onChange={this.inputNumOnChange}
                placeholder={this.props.placeholder}
                className="numInpCtlNumTxtBox"
                disabled={ro}
            />
            {this.props.isRequired && <span className="numInpCtlNumRequired">*</span>}
        </div>);
    }

    render() {

        switch (this.state.mode) {
            case InputCtlViewMode.ReadOnly:
            default:
                return (<div className='NumberInputCtl readonly'>{this.state.inputValue}</div>)

            case InputCtlViewMode.Select:
                return (<div className='NumberInputCtl edit' >{this.renderNumInp(false)}</div>);

            case InputCtlViewMode.View:
                return (<div className='NumberInputCtl view' onClick={() => this.inputNumChangeToEdit()}>{this.state.inputValue}</div>)

            case InputCtlViewMode.Edit:
                return (<div className='NumberInputCtl edit' >
                    {this.renderNumInp(false)}
                    <img className='check' hidden={this.state.mode !== InputCtlViewMode.Edit} src={checkImg} alt="Save" title='Save' onClick={this.inputNumSaveChange} />
                    <img className='cross' hidden={this.state.mode !== InputCtlViewMode.Edit} src={crossImg} alt="Cancel" title='Cancel' onClick={this.inputNumCancelEdit} />
                </div>);

            case InputCtlViewMode.Saving:
                return (<div className='NumberInputCtl edit' >
                    {this.renderNumInp(true)}
                    <div className='saving' hidden={this.state.mode !== InputCtlViewMode.Saving}><span className="k-icon k-font-icon k-i-loading" /></div>
                </div>);

        }
    }
}

export default NumberInputCtl;
