import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Splitter, SplitterPaneProps, SplitterOnChangeEvent } from '@progress/kendo-react-layout';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar, DialogProps } from '@progress/kendo-react-dialogs';

import MilestoneLinesGrid from './MilestoneLinesGrid';
import MilestoneScanImages from './MilestoneScanImages'
import NumberInputCtl from '../InputCtls/NumberInputCtl';
import { UserInfo, MilestoneItem, TcbFileItem, TcbObjInfo, MilestoneScanHistory, MilestoneScanLog } from '../../models/models';
import { addPageViewClass, uploadImage, linkFileToMilestoneScan, deleteImage } from '../../functions/generalFunctions'
import { CustomWindow } from '../../models/custom.window'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { PageViewTypeEnum, MilestoneScanStatusEnum, MilestoneScanDialogViewEnum, TcbFileLoadStatusEnum, InputCtlViewMode } from '../../models/enums';

import '@progress/kendo-theme-default/dist/all.css'
import './MilestoneScanDialog.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

declare function invokeOpenCameraAction(callback: string, id: string): void;
declare let window: CustomWindow;

type MilestoneScanDialogProps = PropsFromRedux & {
    msh: MilestoneScanHistory;
    onUpdate?: (e: MilestoneScanHistory, action: string) => void;
}


interface MilestoneScanDialogState {
    milestoneScanItem: MilestoneScanHistory;
    panesListScanHistoryDialog: Array<SplitterPaneProps>;
    currentImageView?: TcbFileItem;
}

class MilestoneScanDialog extends React.Component<MilestoneScanDialogProps, MilestoneScanDialogState> {
    constructor(props: MilestoneScanDialogProps) {
        super(props);

        //Make functions available to WebView
        window.milestonScanDialogRef = this;

        let section1Size = '30%';
        if (this.props.msh.tcbObjs.length > 2) {
            section1Size = '40%';
        }
        let plScHistDlg: Array<SplitterPaneProps> = [
            { size: section1Size, min: '20px', resizable: true, collapsible: true, collapsed: false },
            {},
            { size: '30%', min: '20px', resizable: true, collapsible: true, collapsed: this.props.msh.scanImages.length > 0 ? false : true },
        ];

        this.state = {
            milestoneScanItem: this.props.msh,
            panesListScanHistoryDialog: plScHistDlg
        };


    }

    componentDidUpdate(prevProps: MilestoneScanDialogProps) {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    splitterChangeDialog = (e: SplitterOnChangeEvent) => {
        this.setState({
            panesListScanHistoryDialog: e.newState
        });
    }

    updateMilestoneItem = (e: MilestoneItem) => {
        let msh = this.state.milestoneScanItem;
        msh.milestone = e;
        if (this.props.onUpdate) {
            this.props.onUpdate(msh, "U");
        }
    }
    updateMilestoneScanHistory = (msi: MilestoneScanHistory) => {
        this.setState({ milestoneScanItem: msi });
        if (this.props.onUpdate)
            this.props.onUpdate(msi, "U");
    }
    submitMilestoneScanHistory = (msi: MilestoneScanHistory) => {
        this.setState({ milestoneScanItem: msi });
        if (this.props.onUpdate)
            this.props.onUpdate(msi, "S");
    }



    updateTcbObjQty = (e: TcbObjInfo, v: number | null) => {
        e.tcbObjQty = v;
        this.updateMilestoneScanHistory(this.state.milestoneScanItem);
    }

    actionBack = (msi: MilestoneScanHistory) => {
        msi.scanDialogView = msi.scanDialogOnCloseView;
        this.updateMilestoneScanHistory(msi);
    }
    actionClose = (msi: MilestoneScanHistory) => {
        msi.scanDialogView = MilestoneScanDialogViewEnum.None;
        this.updateMilestoneScanHistory(msi);
    }
    actionConfirmClose = (msi: MilestoneScanHistory) => {
        msi.scanDialogView = msi.scanDialogOnCloseView;
        msi.scanDialogStatus = msi.scanStatus;
        this.updateMilestoneScanHistory(msi);
    }
    actionConfirmDelete = (msi: MilestoneScanHistory) => {
        msi.scanDialogView = MilestoneScanDialogViewEnum.Edit
        msi.scanDialogStatus = MilestoneScanStatusEnum.DeleteConfirm;
        msi.scanDialogOnCloseView = MilestoneScanDialogViewEnum.Edit;
        this.updateMilestoneScanHistory(msi);
    }

    actionSubmit = (msi: MilestoneScanHistory) => {
        msi.scanDate = moment();
        msi.scanStatus = MilestoneScanStatusEnum.NotStarted;
        msi.scanDialogView = MilestoneScanDialogViewEnum.None;
        this.submitMilestoneScanHistory(msi);
    }


    actionDelete = (msi: MilestoneScanHistory) => {
        msi.scanDialogView = MilestoneScanDialogViewEnum.None;
        msi.scanStatus = MilestoneScanStatusEnum.Deleting;
        msi.scanLog.push(new MilestoneScanLog("Delete Scan", "Deleting..."));
        if (this.props.onUpdate) {
            this.props.onUpdate(msi, "D");
        }
    }

    actionDeleteImage = (msi: MilestoneScanHistory) => {
        if (this.state.currentImageView) {
            deleteImage(this.props.userInf, msi, this.state.currentImageView,
                //Success
                (e) => {
                    msi.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                    msi.scanStatus = MilestoneScanStatusEnum.Complete;
                    this.setState({ milestoneScanItem: msi, currentImageView: undefined });
                    if (this.props.onUpdate)
                        this.props.onUpdate(msi, "U");
                },
                //UnAuthorised
                (e) => {
                    let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                    msi.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                    this.updateMilestoneScanHistory(msi);
                    this.props.updateUserInfo(uInf);
                },
                //Error
                (e) => {
                    msi.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                    this.updateMilestoneScanHistory(msi);
                });
        }
    }

    actionScanLog = (msi: MilestoneScanHistory) => {
        msi.scanDialogView = MilestoneScanDialogViewEnum.ScanLog;
        msi.scanDialogOnCloseView = MilestoneScanDialogViewEnum.Edit;
        this.setState({ milestoneScanItem: msi });
    }


    OpenCamera = (msi: MilestoneScanHistory) => {

        if (process.env.REACT_APP_RunMode === "DEV") {
            this.SaveImage(msi.milestoneScanHistoryId, 'IMG_2022_07_20_19_30_00.png', 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=')
        } 
        else if (typeof invokeOpenCameraAction !== 'undefined') {
            invokeOpenCameraAction('window.milestonScanDialogRef.SaveImage', msi.milestoneScanHistoryId.toString());
        }
        else {
            alert('invokeOpenCameraAction not defined');
        }
    }

    //Image upload Step 1 - Upload Image
    SaveImage = (shId: number, fileName: string, imgBase64: string) => {

        let msi = this.state.milestoneScanItem;
        let imgs: TcbFileItem[] = [...msi.scanImages];

        //Create a temporary Image item, with a loading status
        let tmpImg: TcbFileItem = new TcbFileItem();
        tmpImg.fileLoadStatus = TcbFileLoadStatusEnum.InProgress;
        tmpImg.fileName = fileName;
        imgs.push(tmpImg);
        msi.scanImages = imgs;
        let ps = this.state.panesListScanHistoryDialog;
        if (ps[2].collapsed) {
            ps[2].collapsed = false;
            this.setState({ panesListScanHistoryDialog: ps });
        }
        this.updateMilestoneScanHistory(msi);
        let siIndex = msi.scanImages.findIndex(x => x.fileName === fileName && x.fileLoadStatus === TcbFileLoadStatusEnum.InProgress);

        uploadImage(this.props.userInf, "Milestone", fileName, imgBase64,
            (e: TcbFileItem) => {
                //Upload Success... now link into Milestone Scan
                linkFileToMilestoneScan(this.props.userInf, msi, e, "Milestone",
                    this.updateMilestoneScanHistory,
                    this.SaveImage_LinkUnAuth,
                    this.updateMilestoneScanHistory)
            },
            (u: UserInfo) => {
                if (siIndex)
                    msi.scanImages[siIndex].fileLoadStatus = TcbFileLoadStatusEnum.Error;
                this.SaveImage_UploadUnAuth(u, msi)
            },
            (e: string) => {
                if (siIndex)
                    msi.scanImages[siIndex].fileLoadStatus = TcbFileLoadStatusEnum.Error;
                this.SaveImage_Error(e, msi);
            });
    }


    SaveImage_UploadUnAuth = (u: UserInfo, msi: MilestoneScanHistory) => {
        let uInf: UserInfo = { ...u, isAuthorised: false };
        msi.scanLog.push(new MilestoneScanLog("Add Photo", "Unauthorised"));
        this.updateMilestoneScanHistory(msi);
        this.props.updateUserInfo(uInf);
    }
    SaveImage_LinkUnAuth = (u: UserInfo, msi: MilestoneScanHistory) => {
        this.updateMilestoneScanHistory(msi);
        this.props.updateUserInfo(u);
    }

    SaveImage_Error = (e: string, msi: MilestoneScanHistory) => {
        msi.scanStatus = MilestoneScanStatusEnum.Error;
        msi.scanLog.push(new MilestoneScanLog("Add Photo", "Error - " + e));
        this.updateMilestoneScanHistory(msi);
    }

    milestoneScanDialogImagePreview_DoubleClick = (scanImage: TcbFileItem) => {
        let msi = this.state.milestoneScanItem;
        msi.scanDialogView = MilestoneScanDialogViewEnum.ImageView;
        msi.scanDialogOnCloseView = MilestoneScanDialogViewEnum.Edit;

        this.setState({ milestoneScanItem: msi, currentImageView: scanImage });
        if (this.props.onUpdate) {
            this.props.onUpdate(msi, "U");
        }

    }

    //Render Functions

    renderTitle = (msh: MilestoneScanHistory) => {
        let dlgTxt = "";
        switch (msh.scanDialogView) {
            case MilestoneScanDialogViewEnum.Edit:
                dlgTxt = "Scan : " + msh.milestone.milestoneDesc;
                break;
            case MilestoneScanDialogViewEnum.ScanLog:
                dlgTxt = "Scan Log : " + msh.milestone.milestoneDesc;
                break;
        }
        return (<div id="msdTitleDiv"><div>{dlgTxt}</div></div>)
    }

    renderBody = (msh: MilestoneScanHistory) => {

        switch (msh.scanDialogView) {
            case MilestoneScanDialogViewEnum.Edit:
                return (
                    <Splitter panes={this.state.panesListScanHistoryDialog} className="msdSplitter"
                        orientation={'vertical'}
                        onChange={this.splitterChangeDialog} >
                        <table id="mssScanDialogHdr">
                            <thead>
                                <tr>
                                    <th className="alignLeft" colSpan={2}>The following <span className='mssScanDialogObjectTypeSpan'>{msh.tcbObjs[0].tcbObjTypeDesc}</span> items will be scanned with the details listed below;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <table id="mssScanDialogTcbObjs">
                                            <tbody>
                                                {msh.tcbObjs.map((e, i) => { return this.renderTcbObjRows(e, i) })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table id="mssScanDialogBdy">
                            <tbody>
                                <tr>
                                    <td>
                                        <MilestoneLinesGrid milestone={msh.milestone} editable={(msh.scanStatus === MilestoneScanStatusEnum.Complete) ? false : true} onUpdate={this.updateMilestoneItem} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <MilestoneScanImages imgs={msh.scanImages} editable={true} onImageDoubleClick={this.milestoneScanDialogImagePreview_DoubleClick} />
                    </Splitter>
                );
            case MilestoneScanDialogViewEnum.ScanLog:
                return (
                    <Grid className="mssScanHistoryScanLog"
                        data={msh.scanLog}
                        resizable={true}
                    >
                        <GridNoRecords>
                            No scans performed
                    </GridNoRecords>
                        <GridColumn field="logDate" title="Date" width={80} resizable={true} cell={this.renderDateCell} />
                        <GridColumn field="action" title="Action" resizable={true} width={90} />
                        <GridColumn field="logText" title="Message" resizable={true} cell={this.renderMessageCell} />
                    </Grid>);

            case MilestoneScanDialogViewEnum.ImageView:
                let imtItm = this.state.currentImageView;
                if (imtItm) {
                    return (<img key={imtItm.fileId} src={'data:image/jpeg;base64,' + imtItm.fileDataStr}
                        alt={imtItm.fileName}
                        className="ImagePreview"
                    />);
                }
        }
    }


    renderTcbObjRows = (e: TcbObjInfo, i: number) => {
        let cn = "mssTcbObjRow" + addPageViewClass(this.props.pageInf) + ((i % 2) > 0 ? " alt" : "");
        return (<tr key={i} className={cn}>
            <td className="mssTcbObjDesc">{e.tcbObjDesc}</td>
            <td className="mssTcbObjQty">{this.renderTcbObjQty(e)} </td>
        </tr>);
    }

    renderTcbObjQty = (e: TcbObjInfo) => {
        if (e.tcbObjSundry){
            return (<NumberInputCtl mode={InputCtlViewMode.Select} key={e.tcbObjId} value={e.tcbObjQty} onUpdate={(v) => this.updateTcbObjQty(e, v)} isRequired={e.tcbObjSundry} />);
        }
    }

    renderActions = (msh: MilestoneScanHistory) => {
        let cn = "mssScanDialogActionBtn" + addPageViewClass(this.props.pageInf);
        switch (msh.scanDialogView) {
            case MilestoneScanDialogViewEnum.Edit:
                switch (msh.scanDialogStatus ?? msh.scanStatus) {
                    case MilestoneScanStatusEnum.Complete:
                        return (<DialogActionsBar>
                            <Button className={cn + " back"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.actionClose(msh)}>Close</Button>
                            <Button className={cn + " cancel"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.actionConfirmDelete(msh)}>Delete</Button>
                            <Button className={cn + " ok"} style={{ backgroundColor: 'rgb(216, 182, 224)' }} onClick={e => this.OpenCamera(msh)}>Add Photo</Button>
                            <Button className={cn + " log"} style={{ backgroundColor: 'rgb(149, 207, 168)' }} onClick={e => this.actionScanLog(msh)}>Scan Log</Button>
                        </DialogActionsBar>
                        );

                    case MilestoneScanStatusEnum.Cancelled:
                        return (<DialogActionsBar>
                            <Button className={cn + " back"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.actionClose(msh)}>Back</Button>
                            <Button className={cn + " ok"} style={{ backgroundColor: 'rgb(149, 207, 168)' }} onClick={e => this.actionSubmit(msh)}>ReSubmit</Button>
                        </DialogActionsBar>
                        );

                    case MilestoneScanStatusEnum.DeleteConfirm:
                        return (<DialogActionsBar>
                            <Button className={cn + " back"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.actionConfirmClose(msh)}>Back</Button>
                            <Button className={cn + " ok"} style={{ backgroundColor: 'rgb(149, 207, 168)' }} onClick={e => this.actionDelete(msh)}>Confirm Delete</Button>
                        </DialogActionsBar>
                        );

                    case MilestoneScanStatusEnum.Error:
                        return (<DialogActionsBar>
                            <Button className={cn + " back"} style={{ backgroundColor: 'rgb(126, 145, 151)' }} onClick={e => this.actionClose(msh)}>Back</Button>
                            <Button className={cn + " cancel"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.actionDelete(msh)}>Delete</Button>
                            <Button className={cn + " ok"} style={{ backgroundColor: 'rgb(149, 207, 168)' }} onClick={e => this.actionSubmit(msh)}>ReSubmit</Button>
                        </DialogActionsBar>
                        );
                    case MilestoneScanStatusEnum.Verify:
                        let isDisabled: boolean = false;
                        if (msh.tcbObjs.some(x => x.tcbObjSundry)) {
                            isDisabled = msh.tcbObjs.filter(x => x.tcbObjSundry).some(x => x.tcbObjQty === null);
                        }
                        return (<DialogActionsBar>
                            <Button className={cn + " cancel"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.actionDelete(msh)}>Cancel Submit</Button>
                            <Button className={cn + " ok"} style={{ backgroundColor: 'rgb(149, 207, 168)' }} disabled={isDisabled} onClick={e => this.actionSubmit(msh)}>Submit Scan</Button>
                        </DialogActionsBar>
                        );
                    case MilestoneScanStatusEnum.LoadingImages:
                        return (<DialogActionsBar>
                            <Button className={cn + " back"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} >Loading Images...</Button>
                        </DialogActionsBar>
                        );

                }
                break;
            case MilestoneScanDialogViewEnum.ScanLog:
                return (<DialogActionsBar>
                    <Button className={cn + " back"} style={{ backgroundColor: "rgb(126, 145, 151)" }} onClick={e => this.actionBack(msh)}>Close</Button>
                </DialogActionsBar>
                );

            case MilestoneScanDialogViewEnum.ImageView:
                return (<DialogActionsBar>
                    <Button className={cn + " back"} style={{ backgroundColor: "rgb(126, 145, 151)" }} onClick={e => this.actionBack(msh)}>Close</Button>
                    <Button className={cn + " cancel"} style={{ backgroundColor: 'rgb(231, 180, 180)' }} onClick={e => this.actionDeleteImage(msh)}>Delete</Button>
                </DialogActionsBar>
                );

        }

    }

    renderDateCell = (cellProps: any) => {
        let dataItem: MilestoneScanLog = cellProps.dataItem;
        return <td>{dataItem.logDate.format("DD-MMM-YY HH:mm")}</td>;
    }

    renderMessageCell = (cellProps: any) => {
        let dataItem: MilestoneScanLog = cellProps.dataItem;

        return <td className="messageCell">{dataItem.logText}</td>;
    }



    render() {
        let dlgProps: DialogProps = {}
        let stl: React.CSSProperties = {};

        if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser) {
            dlgProps.width = "80%";
            dlgProps.height = "70%";
        } else {
            dlgProps.width = "100%";
            dlgProps.height = "100%";
            stl.borderColor = ""
            dlgProps.style = stl;
        }
        dlgProps.title = this.renderTitle(this.state.milestoneScanItem);
        dlgProps.closeIcon = false;
        return (
            <Dialog className="milestoneScanDialog" {...dlgProps} >
                { this.renderBody(this.state.milestoneScanItem)}
                {this.renderActions(this.state.milestoneScanItem)}
            </Dialog>
        );
    }
}

export default connector(MilestoneScanDialog);
