import React from 'react';
import {
    MenuItemTypeEnum, PageViewTypeEnum, MilestoneScanStatusEnum, MilestoneScanDialogViewEnum, TcbFileLoadStatusEnum, TcbObjDataCatagory, TcbObjClassEnum,
    TcbFileSourceEnum, SplColumnDataTypeEnum, fpcTypeEnum, CQStatusEnum, InputCtlViewMode, MemberAddScanStatusEnum, AddMemberToTcbObjEnum
} from './enums';
import { UploadFileStatus } from '@progress/kendo-react-upload';
import moment from 'moment';


export class DropDownListData {
    public value: string = '';
    public display: string = '';
}

export class ProjectInfo {
    public projectId: number = 0;
    public projectCode: string = '';
    public projectName: string = '';
    public projectDesc: string = '';
    public apiUrl: string = '';
    public homePageUrl: string = '';
    public backgroundImg: string = '';
    public backgroundImgOpacity?: number;
    public isDefaultProject: boolean = false;
    public connInfo: ProjectConnectionInfo = new ProjectConnectionInfo();
    public selected: boolean = false;
    public isUAT: boolean = false;
    public offsetMinutes: number = 0;
}


export class ProjectConnectionInfo {
    public applicationName: string = '';
    public sqlServerName: string = '';
    public sqlDatabaseName: string = '';
    public sqlDatabaseUser: string = '';
}


export class PageInfo {
    public pageHeight: number = 0;
    public pageWidth: number = 0;
    public tst_height: number = 0;
    public tst_availHeight: number = 0;
    public tst_innerheight: number = 0;
    public tst_outerheight: number = 0;
    public headerHeight: number = 0;
    public footerHeight: number = 0;
    public sidebarWidth: number = 0;
    public isMenuExpanded: boolean = false
    public pageViewMode: PageViewTypeEnum = PageViewTypeEnum.Browser;
    public deviceVendor: string = '';
    public deviceModel: string = '';
    public hasCamera: boolean = false;
    public hasGPS: boolean = false;
    public hasLazer: boolean = false;
    public contentHeight(): number { return this.pageHeight - this.headerHeight - this.footerHeight }
    public contentWidth(): number { return this.pageWidth - this.sidebarWidth }
}

export class StoreState {
    public userInfo: UserInfo = new UserInfo();
    public pageInfo: PageInfo = new PageInfo();
}


export class AvailableLanguageItem {
    public locale: string = '';
    public description: string = '';
}
export class LanguageLookupItem {
    public text: string = '';
    public translated: string = '';
}


export class SearchTypeSummaryResults {
    public objCode: string = '';
    public objTypeId: number = 0;
    public objTypeDesc: string = '';
    public canView: boolean = false;
    public objCount: number = 0;
}

export class SearchResultsCriteria {
    public objCode: string = '';
    public objTypeId: number = 0;
    public objTypeDesc: string = '';
    public srchString: string = '';
}

export class SearchItemsResults {
    public itemId: number = 0;
    public displayText: string = '';
    public itemNo: string = '';
    public partNo: number = 0;
    public itemDesc: string = '';
    public itemTypeId: number = 0;
    public itemTypeDesc: string = '';
    public isSundry: boolean = false;
    public qty: number = 0;
}

export class SearchPackListsResults {
    public packListId: number = 0;
    public displayText: string = '';
    public packListDesc: string = '';
    public packListTypeId: number = 0;
    public packListTypeDesc: string = '';
    public parentCount: number = 0;
    public childrenCount: number = 0;
}

export class SearchPackListItemResults {
    public packListItemId: string = '';
    public displayTextPackList: string = '';
    public displayTextItem: string = '';
    public packListId: number = 0;
    public itemId: number = 0;
    public itemNo: string = '';
    public partNo: number = 0;
    public itemDesc: string = '';
    public itemTypeDesc: string = '';
    public isSundry: boolean = false;
    public qty: number = 0;
}

export class TcbObjInfo {
    public tcbObjClass: TcbObjClassEnum = TcbObjClassEnum.Item;     // I,P,T(pli)
    public tcbObjId: number = 0;        // Packlist,Item
    public tcbObjGuidStr: string = '';     //  PackListItemId
    public tcbObjDesc: string = '';     // DisplayText
    public tcbObjTypeId: number = 0;
    public tcbObjTypeDesc: string = '';
    public tcbObjTypeIconCode?: number;
    public tcbObjSundry: boolean = false;
    // public tcbObjMilestoneIn: number | null = null;
    // public tcbObjMilestoneOut: number | null = null;
    public tcbObjQty: number | null = null;
    public tcbObjUOM: string = '';
    public tcbMilestoneScanId: string = '';
    public hasChildren: boolean = false;
    // public hasAttachments: boolean = false;
    // public attachmentFiles: TcbFileItem[] = [];
    public addQty?: number; //for adding items to packlist

    public constructor(init?: Partial<TcbObjInfo>) {
        Object.assign(this, init);
    }
}


export class DrawerItemsType {
    public menutypeid: MenuItemTypeEnum = MenuItemTypeEnum.Blank;
    public menuitemid: number = 0;
    public fixedItem: boolean = true;
    public textBase: string = '';   // e.g. Search, 
    public text: string = '';           //  Build using Base + details e.g. Search (bg404)
    public iconCode: string = '';   //HTML code - use first, otherwise icon
    public iconImgStr: string = ''  //Base64
    public separator: boolean = false;
    public tcbobj?: TcbObjInfo;
    public securityid?: number;
    public clientqueryid?: number;
    public tcbobjs?: TcbObjInfo[];
    public dashboardUrl?: string = '';
    public itemstate?: any;
    public titleview: JSX.Element = React.createElement('div');
    public visible: boolean = true;
    public displaygroup: number = 0;
    public displayorder: number = 0;
    public disabled: boolean = false;
    public selected: boolean = false;
    public groupMenuItems: MenuItemModel[] = [];
    public previousMenu?: number;

    public constructor(init?: Partial<DrawerItemsType>) {
        Object.assign(this, init);
    }
}


export class ItemDetailTabRow {
    keyId: number = 0;
    tabName: string = '';
    tabDisplayName: string = '';
    canEdit: boolean = false;
    tabState?: any;
}

export class MilestoneItem {
    public milestoneId: number = 0;
    public milestoneDesc: string = '';
    public milestoneLines: Array<MilestoneLineItem> = [];
    public mustEditLines: boolean = false;
    public selected: boolean = false;
    public scanDate?: Date;
    public includeTime: boolean = false;
}
export class MilestoneLineItem {
    public milestoneLineId: string = '';
    public milestoneLineDesc: string = '';
    public attribClassId: number = 0;
    public attribClassCode: string = '';
    public dataType: string = '';
    public responseRequired: boolean = false;
    public isSystemData: boolean = false;
    public isSystemDataFetched: boolean = false;
    public isVisiblePDA: boolean = false;
    public isVisibleWeb: boolean = false;
    public isReadOnlyPDA: boolean = false;
    public isReadOnlyWeb: boolean = false;
    public milestoneLineAnswer: string | null = '';
}

export class TcbObjDetailGroup {
    public groupName: string = '';
    public groupOrder: number = 0;
    public objDetails: Array<TcbObjDetailRow> = [];
}

export class TcbObjDetailRow {
    public id: number = 0;
    public tcbObjClass: TcbObjClassEnum = TcbObjClassEnum.Item;
    public tcbObjDataCat: TcbObjDataCatagory = TcbObjDataCatagory.Property;
    public propertyName: string = '';
    public label: string = '';
    public valueStr: string = '';
    public dataType: string = '';
    public attributeClassDataType: string = '';
    public documentTypeId: number = 0;
    public formatStr: string = '';
    public allowEdit: boolean = false;
    public sortOrder: number = 0;
    public viewMode: InputCtlViewMode = InputCtlViewMode.View;
    public toolTip: string = '';
    public attributeClassPrompt: string = '';
}

export class TcbObjMilestoneGrp {
    public milestoneId: number = 0;
    public milestoneDesc: string = '';
    public displayOrder: number = 0;
    public latestMilestoneDate: Date = new Date();
    public includeTime:boolean = false;
    public latestQuantity: number = 0;
    public latestSource: string = '';
    public isExpanded: boolean = false;
    public milestoneItems: Array<TcbObjMilestone> = [];
    public milestoneItemIsAllExpanded: boolean = false;
}

export class TcbObjMilestone {
    public tcbObjMilestoneId: string = '';
    public milestoneId: number = 0;
    public milestoneDesc: string = '';
    public displayOrder: number = 0;
    public milestoneDate: Date = new Date();
    public includeTime:boolean = false;
    public sundry: boolean = false;
    public quantity: number = 0;
    public gpsCoordinates: string = "";
    public lastChangedOn?: Date;
    public lastChangedBy: string = '';
    public isInherited: boolean = false;
    public inheritedTcbObj?: TcbObjInfo;
    public sourceName: string = '';
    public sourcePaths: Array<TcbObjParentPath> = [];
    public sourcePathStr: string = '';
    public isExpanded: boolean = false;
    public milestoneLines: Array<TcbObjMilestoneLineItem> = [];
    public milestoneLinesFetched: boolean = false;
    public milestoneImgs: Array<TcbFileItem> = [];
    public milestoneImgsFetched: boolean = false;

}

export class TcbObjMilestoneLineItem {
    public milestoneLineId: string = '';
    public milestoneLineDesc: string = '';
    public displayOrder: number = 0;
    public answer: string = '';
}


export class TcbObjFileGroup {
    public groupName: string = '';
    public groupOrder: number = 0;
    public defaultGroup: boolean = false;
    public imageList: Array<TcbFileItem> = [];
}
export class TcbFileItem {
    public fileId: string = '';
    public fileName: string = '';
    public fileExt: string = '';
    public fileDesc: string = '';
    public fileRefNo: string = '';
    public documentTypeId?: number;
    public documentTypeDesc: string = '';
    public lastChangedBy: string = '';
    public lastChangedOn?: Date;
    public thumbnailStr: string = ''; //base 64
    public fileOrder: number = 0;
    public fileSrc: TcbFileSourceEnum = TcbFileSourceEnum.Item;
    public fileSrcDesc: string = '';
    public fileSrcFileId: string = '';
    public fileDataStr: string = ''; //base64
    public fileLoadStatus: TcbFileLoadStatusEnum = TcbFileLoadStatusEnum.NotLoaded;
    public fileLoadErrorMsg: string = '';
    // public milestoneDesc: string = '';
    public canEdit: boolean = false;
    public canDelete: boolean = false;
    public displayText: string = '';
    public isImage: boolean = false;
    public selected: boolean = false;
}

export class TcbObjNoteGroup {
    public noteGrpId: number = 0;
    public noteGrpName: string = '';
    public noteAttribValue: string = '';
    public sortOrder: number = 0;
    public canEdit: boolean = false;
    public canDelete: boolean = false;
    public isExpanded: boolean = false;
    public notes: Array<TcbObjNote> = [];
}
export class TcbObjNote {
    public noteId: string = '';
    public noteGrpId: number = 0;
    public author: string = '';
    public created: moment.Moment = moment();
    public noteTxt: string = '';
    public canEdit: boolean = false;
    public editView: boolean = false;
    public noteTxtSave: string = '';
    public saveInProgress: boolean = false;
}

export class TcbObjMember {
    public tcbObj: TcbObjInfo = new TcbObjInfo();
    public memberDesc: string = '';
    public memberStatus: string = '';
    //public milestoneInDesc: string = '';
    //public milestoneOutDesc: string = '';
    public canViewDetails: boolean = false;
    public selected: boolean = false;
}

export class TcbObjMemberOf {
    public memberOfId: string = '';
    public parentPathStr: string = '';
    public parentPathIds: string = '';
    public parentPath: Array<TcbObjMemberOfParentItem> = [];
}

export class TcbObjQuarantine {
    public quarantineId: string = '';
    public comments: string = '';
    public quarantinedOn: Date = new Date();
    public isActive: string = '';
}

export class TcbObjMemberOfParentItem {
    public depth: number = 0;
    public parentObj: TcbObjInfo = new TcbObjInfo();
    public canViewDetails: boolean = false;
}

export class TcbObjParentPath {
    public parentTcbObj: TcbObjInfo = new TcbObjInfo();
    public pathDesc: string = '';
}

export class MemberAddScanHistory {
    public historyId: number = 0;
    public addStatus: MemberAddScanStatusEnum = MemberAddScanStatusEnum.NotStarted;
    public historyText: string = '';
    public tcbObj: TcbObjInfo = new TcbObjInfo();
    public newQty: number = 0;
}


export class MilestoneScanHistory {
    public milestoneScanHistoryId: number = 0;
    public milestone: MilestoneItem = new MilestoneItem();
    public scanDate?: moment.Moment;
    public tcbObjs: TcbObjInfo[] = [];
    public gpsCoordinates: string|null = null;
    public scanImages: TcbFileItem[] = [];
    public scanSubmitted: boolean = false;
    public scanStatus: MilestoneScanStatusEnum = MilestoneScanStatusEnum.NotStarted;
    public scanDialogView: MilestoneScanDialogViewEnum = MilestoneScanDialogViewEnum.None;
    public scanDialogOnCloseView: MilestoneScanDialogViewEnum = MilestoneScanDialogViewEnum.None;
    public scanDialogStatus?: MilestoneScanStatusEnum;
    public scanLog: MilestoneScanLog[] = [];
}

export class MilestoneScanLog {
    public logDate: moment.Moment;
    public action: string = '';
    public logText: string = '';

    constructor(action: string, logText: string) {
        this.logDate = moment();
        this.action = action;
        this.logText = logText
    }
}
export class PdaAddMemberLog {
    public logDate: moment.Moment;
    public action: string = '';
    public logText: string = '';

    constructor(action: string, logText: string) {
        this.logDate = moment();
        this.action = action;
        this.logText = logText
    }
}

export class GetMenuItemRow {
    public securityId: number = 0;
    public parentSecurityId?: number;
    public securityActionTypeId: MenuItemTypeEnum = MenuItemTypeEnum.Blank;
    public description: string = '';
    public sortOrder: number = 0;
    public packListTypeId?: number;
    public clientQueryId?: number;
    public dashboardUrl: string = '';
    public iconCode: string = '';
    public iconImageStr: string = '';
    public browserEnable: boolean = true;
    public mobileEnable: boolean = true;
    public tabletEnable: boolean = true;
    public items: GetMenuItemRow[] = [];
}

export class MenuItemModel {
    public id?: string = '';
    public text?: string;
    public cssClass?: string;
    public data?: any;
    public icon?: string;
    public disabled?: boolean;
    public linkRender?: any;
    public action?: string;
    public items: MenuItemModel[] = [];
}

export class SearchAttributeLookup {
    public attribType: string = '';
    public attribDesc: string = '';
}

export class UserInfo {
    public userId: number = 0;
    public username: string = '';
    public displayName: string = '';
    public token: string = '';
    public currProject: ProjectInfo = new ProjectInfo();
    public currProjectUserId: number = 0;
    public currProjectUserRoleId: number = 0;
    public currProjectUserRoleName: string = '';
    public userProjects: ProjectInfo[] = [];
    public mFAChallenge: boolean = false;
    public locale: string = 'en-AU';
    public langlookups: Array<LanguageLookupItem> = [];
    public isAuthorised: boolean = false;
    public isSuperUser: boolean = false;
    public userData: string|null = null;
}

export class TcbObjTreeItem {
    public id: string = '';  //unique id for each row (Guid frm api)
    public parentId: string = '';
    public tcbObj: TcbObjInfo = new TcbObjInfo();
    public hasChildren: boolean = false;
    public childItmsFetching: boolean = false;
    public childItmsFetched: boolean = false;
    public itemExpanded: boolean = false;
    public itemSelected: boolean = false;
    public itemHighlighted: boolean = false;
    public contextMenuEnabled: boolean = true;
    public colData: TcbObjTreeColItem[] = [];
    public childItems: TcbObjTreeItem[] = [];
    public hasAttachments: boolean = false;
    public attachmentDownloading: boolean = false;
    public attachmentFiles: TcbFileItem[] = [];
    public hasImages:boolean = false;
    public isLinked:boolean = false;
}

export class TcbObjTreeColItem {
    public id: number = 0;
    public dataType: SplColumnDataTypeEnum = SplColumnDataTypeEnum.NoData;
    public value: any;
    public valueFetched: boolean = false;
}

export class SplFetchColDataResult {
    public treeId: string = '';
    public colIndex: number = 0;
    public dataType: SplColumnDataTypeEnum = SplColumnDataTypeEnum.String;
    public stringVal: string = '';
    public dateVal?: Date;
    public boolVal?: boolean;
    public attachmentList: TcbFileItem[] = [];
}

export class SplColDataDesc {
    public id: string = '';
    public metadataId: string = '';
    public value: string = '';
    public visible: boolean = false;
    public columnType: string = '';
    public pixels: number = 0;
    public dataType: string = '';
    public columnDesc: string = '';
    public columnOrder: number = 0;
    public locked: boolean = true;

    public constructor(init?: Partial<SplColDataDesc>) {
        Object.assign(this, init);
    }
}

// export class SplColDataParams {
//     public colDataItm: Array<SplColDataItm> = [];
// }

// export class SplColDataItm {
//     public tcbObj: TcbObjInfo = new TcbObjInfo();
//     public colDataDesc: SplColDataDesc = new SplColDataDesc();
// }

export class SplSearchFilterResultItem {
    public key: string = '';
    public value: string = '';
}

export class SplFetchQueueItem {
    public tcbObj: TcbObjInfo = new TcbObjInfo();
    public colDataDesc: SplColDataDesc = new SplColDataDesc();
    public treeId: string = '';
    public colIndex: number = 0;
}


export class TcbObjContextMenuItem {
    public tcbObjClass: TcbObjClassEnum = TcbObjClassEnum.Item;     // I,P,L
    public tcbObjTypeId: number = 0;
    public menuItems: MenuItemModel[] = [];
}

export class GetAddItemsForPacklistResult {
    public packListId: number = 0;
    public firstMilestoneId: number = 0;
    public askInherit: boolean = false;
    public doInherit: boolean = false;
    public itemResults: AddItemForPacklist[] = [];
}
export class AddItemForPacklist {
    public itemId: number = 0;
    public itemNo: string = '';
    public partNo: number = 0;
    public itemDesc: string = '';
    public itemDisplayText: string = '';
    public sundry: boolean = false;
    public existingQty?: number;
    public sortOrder: string = '';
    public level: number = 0;
    public isAvailable: boolean = false;
    public toolTip: string = '';
    public selected: boolean = false;
    public changeQty?: number;
}

export class AddScanItemToPackListResult {
    public retStatus: string = '';
    public retStatusMsg: string = '';
}

export class TcbFileUploadDefaultsResp {
    public maxUploadFileSize: number = 0;
    public allowedAttachmentTypes: string[] = [];
    public allowedImageTypes: string[] = [];
    public defaultItemImageDocTypeID: number | undefined;
    public defaultPacklistImageDocTypeID: number | undefined;
    public defaultMilestoneImageDocTypeID: number | undefined;
}



//Region -  Fpc Models
export class FpcModel {

    // public isLoading: boolean = false;
    public fpcType: fpcTypeEnum = fpcTypeEnum.ClientQuery;
    public fpcStatus: CQStatusEnum = CQStatusEnum.SelectCQ;
    public cqHeader: string = 'Process';

    public stepperItems: StepperItem[] = [];
    public stepperCurrentIndex: number = 0;
    public stepperEnableBackButton: boolean = false;
    public stepperEnableNextButton: boolean = true;
    public showStepper: boolean = true;

    public clientQueryGrpId?: number;
    public clientQueryId: number = 0;
    public clientQueryDesc: string = '';
    public clientQueryTcbObjs: TcbObjInfo[] = [];
    public clientQueryParamValues: string = '';
    public clientQueryParams: Array<ClientQueryParamsView> = [];
    public cqValidationTypeId: number = 0;
    public cqSQL: string = '';
    public cqValidationSQL: string = '';
    public cqHelpDescription: string = '';

    public userInfo: UserInfo = new UserInfo();

    // public currentUserRoleName: string = '';
    public replyEmailAddr: string = '';
    // public noTitleMode: boolean = false;
    public webPageTitle: string = '';
    public information: string = '';

    public canSelectQuery: boolean = false;
    public errorTitle: string = '';
    public errorMessage: string = '';

    public loadingStatusMsg: string = '';
    public resultJsonStr: string = '';

    //Select CQ Persisted State Data
    public defClientQueryLookupVal: ClientQueryLookupData = new ClientQueryLookupData();


    //Validation Returned Data
    public ValidationInfo: ValidationData = new ValidationData();

    //Results Returned Data
    public ResultData: ClientQueryResult = new ClientQueryResult();


    //Error Page Data
    public ErrorData: ClientQueryErrorData = new ClientQueryErrorData();

}

export class FpcParam {
    public ParameterName: string = '';
    public ParameterValue: string = '';
}

export class ClientQueryParamsView {
    public clientQueryParameterID: number = 0;
    public parameterName: string = '';
    public displayName: string = '';
    public parameterClassCode: string = '';
    public parameterSqlType: string = '';
    public returnValueType: string = '';
    public isRequired: boolean = false;
    public isVisible: boolean = false;
    public hasLookupSQL: boolean = false;
    public lookupSQL: string = '';
    public lookupData: Array<ClientQueryLookupData> = [];
    public defaultValueSQL: string = '';
    public defaultValue: string = '';
    public defaultValueFetched: boolean = false;
    public defaultValueFetchInProg: boolean = false;
    public displayOrder: number = 0;
    public parameterValue: string = '';
    public parameterHelp: string = '';
}

export class ValidationData {
    public ActionCode: string = '';
    public Columns: Array<ValidationColumn> = [];
    public Data: Array<any> = [];
    public DataKeyName: string = '';
    public inEdit: boolean = false;
}

export class ValidationColumn {
    constructor(name: string, fieldName: string) {
        this.name = name;
        this.fieldName = fieldName;
    }
    public name: string = '';
    public fieldName: string = '';
    public displayName: string = '';
    public dataType: string = 'string';
    public formatCode: string = '';
    public headerAlign: string = '';
    public columnAlign: string = '';
    public allowEdit: boolean = false;
    public visible: boolean = true;
    public width: number = 0;
    public minWidth: number = 0;
    public lookupSql: string = '';
    public lookupData: Array<ClientQueryLookupData> = [];
    public canFilter: boolean = true;
}

export class ClientQueryItem {
    public id: number = 0;
    public description: string = '';
    public sortOrder: number = 0;
}

export class ClientQueryResult {
    public ActionCode: string = '';
    public Columns: Array<ValidationColumn> = [];
    public Data: Array<any> = [];
    public ClientQueryResultId: string = '';
    public ClientQueryResultRowCount: number = 0;
}

// export class ClientQueryResultRow {
//     public Id: string = '';
//     public Cells: Array<string> = [];
// }

export class ClientQueryLookupData {
    public value: string = '';
    public display: string = '';
}

export class ClientQueryErrorData {
    public ErrorTitle: string = '';
    public Columns: Array<ValidationColumn> = [];
    public Data: Array<any> = [];
}

export class StepperItem {
    public index: number = 0;
    public label: string = '';
    public icon: string = '';
    public disabled: boolean = false;
    public required: boolean = false;
    public optional: boolean = false;
    public visible: string = '';
    public isValid: boolean = true;
    public current: boolean = false;
}

export class FileList {
    public fileId: string = '';
    public name: string = '';
    public extension: string = '';
    public progress: number = 0;
    public size?: number = 0;
    public status: UploadFileStatus = UploadFileStatus.Initial;
}

export class DashboardItem {
    public powerBiDashboardId: string = '';
    public dashboardName: string = '';
    public dashboardUrl: string = '';
}

export class SearchPageColDef {
    public field: string = '';
    public title?: string = '';
    public width?: number;
    public canFilter: boolean = true;
}


export class GridColConfig {
    public name: string = '';
    public title: string = '';
    public field: string = '';
    public width: number = 0;
    public resizable: boolean = false;
    public canSort: boolean = true;
    public canFilter: boolean = true;
}

export class MemberAddHistory {
    public parentObj: TcbObjInfo = new TcbObjInfo();
    public addObj: TcbObjInfo = new TcbObjInfo();
    public addStatus: AddMemberToTcbObjEnum = AddMemberToTcbObjEnum.NotStarted;
    public addStatusMsg: string = '';
    public addDate: Date = new Date();
}


export class PdaMemberAddHistory {
    public pdaMemberAddHistoryId: number = 0;
    public parentObj: TcbObjInfo = new TcbObjInfo();
    public addObj: TcbObjInfo = new TcbObjInfo();
    public addMemberDate: moment.Moment = moment();
    public addMemberStatus: AddMemberToTcbObjEnum = AddMemberToTcbObjEnum.NotStarted;
    public addMemberStatusMsg: string = '';
    public addMemberDialogView: AddMemberToTcbObjEnum = AddMemberToTcbObjEnum.NotStarted;
    public actionLog: PdaAddMemberLog[] = [];
}
