import { StoreState, UserInfo, PageInfo } from '../models/models';
import { UPDATE_USERINFO, CLEAR_USERINFO, UPDATE_PAGEINFO, UserInfoActionType, PageInfoActionType} from './reduxActionTypes';

export function updateUserInfo(newUsrInf: UserInfo): UserInfoActionType {
    return {
        type: UPDATE_USERINFO,
        userInf: newUsrInf
    }
}

export function clearUserInfo():UserInfoActionType {
    return {
        type: CLEAR_USERINFO        
    }
}

export function updatePageInfo(newPageInf: PageInfo): PageInfoActionType {
    return {
        type: UPDATE_PAGEINFO,
        pageInf: newPageInf
    }
}

export const mapStateToProps = (state: StoreState) => ({
    userInf: state.userInfo,
    pageInf: state.pageInfo
  })
  

export const mapDispatchToProps = {
    updateUserInfo: (newUsrInf: UserInfo) => {
      return {
        type: UPDATE_USERINFO,
        userInf: newUsrInf
      }
    },
    clearUserInfo: () => {
        return {
            type: CLEAR_USERINFO
        }
    },
    updatePageInfo: (newPageInf: PageInfo) => {
      return {
        type: UPDATE_PAGEINFO,
        pageInf: newPageInf
      }
    }
  }
  