import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { FpcModel, ClientQueryParamsView, ClientQueryLookupData } from '../../models/models';
import { Input, NumericTextBox, NumericTextBoxChangeEvent, Checkbox, CheckboxChangeEvent, NumericTextBoxProps } from '@progress/kendo-react-inputs'
import { DatePicker, DatePickerChangeEvent, DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { UploadFileInfo } from '@progress/kendo-react-upload';
import DropDownListCtl from './InputCtls/DropDownListCtl'
import FileUploadCtl from './InputCtls/FileUploadCtl';
import PasswordCtl from './InputCtls/PasswordCtl';
import { FpcLib } from '../../models/fpcModule';

import { StepItemIndexEnum } from '../../models/enums';

import moment from 'moment'

import './Params.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type ParamsProps = PropsFromRedux & {
    model: FpcModel;
    onModelUpdate: (r: FpcModel) => void;
}

interface ParamsState {
    fpc: FpcModel;
}

class Params extends React.Component<ParamsProps, ParamsState> {
    constructor(props: ParamsProps) {
        super(props);

        this.state = {
            fpc: props.model
        }

    };
    private _fpcLib: FpcLib = new FpcLib();

    componentDidMount() {
        // Check if any "required system params" are not supplied
        let fpc = this.state.fpc;

        if (fpc.clientQueryParams.some(x => x.isRequired && !x.isVisible && x.parameterValue == null)) {
            this._fpcLib.SetErrorMessage(fpc, "Correct item(s) not Selected", "Please select appropriate item(s) for this query and try again");
            this.props.onModelUpdate(fpc);
        } else {
            this._fpcLib.UpdateStepperStatusWithRequiredParamsStatus(fpc);
            this._fpcLib.SetCurrentStepperItem(fpc, StepItemIndexEnum.Parameters);
            fpc.clientQueryParams.filter(x => x.defaultValueSQL && !x.defaultValueFetched).forEach(x => {
                this.fetchDefaultValue(x);
            });
            this.setState({ fpc: fpc });
            this.props.onModelUpdate(fpc);
        }
    }


    paramCtlTextBox = (paramView: ClientQueryParamsView) => {

        let inputCtl: JSX.Element;
        if (paramView.defaultValueSQL && !paramView.defaultValueFetched) {
            inputCtl = <div className="k-loading-image paramLoader"></div>
        } else {

            switch (paramView.parameterClassCode) {
                case "FreeText":
                case "CQ - Text":
                default:
                    inputCtl = <Input type='text' autoComplete='off' className="paramsInput" defaultValue={paramView.parameterValue} onChange={e => this.UpdateInputParamVal(e.value, paramView)} width="100%" />
                    break;

                case "LookupList":
                case "QueryList":
                    inputCtl = <DropDownListCtl authToken={this.state.fpc.userInfo.token} lookupSql={paramView.lookupSQL} value={paramView.parameterValue}
                        onSelectChange={(e: string) => { this.UpdateParamVal(paramView, e) }}
                        onCancelChange={() => { }} />

                    break;

                case "Date":
                case "CQ - Date":

                    let defDateVal: Date | undefined = undefined;
                    if (paramView.parameterValue) {
                        let tmp = moment(paramView.parameterValue, "YYYY-MM-DD");
                        if (tmp.isValid()) {
                            defDateVal = tmp.toDate();
                        } else {
                            tmp = moment(paramView.parameterValue, "DD-MM-YYYY");
                            if (tmp.isValid()) {
                                defDateVal = tmp.toDate();
                            }
                        }
                    }
                    inputCtl = <DatePicker defaultValue={defDateVal} onChange={e => this.UpdateDateParamVal(e, paramView)} format="dd-MMM-yyyy" />

                    break;

                case "DateTime":
                case "CQ - DateTime":

                    let defTimeVal: Date | undefined = undefined;
                    if (paramView.parameterValue) {
                        let tmp = moment(paramView.parameterValue, "YYYY-MM-DD HH:mm:ss");
                        if (tmp.isValid()) {
                            defTimeVal = tmp.toDate();
                        } else {
                            tmp = moment(paramView.parameterValue, "DD-MM-YYYY HH:mm:ss");
                            if (tmp.isValid()) {
                                defTimeVal = tmp.toDate();
                            }
                        }
                    }
                    inputCtl = <DateTimePicker defaultValue={defTimeVal} onChange={e => this.UpdateDateTimeParamVal(e, paramView)} format="dd-MMM-yyyy HH:mm:ss" />

                    break;


                case "File Upload":
                    let files: Array<UploadFileInfo> = [];
                    if (paramView.parameterValue)
                        files = JSON.parse(paramView.parameterValue);
                    inputCtl = <FileUploadCtl authToken={this.state.fpc.userInfo.token} onChange={e => this.UpdateFileListParamVal(e, paramView)} defaultFiles={files} />
                    break;

                case "Yes or No":
                case "CQ - Yes or No":
                    let tmpParamValBool = (paramView.parameterValue === "1");
                    inputCtl = <Checkbox checked={tmpParamValBool} onChange={(e) => { this.UpdateBoolParamVal(e, paramView) }} />

                    break;

                case "CQ - Yes or No or Null":

                    let availVals: Array<ClientQueryLookupData> = [];
                    availVals.push({ value: '', display: '' });
                    availVals.push({ value: '1', display: 'True' });
                    availVals.push({ value: '0', display: 'False' });

                    inputCtl = <DropDownListCtl authToken={this.state.fpc.userInfo.token} lookupData={availVals} value={paramView.parameterValue}
                        filterable={false} width='150px'
                        onSelectChange={(e: string) => { this.UpdateParamVal(paramView, e) }}
                        onCancelChange={() => { }} />

                    break;


                case "CQ - Decimal":
                    let tbProps: NumericTextBoxProps = {}
                    if (paramView.parameterValue)
                        tbProps["value"] = Number(paramView.parameterValue);
                    inputCtl = <NumericTextBox {...tbProps} onChange={e => this.UpdateNumericParamVal(e, paramView)} />

                    break;

                case "CQ - Password":
                    inputCtl = <PasswordCtl onChange={e => this.UpdateInputParamVal(e, paramView)} />
                    break;
            }
        }

        let retVal: any[] = [];
        retVal.push(<td className="paramsCtlTblInputCell" key={1}>{inputCtl}</td>);
        retVal.push(<td className="paramsCtlTblReqCell" key={2}>{paramView.isRequired && <span className='requiredIndicator'>*</span>}</td>);

        return (retVal);
    }

    UpdateInputParamVal = (val: string, paramView: ClientQueryParamsView) => {

        this.UpdateParamVal(paramView, val);

    }

    UpdateDateParamVal = (event: DatePickerChangeEvent, paramView: ClientQueryParamsView) => {

        if (event.value) {
            let dateStr: string = moment(event.value).format("YYYY-MM-DD");
            this.UpdateParamVal(paramView, dateStr);
        }

    }

    UpdateDateTimeParamVal = (event: DateTimePickerChangeEvent, paramView: ClientQueryParamsView) => {

        if (event.value) {
            let dateStr: string = moment(event.value).format("YYYY-MM-DD HH:mm");
            this.UpdateParamVal(paramView, dateStr);
        }

    }

    UpdateBoolParamVal = (event: CheckboxChangeEvent, paramView: ClientQueryParamsView) => {
        if (event.value)
            this.UpdateParamVal(paramView, "1");
        else
            this.UpdateParamVal(paramView, "0");
    }

    UpdateNumericParamVal = (event: NumericTextBoxChangeEvent, paramView: ClientQueryParamsView) => {
        this.UpdateParamVal(paramView, (event.value !== null) ? event.value.toString() : '');
    }

    UpdateFileListParamVal = (files: Array<UploadFileInfo>, paramView: ClientQueryParamsView) => {
        if (files.length === 0) {
            this.UpdateParamVal(paramView, '');
        } else {
            this.UpdateParamVal(paramView, JSON.stringify(files));
        }
    }

    UpdateParamVal = (paramView: ClientQueryParamsView, paramValue: string) => {

        let fpc = this.state.fpc;

        var par = fpc.clientQueryParams.find((f) => f.clientQueryParameterID === paramView.clientQueryParameterID);
        if (par) {
            par.parameterValue = paramValue;

            this._fpcLib.UpdateStepperStatusWithRequiredParamsStatus(fpc);
            this._fpcLib.SetCurrentStepperItem(fpc, StepItemIndexEnum.Parameters);

            this.setState({ fpc: fpc });
            this.props.onModelUpdate(fpc);
        }

    }

    fetchDefaultValue = (paramView: ClientQueryParamsView) => {
        let fpm = this.state.fpc;

        let url = this.props.userInf.currProject.apiUrl + '/api/Fpc/GetClientQueryParamDefValue';
        let body = {
            lookupSql: paramView.defaultValueSQL
        }
        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + fpm.userInfo.token }) })
            .then(this._fpcLib.HandleErrors)
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    this._fpcLib.SetErrorMessage(fpm, "Fetch default value failed", res.callStatusMessage);
                } else {

                    var par = fpm.clientQueryParams.find((f) => f.clientQueryParameterID === paramView.clientQueryParameterID);
                    if (par) {
                        par.parameterValue = res.stringResponse;
                        par.defaultValueFetchInProg = false;
                        par.defaultValueFetched = true;

                        this._fpcLib.UpdateStepperStatusWithRequiredParamsStatus(fpm);
                        this._fpcLib.SetCurrentStepperItem(fpm, StepItemIndexEnum.Parameters);

                        this.setState({ fpc: fpm });
                        this.props.onModelUpdate(fpm);
                    }
                }
            })
            .catch(err => {
            })
            .finally(() => {
            });
    }



    private checkRequiredParams(): boolean {
        let fpc = this.state.fpc;

        return !fpc.clientQueryParams.find((x) => x.isRequired && !x.parameterValue)
    }

    private submitToolTip(): string {
        if (!this.checkRequiredParams()) {
            return 'Some required parameters have not been supplied';
        } else {
            return 'Submit for Processing'
        }
    }


    buildParameterControls = () => {
        return (
            <table id='paramCtlsTable'>
                <tbody>
                    {this.state.fpc.clientQueryParams.sort((a, b) => { return a.displayOrder - b.displayOrder }).map((val: ClientQueryParamsView, index) => {
                        if (val.isVisible) {

                            return (
                                <tr key={index}>
                                    <th>{val.displayName}&nbsp;
                                        {val.parameterHelp &&
                                            <span className="k-icon k-font-icon k-i-information" title={val.parameterHelp} />}
                                    </th>
                                    {this.paramCtlTextBox(val)}
                                </tr>
                            );
                        } else {
                            return null;
                        }
                    })}
                </tbody>
            </table>
        );
    }

    render() {


        return (
            <div className='paramsCqDiv'>
                <this.buildParameterControls />
            </div>
        )
    };

}


export default connector(Params);
