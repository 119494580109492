import React from 'react';
import { ValidationColumn, FpcModel } from '../../models/models';
import { Input, Checkbox, NumericTextBox } from '@progress/kendo-react-inputs'
import DropDownListCtl from './InputCtls/DropDownListCtl'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { Grid, GridColumn as Column, GridColumnProps, GridSortChangeEvent, GridFilterChangeEvent, GridProps, GridHeaderSelectionChangeEvent, GridHeaderCellProps, GridColumnMenuFilter, GridColumnMenuSort } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import moment from 'moment'

import './Confirm.css';
//import { align } from '@progress/kendo-popup-common';


interface ConfirmProps {
    model: FpcModel;
    onModelUpdate: (r: FpcModel) => void;
}

interface ConfirmState {
    fpc: FpcModel;
    gridData: any[];
    gridFilteredData: any[];
    isLoading: boolean;
    editRowKeyVal: any;
    editColfieldName: string;
    colDefs: JSX.Element[];
    gridStyle: React.CSSProperties;
    gridSortDescriptor: SortDescriptor[];
    gridFilterDescriptor: CompositeFilterDescriptor | undefined;
    selectAllValue: boolean;
}

class Confirm extends React.Component<ConfirmProps, ConfirmState> {
    constructor(props: ConfirmProps) {
        super(props);

        let d = document,
        body = d.getElementById('fpcMainDiv'),
        header = d.getElementById('fpcAppHeader');

        let gridHeight: number
        
        if (body && header){
            gridHeight = body.clientHeight - header.clientHeight
                        }
                        else{
                            gridHeight = 400
                        }

        this.state = {
            fpc: props.model,
            gridData: props.model.ValidationInfo.Data,
            gridFilteredData: props.model.ValidationInfo.Data,
            isLoading: false,
            editRowKeyVal: null,
            editColfieldName: '',
            colDefs: props.model.ValidationInfo.Columns.filter((f) => f.visible).map((c: ValidationColumn) => this.renderGridCol(c)),
            gridStyle: { height: gridHeight },
            gridSortDescriptor: [],
            gridFilterDescriptor: undefined,
            selectAllValue: false//this.setSelectAllVal()
        }
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        //this.setState({selectAllValue: this.setSelectAllVal()})
    }


    setSelectAllVal = () => {
        //Does "IsSelect" field exist
        if (this.props.model.ValidationInfo.Columns.some(x => x.fieldName === "IsSelected")) {
            //Only return true if all rows are true
            this.props.model.ValidationInfo.Data.forEach(e => { if (!e["IsSelected"]) return false; });

        }
        return true;
    }

    gridSortChange = (e: GridSortChangeEvent) => {
        // const tmpData = orderBy(this.state.gridFilteredData, e.sort);

        // this.setState({
        //     gridFilteredData: tmpData,
        //     gridFilteredDataView: tmpData.slice(0, gridPageSize),
        //     gridFilteredDataViewSkip: gridPageSize,
        //     gridSortDescriptor: e.sort
        // });

    }

    gridFilterChange = (event: GridFilterChangeEvent) => {

        const tmpData = orderBy(filterBy(this.state.gridData, event.filter), this.state.gridSortDescriptor);
        this.setState({
            gridFilteredData: tmpData,
            gridFilterDescriptor: event.filter,
        });

    }


    updateDimensions = () => {

        //Set Grid width first... it determines if scroll bar appears at bottom (which affects buttons)
        //this.setGridwidth(() => this.setGridHeight())

        let d = document,
        body = d.getElementById('fpcMainDiv'),
        header = d.getElementById('fpcAppHeader');

        let gridHeight: number
        
        if (body && header){
            gridHeight = body.clientHeight - header.clientHeight
                        }
                        else{
                            gridHeight = 400
                        }

            this.setState({gridStyle: { height: gridHeight }})

    }

    renderGridCol = (c: ValidationColumn) => {

        var colProps: GridColumnProps = {};
        if (c.width !== 0)
            colProps.width = c.width;
        colProps.field = c.fieldName;
        colProps.title = c.displayName;
        colProps.filterable = true;

        if (c.fieldName === "IsSelected") {
            colProps.headerCell = this.selectHeaderCell;
        } else {
            colProps.headerCell = this.standardHeaderCell;
        }
        return (<Column key={c.fieldName} headerClassName="confirmHeader"
            columnMenu={props => <div><GridColumnMenuSort {...props} />
                <GridColumnMenuFilter {...props} />
            </div>}  {...colProps} ></Column>);

    }

    standardHeaderCell = (e: GridHeaderCellProps) => {
        return (<span title={e.title} style={{ fontWeight: "bold" }}>{e.title}</span>);
    }

    selectHeaderCell = (e: GridHeaderCellProps) => {
        return (<span title="Select All"><Checkbox onChange={(e) => this.SelectAllChanged(e.value)} /></span>);
    }




    buildValidationGrid = () => {

        if (this.state.isLoading) {
            return (
                <div className="centreAlign">  
                    <div className="k-loading-mask">
                        <span className="k-loading-text">Loading</span>
                        <div className="k-loading-image"></div>
                        <div className="k-loading-color"></div>
                    </div>
                </div>
            );
        } else {


            let gridProps: GridProps = {};

            if (this.state.fpc.ValidationInfo.ActionCode.toLowerCase() === "validatemultiselect" ||
                this.state.fpc.ValidationInfo.ActionCode.toLowerCase() === "confirm_with_multiple_selection") {

                gridProps["selectedField"] = "selected";
                gridProps["onHeaderSelectionChange"] = this.GridHeaderSelectChanged;

            }

            return (
                <div className="centreAlign" >
                    <div className="confirmGridDiv" >
                        <Grid
                            className="confirmGrid"
                            style={this.state.gridStyle}
                            data={this.state.gridFilteredData}
                            sortable={true}
                            sort={this.state.gridSortDescriptor}
                            onSortChange={this.gridSortChange}
                            filterable={true}
                            filter={this.state.gridFilterDescriptor}
                            onFilterChange={this.gridFilterChange}
                            rowRender={this.gridRowRender}
                            cellRender={this.gridCellRender}
                            resizable
                            {...gridProps}
                        >
                            {this.state.colDefs}
                        </Grid>
                    </div>
                </div>
            );
        }
    }

    gridRowRender = (row: React.ReactElement<HTMLTableRowElement>, rowProps: any) => {
        let retRow: JSX.Element;

        if (rowProps.dataItem.dirty)
            retRow = <tr className="dirty">{rowProps.children}</tr>;
        else
            retRow = <tr>{rowProps.children}</tr>;


        return retRow;

    }


    gridCellRender = (tdElement: any, cellProps: any) => {
        let fieldName: string = cellProps.field;
        let fieldVal = cellProps.dataItem[cellProps.field];
        let keyVal = cellProps.dataItem[this.state.fpc.ValidationInfo.DataKeyName];
        let colDef = this.state.fpc.ValidationInfo.Columns.find((x) => x.fieldName === fieldName) || new ValidationColumn(fieldName, fieldName);


        let retCell: JSX.Element;
        let tdProps: any = {};
        if (colDef.columnAlign)
            tdProps = { style: { textAlign: colDef.columnAlign } };

        if (colDef.allowEdit && (this.state.editRowKeyVal === keyVal) && (this.state.editColfieldName === fieldName)) {

            let retComp: JSX.Element;
            if (colDef.lookupSql) {
                retComp = <DropDownListCtl lookupData={colDef.lookupData} value={fieldVal}
                    onSelectChange={(val: string) => { this.UpdateFieldValue(keyVal, fieldName, val, true) }}
                    onCancelChange={this.CancelEditMode} />
            } else {

                switch (colDef.dataType.toLowerCase()) {
                    case "select":
                        let valBool: boolean = (fieldVal === true || fieldVal === 1 || fieldVal === "1");
                        retComp = <Checkbox key={keyVal} checked={valBool} onChange={(e) => this.SetRowSelected(keyVal, e.value)} />
                        break;
                    case "boolean":
                        let valBool2: boolean = (fieldVal === true || fieldVal === 1 || fieldVal === "1");
                        retComp = <Checkbox key={keyVal} checked={valBool2} onChange={(e) => this.UpdateCheckboxField(keyVal, fieldName, e.value)} />
                        break;
                    case "date":
                        let dateVal = new Date();
                        let dateParse = moment(fieldVal);
                        if (!dateParse.isValid()) {
                            // Try crappy old format
                            dateParse = moment(fieldVal, "DD/MM/YYYY");
                        }
                        if (dateParse.isValid())
                            dateVal = moment(dateParse).toDate();

                        retComp = <DatePicker key={keyVal} value={dateVal} onChange={(e) => this.ExitDateEditMode(keyVal, fieldName, e.value)} format="dd-MMM-yy" width="100%" />

                        break;
                    case "number":
                    case "decimal":
                        retComp = <NumericTextBox key={keyVal} value={fieldVal} inputType="text" width="100%"
                            onChange={(e) => this.UpdateFieldValue(keyVal, fieldName, e.value, false)}
                            onBlur={() => this.CancelEditMode()} />
                        break;

                    case "html":
                    case "string":
                    default:
                        retComp = <Input key={keyVal} autoFocus defaultValue={fieldVal} style={{ width: "100%" }}
                            onChange={(e) => this.UpdateInputField(e.value, keyVal, fieldName)}
                            onBlur={() => this.CancelEditMode()} />
                        break;
                }
            }

            retCell = <td {...tdProps} >{retComp}</td>

        } else {
            //Just format as per dataType

            if (colDef.lookupSql) {
                let lookupItem = colDef.lookupData.find(x => x.value === fieldVal?.toString());
                if (lookupItem) {
                    retCell = <td {...tdProps} onClick={() => { this.EnterEditMode(keyVal, fieldName) }} >{lookupItem.display}<span className="k-icon k-font-icon k-i-edit k-icon-8" style={{ float: "right", color: "gainsboro" }} onClick={() => { this.EnterEditMode(keyVal, fieldName) }}></span></td>
                } else {
                    retCell = <td {...tdProps} onClick={() => { this.EnterEditMode(keyVal, fieldName) }} ><span className="k-icon k-font-icon k-i-edit k-icon-8" style={{ float: "right", color: "gainsboro" }} onClick={() => { this.EnterEditMode(keyVal, fieldName) }}></span></td>
                }
            } else {

                switch (colDef.dataType.toLowerCase()) {
                    case "select":
                        let valBool: boolean = (fieldVal === true || fieldVal === 1 || fieldVal === "1");
                        retCell = <td {...tdProps} ><Checkbox key={keyVal} className="selCbx" checked={valBool} onChange={(e) => this.SetRowSelected(keyVal, e.value)} /></td>
                        break;

                    case "boolean":
                        let valBool2: boolean = (fieldVal === true || fieldVal === 1 || fieldVal === "1");
                        retCell = <td {...tdProps} ><Checkbox key={keyVal} className="selCbx" disabled={!colDef.allowEdit} checked={valBool2} onChange={(e) => this.UpdateCheckboxField(keyVal, fieldName, e.value)} /></td>
                        break;

                    case "date":
                    case "datetime":
                        let dateParse = moment(fieldVal);
                        if (!dateParse.isValid()) {
                            // Try converting using old format
                            dateParse = moment(fieldVal, "DD/MM/YYYY");
                        }
                        if (dateParse.isValid()) {
                            if (colDef.formatCode)
                                fieldVal = moment(dateParse).format(colDef.formatCode);
                            else
                                fieldVal = moment(dateParse).format("DD-MMM-YY");
                        }

                        retCell = <td {...tdProps} onClick={() => { this.EnterEditMode(keyVal, fieldName) }} >{fieldVal}</td>

                        break;

                    case "html":
                        retCell = <td dangerouslySetInnerHTML={{ __html: fieldVal }} {...tdProps} onClick={() => { this.EnterEditMode(keyVal, fieldName) }}  ></td>

                        break;

                    case "string":
                    default:
                        if (colDef.allowEdit)
                            retCell = <td {...tdProps} onClick={() => { this.EnterEditMode(keyVal, fieldName) }}  ><span>{fieldVal}</span><span className="k-icon k-font-icon k-i-edit k-icon-8" style={{ float: "right", color: "gainsboro" }} onClick={() => { this.EnterEditMode(keyVal, fieldName) }}></span></td>
                        else
                            retCell = <td {...tdProps} >{fieldVal}</td>

                        break;
                }

            }
        }
        return (retCell);
    }


    GridHeaderSelectChanged = (e: GridHeaderSelectionChangeEvent) => {
        const target = e.syntheticEvent.target as HTMLInputElement;

        //Update both full datalist and the grid view.
        let fpc = this.state.fpc;
        fpc.ValidationInfo.Data.forEach(x => x.selected = target.checked);
        this.setState({ fpc: fpc });
        this.props.onModelUpdate(fpc);
    }

    EnterEditMode = (keyVal: any, fieldName: string) => {
        this.setState({
            editRowKeyVal: keyVal,
            editColfieldName: fieldName
        });
    }

    CancelEditMode = () => {
        this.setState({
            editColfieldName: '',
            editRowKeyVal: ''
        });
    }

    SelectAllChanged = (selVal: boolean) => {
        let fp = this.state.fpc;
        fp.ValidationInfo.Data.forEach(x => x["IsSelected"] = selVal);
        this.setState({ fpc: fp });
        this.props.onModelUpdate(fp);
        this.setState({ selectAllValue: selVal });
    }

    SetRowSelected = (keyVal: any, selVal: boolean) => {

        let fp = this.state.fpc;

        //If single select, unselect existing first
        if (this.state.fpc.ValidationInfo.ActionCode.toLowerCase() === "validatesingleselect") {
            let curSel = fp.ValidationInfo.Data.filter(x => x.selected === true);
            curSel.forEach(x => x.selected = false);
        }

        let rw = fp.ValidationInfo.Data.find((x) => x[fp.ValidationInfo.DataKeyName] === keyVal);
        if (rw) {
            rw["Selected"] = selVal;
            rw.dirty = true;
        }

        this.setSelectAllVal();
        this.setState({ fpc: fp });
        this.props.onModelUpdate(fp);
    }

    UpdateFieldValue = (keyVal: any, fieldName: string, dataVal: any, exitEditMode: boolean) => {
        let fp = this.state.fpc;

        //Find Row
        let rw = fp.ValidationInfo.Data.find((x) => x[fp.ValidationInfo.DataKeyName] === keyVal)
        rw[fieldName] = dataVal;
        rw.dirty = true;
        if (exitEditMode) {
            this.setState({
                fpc: fp,
                editColfieldName: '',
                editRowKeyVal: ''
            });
        } else {
            this.setState({
                fpc: fp,
            });
        }
        this.props.onModelUpdate(fp);
    }

    ExitDateEditMode = (keyVal: any, fieldName: string, dateVal: Date | null) => {
        if (dateVal) {
            let dateFmt = moment(dateVal);
            if (dateFmt.isValid())
                this.UpdateFieldValue(keyVal, fieldName, moment(dateFmt).format("YYYY-MM-DD"), true);
        } else {
            this.UpdateFieldValue(keyVal, fieldName, null, true);
        }
    }

    UpdateInputField = (fieldVal: string, keyVal: any, fieldName: string) => {

        this.UpdateFieldValue(keyVal, fieldName, fieldVal, false);
    }

    UpdateCheckboxField = (keyVal: any, fieldName: string, dataVal: boolean) => {
        if (dataVal === true) {
            this.UpdateFieldValue(keyVal, fieldName, 1, true);
        } else {
            this.UpdateFieldValue(keyVal, fieldName, 0, true);
        }
    }

    render() {

        return (
            <div className='centreAlign'>
                <this.buildValidationGrid />
            </div>
        )
    };

}

export default Confirm;