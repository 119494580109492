import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { FpcModel, DrawerItemsType } from '../../models/models';
import { FpcLib } from '../../models/fpcModule';
import SelectCQ from './SelectCQ';
import Params from './Params';
import Confirm from './Confirm';
import ResultsGrid from './ResultsGrid';
import Results from './Results';
import ReportGen from './ReportGen';
import Error from './Error';
import { PageViewTypeEnum, fpcTypeEnum, CQStatusEnum, StepItemIndexEnum } from '../../models/enums';
import { addPageViewClass } from '../../functions/generalFunctions'

import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';


import './FpcCtl.css';
import { TcbObjInfo } from '../../models/models';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type FpcCtlProps = PropsFromRedux & {
  menuItem: DrawerItemsType;
  clientQueryGrpId?: number;
  clientQueryId?: number;
  tcbObjs?: TcbObjInfo[];
  securityId?: number;
  stateToLoad?: FpcCtlState;
  onSaveState?: (menuItemId: number, itemState: any, callback?: () => void) => void;
  onSaveMenuItem?: (menuItemId: number, itemState: any, menuCustomTxt: string, callback?: () => void) => void;
}

interface FpcCtlState {
  fpc: FpcModel;
  isLoading: boolean;
  pageStyle: React.CSSProperties;
}

const getRedirectUri = () => {
  return process.env.PUBLIC_URL;

};

class FpcCtl extends React.Component<FpcCtlProps, FpcCtlState> {

  constructor(props: FpcCtlProps) {
    super(props);

    if (this.props.stateToLoad) {
      this.state = { ...this.props.stateToLoad }
    } else {

      let fpc = new FpcModel();
      fpc.userInfo = this.props.userInf;
      fpc.fpcStatus = CQStatusEnum.DisplayError;
      fpc.errorMessage = "No Group or Client Query supplied";

      fpc.clientQueryGrpId = this.props.clientQueryGrpId;
      if (fpc.clientQueryGrpId) {
        fpc.fpcStatus = CQStatusEnum.SelectCQ;
        fpc.canSelectQuery = true;
        switch (fpc.clientQueryGrpId) {
          case 1:
            fpc.cqHeader = "Admin";
            break;
          case 2:
            fpc.cqHeader = "Report"
            break;
          case 3:
          case 4:
            fpc.cqHeader = "Action"
            break;
        }
      }
      if (this.props.clientQueryId) {
        fpc.fpcStatus = CQStatusEnum.LoadCQ;
        fpc.canSelectQuery = false;
        fpc.clientQueryId = this.props.clientQueryId;
      }
      if (this.props.tcbObjs) {
        fpc.clientQueryTcbObjs = this.props.tcbObjs
        fpc.clientQueryParamValues = this._fpcLib.BuildParamValsFromTcbObjs(fpc.clientQueryTcbObjs, this.props.securityId);
      }

      this.state = {
        fpc: fpc,
        isLoading: true,
        pageStyle: {}
      }
    }
  }

  private _fpcLib: FpcLib = new FpcLib();
  private _updateMenuItem: boolean = false;

  componentDidMount() {
    this.updateDimensions();
    let fpc = this.state.fpc;
    switch (fpc.fpcStatus) {
      case CQStatusEnum.SelectCQ:   //Setup page for Selection of CQ
        if (fpc.canSelectQuery) {
          this._fpcLib.LoadStepperItems(fpc, this.gotoToSelect, this.validateError);
        }
        break;

      case CQStatusEnum.DisplayCqResults:
      case CQStatusEnum.DisplayCqResultsGrid:
      case CQStatusEnum.DisplayError:
      case CQStatusEnum.ReportGen:
        this.displayResults();
        break;

      default:
        this.loadSelectedCQ(fpc);
        break;
    }
  }

  updateDimensions = () => {
    let body = document.body;
    if (body) {
        let ps: React.CSSProperties = { ...this.state.pageStyle };
        if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
            ps.width = body.clientWidth;
        }
        this.setState({
            pageStyle: ps
        });
    }
}

  // saveState = (fpc: FpcModel, isLoading: boolean, callback?: () => void) => {
  //   this.setState({ fpc: fpc, isLoading: isLoading }, () => {
  //     if (fpc.canSelectQuery) {
  //       if (this.props.onSaveState)
  //         this.props.onSaveState(this.props.menuItemId, this.state, callback);
  //     } else {
  //       if (this.props.onSaveMenuItem)
  //         this.props.onSaveMenuItem(this.props.menuItemId, this.state, fpc.clientQueryDesc, callback);
  //     }
  //   });
  // }

  validateError = (r: FpcModel) => {
    this.setState({ isLoading: false, fpc: r });
  }

  updateFpcModel = (r: FpcModel) => {
    this.setState({
      fpc: r
    });
  }

  // backToSelectCQ = () => {
  //   let fpc = this.state.fpc;
  //   fpc.fpcStatus = CQStatusEnum.SelectCQ;
  //   this.setState({ isLoading: false, fpc: fpc });
  // }


  loadSelectedCQ = (r: FpcModel) => {
    this._fpcLib.LoadStepperItems(r, () => {
      this._fpcLib.LoadClientQuery(r, () => {
        if (!this.props.menuItem.fixedItem) this._updateMenuItem = true;
        this._fpcLib.UpdateStepperStatusWithRequiredParamsStatus(r);

        let currItm = r.stepperItems.find(x => x.current);
        if (currItm) {
          switch (currItm.index) {
            case StepItemIndexEnum.Select:
              this.gotoToSelect();
              break;
            case StepItemIndexEnum.Parameters:
              this.gotoToParams();
              break;
            case StepItemIndexEnum.Validate:
              this.gotoToValidate();
              break;
            default:
              this.gotoToResults();
              break;
          }
        }
        // this.setState({ isLoading: false, fpc: r });

      }, this.validateError);
    }, this.validateError);
  }

  saveMenuItem = () => {
    let fpc = this.state.fpc;
    if (!this.props.menuItem.fixedItem) {
      if (this.props.onSaveMenuItem)
        this.props.onSaveMenuItem(this.props.menuItem.menuitemid, this.state, fpc.clientQueryDesc);
      this._updateMenuItem = false;
    }
  }

  gotoToSelect = () => {
    let fpc = this.state.fpc;

    if (fpc.canSelectQuery) {
      this._fpcLib.SetCurrentStepperItem(fpc, 0);
      fpc.fpcStatus = CQStatusEnum.SelectCQ;

      this.setState({ fpc: fpc, isLoading: false }, this.saveMenuItem);
    }
  }

  gotoToParams = () => {
    let fpc = this.state.fpc;
    fpc.fpcStatus = CQStatusEnum.DisplayParams;
    this._fpcLib.SetCurrentStepperItem(fpc, StepItemIndexEnum.Parameters);
    this.setState({ fpc: fpc, isLoading: false });
  }

  gotoToValidate = () => {
    let fpc = this.state.fpc;

    if (fpc.clientQueryId !== 0) {
      this._fpcLib.SetCurrentStepperItem(fpc, StepItemIndexEnum.Validate);
      fpc.loadingStatusMsg = "Running Validation...";
      this.setState({ fpc: fpc }, () => this._fpcLib.RunCqValidation(fpc, () => {
        this.runCqValidationComplete(fpc);
      }));
    }
  }

  gotoToResults = () => {
    let fpc = this.state.fpc;

    if (fpc.clientQueryId !== 0) {
      this._fpcLib.SetCurrentStepperItem(fpc, StepItemIndexEnum.Results);

      // Set the loading message.
      if (fpc.clientQueryDesc) {
        fpc.loadingStatusMsg = fpc.clientQueryDesc + "...";
      }
      else {
        fpc.loadingStatusMsg = "Running Process...";
      }

      this.setState({ fpc: fpc, isLoading: true }, () => {
        this.saveMenuItem();
        this._fpcLib.RunCq(fpc, this.runCqComplete);
      });
      // this.saveState(fpc, true, () => this.state.fpc.RunCq(this.runCqComplete));

    }
  }

  displayResults = () => {
    let fpc = this.state.fpc;
    this._fpcLib.SetCurrentStepperItem(fpc, StepItemIndexEnum.Results);
    this.setState({ fpc: fpc, isLoading: false });

  }



  disableStepperItemsAfterError = (r: FpcModel) => {
    r.stepperEnableNextButton = false;
    r.stepperItems.forEach(x => { x.disabled = true });
    if (r.clientQueryParams.some(x => x.isVisible)) {
      let par = r.stepperItems.find(x => x.index === StepItemIndexEnum.Parameters);
      if (par)
        par.disabled = false;
    }

    return r;
  }



  // confirmSubmit = (r: FpcModel) => {
  //   if (r.clientQueryDesc) {
  //     r.loadingStatusMsg = r.clientQueryDesc + "...";
  //   }
  //   else {
  //     r.loadingStatusMsg = "Running Process...";
  //   }

  //   this.setState({ isLoading: true, fpc: r }, () => this._fpcLib.RunCq(r, this.runCqComplete));
  // }


  runCqValidationComplete = (r: FpcModel) => {
    switch (r.fpcStatus) {
      case CQStatusEnum.DisplayError:
        //Got straight to error page
        let r1 = this.disableStepperItemsAfterError(r);
        this.setState({ isLoading: false, fpc: r1 }, () => this.cleanupConnection(false));
        break;

      case CQStatusEnum.DisplayConfirmResults:
        //Load confirm page
        this._fpcLib.UpdateStepperStatusWithValidationStatus(r);
        this._fpcLib.SetCurrentStepperItem(r, StepItemIndexEnum.Validate);
        this.setState({ isLoading: false, fpc: r });
        break;

      case CQStatusEnum.RunCq:
        //Validation has returned RUN response - run CQ immediately
        this.gotoToResults();
        break;

      case CQStatusEnum.ReportGen:
        //Run Report
        this._fpcLib.SetCurrentStepperItem(r, StepItemIndexEnum.Results);
        this.setState({ isLoading: false, fpc: r }, () => this._fpcLib.RunCqSSRS(r, this.runCqSSRSComplete));
        break;

    }
  }


  runCqComplete = (r: FpcModel) => {
    console.log('Run CQ Complete');
    if (r.fpcStatus === CQStatusEnum.DisplayError) {
      let r1 = this.disableStepperItemsAfterError(r);
      this.setState({ isLoading: false, fpc: r1 }, () => this.saveMenuItem());
    } else if (r.fpcType === fpcTypeEnum.ReportViewer) {
      r.loadingStatusMsg = "Running SSRS Report : " + r.clientQueryDesc;
      this.setState({ isLoading: true, fpc: r }, () => this._fpcLib.RunCqSSRS(r, this.runCqSSRSComplete));
    } else {
      if (r.ResultData.ClientQueryResultId === '') {
        this.setState({ isLoading: false, fpc: r }, () => this.saveMenuItem());
      } else {
        r.loadingStatusMsg = "Fetching results...  0% completed";
        this.setState({ isLoading: true, fpc: r }, () => this.fetchCqData(r, 0, this.saveMenuItem));
      }
    }

  }


  fetchCqData = (r: FpcModel, rowNum: number, callback?: (e: FpcModel) => void) => {
    var retryCount = 0;
    this._fpcLib.FetchCqData(r, rowNum, (r: FpcModel) => {
      //Row Fetched
      rowNum++;
      retryCount = 0;

      if (r.ResultData.ClientQueryResultRowCount > rowNum) {
        r.loadingStatusMsg = "Fetching results... " + Math.floor(rowNum / r.ResultData.ClientQueryResultRowCount * 100).toString() + "% completed";
        this.setState({ isLoading: true, fpc: r }, () => this.fetchCqData(r, rowNum, callback));
      } else {
        r.loadingStatusMsg = "Fetching results... 100% completed";
        this.setState({ isLoading: true, fpc: r }, () => {
          this._fpcLib.ConvertFetchedData(r);
          r.fpcStatus = CQStatusEnum.DisplayCqResultsGrid;
          this.setState({ isLoading: false, fpc: r }, () => { if (callback) callback(r) });
        });
      }
    }, (e) => {
      //Fail
      retryCount++;
      r.loadingStatusMsg = "Fetching results... " + Math.floor(rowNum / r.ResultData.ClientQueryResultRowCount * 100).toString() + "% completed";
      r.loadingStatusMsg += "Failed to fetch : " + retryCount.toString()
      r.loadingStatusMsg += e;
      this.setState({ isLoading: true, fpc: r }, () => this.fetchCqData(r, rowNum));

    });
  }


  runCqSSRSComplete = (r: FpcModel) => {
    this.setState({ isLoading: false, fpc: r },
      () => this.saveMenuItem());
  }

  cleanupDownloadCache = (fpc: FpcModel, callback: (e: FpcModel) => void) => {
    this._fpcLib.RemoveCqResultData(fpc, callback);
  }

  cleanupConnection = (addToMenu: boolean) => {
    let fpc = this.state.fpc;
    if (fpc.ResultData.ClientQueryResultId) {
      this._fpcLib.RemoveCqResultData(fpc);
      fpc.ResultData.ClientQueryResultId = '';
      this.setState({ isLoading: false, fpc: fpc });
    }

    if (addToMenu && this.props.onSaveMenuItem) {
      this.props.onSaveMenuItem(this.props.menuItem.menuitemid, this.state, this.state.fpc.clientQueryDesc);
    } else if (this.props.onSaveState) {
      this.props.onSaveState(this.props.menuItem.menuitemid, this.state);
    }
  }

  closePage = () => {

  }

  stepperChangePage = (e: StepperChangeEvent) => {
    switch (e.value) {
      case 0: //Select CQ
        this.gotoToSelect();
        break;

      case 1: //Parameters
        this.gotoToParams();
        break;

      case 2: //Validate
        this.gotoToValidate();
        break;

      case 3: //Results
        this.gotoToResults();
        break;

    }
  }

  stepperButtonBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    let fpc = this.state.fpc;

    switch (fpc.stepperCurrentIndex) {

      case 0: //Select CQ

        break;

      case 1: //Parameters
        if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Select))
          this.gotoToSelect();
        break;

      case 2: //Validate
        if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Parameters))
          this.gotoToParams();
        else if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Select))
          this.gotoToSelect();
        break;

      case 3: //Results
        if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Validate))
          this.gotoToValidate();
        else if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Parameters))
          this.gotoToParams();
        else if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Select))
          this.gotoToSelect();



    }

  }

  stepperButtonNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    let fpc = this.state.fpc;

    switch (fpc.stepperCurrentIndex) {

      case 0: //Select CQ
        if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Parameters))
          this.gotoToParams();
        else if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Validate))
          this.gotoToValidate();
        else if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Results))
          this.gotoToResults();

        break;

      case 1: //Parameters
        if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Validate))
          this.gotoToValidate();
        else if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Results))
          this.gotoToResults();
        break;

      case 2: //Validate
        if (this._fpcLib.IsItemEnabled(fpc, StepItemIndexEnum.Results))
          this.gotoToResults();
        break;

      case 3: //Results
        break;


    }

  }

  AppHeader() {
    return (
      <div key={1} id={"fpcAppHeader"} className= {addPageViewClass(this.props.pageInf)} >
        <div className={"fpcStepperDiv" + addPageViewClass(this.props.pageInf) }>
          <div className="fpcStepperBtnDiv">
            <button onClick={this.stepperButtonBack} disabled={!this.state.fpc.stepperEnableBackButton}><div className="k-icon k-font-icon k-i-arrow-60-left" /></button>
            <div>Back</div>
          </div>
          <Stepper className={"fpcStepper" + addPageViewClass(this.props.pageInf)} items={this.state.fpc.stepperItems} value={this.state.fpc.stepperCurrentIndex} onChange={this.stepperChangePage} />
          <div className="fpcStepperBtnDiv">
            <button onClick={this.stepperButtonNext} disabled={!this.state.fpc.stepperEnableNextButton}><div className="k-icon k-font-icon k-i-arrow-60-right" /></button>
            <div>Next</div>
          </div>
        </div>
        <div className={"fpcReportName" + addPageViewClass(this.props.pageInf)}>{this.state.fpc.clientQueryDesc}</div>
      </div>);
  }


  AppBody() {
    if (this.state.isLoading) {
      return (
        <div className="fpcLoading">
          <div className="fpcLoadingIndicator"><span className="k-icon k-font-icon k-i-loading" /></div>
          <div className="fpcStatusMsg">{this.state.fpc.loadingStatusMsg}</div>
        </div>
      );
    } else {
      switch (this.state.fpc.fpcStatus) {
        case CQStatusEnum.SelectCQ:
          return <SelectCQ model={this.state.fpc} onSelectChanged={this.loadSelectedCQ} />
        case CQStatusEnum.DisplayParams:
          return <Params model={this.state.fpc} onModelUpdate={this.updateFpcModel} />
        case CQStatusEnum.DisplayConfirmResults:
          return <Confirm model={this.state.fpc} onModelUpdate={this.updateFpcModel} />
        case CQStatusEnum.DisplayCqResultsGrid:
          return <ResultsGrid model={this.state.fpc} />
        case CQStatusEnum.DisplayCqResults:
          return <Results model={this.state.fpc} />
        case CQStatusEnum.ReportGen:
          return <ReportGen model={this.state.fpc} />
        case CQStatusEnum.DisplayError:
          return <Error model={this.state.fpc} />
      };
    }
  }


  render() {

    return (

    <div id="fpcMainDiv" className={addPageViewClass(this.props.pageInf)} style={this.state.pageStyle}>
      {this.AppHeader()}
      <div key={2} className={"appContent" + addPageViewClass(this.props.pageInf)}>{this.AppBody()}</div>
    </div>);
  }
}



export default connector(FpcCtl);


