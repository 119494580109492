import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { TreeList } from "@progress/kendo-react-treelist";

import products from "./TestDrawerData.json";

import './TestDrawerContent.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TestDrawerContentProps = PropsFromRedux & {
    type: number;
}


interface TestDrawerContentState {
    gridData: any;
    gs: React.CSSProperties
}

const treeListCols = [
    {
        field: "ProductID",
        title: "ProductID",
        expandable: false,
        width: 100,
        locked: true
    },
    {
        field: "ProductName",
        title: "ProductName",
        expandable: false,
        locked: false
    },
    {
        field: "Category.CategoryName",
        title: "CategoryName",
        expandable: false,
        locked: false
    },
    {
        field: "ProductName",
        title: "ProductName",
        expandable: false,
        locked: false
    },
    {
        field: "Category.CategoryName",
        title: "CategoryName",
        expandable: false,
        locked: false
    },
    {
        field: "ProductName",
        title: "ProductName",
        expandable: false,
        locked: false
    },
    {
        field: "Category.CategoryName",
        title: "CategoryName",
        expandable: false,
        locked: false
    },
];

class TestDrawerContent extends React.Component<TestDrawerContentProps, TestDrawerContentState> {
    constructor(props: TestDrawerContentProps) {
        super(props);

        this.state = {
            gridData: products,
            gs: {}
        }

    }

    componentDidMount() {
        // this.updateDimensions();
        // window.addEventListener("resize", this.updateDimensions);

    }

    componentDidUpdate(prevProps: TestDrawerContentProps) {
        // if (prevProps.parentHeight !== this.props.parentHeight) {
        //     let gs = this.state.gs;
        //     gs.height = this.props.parentHeight
        //     this.setState({gs: this.props.parentHeight});
        // }
    }

    componentWillUnmount() {
    }


    updateDimensions = () => {
        let body = document.body;
        // let gg2 = document.documentElement;

        // let d = document,
        //     body = d.getElementById('appDiv');

        if (body) {
            var gs: React.CSSProperties = { ...this.state.gs };
            gs.height = body.clientHeight - 200;
            this.setState({
                gs: gs
            });
        }
    }


    render() {
        switch (this.props.type) {
            case 1:
                return (<TreeList
                    className="testdrawerFullHeight"
                    style={this.state.gs}
                    data={this.state.gridData}
                    dataItemKey="ProductID"
                    columns={treeListCols}
                />);

            case 2:
                return (
                    <div className='anotherDiv'>
                        <Grid className='testdrawerFullHeight'
                            style={this.state.gs}
                            data={this.state.gridData}
                        >
                            <GridColumn field="ProductID" title='Id' width={50} />
                            <GridColumn field="ProductName" title='Name' width={500} />
                            <GridColumn field="Category.CategoryName" title='CategoryName' width={500} />

                        </Grid>
                    </div>
                );

            default:
                return (<div id="tstOuterDiv">
                    <div id="tstInnerDiv"></div>
                </div>)

        }
    }
}

export default connector(TestDrawerContent);
