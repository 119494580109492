import React from 'react';
import { FpcModel } from '../../models/models';

import './ReportGen.css';

interface ReportGenProps {
    model: FpcModel;
}

interface ReportGenState {
    fpc: FpcModel;
}

class ReportGen extends React.Component<ReportGenProps, ReportGenState> {
    constructor(props: ReportGenProps) {
        super(props);

        this.state = {
            fpc: props.model
        }

    };


    render() {
        return (
            <div className='reportGenDiv'>Report successully generated. Results file attached</div>
        );
 
    };

}

export default ReportGen;