import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ErrorMsg from '../ErrorMsg';
import { UserInfo, TcbObjDetailGroup, TcbObjDetailRow, TcbObjInfo, DropDownListData, TcbFileItem } from '../../models/models';
import { addPageViewClass } from '../../functions/generalFunctions'
import { loadingDiv } from '../../functions/componentFunctions';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { InputCtlViewMode, InputCtlDdlDataSource, TcbObjDataCatagory, FileDisplayModeEnum, TcbFileLoadStatusEnum } from '../../models/enums';
import TextInputCtl from '../InputCtls/TextInputCtl';
import MemoCtl from '../InputCtls/MemoCtl';
import DropDownListCtl from '../InputCtls/DropDownListCtl';
import NumberInputCtl from '../InputCtls/NumberInputCtl';
import DateSelectCtl from '../InputCtls/DateSelectCtl';
import DateTimeSelectCtl from '../InputCtls/DateTimeSelectCtl';
import SignatureCtl from '../InputCtls/SignatureCtl';
import moment from 'moment'
import './TcbObjProperties.css';
import MapDlg from '../Dialogs/MapDlg';
import FileUploadCtl from '../InputCtls/FileUploadCtl';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { Tooltip } from "@progress/kendo-react-tooltip";

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TcbObjPropertiesProps = PropsFromRedux & {
    tcbObj: TcbObjInfo;
    stateToLoad?: TcbObjPropertiesState;
    onSaveState?: (state: TcbObjPropertiesState) => void;
}

type TcbObjPropertiesState = {
    searchInProg: boolean;
    TcbObjProperties: TcbObjDetailGroup[];
    detailsFetched: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;
    showMapDlg: boolean;
}


//class TcbObjProperties extends React.Component<TcbObjPropertiesProps, TcbObjPropertiesState> {
function TcbObjProperties(props: TcbObjPropertiesProps) {



    const [state, setState] = useState<TcbObjPropertiesState>({
        searchInProg: true,
        TcbObjProperties: [],
        detailsFetched: false,
        fetchError: false,
        fetchErrorMsg: '',
        showMapDlg: false,
    });


    const handleScroll = () => {
        const childDiv = document.getElementById('TcbObjProperties')

        if (childDiv) {
            childDiv.scroll(0, 0);
        }

    }

    useEffect(() => {

        if (props.stateToLoad) {

        var myState = props.stateToLoad;

            setState(myState);

                if (!props.stateToLoad.detailsFetched) {
                    getTcbObjProperties();
                }
   
        } else {
            setState((prevState) => ({
                ...prevState,
                searchInProg: true,
                TcbObjProperties: [],
                detailsFetched: false,
                fetchError: false,
                fetchErrorMsg: '',
                showMapDlg: false,
            }));
    
            getTcbObjProperties();


        }

    }, []);

    useEffect(() =>{
        setTimeout(()=>{
            if (state.detailsFetched){
                handleScroll();
            }
            })
    },[state.detailsFetched])

    const saveToParentState = (s:TcbObjPropertiesState ) => {
        if (props.onSaveState){
            props.onSaveState(s);
        }

    }

    const getTcbObjProperties = () => {
        setState((prevState) => ({
            ...prevState,
            TcbObjProperties: [],
            searchInProg: true,
            detailsFetched: false
        }));

        const url = props.userInf.currProject.apiUrl + '/api/details/GetTcbObjDetails';

        fetch(url, { method: 'POST', body: JSON.stringify(props.tcbObj), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let tpr: TcbObjDetailGroup[] = res.results;
                        initialiseViewMode(tpr, InputCtlViewMode.View);

                        setState((prevState) => {
                            const updatedState = {
                                ...prevState,
                                TcbObjProperties: tpr,
                                detailsFetched: true,
                                searchInProg: false,
                                scrollToTop:true
                            };
                        
                            saveToParentState(updatedState); // Use the updated state directly
                            return updatedState; // Return the new state for setState
                        });

                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...props.userInf, isAuthorised: false };
                        props.updateUserInfo(uInf);

                        setState((prevState) => ({
                            ...prevState,
                            searchInProg: false
                        }));

                        break;

                    default:

                        setState((prevState) => ({
                            ...prevState,
                            searchInProg: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage
                        }));
                }
            })
            .catch(err => {
                setState((prevState) => ({
                    ...prevState,
                    searchInProg: false,
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                }));

            });

    }

    const initialiseViewMode = (tpr: TcbObjDetailGroup[], newMode: InputCtlViewMode) => {
        tpr.forEach(x => {
            x.objDetails.forEach(y => {
                if (newMode === InputCtlViewMode.Select) {
                    y.viewMode = newMode;
                } else {
                    if (!y.allowEdit) {
                        y.viewMode = InputCtlViewMode.ReadOnly;
                    } else {
                        y.viewMode = newMode;
                    }
                }
            })
        });
    }

    const UpdateDDLValue = (rw: TcbObjDetailRow, newVal?: DropDownListData, svm?: (newMode: InputCtlViewMode) => void) => {
        if (newVal) {
            UpdateDetailValue(rw, newVal.value, svm);
        } else {
            UpdateDetailValue(rw, '', svm);
        }
    }


    const UpdateDetailValue = (rw: TcbObjDetailRow, newVal?: string | null, svm?: (newMode: InputCtlViewMode) => void) => {

        let url = props.userInf.currProject.apiUrl + '/api/details/SetTcbObjDetailValue';

        let body = {
            tcbObj: props.tcbObj,
            tcbObjDataCat: rw.tcbObjDataCat,
            propertyName: rw.propertyName,
            valueStr: newVal
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let tprp = state.TcbObjProperties;

                        let drf = findTcbObjDetailRow(rw.id, tprp);
                        if (drf) {
                            let dr: TcbObjDetailRow = drf;
                            dr.valueStr = newVal ?? '';
                            dr.viewMode = (dr.attributeClassDataType === "Signature" ? InputCtlViewMode.ReadOnly : InputCtlViewMode.View);

                            setState((prevState) => ({
                                ...prevState,
                                TcbObjProperties: tprp
                            }));
                            
                            if (svm) {
                                svm(dr.attributeClassDataType === "Signature" ? InputCtlViewMode.ReadOnly : InputCtlViewMode.View);
                            }


                        }

                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...props.userInf, isAuthorised: false };
                        props.updateUserInfo(uInf);
                        break;
                    default:
                        throw (res.callStatusMessage)
                }
            })
            .catch(err => {
                alert('Set Data Error - ' + err);
            });
    }

    const findTcbObjDetailRow = (id: number, tprp: TcbObjDetailGroup[]) => {
        let retVal: TcbObjDetailRow | null = null;
        tprp.forEach(x => {
            let gg = x.objDetails.find(y => y.id === id);
            if (gg) {
                retVal = gg;
            }
        })
        return retVal;
    }

    const errorClose = () => {

        setState((prevState) => ({
            ...prevState,
        fetchError: false,
        fetchErrorMsg: ''}));

    }

    const fileUploadCompleted = (files: UploadFileInfo[], rw: TcbObjDetailRow) => {
        let fi = files[0];
        let docTypeId = rw.documentTypeId

        if (docTypeId > 0) {
            getFileDetails(fi.uid, true, (fi) => {
                fi.fileLoadStatus = TcbFileLoadStatusEnum.OK;
                fi.canDelete = true;
                fi.canEdit = true;
                fi.fileRefNo = rw.attributeClassPrompt;

                if (fi.fileName.length > 0) {


                    saveFileDetails(fi.fileId,
                        fi.fileName,
                        fi.fileDesc,
                        fi.fileRefNo,
                        docTypeId,
                    )

                    linkImageToTcbObj(fi.fileId)

                    let fileCount = 0;

                    if (rw.valueStr !== null) {


                        if (rw.valueStr.indexOf(" file(s) uploaded") > 0) {
                            fileCount = parseInt(rw.valueStr.substring(0, rw.valueStr.indexOf(" file(s) uploaded")));
                        }


                    }

                        rw.valueStr = (fileCount + 1).toString() + " file(s) uploaded";

                    let tprp = state.TcbObjProperties;

                    let drf = findTcbObjDetailRow(rw.id, tprp);
                    if (drf) {
                        let dr: TcbObjDetailRow = drf;
                        dr.valueStr = rw.valueStr ?? '';
                        

                        setState((prevState) => ({
                            ...prevState,
                            TcbObjProperties: tprp
                        }));


                     }  
                    }
                }
            )     

        }
    }

    const linkImageToTcbObj = (fileId: string) => {
        let url = props.userInf.currProject.apiUrl + '/api/details/LinkFileToTcbObj';

        let tcbObjs: TcbObjInfo[] = [];
        tcbObjs.push(props.tcbObj);

        let docType = "Item Photo";
        if (props.tcbObj.tcbObjClass === "P") {
            docType = "Packlist Photo"
        }

        let body = {
            fileId: fileId,
            tcbObjs: tcbObjs,
            documentDefaultType: docType
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                }
            })
            .catch();

    }

    const getFileDetails = (fileId: string, fetchFileData: boolean, callBack?: (fi: TcbFileItem) => void) => {
        let url = props.userInf.currProject.apiUrl + '/api/file/GetTcbFileInfo';

        let body = {
            fileId: fileId,
            fetchFileData: fetchFileData,
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    if (callBack)
                        callBack(fi);
                }
            })
            .catch(err => {
                alert('Error getting file details - ' + err.toString());
            });
    }

    const saveFileDetails = (fileId: string, fileName: string, fileDesc: string, fileRefNo: string, docTypeId?: number, callBack?: (fi: TcbFileItem) => void) => {
        let url = props.userInf.currProject.apiUrl + '/api/file/SetTcbFileDetails';

        let body = {
            fileId: fileId,
            fileName: fileName,
            refNo: fileRefNo,
            desc: fileDesc,
            docTypeId: docTypeId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userInf.token } })
            .then(resp => { if (!resp.ok) { throw Error(resp.statusText); } return resp; })
            .then(resp => resp.json())
            .then(res => {
                if (res.callStatus !== "OK") {
                    throw Error(res.CallStatusMessage);
                } else {
                    let fi: TcbFileItem = res.result;
                    //This fi does not contain the full data str
                    if (callBack)
                        callBack(fi);
                }
            })
            .catch(err => {
                alert('Error saving file details - ' + err.toString());
            });

    }

    const renderDetailsTableRow = (e: TcbObjDetailRow, i: number) => {

        //Can be added back in when required
        //         if (e.attributeClassDataType?.toLowerCase() === 'datagrid') {
        //             // return ( <td className={cn}>
        //             //     {rw.valueStr}
        //             //         </td>)
        //             return (<div className="TcbObjDataGrid" dangerouslySetInnerHTML={{__html: e.valueStr}}/> )
        //         }     


        // else
        return (<tr key={i} className="TcbObjPropertiesTableRow">
            <Tooltip anchorElement="target" position="top">
                <th className="TcbObjPropertiesTableLbl" title={e.toolTip} >{e.label}
                </th>
                </Tooltip>            
            {renderDetailCell(e)}

        </tr>)
    }


    const mapImageClick = () => {
        setState((prevState) => ({
            ...prevState,
        showMapDlg: true}));

    }

    const mapImageClose = () => {
        setState((prevState) => ({
            ...prevState,
        showMapDlg: false}));

    }

    const renderDetailCell = (rw: TcbObjDetailRow) => {
        let cn = 'TcbObjPropertiesCellRO';
        if (rw.allowEdit) cn = 'TcbObjPropertiesCell';
        let myMode: InputCtlViewMode = InputCtlViewMode.View;
        if (!rw.allowEdit) myMode = InputCtlViewMode.ReadOnly;
        switch (rw.dataType.toLowerCase()) {
            case 'list':
                return (<td className={cn}>
                    <DropDownListCtl
                        key={rw.id.toString()}
                        mode={myMode}
                        dataSource={setDdlDataSource(rw)}
                        lookupKey={rw.propertyName}
                        lookupKeyId={props.tcbObj.tcbObjTypeId}
                        selectedValue={rw.valueStr}
                        onSave={(newVal?: DropDownListData, svm?: (newMode: InputCtlViewMode) => void) => UpdateDDLValue(rw, newVal, svm)} /></td>)

            case 'decimal':
                return (<td className={cn}>
                    <NumberInputCtl
                        key={rw.id.toString()}
                        mode={myMode}
                        value={isNaN(parseFloat(rw.valueStr)) ? null : parseFloat(rw.valueStr)}
                        onUpdate={(newVal?: number | null, svm?: (newMode: InputCtlViewMode) => void) => {
                            if (newVal)
                                UpdateDetailValue(rw, newVal.toString(), svm);
                            else
                                UpdateDetailValue(rw, undefined, svm);
                        }} /></td>)

            case 'date':
                return (<td className={cn}>
                    <DateSelectCtl
                        key={rw.id.toString()}
                        mode={myMode}
                        valueStr={rw.valueStr}
                        onUpdate={(newDate?: Date, svm?: (newMode: InputCtlViewMode) => void) => {
                            if (newDate) {
                                let str = '';
                                let tmp = moment(newDate);
                                if (tmp.isValid()) {
                                    str = tmp.format("DD-MMM-yyyy");
                                }

                                UpdateDetailValue(rw, str, svm);
                            }
                            else
                                UpdateDetailValue(rw, undefined, svm);
                        }} /></td>)

            case 'datetime':
                return (<td className={cn}>
                    <DateTimeSelectCtl
                        key={rw.id.toString()}
                        mode={myMode}
                        valueStr={rw.valueStr}
                        onUpdate={(newDate?: Date, svm?: (newMode: InputCtlViewMode) => void) => {
                            if (newDate) {
                                let str = '';
                                let tmp = moment(newDate);
                                if (tmp.isValid()) {
                                    str = tmp.format("DD-MMM-yyyy HH:mm");
                                }

                                UpdateDetailValue(rw, str, svm);
                            }
                            else
                                UpdateDetailValue(rw, undefined, svm);
                        }} /></td>)

            case 'memo':
                return (<td className={cn}>
                    <MemoCtl
                        key={rw.id.toString()}
                        value={rw.valueStr}
                        mode={myMode}
                        onUpdate={(e: string, svm?: (newMode: InputCtlViewMode) => void) => UpdateDetailValue(rw, e, svm)} /></td>)





            default:

                if (rw.attributeClassDataType?.toLowerCase() === 'signature') {
                    return (<td className={cn}>
                        <SignatureCtl
                            key={rw.id.toString()}
                            value={rw.valueStr}
                            mode={(rw.allowEdit && !rw.valueStr) ? InputCtlViewMode.Edit : InputCtlViewMode.ReadOnly}
                            readOnlyAfterSave={true}
                            onUpdate={(e: string | null, svm?: (newMode: InputCtlViewMode) => void) => UpdateDetailValue(rw, e, svm)}
                        /></td>)
                }

                if (rw.attributeClassDataType?.toLowerCase() === 'attachment') {
                    if (rw.allowEdit) {
                        return (<td className={cn}>
                            <TextInputCtl
                                key={rw.id.toString()}
                                value={rw.valueStr}
                                mode={InputCtlViewMode.ReadOnly} />
                            <FileUploadCtl
                                onChange={(fi: UploadFileInfo[]) => fileUploadCompleted(fi, rw)}
                                mode={FileDisplayModeEnum.All}
                                key={rw.id.toString()}
                            /></td>)
                    }
                    else {
                        return (<td className={cn}>
                            <TextInputCtl
                                key={rw.id.toString()}
                                value={rw.valueStr}
                                mode={InputCtlViewMode.ReadOnly}
                            /></td>)
                    }
                }

                if (rw.attributeClassDataType?.toLowerCase() === 'gpscoordinates') {
                    if (rw.valueStr && rw.valueStr.length > 0)
                        return (
                            <>
                                <img className="mapImage" width="300px" height="300px" src={`https://maps.googleapis.com/maps/api/staticmap?markers=color:blue%7Clabel:S%7C${rw.valueStr}&center=${rw.valueStr}&size=300x300&key=AIzaSyAFA1QGv3USTM4ELzxxKEKsbzHAdPhK-s8&zoom=8&maptype=satellite`} onClick={(e) => mapImageClick()} alt='' />

                                {state.showMapDlg && <MapDlg location={rw.valueStr} onClose={() => mapImageClose()} />}

                                {/* <img className="mapImage" width="300px" height="300px" src={`https://maps.googleapis.com/maps/api/staticmap?markers=color:blue%7Clabel:S%7C${rw.valueStr}&center=${rw.valueStr}&size=300x300&key=AIzaSyAFA1QGv3USTM4ELzxxKEKsbzHAdPhK-s8&zoom=8&maptype=satellite`} alt='' /> */}

                            </>

                        )
                    else
                        return 'N/A'
                }



                else {
                    if (rw.valueStr?.length > 30) {
                        return (<td className={cn}>
                            <MemoCtl
                                key={rw.id.toString()}
                                value={rw.valueStr}
                                mode={myMode}
                                onUpdate={(e: string, svm?: (newMode: InputCtlViewMode) => void) => UpdateDetailValue(rw, e, svm)} /></td>)

                    } else {
                        return (<td className={cn}>
                            <TextInputCtl
                                key={rw.id.toString()}
                                value={rw.valueStr}
                                mode={myMode}
                                onUpdate={(e: string, svm?: (newMode: InputCtlViewMode) => void) => UpdateDetailValue(rw, e, svm)} /></td>)

                    }
                }

        }
    }

    const setDdlDataSource = (rw: TcbObjDetailRow) => {
        let myDataSrc: InputCtlDdlDataSource = InputCtlDdlDataSource.PlProp;
        switch (rw.tcbObjClass) {
            case "P":
                if (rw.tcbObjDataCat === TcbObjDataCatagory.Property)
                    myDataSrc = InputCtlDdlDataSource.PlProp;
                else
                    myDataSrc = InputCtlDdlDataSource.PlAttr;
                break;
            case "I":
            case "T":
                if (rw.tcbObjDataCat === TcbObjDataCatagory.Property)
                    myDataSrc = InputCtlDdlDataSource.ItProp;
                else
                    myDataSrc = InputCtlDdlDataSource.ItAttr;
                break;
        }
        return myDataSrc;
    }


    if (state.fetchError) {
        return (<ErrorMsg message={state.fetchErrorMsg} onClose={errorClose} />);
    }
    if (state.searchInProg) {
        return loadingDiv();
    }

    return (
        <div id="TcbObjProperties" className={addPageViewClass(props.pageInf)}>
            {state.TcbObjProperties.sort((x, y) => { return (x.groupOrder - y.groupOrder) }).map((x, i) => {
                return (
                    <table key={i} className={"TcbObjPropertiesTable" + addPageViewClass(props.pageInf)}>
                        <thead>
                            <tr>
                                <td colSpan={2}>{x.groupName}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {x.objDetails.sort((x, y) => { return (x.sortOrder - y.sortOrder) }).map((y, i) => { return renderDetailsTableRow(y, i) })}
                        </tbody>
                    </table>
                );
            })}
        </div>
    );
}


export default connector(TcbObjProperties);
