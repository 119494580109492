import React from 'react';
import { TcbFileItem } from '../models/models';
export function loadingDiv() {

    return (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>)
}
   

export function downloadFileItem(f: TcbFileItem) {

    if (!f.fileDataStr) return;

    let href = "";
    switch (f.fileExt.toLowerCase()) {
        case ".csv":
            href = "data:application/octet-stream;base64,";
            break;
        case ".xls":
            href = "data:application/vnd.ms-excel;base64,";
            break;
        case ".xlsx":
            href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            break;
        case ".doc":
            href = "data:application/msword;base64,";
            break;
        case ".docx":
            href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
            break;
        case ".ppt":
        case ".pptx":
            href = "data:application/octet-stream;base64,";
            break;
        case ".pdf":
            href = "data:application/pdf;base64,";
            break;
        case ".zip":
            href = "data:application/octet-stream;base64,";
            break;
        case ".jpg":
            href = "data:image/jpg;base64,";
            break;
        case ".gif":
            href = "data:image/gif;base64,";
            break;
        case ".png":
            href = "data:image/png;base64,";
            break;
    }
    // return (<td><a href={href + fi.thumbnailStr} download={fi.fileName} ><span className={"k-icon " + icon} /></a></td>);

    const link = document.createElement('a');
    link.href = href + f.fileDataStr;
    link.download = f.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}