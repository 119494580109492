export enum FetchResultsEnum {
    NotStarted,
    InProgress,
    OK,
    Fail,
    Error
}

export enum FileDisplayModeEnum {
    Attachments = 0,
    Images = 1,
    All = 2
}

export enum FileDialogModeEnum {
    Closed,
    Add,
    AddFromCamera,
    AddEdit,
    View,
    Edit,
    Delete,
    Loading,
}


export enum MainPageViewType {
    Blank,
    Home,
    Search,
    SearchResults,
    ItemDetails,
    MilestoneScan,
    PackListDetails,
    Settings,
    About
}


export enum MenuItemTypeEnum {
    MenuGroup = 1,
    About = 2,
    Actions = 3,
    Admin = 4,
    Reports =5,
    ClientQuery = 6,
    Dashboards = 7,
    MilestoneScan = 8,
    Search = 9,
    SPLSearch = 10,
    Settings = 11,
    DashboardGroup = 12,
    Home = 13,
    AddMbr = 14,
    Quarantine =15,
    Results = 100,
    ItemDetails = 101,
    Logout = 102,
    NonAction = 103,
    Blank = 104,
    RefreshPage = 105,
    Loading = 106,
    TestPage = 107,
    TestPage2 = 108,
    SuperUserPage = 109
}

export enum PageViewTypeEnum {
    Mobile,
    Tablet,
    Browser
}

export enum SearchModeEnum {
    ViewDetails,
    MilestoneScan,
    SelectItem,
    SelectItems,
    AddItem
}

export enum MilestoneScanStatusEnum {
    NotStarted = "Not Started",
    DeleteConfirm = "Confirm Delete",
    FetchingGPS = "Fetching GPS",
    LoadingScan = "Loading Scan",
    LoadingImages = "Loading Images",
    Complete = "Scan Complete",
    Verify = "Verify Scan",
    Error = "Error Loading",
    Cancelled ="Scan Cancelled",
    Deleting = "Deleting"
}

export enum MilestoneScanDialogViewEnum {
    None,
    Edit,
    ImageView,
    ScanLog
}

export enum MilestoneScanDialogActionEnum {
    CloseDialog,
    DeleteScanHistory,

    ScanLog
}

// export enum ContextMenuItemActionEnum {
//     Command,
//     Separator,
//     Text
// }


export enum TcbFileLoadStatusEnum {
    NotLoaded,
    InProgress,
    OK,
    Error,
    Deleting
}

export enum TcbObjDataCatagory{
    Property = 1,
    Attribute =2
}

export enum TcbFileSourceEnum {
    Item = 0,
    PackList = 1,
    ItemMilestone = 2
}

export enum TcbObjClassEnum {
    Item = "I",
    PackList = "P",
    ItemMilestone = "M",
    PackListItem = "T"
}

export enum SplColumnDataTypeEnum {
    AccessDenied = -1,
    NoData = 0,
    String = 1,
    Date = 2,
    Number = 3,
    Boolean = 4,
    Attachment = 5,
    Image = 6,
    DateTime = 7
}

export enum InputCtlViewMode {
    ReadOnly = "R",
    View = "V",
    Edit = "E",
    Saving = "S",
    Select = "X"
}

export enum InputCtlDdlDataSource {
    Data = "D",
    Sql = "S",
    PlProp = "PP",
    PlAttr = "PA",
    ItProp = "IP",
    ItAttr = "IA",
    AttrCl = "AC",       //Attribute Class Lookup
    General = "G"
}

export enum UploadStatusEnum {
    NotLoaded = 0,
    InProgess= 1,
    Uploaded = 2,
    UploadError = 3
}

export enum MembersPageTypeEnum {
    Members,
    MembersOf
}

export enum SaveButtonStatusEnum {
    Disabled = 0,
    Enabled = 1,
    InProgress = 2
}

export enum MemberAddByScanModeEnum {
    Add = 0,
    Remove = 1
}

export enum MemberAddScanStatusEnum {
    NotStarted,
    SearchingBarcode,
    AddingMember,
    AddSuccess,
    AddFail
}


export enum AddMemberToTcbObjEnum {
    NotStarted,    
    Adding,
    AddSuccess,
    AddFail,
    Removing,
    RemoveSuccess,
    RemoveFail

}

export enum MemberSelectStatusEnum {
    NotSelected,
    Selected,
    Invalid
}


//4PC Enums

export enum fpcClientQueryGrpEnum {
    Admin = 1,
    Reports = 2,
    Actions = 3
}

export enum fpcTypeEnum {
    ClientQuery,
    ReportViewer,
    ImageViewer
}

export enum CQStatusEnum {
    SelectCQ =0,
    LoadCQ = 9,
    DisplayParams = 1,
    RunConfirm = 2,
    DisplayConfirmResults = 3,
    RunCq =4,
    DisplayCqResults=5,
    DisplayCqResultsGrid=6,
    ReportGen=7,
    DisplayError=8
}

export enum CQValidationTypeEnum {
    None = 0,
    Confirm = 2,
    ConfirmWithSingleSelection = 3,
    ConfirmWithMultipleSelection = 4,
    ConfirmWithMultipleColumnSelection = 5
}

export enum StepItemIndexEnum {
    Select = 0,
    Parameters = 1,
    Validate =2,
    Results = 3
}

