import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { addPageViewClass } from '../../functions/generalFunctions'
import './PackListDelItemsDlg.css';
import { SaveButtonStatusEnum } from '../../models/enums';
import { UserInfo, TcbObjInfo,TcbObjMember } from '../../models/models';
import tcbLogoSml from '../../images/Tcb-50.gif';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PackListDelItemsDlgProps = PropsFromRedux & {
	tcbObj: TcbObjInfo;
    delObjs: TcbObjMember[];
	onClose: () => void;
	onDel?: () => void;
}

interface PackListDelItemsDlgState {
	deleteStatus: SaveButtonStatusEnum;
}

class PackListDelItemsDlg extends React.Component<PackListDelItemsDlgProps, PackListDelItemsDlgState> {
	constructor(props: PackListDelItemsDlgProps) {
		super(props);

		this.state = {
			deleteStatus: SaveButtonStatusEnum.Enabled
		}
	}

	private gridPageSize = 50;

	componentDidMount() {
		//Manually setting height so we can use virtual scrolling in the grid

	}

	componentWillUnmount() {
	}

	dlgActionClose = () => {
		this.props.onClose();
	}



	dlgActionDel = () => {

		this.setState({deleteStatus: SaveButtonStatusEnum.InProgress});

		let url = this.props.userInf.currProject.apiUrl + '/api/details/DelMembersFromTcbObj';

		let body = {
			tcbObjId: this.props.tcbObj.tcbObjId,
			delItems: this.props.delObjs.map(x => {return(x.tcbObj.tcbObjGuidStr)})
		}

		fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
			.then(r => r.json())
			.then(res => {
				switch (res.callStatus) {
					case "OK":				
						if (this.props.onDel) this.props.onDel();
						this.props.onClose();
						break;
					case "UNAUTH":
						let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
						this.props.updateUserInfo(uInf);
						break;

					default:
						throw (res.callStatusMessage)

				}
			})
			.catch(err => {
				alert('Error has occurred!');
				console.log('PackListDelItemsDlg: ' + err);
			});
	}


	renderTitle = () => {
		let titleDesc = "Remove member - Confirm";
		if (this.props.delObjs.length > 1)
			titleDesc = "Remove members - Confirm";
		return (
			<table id="dlgTitleStd">
				<thead>
					<tr>
						<td className='left'><img style={{ height: "48px" }} src={tcbLogoSml} alt="TracBASE" /> </td>
						<th>{titleDesc}</th>
						<td className='right'><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.dlgActionClose()}></span></td>
					</tr>
				</thead>
			</table>)
	}

	renderBody = () => {
		let titleDesc = "Confirm you want to remove the following member from ";
		if (this.props.delObjs.length > 1)
			titleDesc = "Confirm you want to remove the following members from ";
        return(<div id='remMbrDelDlgBdy'>
				<p>{titleDesc}<b>{this.props.tcbObj.tcbObjDesc}</b></p>
			<table id='remMbrDelTbl'>
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Qty</th>
                </tr>
            </thead>
            <tbody>
                {this.props.delObjs.map(x => {
                    return (<tr>
                        <td>{x.memberDesc}</td>
                        <td>{x.tcbObj.tcbObjTypeDesc}</td>
                        <td>{x.tcbObj.tcbObjQty}</td>
                    </tr>);
                })}
            </tbody>
        </table>
		</div>)
	}

	renderDelButtonTxt = (e: string) => {
		if (this.state.deleteStatus === SaveButtonStatusEnum.InProgress)
			return (<span className='k-icon k-font-icon k-i-loading' />);
		else
			return (e);
	}

	render() {
		return (
			<Dialog className={"plItmsDelDlg" + addPageViewClass(this.props.pageInf)} title={this.renderTitle()} closeIcon={false}>
				{this.renderBody()}
				<DialogActionsBar>
					<Button onClick={e => this.dlgActionDel()} disabled={(this.state.deleteStatus !== SaveButtonStatusEnum.Enabled)} >{this.renderDelButtonTxt('Confirm')}</Button>
					<Button onClick={e => this.dlgActionClose()} >Cancel</Button>
				</DialogActionsBar>
			</Dialog>
		)
	}
}

export default connector(PackListDelItemsDlg);
