import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import NumberInputCtl from '../InputCtls/NumberInputCtl';
import { CustomWindow } from '../../models/custom.window'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { InputCtlViewMode } from '../../models/enums';

import '@progress/kendo-theme-default/dist/all.css'
import './QtyDialog.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type QtyDialogProps = PropsFromRedux & {
    headerText?: string;
    onClose?: (qty: number) => void;
}


interface QtyDialogState {
    currentQty: number
}

declare let window: CustomWindow;

class QtyDialog extends React.Component<QtyDialogProps, QtyDialogState> {
    constructor(props: QtyDialogProps) {
        super(props);

        this.state = {
            currentQty: 0
        };

        window.qtyDlgRef = this;


    }

    componentDidUpdate(prevProps: QtyDialogProps) {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    updateQty = (v: number | null) => {

        if (v) {
            this.setState({ currentQty: v });
        }


    }


    actionClose = () => {

        if (this.props.onClose) {
            this.props.onClose(this.state.currentQty);
        }
    }

    keyDown = () => {

        if (this.props.onClose) {
            this.props.onClose(this.state.currentQty);
        }
    }


    render() {

        let dlgTxt = "Enter Quantity:";

        if (this.props.headerText){
            dlgTxt = dlgTxt + " " + this.props.headerText;
        }
        return (
            <Dialog id="qtyDlg" className="qtyDlg" >
                <table id="qtyDlgTbl">
                    <tbody>
                        <tr className="qtyDlgHdr">
                            <th colSpan={2}>{dlgTxt}</th>
                        </tr>
                        <tr>
                            <td className="qtyDlgLabelCell">Qty:</td>
                            <td className="qtyDlgInputCell">
                                <NumberInputCtl 
                                mode={InputCtlViewMode.Select}
                                value={null}
                                autoFocus={true}
                                isRequired={false}
                                onUpdate={(v) => this.updateQty(v)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="qtyDlgBtnCell"><Button fillMode={"outline"} className="qtyDlgBtn" onClick={e => this.actionClose()}>OK</Button></td>
                        </tr>

                    </tbody>
                </table>
            </Dialog>
        );
    }
}

export default connector(QtyDialog);
