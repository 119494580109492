import React from 'react';
import { Checkbox , CheckboxChangeEvent  } from '@progress/kendo-react-inputs'


import './CheckBoxInputCtl.css';

interface CheckBoxInputCtlProps {
    value: boolean | null;
    onUpdate?: (e: boolean | null) => void;
}


interface CheckBoxInputCtlState {
    checkVal: boolean | null;
}

class CheckBoxInputCtl extends React.Component<CheckBoxInputCtlProps, CheckBoxInputCtlState> {
    constructor(props: CheckBoxInputCtlProps) {
        super(props);

        this.state = {
            checkVal: this.props.value
        }

    }

    componentDidUpdate(prevProps: CheckBoxInputCtlProps) {

        if (this.props.value !== prevProps.value) {
            this.setState({ checkVal: this.props.value });
        }

    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    checkValOnChange = (e: CheckboxChangeEvent) => {

        this.setState({checkVal: e.value}, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(e.value);
        })
    }


    render() {

        return (<Checkbox checked={this.state.checkVal} 
                    onChange={this.checkValOnChange} 
                    className="cbxInpCtlChkBox"
                    />
        );
    }
}

export default CheckBoxInputCtl;
