import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import DropDownListCtl from '../InputCtls/DropDownListCtl';
import { Splitter, SplitterPaneProps, SplitterOnChangeEvent } from '@progress/kendo-react-layout';
import { Stepper, StepperChangeEvent, StepProps, Step } from '@progress/kendo-react-layout';
import { Grid, GridColumn, GridNoRecords, GridSortChangeEvent, GridFilterChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { GridRowDoubleClickEvent } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Menu, MenuSelectEvent } from '@progress/kendo-react-layout';
import moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';
import SearchMain from '../../components/SearchMain';
import MilestoneLinesGrid from './MilestoneLinesGrid';
import MilestoneScanDialog from './MilestoneScanDialog';
import QtyDialog from '../Dialogs/QtyDialog';
import { UserInfo, MilestoneItem, StepperItem, DropDownListData, TcbFileItem, TcbObjInfo, MilestoneScanHistory, MilestoneScanLog, MenuItemModel } from '../../models/models';
import { loadingDiv } from '../../functions/componentFunctions';
import { addPageViewClass, uploadImage, linkFileToMilestoneScan, addDBEnvironment } from '../../functions/generalFunctions'
import { CustomWindow } from '../../models/custom.window'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { PageViewTypeEnum, SearchModeEnum, MilestoneScanStatusEnum, MilestoneScanDialogViewEnum, TcbFileLoadStatusEnum, InputCtlViewMode, InputCtlDdlDataSource } from '../../models/enums';

import '@progress/kendo-theme-default/dist/all.css'
import './MilestoneScan.css';

import warningSound from "../../audio/warning.mp3";
import dingSound from "../../audio/ding.mp3";
import bulkSound from "../../audio/bulk.mp3";

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

declare function invokeGetGpsLocationAction(callback: string, id: string): void;
declare function invokeOpenCameraAction(callback: string, id: string): void;
declare let window: CustomWindow;

//const ATTRIB_GPS_LOCATION = "gps location";
const StepperItem_Select = 0;
const StepperItem_Details = 1;
const StepperItem_Scan = 2;

type MilestoneScanProps = PropsFromRedux & {
    menuItemId: number;
    stateToLoad?: MilestoneScanState;
    tcbObsj?: TcbObjInfo[];
    onSaveState?: (menuItemId: number, itemState: any) => void;
    onSaveMenuItem?: (menuItemId: number, itemState: any, menuCustomTxt: string) => void;
    onViewItem?: (tcbObj: TcbObjInfo) => void;
}


interface MilestoneScanState {
    pageStyle: React.CSSProperties;
    searchInProg_MilestoneList: boolean;
    searchInProg_ScanHistory: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;
    panesListMain: Array<SplitterPaneProps>;
    stepperItems: Array<StepperItem>;
    stepperCurrentIndex: number;

    milestonesFetched: boolean;
    milestoneFullList: Array<MilestoneItem>;
    milestoneLookupList: Array<DropDownListData>;
    verifyEachScan: boolean;
    selectedMilestone?: MilestoneItem;

    scanInvalidShow: boolean;
    scanInvalidMessage: string;
    scanSearchResultsControlState?: any;

    scanHistoryList: Array<MilestoneScanHistory>;
    scanHistoryAddPhotoItem?: MilestoneScanHistory;
    scanHistorySortDescriptor: SortDescriptor[];
    scanHistoryFilterDescriptor: CompositeFilterDescriptor | undefined;

    searchSelectedObjs: TcbObjInfo[];
    searchMainOffsetHeight: number;

    qtyRequired: boolean;
    selectedTcbObj?: TcbObjInfo;
    clearSelection: boolean

}

class MilestoneScan extends React.Component<MilestoneScanProps, MilestoneScanState> {
    constructor(props: MilestoneScanProps) {
        super(props);

        //Make functions available to WebView
        window.milestoneScanRef = this;

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad }
        } else {

            let headerSize = (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) ? '40px' : '55px';

            let plMain: Array<SplitterPaneProps> = [
                { size: headerSize, resizable: false, collapsible: true },
                {},
                { size: '30%', min: '20px', resizable: true, collapsible: true, collapsed: true }
            ];


            this.state = {
                pageStyle: {},
                searchInProg_MilestoneList: false,
                searchInProg_ScanHistory: false,
                fetchError: false,
                fetchErrorMsg: '',
                panesListMain: plMain,
                stepperItems: this.getBaseStepperItems(),
                stepperCurrentIndex: 0,
                milestonesFetched: false,
                milestoneFullList: [],
                milestoneLookupList: [],
                verifyEachScan: true,
                scanInvalidShow: false,
                scanInvalidMessage: '',
                scanHistoryList: [],
                scanHistorySortDescriptor: [],
                scanHistoryFilterDescriptor: undefined,
                searchSelectedObjs: [],
                searchMainOffsetHeight: this.getDefaultOffsetHeight(),
                qtyRequired: false,
                clearSelection: false
            };
        }
    }

    getDefaultOffsetHeight = () => {
        return this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile ? 125 : 140;
    }

    componentDidUpdate(prevProps: MilestoneScanProps) {
    }

    componentDidMount() {
        if (!this.state.milestonesFetched) {
            this.getMilestoneList();
        }
    }

    componentWillUnmount() {
        if (this.props.onSaveState)
            this.props.onSaveState(this.props.menuItemId, this.state);
    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            let ps: React.CSSProperties = { ...this.state.pageStyle };
            if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
                ps.width = body.clientWidth;
            }
            this.setState({
                pageStyle: ps
            });
        }
    }


    getBaseStepperItems = () => {
        let sl = new Array<StepperItem>();

        sl.push({ index: StepperItem_Select, label: 'Select<br/>Milestone', icon: 'k-i-select-box', disabled: false, optional: false, current: true, visible: 'true', isValid: true, required: true });
        sl.push({ index: StepperItem_Details, label: 'Milestone<br/>Details', icon: 'k-i-catagorize', disabled: true, optional: false, current: false, visible: 'true', isValid: true, required: true });
        if (this.props.tcbObsj) {
            sl.push({ index: StepperItem_Scan, label: 'Confirm<br/>Selection', icon: 'k-i-search', disabled: true, optional: false, current: false, visible: 'true', isValid: true, required: true });
        } else {
            sl.push({ index: StepperItem_Scan, label: 'Scan Barcode<br/>/ Search', icon: 'k-i-search', disabled: true, optional: false, current: false, visible: 'true', isValid: true, required: true });
        }

        if (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile) {
            sl[StepperItem_Select].label = 'Select';
            sl[StepperItem_Details].label = 'Details';
            sl[StepperItem_Scan].label = 'Scan';
        }
        return sl;
    }

    getMilestoneList = () => {
        this.setState({ milestoneFullList: [], milestoneLookupList: [], searchInProg_MilestoneList: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/GetValidMilestones';

        if (this.props.tcbObsj) {
            url = this.props.userInf.currProject.apiUrl + '/api/details/GetValidMilestonesForTcbOjbs?TcbObjClass=' + this.props.tcbObsj[0].tcbObjClass + '&SecurityID=' + this.props.tcbObsj[0].tcbObjTypeId;
        }

        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        let fl: MilestoneItem[] = res.results;

                        this.setState({
                            milestoneFullList: fl,
                            milestoneLookupList: this.buildLookupList(fl),
                            milestonesFetched: true,
                            searchInProg_MilestoneList: false
                        });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg_MilestoneList: false });
                        break;

                    default:
                        this.setState({
                            searchInProg_MilestoneList: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage
                        });
                }
            })
            .catch(err => {
                this.setState({
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                });
            });
    }


    buildLookupList = (fl: MilestoneItem[]) => {
        let ll: DropDownListData[] = [];
        fl.forEach(x => {
            ll.push({ value: x.milestoneId.toString(), display: x.milestoneDesc });
        });
        return ll;
    }

    splitterChange = (e: SplitterOnChangeEvent) => {
        const bottomPanel = e.newState[e.newState.length - 1];
        const newOffsetHeight = this.calculateSearchMainOffsetHeight(bottomPanel);

        this.setState({
            panesListMain: e.newState,
            searchMainOffsetHeight: newOffsetHeight
        });
    }
    stepperChange = (e: StepperChangeEvent) => {

        let currIdx = e.value;
        let si = this.setCurrentStepperItem(e.value);
        this.setState({ stepperCurrentIndex: currIdx, stepperItems: si });
    }

    setCurrentStepperItem = (currIdx: number) => {

        let si = this.state.stepperItems;
        si.forEach((x) => { x.current = false });
        let itm = si.find(x => x.index === currIdx);
        if (itm) {
            itm.current = true;
        }
        return si;
    }

    selectMilestoneEvent = (e: DropDownListData) => {
        let ms = this.state.milestoneFullList.find(x => x.milestoneId.toString() === e.value)!;

        let sitems: StepperItem[] = [];
        let stepperCurrIdx = 0;
        if (ms.milestoneLines.some(x => ((this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile && x.isVisiblePDA) ||
            (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser && x.isVisibleWeb) ||
            (this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet && x.isVisibleWeb)))) {

            sitems = this.setCurrentStepperItem(StepperItem_Details)
            sitems[StepperItem_Details].disabled = false;
            sitems[StepperItem_Scan].disabled = !this.milestoneLineInputRequired(ms);
            stepperCurrIdx = StepperItem_Details;

        } else {
            sitems = this.setCurrentStepperItem(StepperItem_Scan)
            sitems[StepperItem_Details].disabled = false;
            sitems[StepperItem_Scan].disabled = false;
            stepperCurrIdx = StepperItem_Scan;
        }

        ms.scanDate = new Date();

        if (this.props.tcbObsj) {
            this.setState({
                stepperItems: sitems,
                stepperCurrentIndex: stepperCurrIdx,
                selectedMilestone: ms,
                verifyEachScan: ms.mustEditLines,
                searchSelectedObjs: this.props.tcbObsj,
            }, () => { if (this.props.onSaveMenuItem) this.props.onSaveMenuItem(this.props.menuItemId, this.state, ms.milestoneDesc) }
            );

            //this.scanTcbObjs(this.props.tcbObsj);

        } else {
            this.setState({
                stepperItems: sitems,
                stepperCurrentIndex: stepperCurrIdx,
                selectedMilestone: ms,
                verifyEachScan: ms.mustEditLines,
            }, () => { if (this.props.onSaveMenuItem) this.props.onSaveMenuItem(this.props.menuItemId, this.state, ms.milestoneDesc) }
            );
        }



    }


    updateSelectedMilestone = (ms: MilestoneItem) => {
//todo: this fires after viewing the scan dialog - we should NOT be updating the current milestone after this!
        let sitems = this.state.stepperItems;
        sitems[StepperItem_Scan].disabled = !this.canPerformNext();
        this.setState({ selectedMilestone: ms, stepperItems: sitems });
    }

    milestoneSearchNextClick = () => {
        this.scanTcbObjs(this.state.searchSelectedObjs);
    }

    canPerformNext = () => {
        switch (this.state.stepperCurrentIndex) {
            case 0:
                if (this.state.selectedMilestone)
                    return true;
                else
                    return false

            case 1:
                return this.milestoneLineInputRequired(this.state.selectedMilestone);

            case 2:
                if (this.state.searchSelectedObjs.length > 0) {
                    return true;
                }
                else
                    return false;
        }
    }

    milestoneLineInputRequired = (ml) => {
        let retVal = true;
        if (ml) {
            ml.milestoneLines.forEach(x => {
                if (x.responseRequired && !x.milestoneLineAnswer) {
                    retVal = false;
                }
            })
        } else {
            retVal = false;
        }
        return retVal;
    }

    searchSelectedRowsChanged = (e: TcbObjInfo[]) => {
        this.setState({ searchSelectedObjs: e });
    }


    scanSingle = (e: TcbObjInfo) => {
        let warning = new Audio(warningSound);
        let ding = new Audio(dingSound);
        let bulk = new Audio(bulkSound);

        let url = this.props.userInf.currProject.apiUrl + '/api/scan/Validate';
        let body = {
            tcbObjClass: e.tcbObjClass,
            tcbObjId: e.tcbObjId,
            milestoneId: this.state.selectedMilestone?.milestoneId
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        if (e.tcbObjSundry === true) {
                            bulk.play();
                            this.setState({ qtyRequired: true, selectedTcbObj: e });

                        } else {
                            ding.play();
                            let objs: TcbObjInfo[] = [];
                            objs.push(e);
                            this.scanTcbObjs(objs);
                        }


                        break;
                    case "INVALID":
                        warning.play();
                        this.setState({ scanInvalidShow: true, scanInvalidMessage: res.callStatusMessage });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:

                }
            });
    }

    scanTcbObjs = (objs: TcbObjInfo[]) => {
        let msh = new MilestoneScanHistory();
        msh.milestoneScanHistoryId = Math.max.apply(Math, [...this.state.scanHistoryList.map(x => { return x.milestoneScanHistoryId }), 0]) + 1;

        //make a deep copy here of selectedMilestone
        msh.milestone = structuredClone(this.state.selectedMilestone!);
        msh.tcbObjs = objs;
        msh.scanDialogView = MilestoneScanDialogViewEnum.None;
        msh.scanSubmitted = false;
        if (msh.milestone.scanDate)
            msh.scanDate = moment(msh.milestone.scanDate);
        else
            msh.scanDate = moment();
        //Sundry items start with no Qty
        if (msh.tcbObjs.filter(x => x.tcbObjSundry).filter(x => !x.tcbObjQty || x.tcbObjQty <= 0).length > 0) {
            msh.scanStatus = MilestoneScanStatusEnum.Verify;
            msh.scanDialogView = MilestoneScanDialogViewEnum.Edit;
        }
        else {
            msh.scanStatus = MilestoneScanStatusEnum.NotStarted;
        }

        let shl = this.state.scanHistoryList;

        shl.unshift(msh);

        //Add to Session List (so user can display Scan -> This Session)
        // let ssl = this.state.scanHistorySessionList;
        // ssl.unshift(msh);

        //Expand History Pane
        let pl = this.state.panesListMain;
        pl[2].collapsed = false;
        const newOffsetHeight = this.calculateSearchMainOffsetHeight(pl[2]);

        if (this.state.selectedMilestone?.mustEditLines && this.state.selectedMilestone?.milestoneLines.some(x => ((this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile && x.isVisiblePDA) ||
        (this.props.pageInf.pageViewMode === PageViewTypeEnum.Browser && x.isVisibleWeb) ||
        (this.props.pageInf.pageViewMode === PageViewTypeEnum.Tablet && x.isVisibleWeb))))
        {
            //Go back to Milestone Details Page
            let sitems = this.state.stepperItems;
            this.setCurrentStepperItem(StepperItem_Details);
            this.setState({ stepperItems: sitems, stepperCurrentIndex: StepperItem_Details });
        }

        this.setState({ clearSelection: !this.state.clearSelection, scanHistoryList: shl, panesListMain: pl, searchMainOffsetHeight: newOffsetHeight }, () => {
            //Kick off now if it's not started
            if (msh.scanStatus === MilestoneScanStatusEnum.NotStarted) {
                this.milestoneScan_Start(msh);
            }
        });


    }

    calculateSearchMainOffsetHeight = (bottomPanel: SplitterPaneProps) => {
        const defaultOffset = this.getDefaultOffsetHeight();
        const panelHeightPercentage = parseFloat(bottomPanel.size ?? '0') / 100.0;
        const newOffsetHeight = bottomPanel.collapsed ? defaultOffset : defaultOffset + (document.body.clientHeight - 80) * panelHeightPercentage;
        return newOffsetHeight;
    }

    scanHistoryRowDblClick = (e: GridRowDoubleClickEvent) => {
        let itm: MilestoneScanHistory = e.dataItem;
        switch (itm.scanStatus) {
            case MilestoneScanStatusEnum.Cancelled:
            case MilestoneScanStatusEnum.Error:
            case MilestoneScanStatusEnum.Complete:
                itm.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                itm.scanDialogOnCloseView = MilestoneScanDialogViewEnum.None;
                itm.scanDialogStatus = itm.scanStatus;
                this.milestoneScanHistoryUpdate(itm);
                break;
        }
    }

    scanHistoryContextMenuClick = (e: MenuSelectEvent, dataItem: MilestoneScanHistory) => {

if (e.itemId==="0") return;

        let shl = this.state.scanHistoryList;
        let msh = shl.find(x => x.milestoneScanHistoryId === dataItem.milestoneScanHistoryId)!;

        switch (e.item['action']) {
            case "AddPhoto":
                this.milestoneScanDialogOpenCamera(msh);
                break;
            case "DeleteScan":
                msh.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                msh.scanDialogStatus = MilestoneScanStatusEnum.DeleteConfirm;
                msh.scanDialogOnCloseView = MilestoneScanDialogViewEnum.None;
                break;
            case "Edit":
                msh.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                msh.scanDialogStatus = msh.scanStatus;
                msh.scanDialogOnCloseView = MilestoneScanDialogViewEnum.None;
                break;
            case "Resubmit":
                if (this.state.verifyEachScan) {
                    msh.scanDialogView = MilestoneScanDialogViewEnum.Edit;
                    msh.scanDialogStatus = MilestoneScanStatusEnum.Verify;
                    msh.scanDialogOnCloseView = MilestoneScanDialogViewEnum.None;
                } else {
                    msh.scanDialogView = MilestoneScanDialogViewEnum.None;
                    msh.scanStatus = MilestoneScanStatusEnum.NotStarted;
                    this.setState({ scanHistoryList: shl }, () => { this.milestoneScan_Start(msh); });
                    return;
                }
                break;

            case "ScanLog":
                msh.scanDialogView = MilestoneScanDialogViewEnum.ScanLog;
                msh.scanDialogOnCloseView = MilestoneScanDialogViewEnum.None;
                break;

            default:
                msh.scanDialogView = MilestoneScanDialogViewEnum.None;
        }
        this.setState({ scanHistoryList: shl });
    }

    milestoneScanDialogOpenCamera = (dataItem: MilestoneScanHistory) => {

        // this.loadCameraImage('', 'IMG_2022_07_20_19_30_00.png', 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=');
        // return;
        // this.milestoneScanSaveImage(dataItem.milestoneScanHistoryId, 'test.jpg', 'junk');
        if (process.env.REACT_APP_RunMode === "DEV") {
            this.milestoneScanSaveImage(dataItem.milestoneScanHistoryId.toString(), 'PhotoTest.png', 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=');
        } else {

            if (typeof invokeOpenCameraAction !== 'undefined') {
                invokeOpenCameraAction('window.milestoneScanRef.milestoneScanSaveImage', dataItem.milestoneScanHistoryId.toString());
            }
            else
                alert('invokeOpenCameraAction not defined');
        }
    }

    //Image upload Step 1 - Upload Image
    milestoneScanSaveImage = (shIdStr: string, fileName: string, imgBase64: string) => {

        try {
            // function is called from Mobile App, which sends parameter as string... convert
            let shId = parseInt(shIdStr);

            let shl = this.state.scanHistoryList;
            let msh = shl.find(x => x.milestoneScanHistoryId === shId)!;

            if (msh && msh.tcbObjs && msh.tcbObjs.length > 0) {

                let last = fileName.lastIndexOf(".");
                let ext = "";
                if (last > 0) {
                    ext = fileName.substring(last);
                }

                fileName = msh.tcbObjs[0].tcbObjDesc + "-" + moment().format("YYYYMMDDHHmm") + ext;
            }


            //Create a temporary Image item, with a loading status
            let tmpImg: TcbFileItem = new TcbFileItem();
            tmpImg.fileLoadStatus = TcbFileLoadStatusEnum.InProgress;
            tmpImg.fileName = fileName;
            tmpImg.fileDataStr = imgBase64;
            tmpImg.canDelete = true;
            tmpImg.canEdit = true;
            msh.scanImages.push(tmpImg);
            msh.scanStatus = MilestoneScanStatusEnum.LoadingImages;
            this.setState({ scanHistoryList: shl });
            // let siIndex = msh.scanImages.findIndex(x => x.fileName === fileName && x.fileLoadStatus === TcbFileLoadStatusEnum.InProgress);

            uploadImage(this.props.userInf, "Milestone Photo", fileName, imgBase64,
                (e: TcbFileItem) => {
                    linkFileToMilestoneScan(this.props.userInf, msh, e, "Milestone Photo",
                        (e) => {
                            e.scanStatus = MilestoneScanStatusEnum.Complete;
                            e.scanImages.forEach(x => { x.fileLoadStatus = TcbFileLoadStatusEnum.OK });
                            this.milestoneScanHistoryUpdate(e)
                        },
                        this.milestoneScanHistory_LinkImgUnAuth,
                        (e) => this.milestoneScanHistoryUpdate(e))
                },
                (u: UserInfo) => { this.milestoneScanHistory_UploadUnAuth(u, msh) },
                (e: string) => { this.milestoneScanHistory_UploadError(e, msh) });
        }
        catch (err) {
            alert(err);
        }
    }

    milestoneScanHistory_UploadUnAuth = (u: UserInfo, msh: MilestoneScanHistory) => {
        msh.scanStatus = MilestoneScanStatusEnum.Error;
        msh.scanLog.push(new MilestoneScanLog("Add Photo", "Unauthorised"));
        this.milestoneScanHistoryUpdate(msh);
        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
        this.props.updateUserInfo(uInf);
    }

    milestoneScanHistory_UploadError = (e: string, msh: MilestoneScanHistory) => {
        msh.scanStatus = MilestoneScanStatusEnum.Error;
        msh.scanLog.push(new MilestoneScanLog("Add Photo", "Error - " + e));
        this.milestoneScanHistoryUpdate(msh);
    }


    milestoneScanHistory_LinkImgUnAuth = (u: UserInfo, msh: MilestoneScanHistory) => {
        this.milestoneScanHistoryUpdate(msh);
        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
        this.props.updateUserInfo(uInf);
    }

    milestoneScanHistoryUpdate = (e: MilestoneScanHistory, callBack?: () => void) => {

        let shl = this.state.scanHistoryList.filter(x => x.milestoneScanHistoryId !== e.milestoneScanHistoryId);

            shl.unshift(e);
            this.setState({ scanHistoryList: shl }, callBack);

    }


    milestoneScanDialogUpdate = (msh: MilestoneScanHistory, action: string) => {
        switch (action) {
            case "S":
                this.milestoneScanHistoryUpdate(msh, () => this.milestoneScan_Start(msh));
                break;
            case "D":
                this.milestoneScanHistoryUpdate(msh, () => this.milestoneScan_DeleteScan(msh));
                break;
            case "U":
                this.milestoneScanHistoryUpdate(msh);
                break;
        }

    }


    //Milestone Scan Starts Here
    milestoneScan_Start = (msh: MilestoneScanHistory) => {
        msh.scanLog.push(new MilestoneScanLog("Load Scan", "Scan Started"));
        this.milestoneScan_FetchGPS(msh);
    }

    milestoneScan_FetchGPS = (msh: MilestoneScanHistory) => {
        // let msl_gps = msh.milestone.milestoneLines.find(x => x.isSystemData && x.attribClassCode.toLowerCase() === ATTRIB_GPS_LOCATION);
        // if (msl_gps) {
            if (typeof invokeGetGpsLocationAction !== 'undefined') {
                msh.scanStatus = MilestoneScanStatusEnum.FetchingGPS;
                msh.scanLog.push(new MilestoneScanLog("Fetch GPS", "GPS Fetch initiated"));
                this.milestoneScanHistoryUpdate(msh, () => { invokeGetGpsLocationAction('window.milestoneScanRef.milestoneScan_SetGpsLocation', msh.milestoneScanHistoryId.toString()); });
            }
            else {
                msh.scanLog.push(new MilestoneScanLog("Fetch GPS", "Operation unsupported"));
                this.milestoneScan_UploadScan(msh);
            }
        // } else {
        //     this.milestoneScan_UploadScan(msh);
        // }
    }

    milestoneScan_SetGpsLocation = (idStr: string, status: string, location: string) => {
        try {
            // function is called from Mobile App, which sends parameter as string... convert
            let id = parseInt(idStr);
            let shl = this.state.scanHistoryList;
            let msh = shl.find(x => x.milestoneScanHistoryId === id);
            if (msh) {
                if (status === "ok") {
                    msh.scanLog.push(new MilestoneScanLog("Fetch GPS", "Received " + location));
                    //msh.milestone.milestoneLines.filter(x => x.isSystemData && x.attribClassCode.toLowerCase() === ATTRIB_GPS_LOCATION).forEach(x => { x.milestoneLineAnswer = location; x.isSystemDataFetched = true });
                    msh.gpsCoordinates = location;
                } else {
                    msh.scanLog.push(new MilestoneScanLog("Fetch GPS", "Fetch Failed - " + location));
                    //msh.milestone.milestoneLines.filter(x => x.isSystemData && x.attribClassCode.toLowerCase() === ATTRIB_GPS_LOCATION).forEach(x => { x.milestoneLineAnswer = null });
                    msh.gpsCoordinates = null;
                }
                this.milestoneScan_UploadScan(msh);
            } else {
                alert('Error returning GPS data for scan Id : ' + id + ', location : ' + location);
            }
        }
        catch (err) {
            alert(err);
        }
    }

    milestoneScan_UploadScan = (msh: MilestoneScanHistory) => {

        msh.scanStatus = MilestoneScanStatusEnum.LoadingScan;
        msh.scanLog.push(new MilestoneScanLog("Load Scan", "Sending to server"));
        this.milestoneScanHistoryUpdate(msh);

        let url = this.props.userInf.currProject.apiUrl + '/api/scan/AddMilestoneScan';
        let body = {
            scanId: msh.milestoneScanHistoryId.toString(),
            milestone: msh.milestone,
            tcbObjs: msh.tcbObjs,
            gpsCoordinates: msh.gpsCoordinates
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        msh.tcbObjs = res.results;  //Results set contains ScanIds
                        msh.scanStatus = MilestoneScanStatusEnum.Complete;
                        msh.scanSubmitted = true;
                        msh.scanLog.push(new MilestoneScanLog("Load Scan", "Scan Completed"));
                        this.milestoneScanHistoryUpdate(msh);
                        break;
                    case "UNAUTH":
                        msh.scanStatus = MilestoneScanStatusEnum.Error;
                        msh.scanLog.push(new MilestoneScanLog("Load Scan", "Unauthorised"));
                        this.milestoneScanHistoryUpdate(msh);
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        break;

                    default:
                        msh.scanStatus = MilestoneScanStatusEnum.Error;
                        msh.scanLog.push(new MilestoneScanLog("Load Scan", "Unknown Return Status - " + res.callStatusMessage));
                        this.milestoneScanHistoryUpdate(msh);
                }
            })
            .catch(err => {
                msh.scanStatus = MilestoneScanStatusEnum.Error;
                msh.scanLog.push(new MilestoneScanLog("Load Scan", "Unknown Return Status - " + err.toString()));
                this.milestoneScanHistoryUpdate(msh);
            });
    }

    milestoneScan_DeleteScan = (msh: MilestoneScanHistory) => {

        if (!msh.scanSubmitted) {
            let shl = this.state.scanHistoryList.filter(x => x.milestoneScanHistoryId !== msh.milestoneScanHistoryId);
            this.setState({ scanHistoryList: shl });
        } else {

            if (msh.tcbObjs.length > 0) {
                let scanId = msh.tcbObjs[0].tcbMilestoneScanId;
                let scans: string[] = [];
                scans.push(scanId);
                let url = this.props.userInf.currProject.apiUrl + '/api/scan/DeleteMilestoneScan';
                let body = {
                    tcbMilestoneScanIds: scans
                }

                fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                    .then(resp => resp.json())
                    .then(res => {
                        switch (res.callStatus) {
                            case "OK":
                                //Remove from List
                                msh.scanLog.push(new MilestoneScanLog("Delete Scan", "Delete successful"));
                                msh.scanStatus = MilestoneScanStatusEnum.Cancelled;
                                this.milestoneScanHistoryUpdate(msh);
                                break;
                            case "UNAUTH":
                                msh.scanStatus = MilestoneScanStatusEnum.Error;
                                msh.scanLog.push(new MilestoneScanLog("Delete Scan", "Unauthorised"));
                                this.milestoneScanHistoryUpdate(msh);
                                let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                                this.props.updateUserInfo(uInf);
                                break;

                            default:
                                msh.scanStatus = MilestoneScanStatusEnum.Error;
                                msh.scanLog.push(new MilestoneScanLog("Delete Scan", "Delete Error - " + res.callStatusMessage));
                                this.milestoneScanHistoryUpdate(msh);
                        }
                    })
                    .catch(err => {
                        msh.scanStatus = MilestoneScanStatusEnum.Error;
                        msh.scanLog.push(new MilestoneScanLog("Delete Scan", "Delete Error - " + err.toString()));
                        this.milestoneScanHistoryUpdate(msh);
                    });
            }
        }
    }


    scanHistorySortChange = (e: GridSortChangeEvent) => {

        this.setState({
            scanHistorySortDescriptor: e.sort
        });

    }

    scanHistoryFilterChange = (event: GridFilterChangeEvent) => {

        this.setState({
            scanHistoryFilterDescriptor: event.filter,
        });
    }

    closeScanInvalidMsg = () => {
        this.setState({ scanInvalidShow: false, scanInvalidMessage: '' });
    }

    closeQtyDialog = (qty: number) => {
        if (this.state.selectedTcbObj) {
            let objs: TcbObjInfo[] = [];
            let thisTcbObj = this.state.selectedTcbObj;

            if (qty > 0 && thisTcbObj) {
                thisTcbObj.tcbObjQty = qty;

                objs.push(thisTcbObj);

                this.setState({ qtyRequired: false, selectedTcbObj: undefined })

                this.scanTcbObjs(objs);

            }
            else {
                this.setState({ qtyRequired: false, selectedTcbObj: undefined })
            }

        }

    }


    //Render Functions

    renderHeader = () => {
        return (
            <div id="mssHeaderMain" className={addPageViewClass(this.props.pageInf)}>

                <Stepper className={"mssStepperMain" + addPageViewClass(this.props.pageInf)}
                    linear={false}
                    items={this.state.stepperItems.filter(x => x.visible === 'true')}
                    item={this.renderStepperItem}
                    value={this.state.stepperCurrentIndex}
                    onChange={this.stepperChange} />
                {/* <div id="mssHeaderMilestone">
                    {this.state.selectedMilestone?.milestoneDesc}
                </div> */}
            </div>
        )

    }

    renderStepperItem = (e: StepProps) => {

        let classes = '';
        let isMobile = (this.props.pageInf.pageViewMode === PageViewTypeEnum.Mobile);

        if (e.current)
            classes += 'mssStepCurrent ';
        else
            classes += 'mssStepNotCurrent ';
        if (isMobile) {
            classes += 'mobile ';
        }

        if (e.disabled) {
            classes += 'mssStepDisabled ';
        } else {
            classes += 'mssStepEnabled ';
        }


        if ((e.index ?? 0) < this.state.stepperCurrentIndex) {
            classes += 'mssStepCompleted ';
        }

        return (<Step  {...e} >

            <div className={classes} dangerouslySetInnerHTML={{ __html: e.label ?? '' }}></div></Step>);
    }

    renderBody = () => {
        switch (this.state.stepperCurrentIndex) {
            case 0:
                return (<div className={"mssBodyMain" + addDBEnvironment(this.props.userInf)}>{this.renderMilestoneSelect()}</div>);

            case 1:
                return (<div className={"mssBodyMain" + addDBEnvironment(this.props.userInf)}>{this.renderMilestoneDetails()}</div>);

            case 2:
                if (this.props.tcbObsj) {
                    return (<div className={"mssBodyMain" + addDBEnvironment(this.props.userInf)}>{this.renderSearchResults()}</div>);
                } else {
                    return (<div className={"mssBodyMain" + addDBEnvironment(this.props.userInf)}>{this.renderSearch()}</div>);
                }


            default:
                return null;
        }
    }

    renderMilestoneSelect = () => {
        if (this.state.searchInProg_MilestoneList)
            return loadingDiv();
        else {
            let selMilestoneVal: string = '';
            if (this.state.selectedMilestone) {
                selMilestoneVal = this.state.selectedMilestone.milestoneId.toString();
            }
            return (<table id="mssSelectMilestoneTbl" className={addPageViewClass(this.props.pageInf)}>
                <thead>
                    <tr>
                        <th className=''>Select Milestone </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><DropDownListCtl
                        key="MSList"
                            mode={InputCtlViewMode.Select}
                            dataSource={InputCtlDdlDataSource.Data}
                            lookupData={this.state.milestoneLookupList}
                            selectedValue={selMilestoneVal}
                            onSelectChange={this.selectMilestoneEvent}
                            height='280px' /></td>
                    </tr>
                </tbody>
            </table>
            );
        }
    }

    renderMilestoneDetails = () => {
        if (this.state.selectedMilestone) {
            return (
                <table id="mssMilestoneDetails" >
                    <tbody>
                        <tr>
                            <td>
                                <MilestoneLinesGrid milestone={this.state.selectedMilestone} editable={true} onUpdate={this.updateSelectedMilestone} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }

    renderSearchHeader = () => {
        let retVal: JSX.Element[] = [];
        if (this.state.scanInvalidShow) {
            retVal.push(<tr className='invalid'><th>Scan Invalid</th><td><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.closeScanInvalidMsg()} /></td></tr>);
            retVal.push(<tr className='invalid'><td colSpan={2}>{this.state.scanInvalidMessage}</td></tr>);
        }

        return retVal;
    }

    renderSearch = () => {
        return (
            <>
                <div>
                    <table id="mssSearchTable" cellSpacing={0}>
                        <thead>
                            {this.renderSearchHeader()}
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <SearchMain
                                        menuItemId={this.props.menuItemId}
                                        stateToLoad={this.state.scanSearchResultsControlState}
                                        mode={SearchModeEnum.MilestoneScan}
                                        offsetHeight={this.state.searchMainOffsetHeight}
                                        milestoneId={this.state.selectedMilestone?.milestoneId}
                                        onOpenObjAction={this.props.onViewItem}
                                        onScanObjAction={this.scanSingle}
                                        onScanObjsAction={this.scanTcbObjs}
                                        clearSelection={this.state.clearSelection}
                                        onSaveState={(id: number, state: any) => {
                                            this.setState({ scanSearchResultsControlState: state });
                                            let newState: MilestoneScanState = { ...this.state, scanSearchResultsControlState: state };
                                            if (this.props.onSaveState) {
                                                this.props.onSaveState(this.props.menuItemId, newState);
                                            }
                                        }}
                                        onSelectedChanged={this.searchSelectedRowsChanged}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button className="mssNextBtn" onClick={() => this.milestoneSearchNextClick()} disabled={!this.canPerformNext()}>
                        Scan Selected Items
                    </Button>
                </div>
            </>
        );
    }

    renderSearchResults = () => {
        return (
            <>
                <div>
                    <table id="mssSearchTable" cellSpacing={0}>
                        <thead>
                            {this.renderSearchHeader()}
                        </thead>
                        <tbody>
                            <tr>
                                <td id="msSearchHeader" >
                                    The following will be updated:
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <br></br>
                                </td>
                            </tr>
                            {this.props.tcbObsj?.map((item) => (
                                <tr>
                                    <td align='center'>
                                        {item.tcbObjDesc}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <br></br>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button className="mssNextBtn" onClick={() => this.milestoneSearchNextClick()} disabled={!this.canPerformNext()}>
                        Scan Selected Items
                    </Button>
                </div>
            </>
        );

    }

    renderScanHistoryDialog = () => {

        if (this.state.scanHistoryList.some(x => x.scanDialogView === MilestoneScanDialogViewEnum.Edit ||
            x.scanDialogView === MilestoneScanDialogViewEnum.ScanLog ||
            x.scanDialogView === MilestoneScanDialogViewEnum.ImageView)) {
            //There's an item that requires a dialog
            let msh = this.state.scanHistoryList.find(x => x.scanDialogView === MilestoneScanDialogViewEnum.Edit ||
                x.scanDialogView === MilestoneScanDialogViewEnum.ScanLog ||
                x.scanDialogView === MilestoneScanDialogViewEnum.ImageView)!;
            return (<MilestoneScanDialog msh={msh}
                onUpdate={this.milestoneScanDialogUpdate}
            />)
        }
        else
            return;
    }




    renderScanHistory = () => {
        let filtList = this.state.scanHistoryList;
        if (this.state.scanHistoryFilterDescriptor)
            filtList = filterBy(this.state.scanHistoryList, this.state.scanHistoryFilterDescriptor);
        if (this.state.scanHistorySortDescriptor.length > 0)
            filtList = orderBy(filtList, this.state.scanHistorySortDescriptor);


        return (
            <div id="mssScanHistoryDiv">
                <div id="mssScanHistoryHdr">Scan History</div>
                <div id="mssScanHistoryBody">
                    <Grid className="mssScanHistoryGrid"
                        data={filtList}
                        resizable
                        sortable
                        scrollable="scrollable"
                        sort={this.state.scanHistorySortDescriptor}
                        onSortChange={this.scanHistorySortChange}
                        filter={this.state.scanHistoryFilterDescriptor}
                        onFilterChange={this.scanHistoryFilterChange}
                        rowRender={this.scanHistoryRowRender}
                        onRowDoubleClick={this.scanHistoryRowDblClick}
                    >
                        <GridNoRecords>
                            No scans performed
                        </GridNoRecords>
                        <GridColumn field="menu" title=" " resizable={false} width='20px' cell={this.renderScanHistoryContextMenuCell} />
                        <GridColumn field="milestone.milestoneDesc" title="Milestone" resizable={true} />
                        <GridColumn title="Item" cell={this.renderScanHistoryItemCell} />

                    </Grid>
                </div>
                {this.renderScanHistoryDialog()}
            </div>
        );
    }

    scanHistoryRowRender = (row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => {
        let cn = "";
        let dataItem: MilestoneScanHistory = rowProps.dataItem;
        switch (dataItem.scanStatus) {
            case MilestoneScanStatusEnum.Complete:
                cn = "good";
                break;
            case MilestoneScanStatusEnum.Error:
                cn = "error";
                break;
            case MilestoneScanStatusEnum.Cancelled:
                cn = "cancelled";
                break;
            case MilestoneScanStatusEnum.NotStarted:
                cn = "";
                break;
            default:
                cn = "inprogress";
        }

        if (cn !== "")
            return (<tr className={cn} {...rowProps}>{rowProps.children}</tr>);
        else
            return (<tr {...rowProps}>{rowProps.children}</tr>);
    }



    renderScanHistoryContextMenuCell = (cellProps: any) => {
        let retCell: JSX.Element;
        let dataItem: MilestoneScanHistory = cellProps.dataItem;

        let scanHistoryItemMenuItems: MenuItemModel[] = [];
        let dotMenu: MenuItemModel = { items: [], linkRender: () => { return <span id="contextMenuLinkSpan" className="k-icon k-font-icon k-i-more-vertical" /> } };
        scanHistoryItemMenuItems.push(dotMenu);
        switch (dataItem.scanStatus) {
            case MilestoneScanStatusEnum.Cancelled:
            case MilestoneScanStatusEnum.Error:
                dotMenu.items.push({ text: 'View Scan', action: 'Edit', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Delete', action: 'DeleteScan', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Re-Submit', action: 'Resubmit', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Scan Log', action: 'ScanLog', cssClass: 'menuItem', disabled: false, items: [] });
                break;
            case MilestoneScanStatusEnum.Complete:
                dotMenu.items.push({ text: 'View Scan', action: 'Edit', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Delete', action: 'DeleteScan', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Add Photo', action: 'AddPhoto', cssClass: 'menuItem', disabled: false, items: [] });
                dotMenu.items.push({ text: 'Scan Log', action: 'ScanLog', cssClass: 'menuItem', disabled: false, items: [] });
                break;
            default:
                dotMenu.items.push({ text: 'In Progess...', cssClass: 'menuItem', disabled: true, items: [] });
        }

        retCell = <td style={{ paddingLeft: 0 }}>
            <Menu className="cellMenu" items={scanHistoryItemMenuItems}
                openOnClick={true} vertical={true} style={{ width: '10px' }}
                onSelect={(e) => this.scanHistoryContextMenuClick(e, cellProps.dataItem)} />
        </td>
        return retCell;
    }

    renderScanHistoryItemCell = (cellProps: any) => {
        let retCell: JSX.Element;

        let objs: TcbObjInfo[] = cellProps.dataItem['tcbObjs'];

        if (objs.length === 1) {
            retCell = <td>{objs[0].tcbObjDesc}</td>
        } else {
            retCell = <td>{objs.length.toString() + ' ' + objs[0].tcbObjTypeDesc + ' items'}</td>
        }
        return retCell;
    }

    renderQtyDialog = () => {
        if (this.state.qtyRequired === true) {
            //There's an scan that requires a quantity

            return <QtyDialog headerText={this.state.selectedTcbObj?.tcbObjDesc} onClose={this.closeQtyDialog}></QtyDialog>


        }
        //else
        return;
    }



    render() {

        return (
            <div id="msSpDiv" style={this.state.pageStyle}>
                <Splitter className="msSplitterDiv" panes={this.state.panesListMain} style={{ height: '100%' }}
                    orientation={'vertical'}
                    onChange={this.splitterChange} >
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderScanHistory()}
                </Splitter>
                {this.renderQtyDialog()}
            </div>
        );
    }
}

export default connector(MilestoneScan);
