import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid, GridColumn, GridPageChangeEvent, GridColumnProps, GridSortChangeEvent, GridFilterChangeEvent, GridColumnMenuFilter, GridColumnMenuSort, GridRowProps, GridHeaderCellProps, GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid';
import { filterBy, CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Checkbox, CheckboxChangeEvent, NumericTextBox } from '@progress/kendo-react-inputs';
import { Menu, MenuSelectEvent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { Popup, Offset, PopupOpenEvent } from '@progress/kendo-react-popup';

import Login from '../login/Login';
import ErrorMsg from '../components/ErrorMsg';
import { UserInfo, SearchResultsCriteria, SearchPageColDef, TcbObjInfo, SearchItemsResults, SearchPackListsResults, MenuItemModel, ClientQueryItem } from '../models/models';
import { loadingDiv } from '../functions/componentFunctions';
import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';
import { SearchModeEnum, TcbObjClassEnum } from '../models/enums';
import { addPageViewClass } from '../functions/generalFunctions'

import '@progress/kendo-theme-default/dist/all.css'
import './SearchResults.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type SearchResultsProps = PropsFromRedux & {
    searchCriteria: SearchResultsCriteria;
    mode: SearchModeEnum;
    offsetHeight: number;
    stateToLoad?: SearchResultsState;
    onBackSelected: () => void;
    onSelectedChanged: (e: TcbObjInfo[]) => void;
    onOpenObjAction?: (e: TcbObjInfo) => void;
    onOpenObjsAction?: (e: TcbObjInfo[]) => void;
    onScanObjAction?: (e: TcbObjInfo) => void;
    onScanObjsAction?: (e: TcbObjInfo[]) => void;
    onSaveState: (state: SearchResultsState) => void;
    onOpenCqAction?: (cqId: number, tcbObjs: TcbObjInfo[]) => void;
    singleSelect?: boolean;
    clearSelection: boolean;
}

interface SearchResultsState {
    gridFilteredData: any[];
    gridFilteredDataView: any[];
    gridFilteredDataViewSkip: number;
    gridFilteredDataTotal: number;
    colDefs: JSX.Element[];
    colDefData: SearchPageColDef[];
    gridSortDescriptor: SortDescriptor[];
    gridFilterDescriptor: CompositeFilterDescriptor | undefined;
    searchInProg: boolean;
    searchSuccess: boolean;
    searchFailMessage: string;
    searchResults: any[];
    contextMenuShow: boolean;
    contextMenu: MenuItemModel[];
    contextItemMenu: MenuItemModel[];
    contextGroupMenu: MenuItemModel[];
    contextMenuDataFetching: boolean;
    gridStyle: React.CSSProperties;
    selectionCleared: boolean;
    errorMsg: string;
}


class SearchResults extends React.Component<SearchResultsProps, SearchResultsState> {
    constructor(props: SearchResultsProps) {
        super(props);

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad };
            this.fetchResults = false;
        } else {

            this.state = {
                gridFilteredData: [],
                gridFilteredDataView: [],
                gridFilteredDataViewSkip: 0,
                gridFilteredDataTotal: 0,
                colDefs: [],
                colDefData: [],
                gridSortDescriptor: [],
                gridFilterDescriptor: undefined,
                searchInProg: true,
                searchSuccess: true,
                searchFailMessage: '',
                searchResults: [],
                contextMenuShow: false,
                contextMenu: [],
                contextItemMenu: [],
                contextGroupMenu: [],
                contextMenuDataFetching: false,
                errorMsg: '',
                gridStyle: {},
                selectionCleared: false
            };
        }

    }

    private gridPageSize = 50;
    private fetchResults = true;

    private _contextMenuOffset: Offset = { top: 0, left: 0 };
    private _contextMenuBlurTimeoutRef: any;
    private _contextMenuRef: any;
    private _contextMenuCurrentItem?: TcbObjInfo;

    componentDidMount() {
        //Manually setting height so we can use virtual scrolling in the grid
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

        if (this.fetchResults)
            this.getSearchResults(this.props.userInf, this.props.searchCriteria);

    }

    componentDidUpdate(prevProps: SearchResultsProps) {
        if (prevProps.offsetHeight !== this.props.offsetHeight) {
            this.updateDimensions();
        }
        if (prevProps.clearSelection !== this.props.clearSelection) {
            this.gridClearSelection();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        let body = document.body;
        if (body) {
            var gs: React.CSSProperties = { ...this.state.gridStyle };
            gs.height = body.clientHeight - (100 + this.props.offsetHeight);
            this.setState({
                gridStyle: gs
            });
        }
    }

    getSearchResults = (uinfo: UserInfo, e: SearchResultsCriteria) => {

        let url: string = '';

        switch (e.objCode) {
            case "I":
                url = this.props.userInf.currProject.apiUrl + '/api/search/SearchItemsByType';
                break;
            case "P":
                url = this.props.userInf.currProject.apiUrl + '/api/search/SearchPackListsByType';
                break;
        }

        let body = {
            srchObjTypeId: e.objTypeId,
            srchStr: e.srchString,
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        this.setState({
                            searchInProg: false,
                            searchSuccess: true,
                            searchResults: res.results,
                            gridFilteredData: res.results,
                            gridFilteredDataView: res.results.slice(0, this.gridPageSize),
                            gridFilteredDataViewSkip: 0,
                            gridFilteredDataTotal: res.results.length,
                            colDefs: this.getColDefs(e.objCode).map((c: SearchPageColDef) => this.renderGridCol(c)),
                            gridSortDescriptor: [],
                            gridFilterDescriptor: undefined,
                            contextMenuDataFetching: true,
                        }, () => this.fetchContextMenuItems());
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({
                            searchInProg: false
                        });
                        break;

                    default:
                        this.setState({
                            searchInProg: false,
                            searchSuccess: false,
                            searchFailMessage: res.callStatusMessage,
                        });
                }
            })
            .catch(err => {
                this.setState({
                    searchInProg: false,
                    searchSuccess: false,
                    searchFailMessage: err.toString()
                });
            });


    }

    fetchContextMenuItems = () => {

        if (this.props.mode === SearchModeEnum.SelectItem) {
            let menuItms: MenuItemModel[] = [];
            menuItms.push({ text: 'Actions for : <<itemDesc>>', action: 'HeaderSingle', cssClass: 'menuTitle', disabled: true, items: [] });
            menuItms.push({ text: 'Select this Item', action: 'SelectItem', cssClass: 'menuItem', disabled: false, items: [] });
            menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });

            this.setState({
                contextItemMenu: menuItms,
                contextGroupMenu: [],
                contextMenuDataFetching: false
            }, () => this.props.onSaveState(this.state))


        } else if (this.props.mode === SearchModeEnum.MilestoneScan) {
            let menuItms: MenuItemModel[] = [];
            let menuItmsMulti: MenuItemModel[] = [];
            menuItms.push({ text: 'Actions for : <<itemDesc>>', action: 'HeaderSingle', cssClass: 'menuTitle', disabled: true, items: [] });
            menuItms.push({ text: 'Show Details', icon: "reload", action: 'ShowDetails', cssClass: 'menuItem', disabled: false, items: [] });
            menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });
            menuItms.push({ text: 'Scan this item', action: 'ScanAction', cssClass: 'menuItem', disabled: false, items: [] });
            menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });

            menuItmsMulti.push({ text: 'These actions will apply to ALL selected items', action: 'HeaderMulti', cssClass: 'menuTitle', disabled: true, items: [] });
            menuItmsMulti.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });
            menuItmsMulti.push({ text: 'Scan these items', action: 'ScanAction', cssClass: 'menuItem', disabled: false, items: [] });
            menuItmsMulti.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });

            this.setState({
                contextItemMenu: menuItms,
                contextGroupMenu: menuItmsMulti,
                contextMenuDataFetching: false
            }, () => this.props.onSaveState(this.state))


        } else {


            let url = "";
            switch (this.props.searchCriteria.objCode) {
                case TcbObjClassEnum.PackList:
                    url = this.props.userInf.currProject.apiUrl + '/api/search/FetchClientQueriesForPacklistType?packlistTypeid=' + this.props.searchCriteria.objTypeId.toString();
                    break;

                case TcbObjClassEnum.Item:
                case TcbObjClassEnum.PackListItem:
                    url = this.props.userInf.currProject.apiUrl + '/api/search/FetchClientQueriesForItemType?itemTypeId=' + this.props.searchCriteria.objTypeId.toString();;
                    break;
            }

            fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                .then(r => r.json())
                .then(res => {
                    switch (res.callStatus) {
                        case "OK":
                            let menuItms: MenuItemModel[] = [];
                            let menuItmsMulti: MenuItemModel[] = [];
                            menuItms.push({ text: 'Actions for : <<itemDesc>>', action: 'Message', cssClass: 'menuTitle', disabled: true, items: [] });
                            menuItms.push({ text: 'Show Details', icon: "reload", action: 'ShowDetails', cssClass: 'menuItem', disabled: false, items: [] });
                            menuItms.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });

                            menuItmsMulti.push({ text: 'These actions will apply to ALL selected items', action: 'Message', cssClass: 'menuTitle', disabled: true, items: [] });
                            menuItmsMulti.push({ text: '', cssClass: 'k-separator', disabled: true, items: [] });


                            if (res.results.length === 0) {
                                menuItms.push({ text: 'No actions for this item', action: 'Message', cssClass: 'menuMessage', disabled: true, items: [] });
                                menuItmsMulti.push({ text: 'No actions for these items', action: 'Message', cssClass: 'menuMessage', disabled: true, items: [] });
                            } else {
                                let cqItms = res.results as ClientQueryItem[];
                                cqItms.sort((a, b) => {
                                    if (a.sortOrder < b.sortOrder)
                                        return -1;
                                    else if (a.sortOrder > b.sortOrder)
                                        return 1;
                                    else
                                        return a.description.localeCompare(b.description);
                                });
                                cqItms.forEach((x: ClientQueryItem) => {
                                    let newMdl: MenuItemModel = new MenuItemModel();
                                    newMdl.text = x.description;
                                    newMdl.icon = "circle";
                                    newMdl.action = 'ClientQuery';
                                    newMdl.cssClass = 'menuItem';
                                    newMdl.disabled = false;
                                    newMdl.items = [];
                                    newMdl.data = x.id;
                                    menuItms.push(newMdl);
                                    menuItmsMulti.push(newMdl);
                                });
                            }

                            this.setState({
                                contextItemMenu: menuItms,
                                contextGroupMenu: menuItmsMulti,
                                contextMenuDataFetching: false
                            }, () => this.props.onSaveState(this.state))


                            break;
                        case "UNAUTH":
                            let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                            this.props.updateUserInfo(uInf);
                            break;

                        default:
                            throw (res.callStatusMessage)

                    }
                })
                .catch(err => {
                    alert('fetchContextMenuItemsForObjType : ' + err);
                });
        }

    }





    getColDefs = (e: string) => {
        var colDefs: SearchPageColDef[] = [];

        colDefs.push({ field: 'selected', width: 20, canFilter: false })

        colDefs.push({ field: 'displayText', width: undefined, canFilter: true })
        if (this.props.mode === SearchModeEnum.AddItem) {
            colDefs.push({ field: 'addQty', width: 80, canFilter: false, title: 'Qty' })
        }

        // if (e === 'I') {
        //     var cd: SearchPageColDef = { field: 'itemNo', title: 'Item No', width: undefined, canFilter: true };
        //     colDefs.push(cd);
        //     cd = { field: 'partNo', title: 'Part', width: 55, canFilter: false };
        //     colDefs.push(cd);
        //     cd = { field: 'itemDesc', title: 'Description', width: undefined, canFilter: true };
        //     colDefs.push(cd);
        // } else {
        //     var cd1: SearchPageColDef = { field: 'packListDesc', title: 'Description', width: undefined, canFilter: true };
        //     colDefs.push(cd1);
        // }

        return colDefs;
    }


    gridPageChange = (e: GridPageChangeEvent) => {
        console.log("skip:" + e.page.skip);
        this.setState({
            gridFilteredDataViewSkip: e.page.skip,
            gridFilteredDataView: this.state.gridFilteredData.slice(e.page.skip, e.page.skip + this.gridPageSize)
        });
    }

    gridSortChange = (e: GridSortChangeEvent) => {
        const tmpData = orderBy(this.state.gridFilteredData, e.sort);

        this.setState({
            gridFilteredData: tmpData,
            gridFilteredDataView: tmpData.slice(0, this.gridPageSize),
            gridFilteredDataViewSkip: 0,
            gridSortDescriptor: e.sort
        });

    }

    gridFilterChange = (event: GridFilterChangeEvent) => {

        const tmpData = orderBy(filterBy(this.state.searchResults, event.filter), this.state.gridSortDescriptor);
        this.setState({
            gridFilteredData: tmpData,
            gridFilteredDataView: tmpData.slice(0, this.gridPageSize),
            gridFilteredDataViewSkip: 0,
            gridFilteredDataTotal: tmpData.length,
            gridFilterDescriptor: event.filter,
        });
    }

    gridSelectAllChange = (e: CheckboxChangeEvent) => {
        let data = this.state.gridFilteredData;
        data.forEach(x => { x.selected = e.value });
        this.setState({ gridFilteredData: data }, () => { this.props.onSelectedChanged(this.getSelectedDataRows()) });
    }

    gridClearSelection = () => {
        let data = this.state.gridFilteredData;
        data.forEach(x => { x.selected = false });
        this.setState({ gridFilteredData: data }, () => { this.props.onSelectedChanged(this.getSelectedDataRows()) });
    }

    gridSelectChange = (e: any) => {
        let data = this.state.gridFilteredData;

        if (this.props.mode === SearchModeEnum.SelectItem) {
            //Only one selected at a tme
            data.forEach(x => { x.selected = false; });
        }

        if (e.itemId) {
            let itm = data.find((x) => x.itemId === e.itemId);
            if (itm) {
                itm.selected = !itm.selected;
            }
        }
        if (e.packListId) {
            let itm = data.find((x) => x.packListId === e.packListId);
            if (itm) {
                itm.selected = !itm.selected;
            }
        }

        this.setState({ gridFilteredData: data }, () => { this.props.onSelectedChanged(this.getSelectedDataRows()) });
    }

    addQtyChange = (value: any, dataItem: any) => {
        let data = this.state.gridFilteredData;

        if (dataItem.itemId) {
            let itm = data.find((x) => x.itemId === dataItem.itemId);
            itm.addQty = value;
        }
        if (dataItem.packListId) {
            let itm = data.find((x) => x.packListId === dataItem.packListId);
            itm.addQty = value;
        }

        this.setState({ gridFilteredData: data }, () => { this.props.onSelectedChanged(this.getSelectedDataRows()) });
    }

    getSelectedDataRows = () => {
        let sr: TcbObjInfo[] = [];
        this.state.gridFilteredData.forEach(x => {
            if (x.selected) {
                sr.push(this.buildTcbObj(x));
            }
        });
        return sr;
    }

    getSelectedRowCount = () => {
        return this.state.gridFilteredData.filter(x => x.selected).length;
    }

    gridRowClick = (e: GridRowClickEvent) => {
        let data = this.state.gridFilteredData;
        if (this.props.mode === SearchModeEnum.SelectItem) {
            //Only one selected at a tme
            data.forEach(x => { x.selected = false; });
        }
        if (e.dataItem.itemId) {
            let itm = data.find((x) => x.itemId === e.dataItem.itemId);
            if (itm) {
                itm.selected = !itm.selected;
            }
        }
        if (e.dataItem.packListId) {
            let itm = data.find((x) => x.packListId === e.dataItem.packListId);
            if (itm) {
                itm.selected = !itm.selected;
            }
        }

        this.setState({ gridFilteredData: data }, () => { this.props.onSelectedChanged(this.getSelectedDataRows()) });
    }

    // gridRowDblClick = (e: GridRowDoubleClickEvent) => {
    //     switch (this.props.mode) {
    //         case SearchModeEnum.ViewDetails:
    //             if (this.props.onOpenObjAction)
    //                 this.props.onOpenObjAction(this.buildTcbObj(e.dataItem));
    //             break;

    //         case SearchModeEnum.MilestoneScan:
    //             if (this.props.onScanObjAction)
    //                 this.props.onScanObjAction(this.buildTcbObj(e.dataItem));
    //             break;
    //     }
    // }

    contextMenuOpen = (e: PopupOpenEvent) => {
        this._contextMenuRef?.focus();
    }

    onFocusHandler = () => {
        clearTimeout(this._contextMenuBlurTimeoutRef);
        this._contextMenuBlurTimeoutRef = undefined;
    };

    onBlurTimeout = () => {
        this.setState({
            contextMenuShow: false,
        });

        this._contextMenuBlurTimeoutRef = undefined;
    };

    onBlurHandler = (event) => {
        clearTimeout(this._contextMenuBlurTimeoutRef);
        this._contextMenuBlurTimeoutRef = setTimeout(this.onBlurTimeout);
    };


    openContextMenuClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, dataItem: any) => {
        this._contextMenuOffset = { left: e.clientX, top: e.clientY };

        let selCount = this.getSelectedRowCount();
        if (selCount <= 1) {
            this._contextMenuCurrentItem = this.buildTcbObj(dataItem);
            this.setState({
                contextMenuShow: true,
                contextMenu: this.customiseContextHeader(this.state.contextItemMenu, dataItem.displayText),
            });

        } else {
            this.setState({
                contextMenuShow: true,
                contextMenu: this.state.contextGroupMenu,
            });
        }

    }

    customiseContextHeader = (menuItms: MenuItemModel[], desc: string) => {
        let ti = menuItms.find(x => x.action === "HeaderSingle");
        if (ti) {
            ti.text = ti.text = "Actions for : " + desc;
        }
        return menuItms;
    }


    contextMenuClick = (e: MenuSelectEvent) => {

        let dataItm = e.item as MenuItemModel;
        let sr = this.getSelectedDataRows();

        switch (dataItm.action) {
            case "ShowDetails":
                this.setState({
                    contextMenuShow: false,
                }, () => {
                    if (this._contextMenuCurrentItem && this.props.onOpenObjAction) {
                        this.props.onOpenObjAction(this._contextMenuCurrentItem);
                    }
                });
                break;
            case "ScanAction":
                this.setState({
                    contextMenuShow: false,
                }, () => {
                    if (sr.length > 1) {
                        if (this.props.onScanObjsAction) {
                            this.props.onScanObjsAction(sr);
                        }

                    } else {
                        if (this._contextMenuCurrentItem && this.props.onScanObjAction)
                            this.props.onScanObjAction(this._contextMenuCurrentItem);
                    }


                });
                break;
            case "SelectItem":
                this.setState({
                    contextMenuShow: false,
                }, () => {
                    if (this._contextMenuCurrentItem && this.props.onScanObjAction)
                        this.props.onScanObjAction(this._contextMenuCurrentItem);
                });
                break;

            case "ClientQuery":
                this.setState({
                    contextMenuShow: false,
                }, () => {
                    if (this.props.onOpenCqAction) {
                        let cqId = dataItm.data;
                        if (sr.length > 1) {
                            this.props.onOpenCqAction(cqId, sr);
                        } else if (this._contextMenuCurrentItem) {
                            let tcbObjs: TcbObjInfo[] = [];
                            tcbObjs.push(this._contextMenuCurrentItem);
                            this.props.onOpenCqAction(cqId, tcbObjs);
                        }
                    }
                });

                // alert('Run CQ id ' + e.item['data'] + ' for ' + this.state.popupDataItem?.tcbObj.tcbObjDesc);
                break;

            default:
                this.setState({
                    contextMenuShow: false,
                });
                break;

        }
    }


    buildTcbObj = (e: any) => {
        let retObj = new TcbObjInfo();
        if (e.packListItemId) {

        }
        if (e.itemId) {
            let itm: SearchItemsResults = e;
            retObj.tcbObjClass = TcbObjClassEnum.Item;
            retObj.tcbObjId = itm.itemId;
            retObj.tcbObjDesc = itm.displayText;
            retObj.tcbObjTypeId = itm.itemTypeId;
            retObj.tcbObjTypeDesc = itm.itemTypeDesc;
            retObj.tcbObjSundry = itm.isSundry;
            retObj.tcbObjQty = (itm.isSundry ? null : itm.qty);
            retObj.addQty = e.addQty;
        }
        if (e.packListId) {
            let itm: SearchPackListsResults = e;
            retObj.tcbObjClass = TcbObjClassEnum.PackList;
            retObj.tcbObjId = itm.packListId;
            retObj.tcbObjDesc = itm.packListDesc;
            retObj.tcbObjTypeId = itm.packListTypeId;
            retObj.tcbObjTypeDesc = itm.packListTypeDesc;
        }
        return retObj;
    }

    errorMsgClose = () => {

    }

    showDetails = (e: any) => {
        switch (this.props.mode) {
            case SearchModeEnum.ViewDetails:
                if (this.props.onOpenObjAction)
                    this.props.onOpenObjAction(this.buildTcbObj(e));
                break;

            case SearchModeEnum.MilestoneScan:
                if (this.props.onScanObjAction)
                    this.props.onScanObjAction(this.buildTcbObj(e));
                break;
        }
    }



    renderGridCol = (c: SearchPageColDef) => {

        let colProps: GridColumnProps = {};
        let cn = "srchGridHeader"
        colProps.field = c.field;
        colProps.title = c.title;
        // switch (c.field) {
        //     case "selected":
        //         colProps.width = "30px";
        //         break;
        //     case "displayText":
        //         cn += " displayText";
        //         colProps.width = c.width;
        //         break;
        //     default:
        //         colProps.width = c.width;
        //         break;
        // }
        colProps.filterable = c.canFilter;
        colProps.sortable = c.canFilter;

        if (c.canFilter)
            return (<GridColumn key={c.field} headerClassName={cn}
                columnMenu={props => <div><GridColumnMenuSort {...props} />
                    <GridColumnMenuFilter  {...props} />
                </div>}  {...colProps} ></GridColumn>);
        else if (c.field === "addQty") {
            return (<GridColumn width={c.width} editor="numeric" editable={true} key={c.field} headerClassName="srchGridHeader" {...colProps} ></GridColumn>);
        }
        else {
            return (<GridColumn key={c.field} headerClassName="srchGridHeader" {...colProps} ></GridColumn>);
        }
    }

    gridHeaderCellRender = (n: React.ReactNode, e: GridHeaderCellProps) => {
        switch (e.field) {
            case "displayText":
                let selCount = this.state.gridFilteredData.filter(x => x.selected === true).length;
                let filtered = (this.state.gridFilteredData.length === this.state.searchResults.length) ? '' : ' (filtered)';

                return (
                    <table className="displayTextHeaderTable" cellSpacing={0} cellPadding={0} width="100%">
                        <tbody>
                            <tr>
                                <td className="menuCell" ><span id="srchResultsBack" className="k-icon k-font-icon k-i-arrow-left" onClick={() => this.props.onBackSelected()} /></td>
                                <td className="detailsCell">{this.props.searchCriteria.objTypeDesc + ' [ ' + selCount + ' of ' + this.state.gridFilteredData.length + filtered + ' ]'}</td>
                            </tr>
                        </tbody>
                    </table>);
            case "selected":

                if (this.props.mode === SearchModeEnum.SelectItem)
                    return null;
                else
                    return <div><Checkbox id="srchResultsSelAll" onChange={this.gridSelectAllChange} /></div>
            case "addQty":
                return <label>{e.title}</label>
            default:
                return (e.title);
        }
    }

    gridRowRender = (row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactNode => {
        let cn = "";
        if (rowProps.dataItem.selected) {
            cn = "selected";
        } else if (rowProps.isAltRow) {
            cn = "altRow";
        }

        if (cn !== "")
            return (<tr className={cn} {...rowProps}>{rowProps.children}</tr>);
        else
            return (<tr {...rowProps}>{rowProps.children}</tr>);
    }

    gridCellRender = (cell: any, cellProps: GridCellProps) => {

        switch (cellProps.field) {
            case "selected":
                // if (cellProps.dataItem.selected)
                //     return (<td style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center", width:20 }}><span className="k-icon k-font-icon k-i-check" /></td>)
                // else
                //     return (<td style={{width:20}}></td>)

                return (<td style={{ width: 20 }} ><Checkbox value={cellProps.dataItem.selected} onChange={() => this.gridSelectChange(cellProps.dataItem)} /></td>);

            case "displayText":
                return (<td style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <table className="displayTextTable" cellSpacing={0} cellPadding={0} width="100%">
                        <tbody>
                            <tr>
                                <td className="menuCell" >
                                    <Button className={'contextBtn' + addPageViewClass(this.props.pageInf)} onClick={(e) => this.openContextMenuClick(e, cellProps.dataItem)}  ><span className="k-icon k-font-icon k-i-more-vertical" /></Button>
                                    {/* <Menu className="cellMenu" items={this.state.itemMenu} openOnClick={true} vertical={true} style={{ width: '10px' }} onSelect={(e) => this.contextMenuClick(e, cellProps.dataItem)} /> */}
                                </td>
                                <td style={{ borderLeftWidth: 0 }}><span onClick={() => this.showDetails(cellProps.dataItem)}>{cellProps.dataItem[cellProps.field]}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </td>);
            case "addQty":
                cellProps.dataItem.addQty = cellProps.dataItem.addQty ?? 1;
                return (<td><NumericTextBox
                    min={1}
                    width={80}
                    disabled={!cellProps.dataItem.isSundry}
                    value={cellProps.dataItem.addQty}
                    onChange={(e) => this.addQtyChange(e.value, cellProps.dataItem)}
                /></td>);
            default:
                return cell;
        }
    }

    // renderItemContextMenuLink = (props: any) => {
    //     return (<span id="contextMenuLinkSpan" className="k-icon k-i-more-vertical" />);
    // }

    renderContextPopup = () => {

        let selCount = this.getSelectedRowCount();
        if (this.state.contextMenuDataFetching) {
            return (<div id="srContextMenuLoading" ><span className="k-icon k-font-icon k-i-loading" /></div>);
        } else if (selCount <= 1) {
            return (<Menu className="cellMenu" items={this.state.contextItemMenu} openOnClick={true} vertical={true}
                onSelect={(e) => this.contextMenuClick(e)} />);
        } else {
            return (<Menu className="cellMenu" items={this.state.contextGroupMenu} openOnClick={true} vertical={true}
                onSelect={(e) => this.contextMenuClick(e)} />);
        }
    }


    render() {
        console.log("render");
        if (!this.props.userInf.isAuthorised) {
            return (<Login />)
        }
        if (this.state.errorMsg.length > 0) {
            return (<ErrorMsg message={this.state.errorMsg} onClose={this.errorMsgClose} />);
        }

        if (this.state.searchInProg) {
            return loadingDiv();
        } else {

            let selectField = "";
            if (this.props.mode === SearchModeEnum.MilestoneScan) {
                selectField = "selected";
            }
            return (
                <div>
                    <Grid
                        className="srchResultsGrid"
                        style={this.state.gridStyle}
                        data={this.state.gridFilteredDataView}
                        rowHeight={25}
                        pageSize={this.gridPageSize}
                        total={this.state.gridFilteredDataTotal}
                        skip={this.state.gridFilteredDataViewSkip}
                        scrollable={'virtual'}
                        onPageChange={this.gridPageChange}
                        resizable
                        sortable
                        sort={this.state.gridSortDescriptor}
                        onSortChange={this.gridSortChange}
                        filter={this.state.gridFilterDescriptor}
                        onFilterChange={this.gridFilterChange}
                        selectedField={selectField}
                        onRowClick={this.gridRowClick}
                        // onRowDoubleClick={this.gridRowDblClick}
                        headerCellRender={this.gridHeaderCellRender}
                        rowRender={this.gridRowRender}
                        cellRender={this.gridCellRender}
                    >
                        {this.state.colDefs}
                    </Grid>
                    <Popup id="contextPopup"
                        offset={this._contextMenuOffset}
                        show={this.state.contextMenuShow}
                        onOpen={this.contextMenuOpen}
                    >
                        <div
                            ref={(el) => (this._contextMenuRef = el)}
                            onFocus={this.onFocusHandler}
                            onBlur={this.onBlurHandler}
                            tabIndex={-1}>
                            {this.renderContextPopup()}
                        </div>
                    </Popup>
                </div>
            );
        }
    }
}

export default connector(SearchResults);
