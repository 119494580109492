import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar  } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { addPageViewClass } from '../../functions/generalFunctions'
import './MemberAddByScanDlg.css';
import { MemberAddByScanModeEnum, MemberAddScanStatusEnum, TcbObjClassEnum } from '../../models/enums';
import { TcbObjInfo, TcbObjMember, MemberAddScanHistory, AddScanItemToPackListResult } from '../../models/models';
import { CustomWindow } from '../../models/custom.window'
import tcbLogoSml from '../../images/Tcb-50.gif';
declare let window: CustomWindow;

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type MemberAddByScanDlgProps = PropsFromRedux & {
    tcbObj: TcbObjInfo;
    onClose: () => void;
    onAdd?: (e: TcbObjMember) => void;
    onDel?: (e: TcbObjMember) => void;
}

interface MemberAddByScanDlgState {
    scanMode: MemberAddByScanModeEnum;
    scanHistory: MemberAddScanHistory[];
}

declare function invokeEnableZebraScannerAction(callBackFunc: string, callBackId: string): void;
declare function invokeDisableZebraScannerAction(): void;
declare function invokeOpenCameraScannerAction(callBackFunc: string, callBackId: string): void;

class MemberAddByScanDlg extends React.Component<MemberAddByScanDlgProps, MemberAddByScanDlgState> {
    constructor(props: MemberAddByScanDlgProps) {
        super(props);

        window.memberAddRef = this;

        this.state = {
            scanMode: MemberAddByScanModeEnum.Add,
            scanHistory: []
        }
    }

    private historyId: number = 0;

    componentDidMount() {
        if (typeof invokeEnableZebraScannerAction !== 'undefined') {
            invokeEnableZebraScannerAction('window.memberAddRef.processScan', '0');
            // else
            // invokeEnableZebraScannerAction('window.srchMainRef.processScan', '0');
        }

    }

    componentWillUnmount() {
        if (typeof invokeDisableZebraScannerAction !== 'undefined') {
            invokeDisableZebraScannerAction();
        }
    }

    dlgActionClose = () => {
        this.props.onClose();
    }

    modeChange = (e: SwitchChangeEvent) => {
        if (this.state.scanMode === MemberAddByScanModeEnum.Add)
            this.setState({ scanMode: MemberAddByScanModeEnum.Remove })
        else
            this.setState({ scanMode: MemberAddByScanModeEnum.Add })
    }


    cameraScanClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (process.env.REACT_APP_RunMode === "DEV") {
            this.processScan('0', 'AS|31188|20220715141034|');
        } else {

            if (typeof invokeOpenCameraScannerAction !== 'undefined') {
                invokeOpenCameraScannerAction('window.memberAddRef.processScan', '0');
            }
        }
    }


    processScan = (callBackId: string, scannedCode: string) => {

        let sh = this.state.scanHistory;
        var scanRec = new MemberAddScanHistory();
        scanRec.historyId = this.historyId++;

        if (scannedCode.length === 0) {
            scanRec.addStatus = MemberAddScanStatusEnum.AddFail;
            scanRec.historyText = "No scanned code received";
            sh.push(scanRec);
            this.setState({
                scanHistory: sh
            });
            return;
        }

        scanRec.addStatus = MemberAddScanStatusEnum.SearchingBarcode;
        scanRec.historyText = "Searching for barcode : " + scannedCode;
        sh.push(scanRec);
        this.setState({ scanHistory: sh });


        //Search barcode table for match
        let url = this.props.userInf.currProject.apiUrl + '/api/search/SearchByBarcode';
        let body = {
            barcodeStr: scannedCode
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":

                        let srchItm: TcbObjInfo = res.result;
                        scanRec.tcbObj = srchItm;
                        scanRec.addStatus = MemberAddScanStatusEnum.AddingMember;
                        scanRec.historyText = "Adding Member : " + srchItm.tcbObjDesc;
                        this.setState({ scanHistory: this.state.scanHistory }, () => this.addMember(scanRec, srchItm));
                        break;

                    default:
                        scanRec.addStatus = MemberAddScanStatusEnum.AddFail;
                        scanRec.historyText = res.callStatusMessage;
                        this.setState({ scanHistory: this.state.scanHistory });
                }
            })
            .catch(err => {
                scanRec.addStatus = MemberAddScanStatusEnum.AddFail;
                scanRec.historyText = err;                   
                this.setState({ scanHistory: this.state.scanHistory });
            });
    }

    getTcbObjFromScanCode = (scannedCode: string) => {
        let sdt = new TcbObjInfo();

        if (scannedCode.startsWith('AS|') || scannedCode.startsWith('IT|')) {
            sdt.tcbObjClass = TcbObjClassEnum.Item;
        }
        if (scannedCode.startsWith('PL|')) {
            sdt.tcbObjClass = TcbObjClassEnum.PackList;
        }

        let id = parseInt(scannedCode.substring(3, scannedCode.indexOf('|', 3)));
        if (!isNaN(id))
            sdt.tcbObjId = id;
        return sdt;
    }

    addMember = (sr: MemberAddScanHistory, e: TcbObjInfo) => {

        let addItems = [{ itemId: e.tcbObjId, changeQty: 1 }];
        if (this.state.scanMode === MemberAddByScanModeEnum.Remove) addItems[0].changeQty = -1;

        let url = this.props.userInf.currProject.apiUrl + '/api/details/AddScanMbrToTcbObj';
        let body = {
            tcbObjClass: this.props.tcbObj.tcbObjClass,
            tcbObjId: this.props.tcbObj.tcbObjId,
            addItems: addItems
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                let csr = this.state.scanHistory.find(x => x.historyId === sr.historyId);
                if (csr) {
                    switch (res.callStatus) {
                        case "OK":
                            let rs = res.result as AddScanItemToPackListResult;
                            if (rs.retStatus.toLowerCase() === "ok") {
                                csr.addStatus = MemberAddScanStatusEnum.AddSuccess;
                                csr.historyText = rs.retStatusMsg;
                            } else {
                                csr.addStatus = MemberAddScanStatusEnum.AddFail;
                                csr.historyText = rs.retStatusMsg;
                            }
                            this.setState({ scanHistory: this.state.scanHistory });
                            break;

                        default:
                            csr.addStatus = MemberAddScanStatusEnum.AddFail;
                            csr.historyText = res.callStatusMessage;
                            this.setState({ scanHistory: this.state.scanHistory });
                    }
                }
            })
            .catch(err => {
                sr.addStatus = MemberAddScanStatusEnum.AddFail;
                sr.historyText = err;
                this.setState({ scanHistory: this.state.scanHistory });
            });
    }

    getHistRowClassName = (e: MemberAddScanStatusEnum) => {
        switch (e) {
            case MemberAddScanStatusEnum.AddFail:
                return ("histRwStatusFail");
            case MemberAddScanStatusEnum.AddSuccess:
                return ("histRwStatusOk");
            case MemberAddScanStatusEnum.AddingMember:
            case MemberAddScanStatusEnum.SearchingBarcode:
                return ("histRwStatusInProg");
            default:
                return ("");
        }
    }





    renderTitle = () => {
        let titleDesc = "Add Member";
        if (this.state.scanMode === MemberAddByScanModeEnum.Remove) titleDesc = "Remove Member"
        return (
            <table id="dlgTitleStd" className={addPageViewClass(this.props.pageInf)}>
                <thead>
                    <tr>
                        <td className='left'><img style={{ height: "42px" }} src={tcbLogoSml} alt="TracBASE" /> </td>
                        <th>{titleDesc}</th>
                        <td className='right'><span className="k-icon k-font-icon k-i-close-outline" onClick={() => this.dlgActionClose()}></span></td>
                    </tr>
                </thead>
            </table>)
    }

    renderBody = () => {
        let titleDesc = "Scan to Add Members to ";
        if (this.state.scanMode === MemberAddByScanModeEnum.Remove)
            titleDesc = "Scan to Remove Members from ";
        return (
            <table id='mbrAddScanBody'>
                <thead>
                    <tr>
                        <td><Switch className='mbrAddScanSwitch' onLabel={'Add'} offLabel='Remove' onChange={this.modeChange} checked={(this.state.scanMode === MemberAddByScanModeEnum.Add)} /></td>
                        <td>&nbsp;</td>
                        <td className='alignRight'><span className='k-icon k-font-icon k-i-photo-camera' onClick={this.cameraScanClick} /></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <p>{titleDesc}<b>{this.props.tcbObj.tcbObjDesc}</b></p>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>
                            <table id='mbrAddScanHist'>
                                <thead>
                                    <tr className='histTblHdr hdr'>
                                        <th colSpan={3}>Change Log</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.scanHistory.map(x => {
                                        return (<tr className={this.getHistRowClassName(x.addStatus)}>
                                            <td className='histTblStatus'>{this.renderStatusIndicaor(x.addStatus)}</td>
                                            <td className='histTblText'>{x.historyText}</td>
                                        </tr>);
                                    })}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>)
    }

    renderStatusIndicaor = (e: MemberAddScanStatusEnum) => {
        switch (e) {
            case MemberAddScanStatusEnum.AddFail:
                return (<span className='k-icon k-font-icon k-i-warning' />);
            case MemberAddScanStatusEnum.AddSuccess:
                return (<span className='k-icon k-font-icon k-i-check-outline' />);
            case MemberAddScanStatusEnum.AddingMember:
                return (<span className='k-icon k-font-icon k-i-loading' />);
            case MemberAddScanStatusEnum.SearchingBarcode:
                return (<span className='k-icon k-font-icon k-i-loading' />);

            default:
                return (<span className='k-icon k-font-icon k-i-warning' />);

        }
    }


    render() {
        return (
            <Dialog data-component="memberAddRef" className={"MbrAddScanDlg" + addPageViewClass(this.props.pageInf)} title={this.renderTitle()} closeIcon={false}>
                {this.renderBody()}
                <DialogActionsBar>
                    <Button onClick={e => this.dlgActionClose()}>Close</Button>
                </DialogActionsBar>
            </Dialog>
        )
    }
}

export default connector(MemberAddByScanDlg);
