import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import { UserInfo, AvailableLanguageItem } from '../models/models';
import Login from '../login/Login';
import { CustomWindow } from '../models/custom.window'

import { mapStateToProps, mapDispatchToProps } from '../redux/reduxActions';


import './Settings.css';
import { PageViewTypeEnum } from '../models/enums';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

declare function invokeOpenSettingsAction(): void;
declare function invokeGetGpsTestAction(callback: string): void;
declare let window: CustomWindow;

// type SettingsProps = PropsFromRedux & {
//     userInfo: UserInfo;
//     onChange: (p: UserInfo) => void;
// }
type SettingsProps = PropsFromRedux;

interface SettingsState {
    isAuthorised: boolean;
    availLangList: Array<AvailableLanguageItem>;
    langLoading: boolean;
    langDataFetched: boolean;
    showGpsTest: boolean;
    Gps1Str: string;
    Gps2Str: string;
}

class Settings extends React.Component<SettingsProps, SettingsState> {

    constructor(props: SettingsProps) {
        super(props);

        window.settingsRef = this;

        this.state = {
            isAuthorised: true,
            availLangList: [],
            langLoading: false,
            langDataFetched: false,
            showGpsTest: false,
            Gps1Str: '',
            Gps2Str: ''
        }
    }

    componentDidMount() {
        if (!this.state.langDataFetched) {
            this.getAvailableLanguageList();
        }
    }

    getAvailableLanguageList = () => {

        this.setState({ langLoading: true, langDataFetched: false });

        let url = this.props.userInf.currProject.apiUrl + '/api/GetAvailableLanguageLookups';

        fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        this.setState({
                            availLangList: res.results,
                            langLoading: false,
                            langDataFetched: true,
                        });
                        break;
                    case "UNAUTH":
                        this.setState({
                            langLoading: false,
                            isAuthorised: false
                        });
                        break;

                    default:
                        this.setState({
                            langLoading: false,
                        });
                }
            })
            .catch(err => {
                this.setState({
                    langLoading: false,
                });
            });
    }

    selectedLanguageChange = (e: DropDownListChangeEvent) => {
        let uinf: UserInfo = {
            ...this.props.userInf,
            locale: e.value.locale
        };
        // uinf.locale = e.value.locale;

        let url = uinf.currProject.apiUrl + '/api/GetLanguageLookups';
        let body = {
            locale: uinf.locale
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(r => r.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        uinf.langlookups = res.results;
                        this.props.updateUserInfo(uinf);
                        break;
                    case "UNAUTH":
                        this.setState({
                            isAuthorised: false
                        });
                        break;
                }
            })
            .catch(err => {
            });
    }

    openMobileSettings = () => {
        if (typeof invokeOpenSettingsAction !== 'undefined') {
            invokeOpenSettingsAction();
        }

    }

    startGpsTest = () => {

        this.setState({showGpsTest: true});
        if (typeof invokeGetGpsTestAction !== 'undefined') {
            invokeGetGpsTestAction('window.settingsRef.settings_SetGpsLocation');
        }

        
    }

    settings_SetGpsLocation = (gps1Str: string, gps2Str: string) => {
        this.setState({Gps1Str: gps1Str, Gps2Str: gps2Str});
    }

    loginSuccess = (userInf: UserInfo) => {
        this.setState({ isAuthorised: true });
        this.props.updateUserInfo(userInf);
        this.getAvailableLanguageList();
    }

    renderGpsTable = () => {
        return (
            <table className='settingsGpsTbl'>
                <tbody>
                    <tr>
                        <th>Curr Loc</th>
                        <td>{this.state.Gps1Str}</td>
                    </tr>
                    <tr>
                        <th>Last Known</th>
                        <td>{this.state.Gps2Str}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render() {

        let defLocVal = this.state.availLangList.find(x => x.locale === this.props.userInf.locale);
        return (
            <div className="settingsOuterDiv">
                <table className="settingsTable">
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <table className='projDetails'>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>Project Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Name:</th>
                                            <td>{this.props.userInf.currProject.projectName}</td>
                                        </tr>
                                        <tr>
                                            <th>Desc:</th>
                                            <td>{this.props.userInf.currProject.projectDesc}</td>
                                        </tr>
                                        <tr>
                                            <th>API:</th>
                                            <td>{this.props.userInf.currProject.apiUrl}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th>Language: </th>
                            <td><DropDownList data={this.state.availLangList} dataItemKey="locale" textField="description" value={defLocVal} loading={this.state.langLoading} onChange={this.selectedLanguageChange} /></td>
                        </tr>
                    </tbody>
                </table>
                <br /><br /><br />
                {this.props.pageInf.pageViewMode !== PageViewTypeEnum.Browser &&
                    <table className="mobileSettingsTable">
                        <tbody>
                            <tr>
                                <td><Button icon="gear" onClick={() => this.openMobileSettings()} >Open Mobile Settings</Button></td>
                            </tr>
                            <tr>
                                <td><Button icon="marker" onClick={() => this.startGpsTest()} >GPS Test</Button></td>
                            </tr>
                        </tbody>
                    </table>
                }
                {this.state.showGpsTest && this.renderGpsTable() }

                {!this.state.isAuthorised && <Login onSuccess={this.loginSuccess} />}
            </div>);
    }
}



export default connector(Settings);
