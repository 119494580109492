import React from 'react';
import { Input } from '@progress/kendo-react-inputs'
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { InputCtlViewMode } from '../../models/enums';
import checkImg from '../../images/Check-2-64.png';
import crossImg from '../../images/Cross-2-64.png';

import './TextInputCtl.css';

interface TextInputCtlProps {
    value?: string | null;
    mode?: InputCtlViewMode;
    style?: React.CSSProperties;
    placeholder?: string;
    onUpdate?: (e: string, setViewMode?:(newMode:InputCtlViewMode)=>void) => void;
    onFocus?: (e:TextInputCtl) => void;
}


interface TextInputCtlState {
    originalStr: string;
    currentStr: string;

    mode: InputCtlViewMode;
}


class TextInputCtl extends React.Component<TextInputCtlProps, TextInputCtlState> {
    constructor(props: TextInputCtlProps) {
        super(props);

        this.state = {
            originalStr: this.props.value ?? '',
            currentStr: this.props.value ?? '',
            mode: this.props.mode ?? InputCtlViewMode.ReadOnly
        }

    }

    componentDidUpdate(prevProps: TextInputCtlProps) {

        if (this.props.value !== prevProps.value){
            this.setState ({
                originalStr: this.props.value ?? '',
                currentStr: this.props.value ?? '',
                //mode: this.props.mode ?? InputCtlViewMode.ReadOnly
            });
        }

        if(this.props.mode !== prevProps.mode){
                  this.setState({mode: this.props.mode ?? InputCtlViewMode.ReadOnly});  
        }


    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onFocus = () => {
            if (this.props.onFocus)
                this.props.onFocus(this);
    }

    inputTxtOnChange = (e: InputChangeEvent) => {
        this.setState({ currentStr: e.value });
    }

    inputTxtChangeToEdit = () => {
        this.setState({ mode: InputCtlViewMode.Edit, originalStr: this.state.currentStr });
        this.onFocus()
    }

    inputTxtCancelEdit = () => {
        this.setState({ mode: InputCtlViewMode.View, currentStr: this.state.originalStr });
    }

    inputTxtSaveChange = () => {
        this.setState({ mode: InputCtlViewMode.Saving }, () => {
            if (this.props.onUpdate)
                this.props.onUpdate(this.state.currentStr, this.setViewMode);
        });
    }

    setValue = (e: string) =>{
        this.setState({originalStr: e, currentStr:e,mode:InputCtlViewMode.View});
        this.inputTxtSaveChange();
    }

    setViewMode = (e:InputCtlViewMode) => {
        this.setState({mode:e});
    }

    render() {

        switch (this.state.mode) {

            case InputCtlViewMode.ReadOnly:
                return (<Input className='txtInpCtlInput' readOnly={true} value={this.state.currentStr} style={this.props.style} />);
            
            case InputCtlViewMode.View:
                return (<Input className='txtInpCtlInput' readOnly={true} autoFocus value={this.state.currentStr} style={this.props.style} onClick={() => this.inputTxtChangeToEdit()} placeholder={this.props.placeholder} />);

            case InputCtlViewMode.Edit:
            case InputCtlViewMode.Saving:
                return (<div className='txtInputCtlDiv' >
                    <Input className='txtInpCtlInput edit' 
                        autoFocus value={this.state.currentStr} 
                        style={this.props.style} 
                        onChange={this.inputTxtOnChange} 
                        placeholder={this.props.placeholder} />
                    <img className='check' hidden={this.state.mode !== InputCtlViewMode.Edit} src={checkImg} alt="Save" title='Save' onClick={this.inputTxtSaveChange} />
                    <img className='cross' hidden={this.state.mode !== InputCtlViewMode.Edit} src={crossImg} alt="Cancel" title='Cancel' onClick={this.inputTxtCancelEdit} />
                    <div className='saving' hidden={this.state.mode !== InputCtlViewMode.Saving}><span className="k-icon k-font-icon k-i-loading" /></div>
                </div>);
        }

    }
}

export default TextInputCtl;
